import { MembershipsTableColumns } from './MembershipsTableColumns'
import useMembershipsPageContext from 'hooks/useMembershipsPageContext'
import { Table, Row, Col, Empty, Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { SortDirection } from 'interfaces/shared'
import mixpanelService from 'services/mixpanel'
import { useEffect } from 'react'

const MembershipsTable = () => {
  const { t } = useTranslation()
  const {
    memberships,
    onOpenCreateMembershipForm,
    onOpenEditMembershipForm,
    setPriceSort,
    priceSort,
    onDeleteMembership,
  } = useMembershipsPageContext()

  const onPriceSortChange = (key: string, direction: SortDirection) => {
    setPriceSort(direction)
  }

  useEffect(() => {
    mixpanelService.track.userEnterMembershipsPage()
  }, [])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="end">
        <Col>
          <Button onClick={onOpenCreateMembershipForm} type="primary">
            {t('memberships.new-button-label')}
          </Button>
        </Col>
      </Row>
      <Row gutter={[0, 32]} justify="center">
        <Col md={24}>
          <Table
            dataSource={memberships}
            columns={MembershipsTableColumns({
              onDeleteMembership,
              onOpenEditMembershipForm: onOpenEditMembershipForm,
              priceSort,
              onPriceSortChange,
            })}
            scroll={{
              x: true,
            }}
            locale={{
              emptyText: (
                <Empty
                  style={{ padding: '50px' }}
                  description={t('table.empty.memberships')}
                />
              ),
            }}
          />
        </Col>
      </Row>
    </Space>
  )
}

export { MembershipsTable }
