import FullscreenModal from 'components/molecules/FullscreenModal'
import useMembershipsPageContext from 'hooks/useMembershipsPageContext'
import { MembershipForm } from './MembershipForm'

const NewMembershipForm = () => {
  const {
    createMembershipFormIsOpen,
    onCloseCreateMembershipForm,
    onCreateMembership,
  } = useMembershipsPageContext()

  return (
    <FullscreenModal
      visible={createMembershipFormIsOpen}
      maskClosable={false}
      onCancel={onCloseCreateMembershipForm}
      maxWidth="450px"
      destroyOnClose
      loading={false}
    >
      <MembershipForm onSubmit={onCreateMembership} />
    </FullscreenModal>
  )
}

export { NewMembershipForm }
