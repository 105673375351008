import axios from 'axios'
import BaseService from '../BaseService'
import { Response } from '../BaseService/declarations'
import { sortQuery } from 'utils/common'
import {
  ICreateMembership,
  Membership,
  FindMembershipsParams,
} from './declarations'

export class MembershipService extends BaseService<Membership> {
  protected name: string = 'membership'

  async find(params?: FindMembershipsParams): Promise<Membership[]> {
    const sortParams = sortQuery({ price: params?.price })
    try {
      const response = await axios.get<Response<Membership[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params: { sort: sortParams, ...params } })
      )
      const data = response.data.data as Membership[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async createMembership(body: ICreateMembership) {
    try {
      const response = await axios.post<Response<Membership>>(
        `${this.url}/${this.name}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as Membership
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async updateMembership(
    membershipId: number,
    body: Partial<ICreateMembership>
  ) {
    try {
      const response = await axios.put<Response<Membership>>(
        `${this.url}/${this.name}/${membershipId}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as Membership
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async uploadMembershipImage(membershipId: number, imageFile: any) {
    try {
      const formData = new FormData()
      formData.append('image', imageFile)
      const response = await axios.post<Response<Membership>>(
        `${this.url}/${this.name}/${membershipId}/image`,
        formData,
        this.getHeadersMultipart()
      )
      const data = response.data.data as Membership
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async deleteMembership(membershipId: number) {
    try {
      const response = await axios.delete<Response<Membership>>(
        `${this.url}/${this.name}/${membershipId}`,
        this.getHeaders()
      )
      const data = response.data.data as Membership
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async deleteMembershipImage(membershipId: number, imageId: number | string) {
    try {
      const response = await axios.delete<Response<Membership>>(
        `${this.url}/${this.name}/${membershipId}/image?image_id=${imageId}`,
        this.getHeaders()
      )
      const data = response.data.data as Membership
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new MembershipService()
export default service
