import { createContext } from 'react'
import type { ICreateLocation, Location } from 'api/Location/declarations'
import IImageData from 'interfaces/shared'

interface IEditFormState {
  isOpen: boolean
  location?: Location
}

interface ILocationsPageContext {
  locations: Location[]
  createLocationFormIsOpen: boolean
  onOpenCreateLocationForm: () => void
  onCloseCreateLocationForm: () => void
  editFormState: IEditFormState
  onCloseEditLocationForm: () => void
  onOpenEditLocationForm: (location: Location) => void
  onCreateLocation: (body: ICreateLocation, image: IImageData) => Promise<void>
  onUpdateLocation: (body: ICreateLocation, image: IImageData) => Promise<void>
  onDeleteLocation: (location: Location) => void
}

const LocationPageContext = createContext<ILocationsPageContext | undefined>(
  undefined
)

export type { ILocationsPageContext, IEditFormState }
export default LocationPageContext
