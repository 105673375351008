import { Select, SelectProps } from 'antd'
import getLocationStatus from 'utils/getLocationsStatus'
import { ELocationStatus } from 'api/Location/declarations'

const LocationStatusSelect = (props: SelectProps<ELocationStatus>) => (
  <Select {...props}>
    <Select.Option value={ELocationStatus.ACTIVE}>
      {getLocationStatus(ELocationStatus.ACTIVE)}
    </Select.Option>
    <Select.Option value={ELocationStatus.INACTIVE}>
      {getLocationStatus(ELocationStatus.INACTIVE)}
    </Select.Option>
    <Select.Option value={ELocationStatus.SOON}>
      {getLocationStatus(ELocationStatus.SOON)}
    </Select.Option>
  </Select>
)

export { LocationStatusSelect }
