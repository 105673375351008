import 'antd/dist/antd.css'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import { GlobalStyle } from 'components/layouts/GlobalStyle'
import Login from 'pages/Login'
import PrivateRoute from 'components/molecules/PrivateRoute'
import withUser, { WithUserProps } from 'hooks/withUser'
import routes from 'shared/constants/routes'
import UsersPage from 'pages/Users'
import PartnerView from 'pages/PartnerView'
import YachtsPage from 'pages/Yachts'
import { EUserRole } from 'api/User/declarations'
import { When } from 'react-if'
import UserService from 'api/User'
import { useEffect } from 'react'
import AdditionalPage from 'pages/Additionals'
import ServicesPage from 'pages/Services'
import CalendarPage from 'pages/Calendar'
import ReservesPage from 'pages/Reserves'
import ReservePage from 'pages/Reserve'
import LocationsPage from 'pages/Locations'
import MembershipsPage from 'pages/Memberships'
import ConvertorsPage from 'pages/Convertors'
import ChargesPage from 'pages/Charges'
import BlockedDatesPage from 'pages/BlockedDates'
import BankAccountPage from 'pages/BankAccount'
import MovementsPage from 'pages/Movements'

type Props = WithUserProps & {}

const App = ({ ...rest }: Props) => {
  const {
    login,
    home,
    partners,
    partnerView,
    customers,
    boats,
    additionals,
    services,
    calendar,
    reserves,
    reserve,
    locations,
    memberships,
    convertors,
    charges,
    blockedDates,
    bankAccount,
    movements,
  } = routes
  const { isAdmin, setUser, clearUser, user } = rest
  useEffect(() => {
    if (user) {
      getMe()
    }
  }, [])

  async function getMe() {
    try {
      const { user } = await UserService.me()
      setUser(user)
    } catch (error) {
      clearUser()
    }
  }

  return (
    <Router>
      <GlobalStyle />
      <PrivateRoute
        path={home}
        exact
        component={() => <Redirect to={partners} />}
        {...rest}
      />
      <PrivateRoute
        path={partners}
        exact
        component={(props: any) => (
          <UsersPage {...props} role={EUserRole.PARTNER} />
        )}
        {...rest}
      />
      <PrivateRoute
        path={partnerView(':partnerId')}
        exact
        component={PartnerView}
        {...rest}
      />
      <When condition={isAdmin()}>
        <PrivateRoute
          path={customers}
          exact
          component={(props: any) => (
            <UsersPage {...props} role={EUserRole.CUSTOMER} />
          )}
          {...rest}
        />
        <PrivateRoute path={calendar} exact component={CalendarPage} />
        <PrivateRoute path={reserves(':date')} exact component={ReservesPage} />
        <PrivateRoute
          path={reserve(':date', ':id')}
          exact
          component={ReservePage}
        />
        <PrivateRoute path={boats} exact component={YachtsPage} />
        <PrivateRoute path={additionals} exact component={AdditionalPage} />
        <PrivateRoute path={services} exact component={ServicesPage} />
        <PrivateRoute path={locations} exact component={LocationsPage} />
        <PrivateRoute path={memberships} exact component={MembershipsPage} />
        <PrivateRoute path={convertors} exact component={ConvertorsPage} />
        <PrivateRoute path={charges} exact component={ChargesPage} />
        <PrivateRoute path={blockedDates} exact component={BlockedDatesPage} />
        <PrivateRoute path={bankAccount} exact component={BankAccountPage} />
        <PrivateRoute path={movements(':id')} exact component={MovementsPage} />
      </When>
      <Route path={login} exact component={Login} />
    </Router>
  )
}

export default withUser(App)
