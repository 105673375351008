import styled from 'styled-components'
import { Popconfirm } from 'antd'
import { CloseSquareOutlined } from '@ant-design/icons'
import { BaseActionButton, IActionButton } from './BaseActionButton'
import COLORS from 'shared/constants/colors'

const { DANGER } = COLORS

const CancelIcon = styled(CloseSquareOutlined)`
  color: ${DANGER};
`

const emptyOnClick = () => {}

interface ICancelButton extends IActionButton {
  confirmationMessage?: string
}

const CancelButton = ({
  confirmationMessage = '¿Quieres cancelar la reserva?',
  onClick,
  ...props
}: ICancelButton) => (
  <Popconfirm
    title={confirmationMessage}
    okText="Sí"
    cancelText="No"
    onConfirm={onClick}
  >
    <BaseActionButton {...props} icon={<CancelIcon />} onClick={emptyOnClick} />
  </Popconfirm>
)

export { CancelButton }
