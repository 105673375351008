import React, { FC } from 'react'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { EMenuKeys } from 'shared/constants/menuElements'
import { LocationsProvider } from 'components/context/LocationsContext'
import { YachtsProvider } from 'components/context/YachtsContext'
import { MembershipsProvider } from 'components/context/MembershipsContext'
import { ReservesPageProvider } from 'components/context/ReservesPageContext'
import ReservesTable from 'components/organisms/Reserves/ReservesTable/ReservesTable'
import { ReseservesHeader } from 'components/organisms/Reserves/ReservesHeader/ReseservesHeader'

const ReservesPage: React.FC<WithUserProps & RouteComponentProps> = (props) => (
  <MainLayout {...props} active={EMenuKeys.RESERVES} showTitle={false}>
    <LocationsProvider>
      <YachtsProvider>
        <MembershipsProvider>
          <ReservesPageProvider>
            <ReseservesHeader />
            <ReservesTable />
          </ReservesPageProvider>
        </MembershipsProvider>
      </YachtsProvider>
    </LocationsProvider>
  </MainLayout>
)

export default ReservesPage
