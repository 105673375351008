import { Table, Row, Col, Empty } from 'antd'
import { PaginationData } from 'api/BaseService/declarations'
import { useTranslation } from 'react-i18next'

export enum ETypeTable {
  PARTNERS = 'partners',
  CUSTOMERS = 'customers',
  RESERVES = 'reserves',
  BOATS = 'boats',
  ADDITIONALS = 'additionals',
  SERVICES = 'services',
  MEMBERSHIPS = 'memberships',
  CHARGES = 'charges',
  BANK_ACCOUNTS = 'bank_accounts',
  MOVEMENTS = 'movements',
  BLOCKED_DATES = 'blocked_dates',
  SALE_ROOMS = 'sales_room',
  CONVERTORS = 'convertors',
}

type YachtsTableProps = {
  loading: boolean
  dataSource: any
  columns: any
  pagination: PaginationData
  count: number
  type: ETypeTable
  style?: React.CSSProperties
  empty?: string
  onChangePage: (page: number) => void
}

const YachtsTable = ({
  loading = false,
  columns = [],
  dataSource,
  pagination,
  count,
  type,
  style = {},
  empty,
  onChangePage,
}: YachtsTableProps) => {
  const { t } = useTranslation()
  return (
    <Row gutter={[0, 32]} justify="center">
      <Col md={24}>
        <Table
          dataSource={dataSource}
          columns={columns}
          scroll={{
            x: true,
          }}
          pagination={{
            total: count,
            pageSize: pagination.per_page,
            current: pagination.page,
            position: ['bottomLeft'],
            onChange: onChangePage,
          }}
          loading={loading}
          locale={{
            emptyText: (
              <Empty
                style={{ padding: '50px' }}
                description={empty || t(`table.empty.${type}`)}
              />
            ),
          }}
          style={style}
        />
      </Col>
    </Row>
  )
}

export default YachtsTable
