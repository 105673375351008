import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { WithUserProps } from 'hooks/withUser'
import { EMenuKeys } from 'shared/constants/menuElements'
import { MembershipsProvider } from 'components/context/MembershipsContext'
import { MembershipsPageProvider } from 'components/context/MembershipsPageContext'
import {
  EditMembershipForm,
  MembershipsTable,
  NewMembershipForm,
} from 'components/organisms/Memberships'

const Memberships: React.FC<WithUserProps & RouteComponentProps> = (props) => (
  <MainLayout {...props} active={EMenuKeys.MEMBERSHIPS}>
    <MembershipsProvider>
      <MembershipsPageProvider>
        <MembershipsTable />
        <NewMembershipForm />
        <EditMembershipForm />
      </MembershipsPageProvider>
    </MembershipsProvider>
  </MainLayout>
)

export default Memberships
