import UserService from 'api/User'
import { EUserRole, User, UserParams, UserSort } from 'api/User/declarations'
import FullscreenModal from 'components/molecules/FullscreenModal'
import useUsersPage from 'hooks/useUsersPageContext'
import { SortDirection } from 'interfaces/shared'
import { useEffect, useState } from 'react'
import tryFunction from 'utils/try'
import { PartnersTable } from './PartnersTable/PartnersTable'

const initialParams: UserParams = {
  page: 1,
  per_page: 7,
  role: EUserRole.PARTNER,
}

const initialSort: UserSort = {
  created_at: SortDirection.DESC,
}

export const PartnersViewModal = () => {
  const {
    showModal,
    userEdit: user,
    handledClosePartnersViewModal: onCancel,
  } = useUsersPage()
  const { partnersView: visible } = showModal
  const [users, setUsers] = useState<User[]>([])
  const [count, setCount] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [userParams, setUserParams] = useState<UserParams>(initialParams)

  useEffect(() => {
    if (visible) {
      getPartners()
    } else if (users?.length) {
      resetStates()
    }
  }, [visible, userParams])

  const resetStates = () => {
    setUsers([])
    setCount(0)
    setUserParams(initialParams)
  }

  const getPartners = async () => {
    tryFunction(async () => {
      const { data: users, count } = await UserService.paginate(
        { ...userParams, customer_id: user?.id },
        initialSort
      )
      setUsers(users)
      setCount(count)
    }, setLoading)
  }

  const onChangePage = (page: number) => {
    setUserParams({ ...userParams, page })
  }

  return (
    <FullscreenModal
      visible={visible}
      loading={loading}
      onCancel={onCancel}
      maxWidth="900px"
      destroyOnClose
    >
      <PartnersTable
        loading={loading}
        users={users}
        userParams={userParams}
        count={count}
        onChangePage={onChangePage}
      />
    </FullscreenModal>
  )
}
