import { Card, Col, Divider, Empty, Row } from 'antd'
import { Reserve } from 'api/Reserve/declarations'
import useCalendarPage from 'hooks/CalendarPage'
import { useTranslation } from 'react-i18next'
import { Else, If, Then, When } from 'react-if'
import { Link } from 'react-router-dom'
import { formatDate, formatHour } from 'utils/common'
import { getPathnameReserve } from 'utils/reserves'

const OVERLAY_STYLE = {
  borderRadius: 10,
  backgroundColor: '#f0f2f5',
  border: '1px solid #008080',
  width: 400,
}

export const PreReservesOverlay = () => {
  const { t } = useTranslation()
  const { preReserves: reserves } = useCalendarPage()

  return (
    <Card style={OVERLAY_STYLE}>
      <If condition={reserves.length > 0}>
        <Then>
          <Row>
            {reserves.map(({ id, code, start_date, end_date, user }, index) => (
              <Col key={id} xs={24}>
                <When condition={index > 0}>
                  <Divider />
                </When>
                <Row justify="space-between" style={{ paddingBottom: '2%' }}>
                  <Col xs={24}>
                    {t('calendar.pre_reserve.reserve')}:
                    <Link
                      to={getPathnameReserve({ id, start_date } as Reserve)}
                    >
                      #{code}
                    </Link>
                  </Col>
                  <Col xs={24}>
                    {t('calendar.pre_reserve.partner')} : {user.email}
                  </Col>
                  <Col xs={24}>
                    {t('calendar.pre_reserve.date')} : {formatDate(start_date)}
                  </Col>
                  <Col>
                    {t('calendar.pre_reserve.from')} : {formatHour(start_date)}-
                    {formatHour(end_date)}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Then>
        <Else>
          <Empty description={t('calendar.pre_reserve.empty')} />
        </Else>
      </If>
    </Card>
  )
}
