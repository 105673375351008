import React from 'react'
import { ButtonProps } from 'antd'
import { YachtButton } from 'components/atoms/YachtButton/YachtButton'

const AppButton: React.FC<ButtonProps> = ({
  children,
  type = 'primary',
  ...rest
}) => (
  <YachtButton {...rest} type={type}>
    {children}
  </YachtButton>
)

export default AppButton
