import { SelectProps, Select } from 'antd'
import { EStatusPartner } from 'api/User/declarations'
import useMainLayout from 'hooks/useAccessUser'
import { tPartnerStatus } from 'utils/user'

type Props = {
  isForm?: boolean
} & SelectProps<'tag'>

export const PartnerStatusSelector = ({ isForm = false, ...props }: Props) => {
  const { isAdmin } = useMainLayout()

  const isDisabledLead = (key) =>
    EStatusPartner[key] === EStatusPartner.LEAD && isForm

  const isDisabledCancelled = (key) =>
    EStatusPartner[key] === EStatusPartner.CANCELLED && !isAdmin && isForm

  const partnerStatus = Object.keys(EStatusPartner).map((key) => ({
    label: tPartnerStatus(EStatusPartner[key]),
    value: EStatusPartner[key],
    disabled: isDisabledLead(key) || isDisabledCancelled(key),
  }))

  return <Select options={partnerStatus} {...props} />
}
