import { Additional } from 'api/Additional/declarations'
import { createContext } from 'react'

interface IAdditionalsContext {
  additionals: Additional[]
  getAdditionals: () => Promise<void>
}

const AdditionalContext = createContext<IAdditionalsContext | undefined>(
  undefined
)

export default AdditionalContext
export type { IAdditionalsContext }
