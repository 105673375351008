import { SelectProps, Select } from 'antd'
import moment from 'moment'
import { filterOptions } from 'utils/common'

type Props = {} & SelectProps<'tag'>

export const MonthSelector = ({ ...props }: Props) => {
  const countries_options = moment.months().map((name, index) => ({
    label: name.replace(/\b\w/g, (letter) => letter.toUpperCase()),
    value: index + 1,
  }))

  return (
    <Select
      options={countries_options}
      {...props}
      filterOption={filterOptions}
    />
  )
}
