import i18n from 'i18next'
import { Col, Row } from 'antd'
import {
  RemoveButton,
  TableHeader,
  EditButton,
  MovementButton,
} from 'components/atoms/Table'
import { BankAccount } from 'api/BankAccount/declarations'
import { formatPrice } from 'utils/common'

type IBankAccountsTableColumns = {
  onOpenEditBankAccountForm: (bankAccount: BankAccount) => void
  deleteBankAccount: (bankAccount: BankAccount) => void
  onOpenBankMovements: (bankAccount: BankAccount) => void
}

export const BankAccountTableColumns = ({
  onOpenEditBankAccountForm,
  deleteBankAccount,
  onOpenBankMovements,
}: IBankAccountsTableColumns) => [
  {
    title: <TableHeader>{i18n.t('table.row.name')}</TableHeader>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: (
      <TableHeader>{i18n.t('bankAccount.table.account_number')}</TableHeader>
    ),
    dataIndex: 'account_number',
    key: 'account_number',
  },
  {
    title: (
      <TableHeader width="120px">{i18n.t('table.row.description')}</TableHeader>
    ),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: <TableHeader>{i18n.t('bankAccount.table.balance')}</TableHeader>,
    render: (text: string, { balance }: BankAccount) =>
      formatPrice(String(balance)),
    key: 'balance',
  },

  {
    title: (
      <TableHeader width="150px">{i18n.t('table.row.options')}</TableHeader>
    ),
    key: 'options',
    render: (text: string, bankAccount: BankAccount) => {
      const onClickEdit = () => onOpenEditBankAccountForm(bankAccount)
      const onClickMovements = () => onOpenBankMovements(bankAccount)
      const onDelete = () => deleteBankAccount(bankAccount)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />
          </Col>
          <Col>
            <MovementButton
              text={i18n.t('table.movements')}
              onClick={onClickMovements}
            />
          </Col>
          <Col>
            <RemoveButton text={i18n.t('table.delete')} onClick={onDelete} />
          </Col>
        </Row>
      )
    },
  },
]
