import ReserveService from 'api/Reserve'
import { FilterReserveTap, Reserve } from 'api/Reserve/declarations'
import FullscreenModal from 'components/molecules/FullscreenModal'
import useUsersPage from 'hooks/useUsersPageContext'
import { useEffect, useState } from 'react'
import tryFunction from 'utils/try'
import { EReserveTap, ReservesView } from './ReservesView/ReservesView'
import mixpanelService from 'services/mixpanel'

const initialPagination = {
  page: 1,
  per_page: 10,
}

const initialParams = {
  ...FilterReserveTap[EReserveTap.AFTER],
  ...initialPagination,
}

export const ReservesViewModal = () => {
  const {
    showModal,
    userEdit: user,
    handledCloseReservesViewModal: onCancel,
  } = useUsersPage()
  const { reservesView: visible } = showModal
  const [reserves, setReserves] = useState<Reserve[]>([])
  const [count, setCount] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState(initialParams)
  const user_id = user?.id

  useEffect(() => {
    if (visible) {
      getReserves()
    }
  }, [visible, params])

  const resetStates = () => {
    setReserves([])
    setCount(0)
    setParams(initialParams)
  }

  const getReserves = async () => {
    tryFunction(async () => {
      const { data: reserves, count } = await ReserveService.paginate({
        ...params,
        user_id,
      })
      setReserves(reserves)
      setCount(count)
    }, setLoading)
  }

  const onChangeTap = (tab: EReserveTap) => {
    if (tab === 'before') {
      mixpanelService.track.viewPartnerReserves({ section: 'Past' })
    }

    if (tab === 'after') {
      mixpanelService.track.viewPartnerReserves({ section: 'Upcoming' })
    }

    if (tab === 'canceled') {
      mixpanelService.track.viewPartnerReserves({ section: 'Canceled' })
    }

    setParams({ ...initialPagination, ...FilterReserveTap[tab] })
  }

  const onChangePage = (page: number) => setParams({ ...params, page })

  const onClickCancel = () => {
    resetStates()
    onCancel()
  }

  return (
    <FullscreenModal
      visible={visible}
      loading={loading}
      onCancel={onClickCancel}
      maxWidth="900px"
      destroyOnClose
    >
      <ReservesView
        reserves={reserves}
        loading={loading}
        onChangePage={onChangePage}
        onChangeTap={onChangeTap}
        params={params}
        count={count}
      />
    </FullscreenModal>
  )
}
