import { useTranslation } from 'react-i18next'
import { When } from 'react-if'
import { Form, Input, Divider } from 'antd'
import { UserCreate } from 'api/User/declarations'
import ImageUploader, {
  IUploadedImage,
} from 'components/molecules/ImageUploader'
import IImageData from 'interfaces/shared'
import { Avatar } from 'components/molecules/Avatar'
import { MembershipSelector } from 'components/organisms/Membership/Selector/MembershipSelector'
import { Membership } from 'api/Membership/declarations'
import { ConvertorSelector } from 'components/organisms/Convertor/Selector/ConvertorSelector'
import { Convertor } from 'api/Convertor/declarations'
import { Location } from 'api/Location/declarations'
import { LocationSelector } from 'components/organisms/Location/Selector/LocationSelector'

type Props = {
  user: UserCreate
  allowEdition: boolean
  memberships?: Membership[]
  convertors?: Convertor[]
  locations?: Location[]
  imageData: IImageData
  onImageUpload: (imageData: IUploadedImage) => void
}

export const CustomersInputs = ({
  user,
  allowEdition,
  memberships = [],
  convertors = [],
  locations = [],
  imageData,
  onImageUpload,
}: Props) => {
  const { t } = useTranslation()

  const {
    email,
    phone,
    avatar,
    location,
    name,
    memberships: membershipsUser,
    convertors: convertorsUser,
    business,
  } = user ? user : ({} as UserCreate)
  const memberships_ids = membershipsUser?.map(({ id }) => id) || []
  let convertors_ids = convertorsUser?.map(({ id }) => id) || []
  if (business?.convertors) {
    convertors_ids = business?.convertors?.map(({ id }) => id)
  }
  const location_id = location?.id

  return (
    <>
      <Form.Item style={{ textAlign: 'center' }} name="avatar">
        <ImageUploader
          disabled={!allowEdition}
          onImageLoaded={onImageUpload}
          defaultPreview={
            <Avatar
              url={imageData?.url || avatar}
              size={150}
              disabled={!allowEdition}
            />
          }
        />
      </Form.Item>
      <Divider>{t('users.form.div.credential')}</Divider>
      <Form.Item
        label={t('users.table.row.email')}
        name="email"
        initialValue={email}
        rules={[{ required: true, message: t('users.form.required.email') }]}
      >
        <Input type="email" disabled={!allowEdition} />
      </Form.Item>
      <When condition={!user}>
        <Form.Item
          label={t('users.form.password')}
          name="password"
          rules={[
            { required: true, message: t('users.form.required.password') },
          ]}
        >
          <Input type="password" />
        </Form.Item>
      </When>
      <Divider>{t('users.form.div.customer')}</Divider>
      <Form.Item
        label={t('users.table.row.customer')}
        name="name"
        initialValue={name}
        rules={[{ required: true, message: t('users.form.required.customer') }]}
      >
        <Input type="text" disabled={!allowEdition} />
      </Form.Item>
      <Form.Item
        label={t('users.table.row.phone')}
        name="phone"
        initialValue={phone}
        rules={[{ required: true, message: t('users.form.required.phone') }]}
      >
        <Input disabled={!allowEdition} type="phone" />
      </Form.Item>
      <Form.Item
        label={t('users.table.row.location')}
        name="location_id"
        initialValue={location_id}
      >
        <LocationSelector disabled={!allowEdition} locations={locations} />
      </Form.Item>
      <Divider>{t('users.form.div.more')}</Divider>
      <Form.Item
        label={t('users.table.row.membership')}
        name="memberships_ids"
        initialValue={memberships_ids}
      >
        <MembershipSelector
          mode="multiple"
          disabled={!allowEdition}
          memberships={memberships}
        />
      </Form.Item>
      <Form.Item
        label={t('users.form.convertors')}
        name="convertors_ids"
        initialValue={convertors_ids}
      >
        <ConvertorSelector
          mode="multiple"
          disabled={!allowEdition}
          convertors={convertors}
        />
      </Form.Item>
    </>
  )
}
