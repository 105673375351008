import { HomeOutlined } from '@ant-design/icons'
import { BaseActionButton, IActionButton } from './BaseActionButton'
import COLORS from 'shared/constants/colors'

const { AQUA_DARK } = COLORS

const SaleRoomButton = (props: IActionButton) => (
  <BaseActionButton {...props} icon={<HomeOutlined color={AQUA_DARK} />} />
)

export { SaleRoomButton }
