export default {
  home: '/',
  login: '/login',
  partners: '/partners',
  partnerView: (partnerId: string) => `/partners/${partnerId}`,
  customers: '/holiday-resorts',
  calendar: '/calendar',
  reserves: (date: string) => `/reserves/${date}`,
  reserve: (date: string, id?: number | string) =>
    `/reserve/${date}/${id ?? 'new'}`,
  boats: '/boats',
  additionals: '/additionals',
  services: '/services',
  memberships: '/memberships',
  charges: '/charges',
  blockedDates: '/blocked-dates',
  locations: '/locations',
  convertors: '/convertors',
  bankAccount: '/bank-accounts',
  movements: (id: number | string) => `/bank-accounts/${id}/movements`,
}
