import { Location } from 'api/Location/declarations'
import LocationService from 'api/Location'
import { FC, useState } from 'react'
import LocationsContext, { ILocationsContext } from './LocationsContext'

interface ILocationsProvider {
  withoutImages?: boolean
}

const LocationsProvider: FC<ILocationsProvider> = ({
  children,
  withoutImages,
}) => {
  const [locations, setLocations] = useState<Location[]>([])

  const getLocations = async () => {
    const Locations = await LocationService.find({
      without_images: withoutImages,
    })
    setLocations(Locations)
  }

  const contextValue: ILocationsContext = {
    locations,
    getLocations,
  }

  return (
    <LocationsContext.Provider value={contextValue}>
      {children}
    </LocationsContext.Provider>
  )
}

export { LocationsProvider }
