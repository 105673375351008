import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { SortDirection } from 'interfaces/shared'
import COLORS from 'shared/constants/colors'

const { AQUA } = COLORS

type Props = {
  name?: string
  sort?
  onChange?: (key: string, direction?: SortDirection) => void
}

export const TableIconSort = ({ sort, name: key, onChange }: Props) => {
  const iconColor = (name: SortDirection) =>
    sort[key] === name
      ? {
          color: AQUA,
        }
      : {}

  const setAsc = () =>
    onChange(
      key,
      sort[key] !== SortDirection.ASC ? SortDirection.ASC : undefined
    )

  const setDesc = () =>
    onChange(
      key,
      sort[key] !== SortDirection.DESC ? SortDirection.DESC : undefined
    )

  return (
    <Row style={{ flexDirection: 'column-reverse' }}>
      <Col>
        <CaretDownOutlined
          style={iconColor(SortDirection.DESC)}
          onClick={setDesc}
        />
      </Col>
      <Col>
        <CaretUpOutlined
          style={iconColor(SortDirection.ASC)}
          onClick={setAsc}
        />
      </Col>
    </Row>
  )
}
