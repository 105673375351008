import {
  IUploadedImage,
  IImagePreview,
} from 'components/molecules/ImageUploader'

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

interface IImageData extends IUploadedImage, IImagePreview {}

export default IImageData
