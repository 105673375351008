import axios from 'axios'
import qs from 'qs'
import { sortQuery } from 'utils/common'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from '../BaseService/declarations'
import {
  IBlockedDate,
  ICreateBlockedDate,
  IUpdateBlockedDate,
  GetBlockedDatesParams,
  IGetBlockedDatesCalendarParams,
  IBlockedDateEvents,
} from './declarations'

export class BlockedDateService extends BaseService<IBlockedDate> {
  protected name: string = 'blocked-date'

  async find(): Promise<IBlockedDate[]> {
    try {
      const response = await axios.get<Response<IBlockedDate[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders()
      )
      const data = response.data.data as IBlockedDate[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async findWithPagination(
    params?: GetBlockedDatesParams
  ): Promise<PaginatedResponse<IBlockedDate>> {
    const sortParams = sortQuery(params?.sort)
    try {
      const response = await axios.get<Response<IBlockedDate[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params: { ...params, sort: sortParams } })
      )
      const data = response.data.data as IBlockedDate[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async createBlockedDate(body: ICreateBlockedDate) {
    try {
      const response = await axios.post<Response<IBlockedDate>>(
        `${this.url}/${this.name}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as IBlockedDate
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async updateBlockedDate(blockedDateId: number, body: IUpdateBlockedDate) {
    try {
      const response = await axios.put<Response<IBlockedDate>>(
        `${this.url}/${this.name}/${blockedDateId}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as IBlockedDate
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async deleteBlockedDate(blockedDateId: number) {
    try {
      const response = await axios.delete<Response<IBlockedDate>>(
        `${this.url}/${this.name}/${blockedDateId}`,
        this.getHeaders()
      )
      const data = response.data.data as IBlockedDate
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async calendar(
    params: IGetBlockedDatesCalendarParams
  ): Promise<IBlockedDateEvents[]> {
    const queryParams = qs.stringify(params)
    try {
      const response = await axios.get<Response<IBlockedDateEvents[]>>(
        `${this.url}/${this.name}/calendar?${queryParams}`,
        this.getHeaders()
      )
      const data = response.data.data as IBlockedDateEvents[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new BlockedDateService()
export default service
