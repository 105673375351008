import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import useServicesPage from 'hooks/translate/useServicesPageContext'
import { ServicesColumns } from './ServicesColumns'
import { useEffect } from 'react'
import mixpanelService from 'services/mixpanel'

const ServicesTable = () => {
  const {
    loading,
    params,
    services,
    count,
    onDelete,
    onChangePage,
    handledOpenServiceModalForm,
    handledOpenImagesModalForm,
  } = useServicesPage()
  const dataSource = services.map((service) => ({
    key: service.id,
    ...service,
  }))

  useEffect(() => {
    mixpanelService.track.userEnterServicesPage()
  }, [])

  return (
    <YachtsTable
      loading={loading}
      dataSource={dataSource}
      count={count}
      pagination={params}
      type={ETypeTable.SERVICES}
      onChangePage={onChangePage}
      columns={ServicesColumns({
        handledOpenServiceModalForm,
        handledOpenImagesModalForm,
        onDelete,
      })}
    />
  )
}

export default ServicesTable
