import { useTranslation } from 'react-i18next'
import { Col, Row, Space, DatePicker } from 'antd'
import { YachtSearch } from 'components/atoms/Search/Search'
import useChargesPageContext from 'hooks/useChargesPageContext'
import { ChargeStatusSelector } from './Selector/ChargeStatusSelector'

const { RangePicker } = DatePicker

const ChargesTableHeader = () => {
  const { t } = useTranslation()
  const { onStatusFilterChange, onDateFilterChange, onSearchChange } =
    useChargesPageContext()

  return (
    <Row justify="space-between">
      <Col>
        <Space size={8}>
          <ChargeStatusSelector
            placeholder={t('charge.table.filter.status')}
            allowClear
            style={{ width: '200px' }}
            onChange={onStatusFilterChange}
          />
          <RangePicker
            style={{ width: '300px' }}
            onChange={onDateFilterChange}
          />
        </Space>
      </Col>
      <Col>
        <YachtSearch
          placeholder={t('charge.table.search')}
          style={{ width: '400px' }}
          onSearch={onSearchChange}
          allowClear
        />
      </Col>
    </Row>
  )
}

export { ChargesTableHeader }
