import styled from 'styled-components'

const TableFilters = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto 1fr;
`

export { TableFilters }
