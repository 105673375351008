import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Button, Divider, DatePicker, Input } from 'antd'
import { Moment } from 'moment'
import { useState, useEffect } from 'react'
import tryFunction from 'utils/try'
import { MembershipSelector } from '../Membership/Selector/MembershipSelector'
import { LocationSelector } from '../Location/Selector/LocationSelector'
import { YachtSelector } from '../Yachts/Selector/YachtSelector'
import { Location } from 'api/Location/declarations'
import { Membership } from 'api/Membership/declarations'
import { Yacht } from 'api/Yacht/declarations'
import showMessage, { NoticeType } from 'utils/notifications'
import useBlockedDatesPageContext from 'hooks/useBlockedDatesPageContext'

const ALL_ITEM_ID = -1
const REGULAR_MEMBERSHIP_ID = -2

interface IBlockedDateFormValues {
  date: Moment
  memberships_ids?: number[]
  locations_ids?: number[]
  yachts_ids?: number[]
  description?: string
  membership_free?: boolean
  whole_fleet?: boolean
}

interface IBlockedDateForm {
  initialValues?: Partial<IBlockedDateFormValues>
  isEditionForm?: boolean
  onSubmit?: (values: IBlockedDateFormValues) => Promise<void>
}

const BlockedDateForm = ({
  initialValues,
  isEditionForm = false,
  onSubmit = async (values) => {},
}: IBlockedDateForm) => {
  const { t } = useTranslation()
  const { locations, memberships, yachts } = useBlockedDatesPageContext()
  const [form] = Form.useForm<IBlockedDateFormValues>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitButtonTranslationKey = `locations.form.${
    isEditionForm ? 'edit' : 'create'
  }-submit`
  const [isBlockedByMembership, setIsBlockedByMembership] = useState(false)
  const [isBlockedByYacht, setIsBlockedByYacht] = useState(false)
  const [allMembershipsAreSelected, setAllMembershipsAreSelected] =
    useState(false)
  const [allLocationsAreSelected, setAllLocationsAreSelected] = useState(false)
  const [allYachtsAreSelected, setAllYachtsAreSelected] = useState(false)

  const getInitialLocations = (locationsIds: number[]) => {
    const allLocationsAreSelected = locationsIds.length === locations.length
    setAllLocationsAreSelected(allLocationsAreSelected)
    return allLocationsAreSelected ? [ALL_ITEM_ID] : locationsIds
  }

  const getInitialYachts = (yachtsIds: number[]) => {
    const allYachtsAreSelected = yachtsIds.length === yachts.length
    setAllYachtsAreSelected(allYachtsAreSelected)
    return allYachtsAreSelected ? [ALL_ITEM_ID] : yachtsIds
  }

  const getInitialMemberships = (
    memberships_ids: number[],
    membership_free: boolean
  ) => {
    const allMembershipsAreSelected =
      memberships_ids.length === memberships.length
    setAllMembershipsAreSelected(allMembershipsAreSelected)
    let membershipsIds = membership_free ? [REGULAR_MEMBERSHIP_ID] : []
    if (allMembershipsAreSelected) {
      membershipsIds = [ALL_ITEM_ID]
    } else {
      membershipsIds = [...membershipsIds, ...memberships_ids]
    }
    return membershipsIds
  }

  const setInitialValues = () => {
    const { locations_ids, yachts_ids, membership_free, memberships_ids } =
      initialValues
    form.setFieldsValue({
      locations_ids: getInitialLocations(locations_ids),
      memberships_ids: getInitialMemberships(memberships_ids, membership_free),
      yachts_ids: getInitialYachts(yachts_ids),
    })
  }

  useEffect(() => {
    if (initialValues) {
      setInitialValues()
    }
  }, [locations, memberships, yachts, initialValues])

  const allItem = {
    id: ALL_ITEM_ID,
    name: t('blocked-dates.form.all-option'),
  }

  const regularMembershipItem = {
    id: REGULAR_MEMBERSHIP_ID,
    name: t('memberships.regular'),
  }

  const isBlockedByLocationCase = ({
    memberships_ids,
    locations_ids,
    yachts_ids,
  }: IBlockedDateFormValues) =>
    Boolean(locations_ids && !memberships_ids && !yachts_ids)

  const isBlockedByMembershipCase = ({
    memberships_ids,
    locations_ids,
    yachts_ids,
  }: IBlockedDateFormValues) =>
    Boolean(!locations_ids && memberships_ids && !yachts_ids)

  const isBlockedByYachtCase = ({
    locations_ids,
    yachts_ids,
  }: IBlockedDateFormValues) => Boolean(locations_ids && yachts_ids)

  const getMembershipsIdsWithoutRegularAndAllItems = (
    membershipsIds?: number[]
  ) => {
    let membershipsIdsWithoutRegularAndAllItems = membershipsIds?.filter(
      (membershipId) =>
        membershipId !== REGULAR_MEMBERSHIP_ID && membershipId !== ALL_ITEM_ID
    )
    if (allMembershipsAreSelected) {
      membershipsIdsWithoutRegularAndAllItems = memberships.map(({ id }) => id)
    }
    return membershipsIdsWithoutRegularAndAllItems
  }

  const getYachtsIdsWithoutAllItem = (yachtsIds?: number[]) => {
    let yachtsIdsWithoutAllItem = yachtsIds?.filter(
      (yachtId) => yachtId !== ALL_ITEM_ID
    )
    if (allYachtsAreSelected) {
      yachtsIdsWithoutAllItem = yachts.map(({ id }) => id)
    }
    return yachtsIdsWithoutAllItem
  }

  const getLocationsIdsWithoutAllItem = (locationsIds?: number[]) => {
    let locationsIdsWithoutAllItem = locationsIds?.filter(
      (locationId) => locationId !== ALL_ITEM_ID
    )
    if (allLocationsAreSelected) {
      locationsIdsWithoutAllItem = locations.map(({ id }) => id)
    }
    return locationsIdsWithoutAllItem
  }

  const onFinish = (values: IBlockedDateFormValues) => {
    const isValidBlockedCase =
      isBlockedByLocationCase(values) ||
      isBlockedByMembershipCase(values) ||
      isBlockedByYachtCase(values)

    if (!isValidBlockedCase) {
      showMessage(
        t('blocked-dates.form.invalid.title'),
        t('blocked-dates.form.invalid.message'),
        NoticeType.ERROR
      )
      return
    }
    const isWholeFleet =
      isBlockedByLocationCase(values) && allLocationsAreSelected
    const regularMembershipIsSelected =
      Boolean(values.memberships_ids?.includes(REGULAR_MEMBERSHIP_ID)) ||
      allMembershipsAreSelected
    const membershipsIdsWithoutRegularAndAllItems =
      getMembershipsIdsWithoutRegularAndAllItems(values.memberships_ids)
    const yachtsIdsWithoutAllItem = getYachtsIdsWithoutAllItem(
      values.yachts_ids
    )
    const locationsIdsWithoutAllItem = getLocationsIdsWithoutAllItem(
      values.locations_ids
    )

    const submitValues: IBlockedDateFormValues = {
      date: values.date.startOf('day'),
      whole_fleet: isWholeFleet,
      membership_free: Boolean(regularMembershipIsSelected),
      memberships_ids: membershipsIdsWithoutRegularAndAllItems,
      yachts_ids: yachtsIdsWithoutAllItem,
      locations_ids: locationsIdsWithoutAllItem,
      description: values.description,
    }

    tryFunction(
      async () => {
        await onSubmit(submitValues)
      },
      setIsSubmitting,
      true
    )
  }

  const onMembershipSelectChange = (value?: unknown) => {
    const { locations_ids } = form.getFieldsValue()
    const membershipsIds = value as number[]

    if (!allMembershipsAreSelected) {
      if (membershipsIds.includes(ALL_ITEM_ID)) {
        form.setFieldsValue({
          memberships_ids: [ALL_ITEM_ID],
        })
        setAllMembershipsAreSelected(true)
      }
    } else {
      const membershipsWithoutAllItem = membershipsIds.filter(
        (membershipId) => membershipId !== ALL_ITEM_ID
      )
      form.setFieldsValue({
        memberships_ids: membershipsWithoutAllItem,
      })
      setAllMembershipsAreSelected(false)
    }
    setIsBlockedByMembership(membershipsIds?.length && !locations_ids)
    setIsBlockedByYacht(Boolean(membershipsIds?.length && locations_ids))
  }

  const onLocationsSelectChange = (value?: unknown) => {
    const locationsIds = value as number[]

    if (!allLocationsAreSelected) {
      if (locationsIds.includes(ALL_ITEM_ID)) {
        form.setFieldsValue({
          locations_ids: [ALL_ITEM_ID],
        })
        setAllLocationsAreSelected(true)
      }
    } else {
      const locationsWithoutAllItem = locationsIds.filter(
        (locationId) => locationId !== ALL_ITEM_ID
      )
      form.setFieldsValue({
        locations_ids: locationsWithoutAllItem,
      })
      setAllLocationsAreSelected(false)
    }
  }

  const onYachtsSelectChange = (value?: unknown) => {
    const yachtsIds = value as number[]

    if (!allYachtsAreSelected) {
      if (yachtsIds.includes(ALL_ITEM_ID)) {
        form.setFieldsValue({
          yachts_ids: [ALL_ITEM_ID],
        })
        setAllYachtsAreSelected(true)
      }
    } else {
      const yachtsWithoutAllItem = yachtsIds.filter(
        (yachtId) => yachtId !== ALL_ITEM_ID
      )
      form.setFieldsValue({
        yachts_ids: yachtsWithoutAllItem,
      })
      setAllYachtsAreSelected(false)
    }
  }

  return (
    <Form initialValues={initialValues} onFinish={onFinish} form={form}>
      <Row>
        <Col xs={24}>
          <Divider>{t('locations.form.section.general')}</Divider>
          <Form.Item
            name="date"
            label={t('blocked-dates.table.date')}
            rules={[
              {
                required: true,
                message: t('blocked-dates.form.date.required'),
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} disabled={isEditionForm} />
          </Form.Item>
          <Form.Item
            name="locations_ids"
            label={t('blocked-dates.table.locations')}
          >
            <LocationSelector
              locations={[allItem as Location, ...locations]}
              allowClear
              disabled={isBlockedByMembership}
              onChange={onLocationsSelectChange}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            name="memberships_ids"
            label={t('blocked-dates.table.memberships')}
          >
            <MembershipSelector
              memberships={[
                allItem as Membership,
                regularMembershipItem as Membership,
                ...memberships,
              ]}
              allowClear
              onChange={onMembershipSelectChange}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            name="yachts_ids"
            label={t('blocked-dates.table.yachts')}
            rules={[
              {
                required: isBlockedByYacht,
                message: t('blocked-dates.form.yacht.required'),
              },
            ]}
          >
            <YachtSelector
              yachts={[allItem as Yacht, ...yachts]}
              allowClear
              disabled={isBlockedByMembership}
              onChange={onYachtsSelectChange}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('blocked-dates.table.comment')}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col offset={16} style={{ width: '100%' }}>
          <Button type="primary" block htmlType="submit" loading={isSubmitting}>
            {t(submitButtonTranslationKey)}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export type { IBlockedDateFormValues }
export { BlockedDateForm }
