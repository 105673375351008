import mixpanel from 'mixpanel-browser'

interface ITrackEnterCalendarDay {
  day: string
}

interface ITrackEnterBookingDetails {
  bookingId: number
}

interface ITrackBookingCancelled {
  bookingId: number
}

const CalendarFlowEvens = () => {
  const userEnterCalendarPage = () => {
    mixpanel.track('Enter Calendar Page')
  }

  const userEnterCalendarDay = ({ day }: ITrackEnterCalendarDay) => {
    mixpanel.track('Enter Calendar Day', { Day: day })
  }

  const userEnterBookingDetails = ({
    bookingId,
  }: ITrackEnterBookingDetails) => {
    mixpanel.track('Enter Booking Details', { BookingId: bookingId })
  }

  const bookingCancelled = ({ bookingId }: ITrackBookingCancelled) => {
    mixpanel.track('Booking Cancelled', { BookingId: bookingId })
  }

  const bookingEdited = () => {
    mixpanel.track('Booking Edited')
  }

  return {
    userEnterCalendarPage,
    userEnterCalendarDay,
    userEnterBookingDetails,
    bookingCancelled,
    bookingEdited,
  }
}

export default CalendarFlowEvens
