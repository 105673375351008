import { Location } from 'api/Location/declarations'
import { createContext } from 'react'

interface ILocationsContext {
  locations: Location[]
  getLocations: () => Promise<void>
}

const LocationsContext = createContext<ILocationsContext | undefined>(undefined)

export default LocationsContext
export type { ILocationsContext }
