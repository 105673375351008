import { Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { Else, If, Then, When } from 'react-if'
import { LoadingSpin } from '../LoadingSpin'

type Props = {
  style?: React.CSSProperties
  allowEdition: boolean
  loading: boolean
  viewEdit?: boolean
  viewCreate?: boolean
  toggleAllowEdition?: () => void
  onCancelEdition: () => void
  dataForm
}

const styleContainer = { marginTop: 25 }

export const FormFooter = ({
  allowEdition,
  loading,
  viewEdit = true,
  viewCreate = true,
  toggleAllowEdition,
  onCancelEdition,
  dataForm,
  style = styleContainer,
}: Props) => {
  const { t } = useTranslation()

  return (
    <If condition={allowEdition}>
      <Then>
        <If condition={loading}>
          <Then>
            <LoadingSpin />
          </Then>
          <Else>
            <Space style={style}>
              <When condition={viewCreate}>
                <Button htmlType="submit" type="primary" loading={loading}>
                  {dataForm ? t('form.save') : t('form.create')}
                </Button>
              </When>
              <When condition={!!dataForm}>
                <Button
                  htmlType="button"
                  onClick={onCancelEdition}
                  loading={loading}
                >
                  {t('form.cancel')}
                </Button>
              </When>
            </Space>
          </Else>
        </If>
      </Then>
      <Else>
        <When condition={viewEdit}>
          <Button
            htmlType="button"
            onClick={toggleAllowEdition}
            disabled={loading}
            style={style}
          >
            {t('form.edit')}
          </Button>
        </When>
      </Else>
    </If>
  )
}
