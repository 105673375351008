import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { FC, useContext, useEffect, useState } from 'react'
import scrollToTop from 'utils/scroll'
import tryFunction from 'utils/try'
import LocationsContext from '../LocationsContext'
import YachtsContext from '../YachtsContext'
import MembershipsContext from '../MembershipsContext'
import routes from 'shared/constants/routes'
import { Reserve, ReserveParams } from 'api/Reserve/declarations'
import ReserveService from 'api/Reserve'
import ReservesPageContext, {
  IReservesPageContext,
} from './ReservesPageContext'
import { FORMAT_DATE_FOR_IPUT } from 'utils/common'
import {
  getParamsDate,
  getPathnameReserve,
  getReserveDateParamUrl,
} from 'utils/reserves'
import { FORMAT_DATE_URL } from '../CalendarPageContext'
import UserService from 'api/User'
import { EUserRole } from 'api/User/declarations'
import { Business } from 'api/Business/declarations'
import { SaleRoom } from 'api/SaleRoom/declaration'

const initialParams: ReserveParams = {
  page: 1,
  per_page: 7,
}

const ReservesPageProvider: FC = ({ children }) => {
  const { replace, push } = useHistory()
  const { date: userParams } = useParams<{ date: string }>()
  const { locations, getLocations } = useContext(LocationsContext)
  const { yachts, getYachts, setLocationId } = useContext(YachtsContext)
  const { memberships, getMemberships } = useContext(MembershipsContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(true)
  const [reserves, setReserves] = useState<Reserve[]>([])
  const [count, setCount] = useState<number>(0)
  const [params, setParams] = useState<ReserveParams>(initialParams)
  const [dateReserves, setDateReserves] = useState<Date>(
    getParamsDate(userParams)
  )
  const [businesses, setBusinesses] = useState<Business[]>([])
  const [saleRooms, setSaleRooms] = useState<SaleRoom[]>([])

  useEffect(() => {
    loadData()
  }, [params, dateReserves, refresh])

  const loadData = () => {
    tryFunction(async () => {
      await getReserves()
      await getLocations()
      await getMemberships()
      await getYachts()
      await getBusinessesAndSalesRoom()
      scrollToTop()
      setRefresh(false)
    }, setLoading)
  }

  const getBusinessesAndSalesRoom = async () => {
    const customers = await UserService.find({ role: EUserRole.CUSTOMER })
    const newBusinesses = customers
      .filter(({ business }) => Boolean(business))
      .map(({ business }) => business)
    const newSaleRooms = newBusinesses
      .filter(({ sales_room }) => Boolean(sales_room))
      .map(({ sales_room }) => sales_room)
      .flat()
    setBusinesses(newBusinesses)
    setSaleRooms(newSaleRooms)
  }

  const getReserves = async () => {
    const { data: reserves, count } = await ReserveService.paginate({
      ...params,
      date: dateReserves,
    })
    setReserves(reserves)
    setCount(count)
  }

  const cancelReserve = (reserve: Reserve) => {
    tryFunction(
      async () => {
        await ReserveService.cancel(reserve.id)
        setRefresh(true)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onChangeDate = (date) => {
    const path = routes.reserves(
      moment(date, FORMAT_DATE_FOR_IPUT).format(FORMAT_DATE_URL)
    )
    setRefresh(true)
    setDateReserves(getParamsDate(date))
    replace(path)
  }

  const onChangeFilter = (params: ReserveParams) => {
    setRefresh(true)
    setParams(params)
  }

  const onChangePage = (page: number) => {
    setRefresh(true)
    setParams({ ...params, page })
  }

  const redirectReservePage = (reserve: Reserve) => {
    const path = getPathnameReserve(reserve)
    push(path)
  }

  const viewReserve = (reserve: Reserve) => redirectReservePage(reserve)

  const createReserve = () => {
    const date = getReserveDateParamUrl(dateReserves)
    const path = routes.reserve(date)
    push(path)
  }

  const contextValue: IReservesPageContext = {
    loading,
    dateReserves,
    reserves,
    count,
    params,
    locations,
    yachts,
    memberships,
    setParams,
    setLocationId,
    onChangeDate,
    viewReserve,
    onChangeFilter,
    onChangePage,
    createReserve,
    cancelReserve,
    businesses,
    saleRooms,
  }

  return (
    <ReservesPageContext.Provider value={contextValue}>
      {children}
    </ReservesPageContext.Provider>
  )
}

export { ReservesPageProvider }
