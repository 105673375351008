import axios from 'axios'
import BaseService from '../BaseService'
import { Response } from '../BaseService/declarations'
import { Country } from './declarations'

export class CountryService extends BaseService<Country> {
  protected name: string = 'country'

  async find(): Promise<Country[]> {
    try {
      const response = await axios.get<Response<Country[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders()
      )
      const data = response.data.data as Country[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new CountryService()
export default service
