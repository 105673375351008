import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import useReservesPage from 'hooks/ReservesPage'
import { ReservesColumns } from './ReservesColumns'

const ReservesTable = () => {
  const {
    loading,
    params,
    reserves,
    count,
    onChangePage,
    cancelReserve,
    viewReserve,
  } = useReservesPage()
  const dataSource = reserves.map((reserve) => ({
    key: reserve.id,
    ...reserve,
  }))
  return (
    <YachtsTable
      loading={loading}
      dataSource={dataSource}
      count={count}
      pagination={params}
      type={ETypeTable.RESERVES}
      onChangePage={onChangePage}
      columns={ReservesColumns({
        cancelReserve,
        viewReserve,
      })}
    />
  )
}

export default ReservesTable
