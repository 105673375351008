import { useTranslation } from 'react-i18next'
import { Reserve } from 'api/Reserve/declarations'
import { TableHeader } from 'components/atoms/Table'
import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import { EReserveTap } from './ReservesView'
import { PaginationData } from 'api/BaseService/declarations'
import { formatDate, formatHour } from 'utils/common'
import { getPathnameReserve, getReserveStatus } from 'utils/reserves'
import { Link } from 'react-router-dom'

type Props = {
  reserves: Reserve[]
  tap: EReserveTap
  loading: boolean
  count: number
  pagination: PaginationData
  onChangePage: (page: number) => void
}

const empty_style: React.CSSProperties = {
  paddingTop: '25%',
}

const table_style: React.CSSProperties = {
  paddingTop: 20,
}
const columns = (t) => [
  {
    title: <TableHeader>{t('reserves.table.row.code')}</TableHeader>,
    dataIndex: 'status',
    render: (text: string, record: Reserve) => (
      <Link to={getPathnameReserve(record)}>#{record.code}</Link>
    ),
  },
  {
    title: <TableHeader>{t('table.row.status')}</TableHeader>,
    dataIndex: 'status',
    render: (text: string, record: Reserve) => getReserveStatus(record),
  },
  {
    title: <TableHeader>{t('reserves.table.row.location')}</TableHeader>,
    dataIndex: 'location',
    render: (text: string, record) => record?.location?.name || 'N/D',
  },
  {
    title: <TableHeader>{t('reserves.table.row.yacht')}</TableHeader>,
    dataIndex: 'yacht',
    render: (text: string, record) => record?.yacht?.name || 'N/D',
  },
  {
    title: <TableHeader>{t('reserves.table.row.date')}</TableHeader>,
    dataIndex: 'date',
    render: (text: string, record) => formatDate(record?.start_date),
  },
  {
    title: <TableHeader>{t('reserves.table.row.start_hour')}</TableHeader>,
    dataIndex: 'start_hour',
    render: (text: string, record) => formatHour(record?.start_date),
  },
  {
    title: <TableHeader>{t('reserves.table.row.end_hour')}</TableHeader>,
    dataIndex: 'end_hour',
    render: (text: string, record) => formatHour(record?.end_date),
  },
]

export const ReservesTable = ({
  reserves,
  tap,
  loading,
  count,
  pagination,
  onChangePage,
}: Props) => {
  const { t } = useTranslation()
  return (
    <YachtsTable
      dataSource={reserves}
      columns={columns(t)}
      loading={loading}
      type={ETypeTable.RESERVES}
      count={count}
      pagination={pagination}
      onChangePage={onChangePage}
      style={table_style}
      empty={t(`reserves.empty.${tap}`)}
    />
  )
}
