import i18n from 'i18next'
import { Col, Modal, Row } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Yacht } from 'api/Yacht/declarations'
import { EditButton, RemoveButton, TableHeader } from 'components/atoms/Table'
import { ActiveSelector } from 'components/organisms/Users/Selector/ActiveSelector'
import { getMembershipsPrice, getPublicPrice, getSize } from 'utils/yachts'
import { ImageButton } from 'components/atoms/Table/ActionButtons/ImagesButton'
import { MapButton } from 'components/atoms/Table/ActionButtons/MapButton'

const { confirm } = Modal

type Props = {
  handledOpenMapModalForm: (Yacht: Yacht) => void
  handledOpenImagesModalForm: (yacht: Yacht) => void
  handledOpenYachtModalForm: (yacht?: Yacht) => void
  onActive?: (yacht: Yacht) => void
  onDelete?: (yacht: Yacht) => void
}
export const YachtsColumns = ({
  handledOpenMapModalForm,
  handledOpenYachtModalForm,
  handledOpenImagesModalForm,
  onActive,
  onDelete,
}: Props) => [
  {
    title: <TableHeader>{i18n.t('yachts.table.row.order')}</TableHeader>,
    dataIndex: 'priority',
    key: 'priority',
  },
  {
    title: <TableHeader>{i18n.t('table.row.name')}</TableHeader>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <TableHeader>{i18n.t('table.row.status')}</TableHeader>,
    dataIndex: 'status',
    render: (text: string, record) => {
      const onChange = () => {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: i18n.t('yachts.confirm.status'),
          onOk() {
            onActive(record)
          },
        })
      }
      return (
        <ActiveSelector
          onChange={onChange}
          style={{ width: 130 }}
          value={record?.active}
        />
      )
    },
  },
  {
    title: <TableHeader>{i18n.t('yachts.table.row.public_price')}</TableHeader>,
    dataIndex: 'public_price',
    render: (text: string, record: Yacht) => getPublicPrice(record),
  },
  {
    title: (
      <TableHeader>{i18n.t('yachts.table.row.memberships_price')}</TableHeader>
    ),
    dataIndex: 'memberships_price',
    render: (text: string, record: Yacht) => getMembershipsPrice(record),
  },
  {
    title: <TableHeader>{i18n.t('yachts.table.row.size')}</TableHeader>,
    dataIndex: 'size',
    render: (text: string, record: Yacht) => getSize(record),
  },
  {
    title: <TableHeader>{i18n.t('yachts.table.row.location')}</TableHeader>,
    dataIndex: 'location',
    render: (text: string, record: Yacht) => record?.location?.name,
  },
  {
    title: (
      <TableHeader width="180px">{i18n.t('table.row.options')}</TableHeader>
    ),
    dataIndex: 'options',
    fixed: 'right',
    render: (text: string, record) => {
      const onClickImages = () => handledOpenImagesModalForm(record)
      const onClickMap = () => handledOpenMapModalForm(record)
      const onClickEdit = () => handledOpenYachtModalForm(record)
      const onClickDelete = () => onDelete(record)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />
          </Col>
          <Col>
            <ImageButton
              text={i18n.t('table.images')}
              onClick={onClickImages}
            />
          </Col>
          <Col>
            <MapButton text={i18n.t('table.map')} onClick={onClickMap} />
          </Col>
          <Col>
            <RemoveButton
              text={i18n.t('table.delete')}
              onClick={onClickDelete}
            />
          </Col>
        </Row>
      )
    },
  },
]
