import { HeartOutlined, HeartFilled } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { BaseActionButton, IActionButton } from './BaseActionButton'
import COLORS from 'shared/constants/colors'

const { AQUA_DARK } = COLORS

interface IFavoriteButton extends IActionButton {
  isFavorite: boolean
}

const FavoriteButton = ({
  isFavorite,
  onClick,
  ...restOfprops
}: IFavoriteButton) => {
  const HeartIcon = isFavorite ? HeartFilled : HeartOutlined

  return (
    <Popconfirm
      title="¿Quieres colocar como favorito?"
      okText="Sí"
      cancelText="Cancelar"
      onConfirm={onClick}
    >
      <BaseActionButton
        {...restOfprops}
        icon={<HeartIcon color={AQUA_DARK} />}
      />
    </Popconfirm>
  )
}

export { FavoriteButton }
