import { SelectProps, Select } from 'antd'
import { User } from 'api/User/declarations'
import { isNullOrUndefined } from 'util'
import { filterOptions } from 'utils/common'

type Props = {
  customers: User[]
} & SelectProps<'tag'>

export const CustomerSelector = ({ customers, ...props }: Props) => {
  const customers_options = customers
    .filter(({ business }) => !isNullOrUndefined(business))
    .map(({ id, business }) => ({
      label: business.name,
      value: id,
    }))

  return (
    <Select
      options={customers_options}
      {...props}
      filterOption={filterOptions}
      showSearch
    />
  )
}
