import { RouteComponentProps } from 'react-router-dom'
import { LocationsProvider } from 'components/context/LocationsContext'
import MainLayout from 'components/layouts/MainLayout'
import {
  NewLocationForm,
  LocationsTable,
  EditLocationForm,
} from 'components/organisms/Locations'
import { WithUserProps } from 'hooks/withUser'
import { EMenuKeys } from 'shared/constants/menuElements'
import { LocationsPageProvider } from 'components/context/LocationsPageContext'

const LocationsPage: React.FC<WithUserProps & RouteComponentProps> = (
  props
) => (
  <MainLayout {...props} active={EMenuKeys.LOCATIONS}>
    <LocationsProvider>
      <LocationsPageProvider>
        <LocationsTable />
        <NewLocationForm />
        <EditLocationForm />
      </LocationsPageProvider>
    </LocationsProvider>
  </MainLayout>
)

export default LocationsPage
