import { Business } from 'api/Business/declarations'
import { Convertor } from 'api/Convertor/declarations'

export const getConvertorsByBusiness = ({
  business,
  convertors,
}: {
  business: Business
  convertors: Convertor[]
}): Convertor[] => {
  if (!business) {
    return convertors
  }
  return convertors?.filter(({ businesses }) =>
    businesses?.some(({ id }) => id === business?.id)
  )
}
