import { useTranslation } from 'react-i18next'
import { MenuElement } from 'shared/constants/menuElements'

type ITranslate =
  | {
      key: string
    }
  | MenuElement

export default function UseMenuTitle() {
  const { t } = useTranslation()

  const translate = ({ key }: ITranslate) => {
    return t(`side-menu.${key}`)
  }

  return {
    translate,
  }
}
