import LocationsPageContext from 'components/context/LocationsPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useLocationsPageContext = () => {
  const contextName = 'LocationsPageContext'
  const contextValue = useContext(LocationsPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useLocationsPageContext
