import ChargesPageContext from 'components/context/ChargesPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useChargesPageContext = () => {
  const contextName = 'ChargesPageContext'
  const contextValue = useContext(ChargesPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useChargesPageContext
