import { Select, SelectProps } from 'antd'
import { Location } from 'api/Location/declarations'
import { filterOptions } from 'utils/common'

type Props = {
  locations: Location[]
} & SelectProps<'tag'>

export const LocationSelector = ({ locations, ...props }: Props) => {
  const locations_options = locations.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  return (
    <Select
      options={locations_options}
      {...props}
      filterOption={filterOptions}
    />
  )
}
