import axios from 'axios'
import { ELanguage } from 'interfaces/i18n'
import moment from 'moment'
import { sortQuery } from 'utils/common'
import { getUser } from 'utils/user'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from './../BaseService/declarations'
import {
  ContractURLResponse,
  User,
  UserCreate,
  UserParams,
  UserProfile,
  UserSort,
  UserUpdate,
  UploadContractPayload,
} from './declarations'

import FileDownload from 'js-file-download'
import mixpanelService from 'services/mixpanel'
import { Beneficiary } from 'api/Beneficiaries/declarations'

export class UserService extends BaseService<User> {
  protected name: string = 'user'

  async me(): Promise<UserProfile> {
    try {
      const response = await axios.get<Response<UserProfile>>(
        `${this.url}/${this.name}/me`,
        this.getHeaders()
      )
      const { data: axiosData } = response
      return axiosData.data as UserProfile
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async paginate(
    params: UserParams,
    sort?: UserSort
  ): Promise<PaginatedResponse<User>> {
    try {
      params.sort = sortQuery(sort)
      const response = await axios.get<Response<User[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      const data = response.data.data as User[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async find(params: UserParams): Promise<User[]> {
    try {
      const response = await axios.get<Response<User[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      if (params.csv) {
        const data = response.data.data
        const now = moment().format('DD-MM-YYYY')
        FileDownload(
          data as unknown as string,
          `users-${params.role}-${now}.csv`
        )
      }
      const data = response.data.data as User[]

      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async findById(userId): Promise<User> {
    try {
      const response = await axios.get<Response<User>>(
        `${this.url}/${this.name}/${userId}`,
        this.getHeaders()
      )
      const data = response.data.data as User

      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async updateLocale(locale: ELanguage): Promise<UserProfile> {
    try {
      const { id } = getUser(true) as User
      const response = await axios.put<Response<UserProfile>>(
        `${this.url}/${this.name}/${id}`,
        {
          profile: {
            locale,
          },
        },
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as UserProfile
      const { profile } = data
      data.user.profile = profile
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async updateActive(user: User | UserCreate): Promise<UserProfile> {
    try {
      const { id, active } = user
      const response = await axios.post<Response<UserProfile>>(
        `${this.url}/${this.name}/${id}/active`,
        {
          active: !active,
        },
        this.getHeaders()
      )
      const { data: axiosData } = response
      return axiosData.data as UserProfile
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async update(user_id: number, body: UserUpdate): Promise<UserProfile> {
    try {
      const response = await axios.put<Response<UserProfile>>(
        `${this.url}/${this.name}/${user_id}`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as UserProfile
      if (body.file) {
        await this.uploadAvatar(data?.user.id, body.file)
      }

      if (body.contract) {
        await this.uploadContract({
          partnerId: String(data?.user.id || ''),
          partnerCode: data?.user.partner_code || '',
          contract: body.contract,
        })
      }

      if (data.user.role === 'partner') {
        mixpanelService.track.partnerInfoEdited()
      } else {
        mixpanelService.track.resortInfoEdited()
      }
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async uploadAvatar(user_id: number, avatar): Promise<UserProfile> {
    try {
      const formData = new FormData()
      formData.append('avatar', avatar)
      const response = await axios.post<Response<UserProfile>>(
        `${this.url}/${this.name}/${user_id}/avatar`,
        formData,
        this.getHeadersMultipart()
      )
      const { data: axiosData } = response
      return axiosData.data as UserProfile
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async uploadContract({
    partnerId,
    partnerCode,
    contract,
  }: UploadContractPayload): Promise<ContractURLResponse> {
    try {
      mixpanelService.track.partnerContractUploadRequested({
        partnerCode: partnerCode,
      })
      const formData = new FormData()
      formData.append('contract', contract)

      const response = await axios.post<Response<ContractURLResponse>>(
        `${this.url}/${this.name}/${partnerId}/contract`,
        formData,
        this.getHeadersMultipart()
      )

      mixpanelService.track.partnerContractUploaded()

      const { data: axiosData } = response
      return axiosData.data as ContractURLResponse
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async getBeneficiariesByPartnerId(id: number): Promise<Beneficiary[]> {
    try {
      const response = await axios.get<Response<Beneficiary>>(
        `${this.url}/${this.name}/${id}/beneficiaries`,
        this.getHeaders()
      )
      const { data: axiosData } = response
      return axiosData.data as Beneficiary[]
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new UserService()
export default service
