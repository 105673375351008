import { Col, Modal, Row } from 'antd'
import { EditButton, TableHeader } from 'components/atoms/Table'
import { SaleRoomButton } from 'components/atoms/Table/ActionButtons/RoomSaleButton'
import { SeePartnersButton } from 'components/atoms/Table/ActionButtons/SeePartners'
import { Avatar } from 'components/molecules/Avatar'
import i18n from 'i18next'
import { concatListByKey } from 'utils/common'
import { PropsActions } from './UserColums'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ActiveSelector } from '../Selector/ActiveSelector'

const { confirm } = Modal

export const CustomerColumns = ({
  userSort,
  onChangeUserSort,
  onEditUser,
  onViewSaleRoom,
  onViewPartners,
  onActive,
}: PropsActions) => [
  {
    title: <TableHeader>{i18n.t('users.table.row.avatar')}</TableHeader>,
    dataIndex: 'avatar',
    render: (text: string, record) => {
      const onClickEdit = () => onEditUser(record)
      return <Avatar url={record.avatar} onEdit={onClickEdit} />
    },
  },
  {
    title: <TableHeader>{i18n.t('users.table.row.status')}</TableHeader>,
    dataIndex: 'status',
    render: (text: string, record) => {
      const onChange = () => {
        confirm({
          icon: <ExclamationCircleOutlined />,
          content: i18n.t('users.confirm.status'),
          onOk() {
            onActive(record)
          },
        })
      }
      return (
        <ActiveSelector
          onChange={onChange}
          style={{ width: 130 }}
          value={record?.active}
        />
      )
    },
  },
  {
    title: (
      <TableHeader
        name="first_name"
        onChange={onChangeUserSort}
        sort={userSort}
        showSort
      >
        {i18n.t('users.table.row.customer')}
      </TableHeader>
    ),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: (
      <TableHeader
        name="email"
        onChange={onChangeUserSort}
        sort={userSort}
        showSort
      >
        {i18n.t('users.table.row.email')}
      </TableHeader>
    ),
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <TableHeader>{i18n.t('users.table.row.phone')}</TableHeader>,
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: (
      <TableHeader>{i18n.t('users.table.row.partners_number')}</TableHeader>
    ),
    dataIndex: 'partners_number',
    key: 'partners_number',
  },
  {
    title: <TableHeader>{i18n.t('users.table.row.location')}</TableHeader>,
    dataIndex: 'location',
    key: 'location',
    render: (text: string, record) => record?.location?.name,
  },
  {
    title: (
      <TableHeader width="180px">
        {i18n.t('users.table.row.convertors')}
      </TableHeader>
    ),
    dataIndex: 'convertors',
    key: 'convertors',
    render: (text: string, record) =>
      concatListByKey(record?.convertors, 'name'),
  },
  {
    title: (
      <TableHeader
        name="created_at"
        onChange={onChangeUserSort}
        sort={userSort}
        showSort
      >
        {i18n.t('users.table.row.register_date')}
      </TableHeader>
    ),
    dataIndex: 'register_date',
    key: 'register_date',
  },
  {
    title: (
      <TableHeader width="150px">
        {i18n.t('users.table.row.options')}
      </TableHeader>
    ),
    key: 'options',
    fixed: 'right',
    render: (text: string, record) => {
      const onClickEdit = () => onEditUser(record)
      const OnClickViewSaleRoom = () => onViewSaleRoom(record)
      const OnClickViewPartners = () => onViewPartners(record)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />
          </Col>
          <Col>
            <SaleRoomButton
              text={i18n.t('table.view.sales_rooms')}
              onClick={OnClickViewSaleRoom}
            />
          </Col>
          <Col>
            <SeePartnersButton
              text={i18n.t('table.view.partners')}
              onClick={OnClickViewPartners}
            />
          </Col>
        </Row>
      )
    },
  },
]
