import { FC } from 'react'
import styled, { css } from 'styled-components'

interface Props {
  onClick?: (event) => void
  disabled?: boolean
}

type ContainerProps = {
  disabled: boolean
}
const Container = styled.div(
  ({ disabled = false }: ContainerProps) => css`
    width: 100%;
    cursor: ${disabled ? 'inline-block' : 'pointer'};
    opacity: ${disabled ? 0.2 : 1};
    :hover {
      opacity: ${disabled ? 0.2 : 0.5};
    }
  `
)

const ActionContainer: FC<Props> = ({ children, disabled, onClick }) => (
  <Container onClick={onClick} disabled={disabled}>
    {children}
  </Container>
)

export { ActionContainer }
