import moment, { Moment } from 'moment'
import { Prices } from './yachts'

export const FORMAT_DATE_FOR_IPUT = 'YYYY-MM-DD'

export function getUserToken() {
  throw new Error('Implement logic')
}

export const formatDate = (date, format = FORMAT_DATE_FOR_IPUT) => {
  if (date && moment(date).isValid()) {
    return moment(date).format(format)
  }
  return undefined
}

export const formatHour = (date: Date | string) => {
  if (!date || !moment(date).isValid()) {
    return 'N.D'
  }
  return moment(date).format('HH:mm')
}

// util in select where value !== label
export const filterOptions = (inputValue: string, option) => {
  const label = option?.label as string
  return label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
}

export const sortQuery = (rows_sort) => {
  if (!rows_sort) {
    return undefined
  }
  return Object.keys(rows_sort).reduce((query, row, index) => {
    if (rows_sort[row]) {
      return `${query}${query.length > 0 ? ',' : ''}${row} ${rows_sort[row]}`
    }
    return query
  }, '')
}

export const isValidateFormatImage = (format: string, excludePng = false) => {
  let formats = ['image/jpeg', 'image/jpg']
  if (!excludePng) {
    formats.push('image/png')
  }
  return formats.includes(format)
}

export const concatListByKey = (list: Array<any> = [], key: string): string => {
  return list?.reduce((concat, object) => {
    if (object[key]) {
      return `${concat}${concat.length > 0 ? ',' : ''} ${object[key]}`
    }
    return concat
  }, '')
}

export const concatPrices = (list: Array<Prices> = []): string => {
  return list?.reduce((concat, data) => {
    if (data.price) {
      return `${concat}${concat.length > 0 ? ',' : ''} ${formatPrice(
        data.price.toString()
      )}`
    }
    return concat
  }, '')
}

export const formatPrice = (value: string) => {
  if (value) {
    return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const parsePrice = (value: string) => {
  if (value) {
    return value.replace(/\$\s?|(,*)/g, '')
  }
}

export const getHourInDate = (date?: string | Date): number => {
  if (!date || !moment(date).isValid()) {
    return
  }
  return moment(date).hour()
}

export const getDateInHourFormatMoment = (hour: string): Moment => {
  const hourNumber = parseInt(hour)
  if (!hour || isNaN(hourNumber)) {
    return
  }
  return moment().hours(hourNumber).startOf('hour')
}

export const getStartOfDayForDate = (date: string | Date) => {
  if (!date || !moment(date).isValid()) {
    return
  }
  return moment(date).startOf('day').toDate()
}
