import YachtsPageContext, { IYachtsPageContext } from './YachtsPageContext'
import { Location } from 'api/Location/declarations'
import { Size } from 'api/Size/declarations'
import YachtService from 'api/Yacht'
import { Yacht, YachtsParams } from 'api/Yacht/declarations'
import { FC, useEffect, useState } from 'react'
import scrollToTop from 'utils/scroll'
import tryFunction from 'utils/try'
import { Additional } from 'api/Additional/declarations'
import { Service } from 'api/Service/declarations'
import { Membership } from 'api/Membership/declarations'
import ServiceService from 'api/Service'
import LocationService from 'api/Location'
import AdditionalService from 'api/Additional'
import MembershipService from 'api/Membership'
import SizeService from 'api/Size'
import { IPickerPosition } from 'components/molecules/Maps/FormMap'

export type YachtsShowModal = {
  yachtForm: boolean
  imagesForm: boolean
  mapForm: boolean
}

const initialParams: YachtsParams = {
  page: 1,
  per_page: 9,
  search: undefined,
  location_id: undefined,
  size_id: undefined,
}

const initialShowModal: YachtsShowModal = {
  yachtForm: false,
  imagesForm: false,
  mapForm: false,
}

const YachtsPageProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(true)
  const [yachts, setYachts] = useState<Yacht[]>([])
  const [count, setCount] = useState<number>(0)
  const [yachtEdit, setyachtEdit] = useState<Yacht>(undefined)
  const [showModal, setShowModal] = useState<YachtsShowModal>(initialShowModal)
  const [params, setParams] = useState<YachtsParams>(initialParams)
  const [locations, setLocations] = useState<Location[]>([])
  const [sizes, setSizes] = useState<Size[]>([])
  const [additionals, setAdditionals] = useState<Additional[]>([])
  const [services, setServices] = useState<Service[]>([])
  const [memberships, setMemberships] = useState<Membership[]>([])

  useEffect(() => {
    if (refresh) {
      loadData()
      setRefresh(false)
    }
  }, [params, showModal, , yachtEdit])

  useEffect(() => {
    if (yachtEdit) {
      updateModalYachtEdit()
    }
  }, [yachts])

  const loadData = () => {
    tryFunction(async () => {
      await getYachts()
      await getLocations()
      await getSizes()
      await getAdditionals()
      await getServices()
      await getMemberships()
      scrollToTop()
    }, setLoading)
  }

  const getYachts = async () => {
    const { data: yachts, count } = await YachtService.paginate(params)
    setYachts(yachts)
    setCount(count)
  }

  const getSizes = async () => {
    const sizes = await SizeService.find()
    setSizes(sizes)
  }

  const getLocations = async () => {
    const locations = await LocationService.find()
    setLocations(locations)
  }

  const getAdditionals = async () => {
    const additionals = await AdditionalService.find()
    setAdditionals(additionals)
  }

  const getServices = async () => {
    const services = await ServiceService.find()
    setServices(services)
  }

  const getMemberships = async () => {
    const memberships = await MembershipService.find()
    setMemberships(memberships)
  }

  const submitYachtForm = (data: Yacht) => {
    tryFunction(
      async () => {
        if (yachtEdit) {
          await YachtService.update(yachtEdit.id, data)
        } else {
          await YachtService.create(data)
        }
        setRefresh(true)
        handledCloseYachtModalForm()
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const submitUpdateMap = (location: IPickerPosition) => {
    tryFunction(
      async () => {
        await YachtService.updateMaps(yachtEdit.id, location)
        setRefresh(true)
        handledCloseMapModalForm()
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onActive = (yacht: Yacht) => {
    tryFunction(
      async () => {
        setyachtEdit(yacht)
        await YachtService.active(yacht)
        setRefresh(true)
        setyachtEdit(undefined)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onDelete = (yacht: Yacht) => {
    tryFunction(
      async () => {
        setyachtEdit(yacht)
        await YachtService.delete(yacht.id)
        setRefresh(true)
        setyachtEdit(undefined)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onUploadImage = (image, image_id?: number) => {
    tryFunction(
      async () => {
        const { id: yacth_id } = yachtEdit
        await YachtService.uploadImage(yacth_id, image, image_id)
        await getYachts()
        setRefresh(true)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onDeleteImage = (image_id?: number) => {
    tryFunction(
      async () => {
        const { id: yacth_id } = yachtEdit
        await YachtService.deleteImage(yacth_id, image_id)
        await getYachts()
        setRefresh(true)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onDefaultImage = (image_id?: number) => {
    tryFunction(
      async () => {
        const { id: yacth_id } = yachtEdit
        await YachtService.defaultImage(yacth_id, image_id)
        await getYachts()
        setRefresh(true)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const updateModalYachtEdit = async () => {
    const { id: yacth_id } = yachtEdit
    const yachtUpdate = yachts.find(({ id }) => id === yacth_id)
    if (yachtUpdate) {
      setyachtEdit(yachtUpdate)
    }
  }

  const onChangeFilter = (params: YachtsParams) => {
    setRefresh(true)
    setParams(params)
  }

  const onChangePage = (page: number) => {
    setRefresh(true)
    setParams({ ...params, page })
  }

  const handledOpenYachtModalForm = async (yacht?: Yacht) => {
    let yachtState = yacht
    if (yacht) {
      const addons = await YachtService.findYachtAddons(yacht.id)
      yachtState.additionals = addons
    }
    setyachtEdit(yachtState)
    setShowModal({ ...showModal, yachtForm: true })
  }

  const handledCloseYachtModalForm = () => {
    setyachtEdit(undefined)
    setShowModal({ ...showModal, yachtForm: false })
  }

  const handledOpenImagesModalForm = (yacht?: Yacht) => {
    setyachtEdit(yacht)
    setShowModal({ ...showModal, imagesForm: true })
  }

  const handledCloseImagesModalForm = () => {
    setyachtEdit(undefined)
    setShowModal({ ...showModal, imagesForm: false })
  }

  const handledOpenMapModalForm = (yacht?: Yacht) => {
    setyachtEdit(yacht)
    setShowModal({ ...showModal, mapForm: true })
  }

  const handledCloseMapModalForm = () => {
    setyachtEdit(undefined)
    setShowModal({ ...showModal, mapForm: false })
  }

  const createYacht = () => {
    handledOpenYachtModalForm()
  }

  const contextValue: IYachtsPageContext = {
    loading,
    locations,
    sizes,
    additionals,
    services,
    memberships,
    yachtEdit,
    params,
    count,
    yachts,
    showModal,
    submitYachtForm,
    onActive,
    onDelete,
    onChangeFilter,
    onChangePage,
    handledOpenYachtModalForm,
    handledCloseYachtModalForm,
    handledOpenImagesModalForm,
    handledCloseImagesModalForm,
    handledOpenMapModalForm,
    handledCloseMapModalForm,
    createYacht,
    onUploadImage,
    onDeleteImage,
    onDefaultImage,
    submitUpdateMap,
  }

  return (
    <YachtsPageContext.Provider value={contextValue}>
      {children}
    </YachtsPageContext.Provider>
  )
}

export { YachtsPageProvider }
