import i18n from 'i18next'
import { Col, Row } from 'antd'
import { EditButton, RemoveButton, TableHeader } from 'components/atoms/Table'
import { Membership } from 'api/Membership/declarations'
import { formatPrice } from 'utils/common'
import { SortDirection } from 'interfaces/shared'

type IMembershipsTableColumns = {
  onOpenEditMembershipForm: (membership: Membership) => void
  onDeleteMembership: (membership: Membership) => void
  priceSort: SortDirection
  onPriceSortChange: (key: string, direction: SortDirection) => void
}

export const MembershipsTableColumns = ({
  onOpenEditMembershipForm,
  onDeleteMembership,
  priceSort,
  onPriceSortChange,
}: IMembershipsTableColumns) => [
  {
    title: <TableHeader>{i18n.t('table.row.name')}</TableHeader>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: (
      <TableHeader
        showSort
        name="price"
        sort={{ price: priceSort }}
        onChange={onPriceSortChange}
      >
        {i18n.t('memberships.table.price')}
      </TableHeader>
    ),
    key: 'price',
    render: (text: string, { price }: Membership) => formatPrice(String(price)),
  },
  {
    title: (
      <TableHeader>{i18n.t('services.table.row.description')} (en)</TableHeader>
    ),
    key: 'description',
    render: (text: string, { description_en }: Membership) =>
      description_en || 'N/D',
  },
  {
    title: (
      <TableHeader>{i18n.t('services.table.row.description')} (es)</TableHeader>
    ),
    key: 'description',
    render: (text: string, { description_es }: Membership) =>
      description_es || 'N/D',
  },
  {
    title: (
      <TableHeader width="150px">{i18n.t('table.row.options')}</TableHeader>
    ),
    key: 'options',
    render: (text: string, membership: Membership) => {
      const onClickEdit = () => onOpenEditMembershipForm(membership)
      const onClickDelete = () => onDeleteMembership(membership)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />
          </Col>
          <Col>
            <RemoveButton
              text={i18n.t('table.delete')}
              onClick={onClickDelete}
            />
          </Col>
        </Row>
      )
    },
  },
]
