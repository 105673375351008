import { Additional } from 'api/Additional/declarations'
import { Membership } from 'api/Membership/declarations'
import { Reserve } from 'api/Reserve/declarations'
import { Yacht } from 'api/Yacht/declarations'
import { FORMAT_DATE_URL } from 'components/context/CalendarPageContext'
import i18n from 'i18next'
import moment, { Moment } from 'moment'
import routes from 'shared/constants/routes'
import { getPricePerAdditionals, getPricePerHourYacht } from './yachts'

export type IReserveHours = {
  start_hour: string | Moment
  end_hour: string | Moment
}
export const FORMAT_HOUR = 'HH'

export const getReserveStatus = (reserve: Reserve) => {
  const status = reserve.cancel ? 'cancel' : reserve.status

  return i18n.t(`reserves.status.${status}`)
}

export const getReserveDateParamUrl = (date: Date) =>
  moment(date).format(FORMAT_DATE_URL)

export const getParamsDate = (date: string) =>
  moment(date, FORMAT_DATE_URL).toDate()

export const getAdditionalsInReserve = (reserve: Reserve) =>
  reserve.yachts_additionals.map(({ additional }) => additional)

export const getPathnameReserve = (reserve: Reserve) => {
  const date = getReserveDateParamUrl(reserve.start_date as Date)
  return routes.reserve(date, reserve.id)
}

export const getValueFormStartAndEndDate = ({ start_hour, end_hour, date }) => {
  const start_date = moment(date)
    .hour(start_hour.hour())
    .startOf('hour')
    .toDate()
  const end_date = moment(date).hour(end_hour.hour()).startOf('hour').toDate()

  return {
    start_date,
    end_date,
  }
}

export const getInitilaFormValueStartAndEndHour = (
  reserve: Reserve
): IReserveHours => {
  const { start_date, end_date } = reserve
  const start_hour = moment(start_date)
  const end_hour = moment(end_date)
  return {
    start_hour,
    end_hour,
  }
}

export const getReserveHours = (reserveHours): number | undefined => {
  const { start_hour, end_hour } = reserveHours ?? {}
  if (!start_hour || !end_hour) {
    return
  }
  return end_hour.diff(start_hour, 'hour')
}

export const calculateAmount = ({
  hoursNumber,
  yacht,
  membership,
  additionals,
}: {
  hoursNumber: number
  yacht: Yacht
  membership?: Membership
  additionals?: Additional[]
}) => {
  const priceByHour = getPricePerHourYacht({ yacht, membership })
  const amountAdditionals = getPricePerAdditionals({ additionals, membership })
  return {
    total: priceByHour * hoursNumber + amountAdditionals,
    amountAdditional: amountAdditionals,
  }
}

export const getPriceAdditional = (reserve: Reserve) => {
  const { user } = reserve
  const additionals = getAdditionalsInReserve(reserve)
  const { membership } = user
  return getPricePerAdditionals({ additionals, membership })
}
