import { Country } from 'api/Country/declarations'
import { Membership } from 'api/Membership/declarations'
import { Location } from 'api/Location/declarations'
import {
  EStatusPartner,
  EUserRole,
  User,
  UserCreate,
  UserParams,
  UserSort,
  UserUpdate,
} from 'api/User/declarations'
import { SortDirection } from 'interfaces/shared'
import { createContext } from 'react'
import { IUserShowModal } from './UsersPageProvider'
import { Convertor } from 'api/Convertor/declarations'

export type UserPageRole = EUserRole.CUSTOMER | EUserRole.PARTNER

interface IUsersPageContext {
  isPartnerPage: boolean
  role: UserPageRole
  loading: boolean
  userEdit: UserCreate
  count: number
  users: UserCreate[]
  customers: User[]
  countries: Country[]
  memberships: Membership[]
  locations: Location[]
  userParams: UserParams
  userSort: UserSort
  showModal: IUserShowModal
  submitUserForm: (data: UserUpdate | UserCreate) => void
  updatePartnerStatus: (user: UserCreate, status: EStatusPartner) => void
  updateActive: (user: UserCreate) => void
  getReport: (requestedPartners: boolean) => void
  onChangeUserSort: (key: string, direction: SortDirection) => void
  onChangeFilter: (params: UserParams) => void
  onChangePage: (page: number) => void
  createUser: () => void
  handledOpenUserModalForm: (user: UserCreate) => void
  handledCloseUserModalForm: () => void
  handledOpenReservesViewModal: (user: UserCreate) => void
  handledCloseReservesViewModal: () => void
  handledOpenPartnersViewModal: (user: UserCreate) => void
  handledClosePartnersViewModal: () => void
  handledOpenSalesRoomViewModal: (user: UserCreate) => void
  handledCloseSalesRoomViewModal: () => void
  convertors: Convertor[]
}

const UsersPageContext = createContext<IUsersPageContext | undefined>(undefined)

export default UsersPageContext
export type { IUsersPageContext }
