import { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Divider } from 'antd'
import { FormFooter } from 'components/molecules/Form/FormFooter'
import { scrollToTopFullscreenModal } from 'utils/scroll'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import {
  getFormPricesForSubmit,
  getInitialFormValuesPrices,
} from 'utils/yachts'
import useAdditionalsPage from 'hooks/useAdditionalsPageContext'
import { AdditionalPricesInputs } from './AdditionalPricesInputs'

const DESCRIPTION_TEX_AREA = { max: 500, height: 150 }

export const STYLE_INPUT_NUMBER = { width: '100%' }

export const AdditonalForm = () => {
  const { t } = useTranslation()
  const {
    additionalEdit,
    loading,
    memberships,
    submitAdditionalForm: submit,
  } = useAdditionalsPage()
  const [form] = Form.useForm()
  const [allowEdition, setAllowEdition] = useState(!additionalEdit)

  useEffect(() => {
    if (allowEdition) {
      scrollToTopFullscreenModal()
    }
  }, [allowEdition])

  useEffect(() => {
    resetForm()
  }, [additionalEdit, form])

  const toggleAllowEdition = () => setAllowEdition(!allowEdition)

  const onCancelEdition = () => {
    resetForm()
    setAllowEdition(false)
  }

  const resetForm = () => {
    if (additionalEdit) {
      const { prices: additonalsPrices } = additionalEdit
      const prices = getInitialFormValuesPrices(additonalsPrices)

      form.setFieldsValue({
        ...additionalEdit,
        ...prices,
      })
    }
  }

  const onSubmit = (additional) => {
    const prices = getFormPricesForSubmit(additional, memberships)
    submit({
      ...additional,
      prices,
    })
  }

  const getInitialValues = () => {
    const { name_en, name_es, description } = additionalEdit ?? {}
    const additionalPrices = getInitialFormValuesPrices(additionalEdit?.prices)

    return {
      name_en,
      name_es,
      description,
      ...additionalPrices,
    }
  }

  const initialValues = getInitialValues()

  return (
    <Form onFinish={onSubmit} form={form} initialValues={initialValues}>
      <Row>
        <Col xs={24}>
          <Row gutter={[12, 12]}>
            <Divider>{t('table.row.name')} (en)</Divider>
            <Col xs={24}>
              <Form.Item
                name="name_en"
                rules={[
                  {
                    required: true,
                    message: t('additionals.form.required.name'),
                  },
                ]}
              >
                <Input type="text" disabled={!allowEdition} />
              </Form.Item>
            </Col>
            <Divider>{t('table.row.name')} (es)</Divider>
            <Col xs={24}>
              <Form.Item
                name="name_es"
                rules={[
                  {
                    required: true,
                    message: t('additionals.form.required.name'),
                  },
                ]}
              >
                <Input type="text" disabled={!allowEdition} />
              </Form.Item>
            </Col>
            <Divider>{t('additionals.form.div.prices')}</Divider>
            <Col xs={24}>
              <AdditionalPricesInputs allowEdition={allowEdition} />
            </Col>
            <Divider>{t('additionals.form.div.description')}</Divider>
            <Col xs={24}>
              <Form.Item name="description">
                <TextArea
                  maxLength={DESCRIPTION_TEX_AREA.max}
                  style={{ height: DESCRIPTION_TEX_AREA.height }}
                  disabled={!allowEdition}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <FormFooter
            allowEdition={allowEdition}
            loading={loading}
            toggleAllowEdition={toggleAllowEdition}
            onCancelEdition={onCancelEdition}
            dataForm={additionalEdit}
          />
        </Col>
      </Row>
    </Form>
  )
}
