import FullscreenModal from 'components/molecules/FullscreenModal'
import useLocationsPageContext from 'hooks/useLocationsPageContext'
import { LocationForm } from '.'

const EditLocationForm = () => {
  const {
    editFormState: { isOpen, location },
    onCloseEditLocationForm,
    onUpdateLocation,
  } = useLocationsPageContext()

  return (
    <FullscreenModal
      visible={isOpen}
      maskClosable={false}
      onCancel={onCloseEditLocationForm}
      maxWidth="450px"
      destroyOnClose
      height="fit-content"
      loading={false}
    >
      <LocationForm
        initialValues={location}
        isEditionForm
        onSubmit={onUpdateLocation}
      />
    </FullscreenModal>
  )
}

export { EditLocationForm }
