import { Additional } from 'api/Additional/declarations'
import { Location } from 'api/Location/declarations'
import { Membership } from 'api/Membership/declarations'
import { Service } from 'api/Service/declarations'
import { Size } from 'api/Size/declarations'
import { IImage } from 'interfaces/image'

export type Yacht = {
  id?: number
  name?: string
  description_en?: string
  description_es?: string
  model?: string
  active?: boolean
  passengers_number?: number
  bathrooms_number?: number
  bedrooms_number?: number
  min_hours?: number
  beds_number?: number
  latitude?: string
  longitude?: string
  priority?: number
  location_id?: number
  location?: Location
  size_id?: number
  size?: Size
  prices?: YachtPrice[]
  created_at?: Date
  images?: IImage[]
  default_image?: IImage
  additionals_ids?: number[]
  additionals?: Additional[]
  services?: YachtService[]
  available_hours?: YatchAvailableHours[]
}

export type FindYachtsParams = {
  location_id?: number
  without_images?: boolean
  dropdown?: boolean
}

export type YachtsParams = {
  page: number
  per_page: number
  search?: string
  location_id?: number
  size_id?: number
}

export enum EYachtPriceInterval {
  HOUR = 'hour',
}

export type YachtPrice = {
  price?: number
  membership_id?: number
  membership?: Membership
  interval?: EYachtPriceInterval
  yacht_id?: number
}

export type YatchAvailableHours = {
  start_hour: string | number
  end_hour: string | number
  membership_id?: number
  membership?: Membership
  yacht_id?: number
}

export type YachtService = {
  service_id: number
  quantity: number
  service?: Service
}
