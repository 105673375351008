import { createContext, Dispatch, SetStateAction } from 'react'
import type { ICreateMembership, Membership } from 'api/Membership/declarations'
import IImageData, { SortDirection } from 'interfaces/shared'

interface IEditFormState {
  isOpen: boolean
  membership?: Membership
}

interface IMembershipsPageContext {
  memberships: Membership[]
  createMembershipFormIsOpen: boolean
  onOpenCreateMembershipForm: () => void
  onCloseCreateMembershipForm: () => void
  editFormState: IEditFormState
  onCloseEditMembershipForm: () => void
  onOpenEditMembershipForm: (membership: Membership) => void
  setPriceSort: Dispatch<SetStateAction<SortDirection>>
  priceSort: SortDirection
  onCreateMembership: (
    body: ICreateMembership,
    images: IImageData[]
  ) => Promise<void>
  onUpdateMembership: (
    body: ICreateMembership,
    images: IImageData[]
  ) => Promise<void>
  onDeleteMembership: (membership: Membership) => void
}

const MembershipsPageContext = createContext<
  IMembershipsPageContext | undefined
>(undefined)

export type { IMembershipsPageContext, IEditFormState }
export default MembershipsPageContext
