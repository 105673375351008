import FullscreenModal from 'components/molecules/FullscreenModal'
import useConvertorsPageContext from 'hooks/useConvertorsPageContext'
import { ConvertorForm } from './ConvertorForm'

const EditConvertorForm = () => {
  const { editFormState, onCloseEditConvertorForm, onUpdateConvertor } =
    useConvertorsPageContext()

  return (
    <FullscreenModal
      visible={editFormState.isOpen}
      maskClosable={false}
      onCancel={onCloseEditConvertorForm}
      maxWidth="450px"
      destroyOnClose
      height="fit-content"
      loading={false}
    >
      <ConvertorForm
        initialValues={editFormState.convertor}
        isEditionForm
        onSubmit={onUpdateConvertor}
      />
    </FullscreenModal>
  )
}

export { EditConvertorForm }
