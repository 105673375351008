import { EStatusPartner, EUserRole, User } from 'api/User/declarations'
import i18n from 'i18next'

export const USER_KEY = 'user'

export const getUser = (validateUser = false): User | null => {
  const userString = localStorage.getItem(USER_KEY)
  if (!userString) {
    if (validateUser) {
      throw new Error(i18n.t('error.not-found-user'))
    }
    return null
  }
  const user = JSON.parse(userString)
  return user
}

export const isCustomer = (role) => role === EUserRole.CUSTOMER

export const isPartner = (role) => role === EUserRole.PARTNER

export const isAdmin = (role) => role === EUserRole.ADMIN

export const tPartnerStatus = (partner_status: EStatusPartner) =>
  i18n.t(`users.partner_status.${partner_status}`)

export const tActive = (status: boolean) => i18n.t(`users.active.${status}`)

export const getUserFullName = (user: User) => {
  if (!user) {
    return 'N/D'
  }
  const { first_name, last_name } = user
  return `${first_name} ${last_name}`
}

export const getMembership = (partner: User) => {
  if (partner?.partner_status !== EStatusPartner.PROCESSABLE) {
    return
  }
  return partner?.membership
}
