import { Button, Tooltip, ButtonProps } from 'antd'
import { ReactNode } from 'react'

interface IActionButton extends ButtonProps {
  text: string
  onClick?: () => void
}

interface IBaseActionButton extends IActionButton {
  icon: ReactNode
}

const BaseActionButton = ({
  text,
  type = 'link',
  ...buttonProps
}: IBaseActionButton) => (
  <Tooltip title={text}>
    <Button type={type} shape="circle" {...buttonProps} />
  </Tooltip>
)

export { BaseActionButton }
export type { IActionButton }
