import mixpanel from 'mixpanel-browser'

const EnterPageEvents = () => {
  const userEnterYachtsPage = () => {
    mixpanel.track('User Enter Yachts Page')
  }

  const userEnterAdditionalPage = () => {
    mixpanel.track('User Enter Additionals Page')
  }

  const userEnterServicesPage = () => {
    mixpanel.track('User Enter Services Page')
  }

  const userEnterMembershipsPage = () => {
    mixpanel.track('User Enter Memberships Page')
  }

  const userEnterChargesPage = () => {
    mixpanel.track('User Enter Charges Page')
  }

  const userEnterBankAccountsPage = () => {
    mixpanel.track('User Enter Bank Accounts Page')
  }

  const userEnterBlockedDatesPage = () => {
    mixpanel.track('User Enter Blocked Dates Page')
  }

  const userEnterLocationsPage = () => {
    mixpanel.track('User Enter Locations Page')
  }

  const userEnterAfterSaleGiftsPage = () => {
    mixpanel.track('User Enter After-Sale Gifts Page')
  }

  return {
    userEnterYachtsPage,
    userEnterAdditionalPage,
    userEnterServicesPage,
    userEnterMembershipsPage,
    userEnterChargesPage,
    userEnterBankAccountsPage,
    userEnterBlockedDatesPage,
    userEnterLocationsPage,
    userEnterAfterSaleGiftsPage,
  }
}

export default EnterPageEvents
