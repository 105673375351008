import i18n from 'i18next'
import { Col, Row } from 'antd'
import { EditButton, RemoveButton, TableHeader } from 'components/atoms/Table'
import { ImageButton } from 'components/atoms/Table/ActionButtons/ImagesButton'
import { Service } from 'api/Service/declarations'

type Props = {
  handledOpenImagesModalForm: (service: Service) => void
  handledOpenServiceModalForm: (service?: Service) => void
  onDelete?: (service: Service) => void
}
export const ServicesColumns = ({
  handledOpenServiceModalForm,
  handledOpenImagesModalForm,
  onDelete,
}: Props) => [
  {
    title: (
      <TableHeader width="200px">{i18n.t('table.row.name')} (en)</TableHeader>
    ),
    dataIndex: 'name_en',
    key: 'name_en',
  },
  {
    title: (
      <TableHeader width="200px">{i18n.t('table.row.name')} (es)</TableHeader>
    ),
    dataIndex: 'name_es',
    key: 'name_es',
  },
  {
    title: (
      <TableHeader>{i18n.t('services.table.row.description')}</TableHeader>
    ),
    dataIndex: 'description',
    render: (text: string, record: Service) => record.description || 'N/D',
  },
  {
    title: (
      <TableHeader width="150px">{i18n.t('table.row.options')}</TableHeader>
    ),
    dataIndex: 'options',
    fixed: 'right',
    render: (text: string, record) => {
      const onClickImages = () => handledOpenImagesModalForm(record)
      const onClickEdit = () => handledOpenServiceModalForm(record)
      const onClickDelete = () => onDelete(record)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />
          </Col>
          <Col>
            <ImageButton
              text={i18n.t('table.images')}
              onClick={onClickImages}
            />
          </Col>
          <Col>
            <RemoveButton
              text={i18n.t('table.delete')}
              onClick={onClickDelete}
            />
          </Col>
        </Row>
      )
    },
  },
]
