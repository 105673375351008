import axios from 'axios'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from '../BaseService/declarations'
import { Movement, MovementParams } from './declarations'

export class MovementService extends BaseService<Movement> {
  protected name: string = 'banking-movement'

  async find(params?: MovementParams): Promise<Movement[]> {
    try {
      const response = await axios.get<Response<Movement[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      const data = response.data.data as Movement[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async findWithPagination(
    params?: MovementParams
  ): Promise<PaginatedResponse<Movement>> {
    try {
      const response = await axios.get<Response<Movement[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      const data = response.data.data as Movement[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async create(body: Movement) {
    try {
      const response = await axios.post<Response<Movement>>(
        `${this.url}/${this.name}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as Movement
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async update(movement_id: number, body: Movement) {
    try {
      const response = await axios.put<Response<Movement>>(
        `${this.url}/${this.name}/${movement_id}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as Movement
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async delete(movement_id: number) {
    try {
      const response = await axios.delete<Response<Movement>>(
        `${this.url}/${this.name}/${movement_id}`,
        this.getHeaders()
      )
      const data = response.data.data as Movement
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async uploadFile(movement_id: number, file) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const response = await axios.post<Response<Movement>>(
        `${this.url}/${this.name}/${movement_id}/file`,
        formData,
        this.getHeadersMultipart()
      )
      const { data: axiosData } = response
      return axiosData.data as Movement
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new MovementService()
export default service
