import { EUserRole } from 'api/User/declarations'
import withUser, { WithUserProps } from 'hooks/withUser'
import React from 'react'
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'
import routes from 'shared/constants/routes'

export type RouteParams = any

type Props = RouteProps &
  WithUserProps & {
    component: React.ComponentType<
      RouteComponentProps<RouteParams> & WithUserProps
    >
  }

const PrivateRoute = ({
  component: Component,
  setUser,
  clearUser,
  isAdmin,
  rememberMe,
  ...rest
}: Props) => {
  const { login } = routes
  const { user } = rest
  const accessPanel =
    user?.role && [EUserRole.ADMIN, EUserRole.CUSTOMER].includes(user.role)

  return (
    <Route
      {...rest}
      render={(props) =>
        accessPanel ? (
          <Component
            setUser={setUser}
            clearUser={clearUser}
            isAdmin={isAdmin}
            user={user}
            rememberMe={rememberMe}
            {...props}
          />
        ) : (
          <Redirect to={login} />
        )
      }
    />
  )
}

export default withUser(PrivateRoute)
