import { EReserveTap } from 'components/organisms/Users/ReservesViewModal/ReservesView/ReservesView'
import mixpanel from 'mixpanel-browser'

interface ITrackReportRequested {
  resort: number
  sale_room: number
  status: string
  membership: number
  startDate: string
  endDate: string
  search: string
}

interface ITrackNewUserAdded {
  email: string
  name: string
  resort: string
}

interface ITrackVIewPartnerReserves {
  section: EReserveTap | string
}

interface ITrackPartnerContractUploadRequested {
  partnerCode: string
}

const PartnersFlowEvens = () => {
  const enterPartnersPage = () => {
    mixpanel.track('Enter Partners Page')
  }

  const partnersReportRequested = ({
    resort,
    sale_room,
    status,
    membership,
    startDate,
    endDate,
    search,
  }: ITrackReportRequested) => {
    mixpanel.track('Partner Report Requested', {
      Filters: {
        Resort: resort,
        Sale_room: sale_room,
        Status: status,
        Membership: membership,
        StartDate: startDate,
        EndDate: endDate,
        Search: search,
      },
    })
  }

  const partnersReportExported = () => {
    mixpanel.track('Partners Report Exported')
  }

  const newPartnerAdded = ({ email, name, resort }: ITrackNewUserAdded) => {
    mixpanel.track('New Partner Added', {
      Email: email,
      Name: name,
      Resort: resort,
    })
  }

  const partnerInfoEdited = () => {
    mixpanel.track('Partner Info Edited')
  }

  const viewPartnerReserves = ({ section }: ITrackVIewPartnerReserves) => {
    mixpanel.track('View Partner Reserves', { Section: section })
  }

  const partnerStatusModified = () => {
    mixpanel.track('Partner Status Modified')
  }

  const partnerContractUploadRequested = ({
    partnerCode,
  }: ITrackPartnerContractUploadRequested) => {
    mixpanel.track('Partner Contract Upload Requested', {
      'Partner Code': partnerCode,
    })
  }

  const partnerContractUploaded = () => {
    mixpanel.track('Partner Contract Uploaded')
  }

  return {
    enterPartnersPage,
    partnersReportRequested,
    partnersReportExported,
    newPartnerAdded,
    partnerInfoEdited,
    viewPartnerReserves,
    partnerStatusModified,
    partnerContractUploadRequested,
    partnerContractUploaded,
  }
}

export default PartnersFlowEvens
