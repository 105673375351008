import { EStatusPartner, UserCreate, UserSort } from 'api/User/declarations'
import { SortDirection } from 'interfaces/shared'
import { CustomerColumns } from './CustomerColumns'
import { PartnerColumns } from './PartnerColumns'

export type PropsActions = {
  userSort: UserSort
  onEditUser: (user: UserCreate) => void
  onActive?: (user: UserCreate) => void
  onChangePartnerStatus?: (user: UserCreate, status: EStatusPartner) => void
  onReservesView?: (user: UserCreate) => void
  onChangeUserSort?: (key: string, direction: SortDirection) => void
  onViewSaleRoom?: (user: UserCreate) => void
  onViewPartners?: (user: UserCreate) => void
}

export type Props = {
  isPartnerPage: boolean
  isAdmin: boolean
} & PropsActions

export const UserColumns = ({
  isAdmin,
  userSort,
  isPartnerPage,
  onEditUser,
  onChangeUserSort,
  onReservesView,
  onChangePartnerStatus,
  onViewSaleRoom,
  onViewPartners,
  onActive,
}: Props) => {
  return isPartnerPage
    ? PartnerColumns({
        isAdmin,
        userSort,
        onChangeUserSort,
        onEditUser,
        onReservesView,
        onChangePartnerStatus,
      })
    : CustomerColumns({
        userSort,
        onChangeUserSort,
        onEditUser,
        onViewSaleRoom,
        onViewPartners,
        onActive,
      })
}
