import { useState, useEffect } from 'react'
import { Form, Row, Col, InputNumber, Card, TimePicker, Button } from 'antd'
import { FormFooter } from 'components/molecules/Form/FormFooter'
import scrollToTop from 'utils/scroll'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import { LocationSelector } from 'components/organisms/Location/Selector/LocationSelector'
import { YachtSelector } from 'components/organisms/Yachts/Selector/YachtSelector'
import { AdditionalSelector } from 'components/organisms/Yachts/Selector/AdditionalSelector'
import useReservePage from 'hooks/ReservePage'
import { getInitilaFormValueAdditional } from 'utils/yachts'
import {
  FORMAT_HOUR,
  getAdditionalsInReserve,
  getInitilaFormValueStartAndEndHour,
  getValueFormStartAndEndDate,
} from 'utils/reserves'
import { When } from 'react-if'
import { ReserveStatusSelector } from '../Selector/ReserveStatusSelector'
import { PaymentTypeSelector } from 'components/organisms/Charges/Selector/ChargeTypeSelector'
import { getAdditionalByYacht } from 'utils/Additional'
import { AppInput } from 'components/atoms/Form/AppInput'
import tryFunction from 'utils/try'
import mixpanelService from 'services/mixpanel'

const DESCRIPTION_TEX_AREA = { max: 400, height: 100 }

export const STYLE_INPUT_ANTD = { width: '100%' }

export const ReserveForm = () => {
  const { t } = useTranslation()
  const {
    loading,
    reserve,
    locations,
    yachts,
    yacht,
    locationId,
    reserveHour,
    amount,
    dateReserve,
    partner,
    onChangeLocation,
    onChangeYacth,
    onChangeStartHour,
    onChangeEndHour,
    onChangeAdditional,
    submitForm,
    validateUserEmail,
    additionals: allAdditionals,
  } = useReservePage()
  const [form] = Form.useForm()
  const [allowEdition, setAllowEdition] = useState<boolean>(!reserve)
  const [emailFieldIsInvalid, setEmailFieldIsInvalid] = useState<boolean>(true)
  const { start_hour, end_hour } = reserveHour ?? {}
  const isNotAlreadyPayment = !yacht || !start_hour || !end_hour
  const additionals = getAdditionalByYacht({
    yacht,
    additionals: allAdditionals,
  })
  const [emailInput, setEmailInput] = useState<string>(partner?.email || '')
  const [load, setLoading] = useState<boolean>(false)
  const getInitialValues = () => {
    const hours = reserve ? getInitilaFormValueStartAndEndHour(reserve) : {}
    const additionals_ids = reserve
      ? getInitilaFormValueAdditional(getAdditionalsInReserve(reserve))
      : []

    const payment_method_type = reserve?.charge?.payment_type
    const email = reserve?.user?.email

    return { ...reserve, ...hours, additionals_ids, email, payment_method_type }
  }

  const initialValues = getInitialValues()

  useEffect(() => {
    if (reserve && !reserve.cancel) {
      mixpanelService.track.userEnterBookingDetails({ bookingId: reserve.id })
    }
  }, [reserve])

  useEffect(() => {
    if (partner) {
      setEmailInput(partner.email)
      form.setFieldsValue({
        email: partner.email,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner])

  useEffect(() => {
    if (initialValues.email) {
      setEmailInput(initialValues.email)
      form.setFieldsValue({
        email: initialValues.email,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  useEffect(() => {
    if (allowEdition) {
      scrollToTop()
    }
  }, [allowEdition])

  useEffect(() => {
    resetForm()
    setAllowEdition(!reserve)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserve, form])

  const toggleAllowEdition = () => setAllowEdition(!allowEdition)

  const onCancelEdition = () => {
    resetForm()
    setAllowEdition(false)
  }

  const resetForm = () => {
    form.setFieldsValue(getInitialValues())
  }

  const onSubmit = (reserve) => {
    const dates = getValueFormStartAndEndDate({ ...reserve, date: dateReserve })
    submitForm({ ...reserve, ...dates, amount })
  }

  const validatorEndHour = {
    validator(_, value) {
      if (
        value?.startOf('hour') <=
        form.getFieldValue('start_hour')?.startOf('hour')
      ) {
        return Promise.reject(t('reserve.form.error.end_hour'))
      }
      return Promise.resolve()
    },
  }

  const validatorStartHour = {
    validator(_, value) {
      if (
        value?.startOf('hour') >=
        form.getFieldValue('end_hour')?.startOf('hour')
      ) {
        return Promise.reject(t('reserve.form.error.start_hour'))
      }
      return Promise.resolve()
    },
  }

  const checkEmailInput = (email: string, creatingReserve: boolean) => {
    tryFunction(
      async () => {
        if (creatingReserve && !email) {
          throw new Error(t('error.not-found-email'))
        }
        await validateUserEmail(email)
      },
      setLoading,
      true,
      '',
      t('form.email.success')
    )
  }

  const handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailInput(event.target.value)
    form.setFieldsValue({
      email: event.target.value,
    })
  }

  const evaluateEmailFieldValidState = (fields: any[]) => {
    const emailField = fields.find((field) => {
      return field.name.includes('email')
    })

    if (!emailField) return

    if (emailField.errors.length) {
      setEmailFieldIsInvalid(true)
    } else {
      setEmailFieldIsInvalid(false)
    }
  }

  return (
    <Card style={{ padding: '1%' }}>
      <Form
        onFinish={onSubmit}
        form={form}
        initialValues={initialValues}
        onFieldsChange={evaluateEmailFieldValidState}
      >
        <Row gutter={[16, 16]}>
          <Col xs={12}>
            <Form.Item
              label={t('reserve.form.status')}
              name="status"
              rules={[
                {
                  required: true,
                  message: t('reserve.form.required.status'),
                },
              ]}
            >
              <ReserveStatusSelector disabled={!allowEdition || !partner} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Row>
              <Col flex={5}>
                <Form.Item
                  name="email"
                  label={t('reserve.form.partner')}
                  rules={[
                    {
                      required: true,
                      message: t('reserve.form.required.partner'),
                    },
                    {
                      type: 'email',
                      message: t('error.email-format'),
                    },
                  ]}
                >
                  <AppInput
                    type="text"
                    value={emailInput}
                    disabled={Boolean(partner)}
                    onChange={handleEmailInputChange}
                  ></AppInput>
                </Form.Item>
              </Col>
              <Col flex={'auto'}>
                <When condition={!reserve}>
                  <Button
                    type="primary"
                    size="middle"
                    disabled={emailFieldIsInvalid || Boolean(partner)}
                    loading={load}
                    onClick={() => checkEmailInput(emailInput, true)}
                    style={{ width: '100%' }}
                  >
                    {t('reserve.form.check_email')}
                  </Button>
                </When>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t('reserve.form.location')}
              name="location_id"
              rules={[
                {
                  required: true,
                  message: t('reserve.form.required.location'),
                },
              ]}
            >
              <LocationSelector
                locations={locations}
                disabled={!!reserve || !partner}
                onChange={onChangeLocation}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t('reserve.form.yacht')}
              name="yacht_id"
              rules={[
                {
                  required: true,
                  message: t('reserve.form.required.yacht'),
                },
              ]}
            >
              <YachtSelector
                yachts={yachts}
                disabled={!!reserve || !locationId}
                onChange={onChangeYacth}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 12, offset: 12 }}>
            <Form.Item
              label={t('yachts.form.passengers_number')}
              name="passengers_number"
              rules={[
                {
                  required: true,
                  message: t('yachts.form.required.passengers_number'),
                },
              ]}
            >
              <InputNumber
                type="number"
                disabled={!allowEdition || (!reserve && !locationId)}
                min={1}
                style={STYLE_INPUT_ANTD}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t('reserve.form.start_hour')}
              name="start_hour"
              rules={[
                {
                  required: true,
                  message: t('reserve.form.required.start_hour'),
                },
                validatorStartHour,
              ]}
            >
              <TimePicker
                disabled={!!reserve || !yacht}
                format={FORMAT_HOUR}
                style={STYLE_INPUT_ANTD}
                onChange={onChangeStartHour}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t('reserve.form.end_hour')}
              name="end_hour"
              rules={[
                {
                  required: true,
                  message: t('reserve.form.required.end_hour'),
                },
                validatorEndHour,
              ]}
            >
              <TimePicker
                disabled={!!reserve || !yacht || !start_hour}
                format={FORMAT_HOUR}
                style={STYLE_INPUT_ANTD}
                onChange={onChangeEndHour}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="additionals_ids"
              label={t('reserve.form.additionals')}
            >
              <AdditionalSelector
                addittionals={additionals}
                disabled={!!reserve || isNotAlreadyPayment}
                mode="multiple"
                onChange={onChangeAdditional}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="payment_method_type"
              label={t('reserve.form.payment_type')}
              rules={[
                {
                  required: true,
                  message: t('reserve.form.required.payment_type'),
                },
              ]}
            >
              <PaymentTypeSelector
                disabled={!allowEdition || (!reserve && isNotAlreadyPayment)}
                isForm={true}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="admin_description"
              label={t('reserve.form.admin_description')}
            >
              <TextArea
                maxLength={DESCRIPTION_TEX_AREA.max}
                style={{ height: DESCRIPTION_TEX_AREA.height }}
                disabled={!allowEdition}
              />
            </Form.Item>
          </Col>
          <When condition={!!reserve && !!reserve?.partner_description}>
            <Col xs={24}>
              <Form.Item
                name="partner_description"
                label={t('reserve.form.partner_description')}
              >
                <TextArea
                  style={{ height: DESCRIPTION_TEX_AREA.height }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </When>
          <Col xs={24}>
            <FormFooter
              allowEdition={allowEdition}
              loading={loading}
              toggleAllowEdition={toggleAllowEdition}
              onCancelEdition={onCancelEdition}
              dataForm={reserve}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
