import { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Divider, InputNumber } from 'antd'
import { FormFooter } from 'components/molecules/Form/FormFooter'
import { scrollToTopFullscreenModal } from 'utils/scroll'
import useYachtsPage from 'hooks/useYachtsPageContext'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import { LocationSelector } from 'components/organisms/Location/Selector/LocationSelector'
import { YachtPricesInputs } from './YachtPricesInputs'
import {
  getFormSizeForSubmit,
  getFormPricesForSubmit,
  getFormYachtServicessForSubmit,
  getInitialFormValuesPrices,
  getInitialFormValuesServices,
  getInitilaFormValueAdditional,
  getInitilaFormValueSize,
  getInitialFormValuesHours,
  getFormHoursForSubmit,
} from 'utils/yachts'
import { YachtServicesInputs } from './YachtServicesInputs'
import { YachtAdditionalsInputs } from './YachtAdditionalsInputs'
import { YachtAvailableHoursInputs } from './YachtAvailableHoursInputs'

const DESCRIPTION_TEX_AREA = { max: 1000, height: 200 }

export const STYLE_INPUT_NUMBER = { width: '100%' }

export const YachtForm = () => {
  const { t } = useTranslation()
  const {
    yachtEdit,
    loading,
    locations,
    memberships,
    services,
    count,
    submitYachtForm: submit,
  } = useYachtsPage()
  const [form] = Form.useForm()
  const [allowEdition, setAllowEdition] = useState(!yachtEdit)
  const yachtOrderMax = yachtEdit ? count : count + 1

  useEffect(() => {
    if (allowEdition) {
      scrollToTopFullscreenModal()
    }
  }, [allowEdition])

  useEffect(() => {
    resetForm()
  }, [yachtEdit, form])

  const toggleAllowEdition = () => setAllowEdition(!allowEdition)

  const onCancelEdition = () => {
    resetForm()
    setAllowEdition(false)
  }

  const resetForm = () => {
    if (yachtEdit) {
      const {
        prices: yachtPrices,
        size: yachtSize,
        services: yachtServices,
        additionals,
      } = yachtEdit
      const prices = getInitialFormValuesPrices(yachtPrices)
      const size = getInitilaFormValueSize(yachtSize)
      const services = getInitialFormValuesServices(yachtServices)
      const additionals_ids = getInitilaFormValueAdditional(additionals)
      form.setFieldsValue({
        ...yachtEdit,
        ...prices,
        ...services,
        size,
        additionals_ids,
      })
    }
  }

  const onSubmit = (yacht) => {
    const size = getFormSizeForSubmit(yacht)
    const prices = getFormPricesForSubmit(yacht, memberships)
    const available_hours = getFormHoursForSubmit(yacht, memberships)
    const bodyServices = getFormYachtServicessForSubmit(yacht, services)
    submit({
      ...yacht,
      prices,
      available_hours,
      size,
      services: bodyServices,
    })
  }

  const validatorPriority = {
    validator(_, value) {
      if (value > yachtOrderMax) {
        return Promise.reject(`Max number is ${yachtOrderMax}`)
      }
      return Promise.resolve()
    },
  }

  const getInitialValues = () => {
    const {
      name,
      model,
      description_en,
      description_es,
      passengers_number,
      priority,
      location_id,
      bathrooms_number,
      bedrooms_number,
      beds_number,
      min_hours,
    } = yachtEdit ?? {}
    const size = getInitilaFormValueSize(yachtEdit?.size)
    const additionals_ids = getInitilaFormValueAdditional(
      yachtEdit?.additionals
    )
    const yachtPrices = getInitialFormValuesPrices(yachtEdit?.prices)
    const yachtHours = getInitialFormValuesHours(yachtEdit?.available_hours)
    const yachtServices = getInitialFormValuesServices(yachtEdit?.services)

    return {
      name,
      size,
      model,
      description_en,
      description_es,
      passengers_number,
      priority,
      location_id,
      bathrooms_number,
      bedrooms_number,
      beds_number,
      additionals_ids,
      min_hours,
      ...yachtPrices,
      ...yachtHours,
      ...yachtServices,
    }
  }

  const initialValues = getInitialValues()

  return (
    <Form onFinish={onSubmit} form={form} initialValues={initialValues}>
      <Row>
        <Col xs={24}>
          <Row gutter={[12, 12]}>
            <Divider>{t('yachts.form.div.general')}</Divider>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('table.row.name')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('yachts.form.required.name'),
                  },
                ]}
              >
                <Input type="text" disabled={!allowEdition} />
              </Form.Item>
              <Form.Item
                label={t('yachts.form.passengers_number')}
                name="passengers_number"
                rules={[
                  {
                    required: true,
                    message: t('yachts.form.required.passengers_number'),
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  disabled={!allowEdition}
                  min={1}
                  style={STYLE_INPUT_NUMBER}
                />
              </Form.Item>
              <Form.Item label={t('yachts.table.row.model')} name="model">
                <Input type="text" disabled={!allowEdition} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('yachts.table.row.order')}
                name="priority"
                rules={[
                  {
                    required: true,
                    message: t('yachts.form.required.priority'),
                  },
                  () => validatorPriority,
                ]}
              >
                <InputNumber
                  type="number"
                  disabled={!allowEdition}
                  min={1}
                  max={yachtOrderMax}
                  style={STYLE_INPUT_NUMBER}
                />
              </Form.Item>
              <Form.Item
                label={t('yachts.table.row.size')}
                name="size"
                rules={[
                  {
                    required: true,
                    message: t('yachts.form.required.size'),
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  disabled={!allowEdition}
                  min={1}
                  style={STYLE_INPUT_NUMBER}
                />
              </Form.Item>
              <Form.Item
                label={t('yachts.table.row.location')}
                name="location_id"
                rules={[
                  {
                    required: true,
                    message: t('yachts.form.required.location'),
                  },
                ]}
              >
                <LocationSelector
                  disabled={!allowEdition}
                  locations={locations}
                />
              </Form.Item>
              <Form.Item
                label={t('yachts.form.min_hours')}
                name="min_hours"
                rules={[
                  {
                    required: true,
                    message: t('yachts.form.required.min_hours'),
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  disabled={!allowEdition}
                  min={1}
                  style={STYLE_INPUT_NUMBER}
                />
              </Form.Item>
            </Col>
            <Divider>{t('yachts.form.div.prices')}</Divider>
            <Col xs={24}>
              <YachtPricesInputs allowEdition={allowEdition} />
            </Col>
            <Divider>{t('yachts.form.div.hours')}</Divider>
            <Col>
              <YachtAvailableHoursInputs
                form={form}
                allowEdition={allowEdition}
              />
            </Col>
            <Divider>{t('yachts.form.div.services')}</Divider>
            <Col xs={24}>
              <YachtServicesInputs allowEdition={allowEdition} />
            </Col>
            <Divider>{t('yachts.form.div.additionals')}</Divider>
            <Col xs={24}>
              <YachtAdditionalsInputs allowEdition={allowEdition} />
            </Col>
            <Divider>{t('yachts.form.div.description')} (en)</Divider>
            <Col xs={24}>
              <Form.Item name="description_en">
                <TextArea
                  maxLength={DESCRIPTION_TEX_AREA.max}
                  style={{ height: DESCRIPTION_TEX_AREA.height }}
                  disabled={!allowEdition}
                />
              </Form.Item>
            </Col>
            <Divider>{t('yachts.form.div.description')} (es)</Divider>
            <Col xs={24}>
              <Form.Item name="description_es">
                <TextArea
                  maxLength={DESCRIPTION_TEX_AREA.max}
                  style={{ height: DESCRIPTION_TEX_AREA.height }}
                  disabled={!allowEdition}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <FormFooter
            allowEdition={allowEdition}
            loading={loading}
            toggleAllowEdition={toggleAllowEdition}
            onCancelEdition={onCancelEdition}
            dataForm={yachtEdit}
          />
        </Col>
      </Row>
    </Form>
  )
}
