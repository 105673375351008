import ConvertorsPageContext from 'components/context/ConvertorsPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useConvertorsPageContext = () => {
  const contextName = 'ConvertorsPageContext'
  const contextValue = useContext(ConvertorsPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useConvertorsPageContext
