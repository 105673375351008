import { Button, ButtonProps } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

export const BackButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  const { goBack } = useHistory()

  return (
    <Button
      {...props}
      type="link"
      onClick={goBack}
      icon={<ArrowLeftOutlined />}
    >
      {t('side-menu.back')}
    </Button>
  )
}
