import { LocationsTableColumns } from './LocationsTableColumns'
import useLocationsPageContext from 'hooks/useLocationsPageContext'
import { Table, Row, Col, Empty, Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import mixpanelService from 'services/mixpanel'
import { useEffect } from 'react'

const LocationsTable = () => {
  const { t } = useTranslation()
  const {
    locations,
    onOpenCreateLocationForm,
    onOpenEditLocationForm,
    onDeleteLocation,
  } = useLocationsPageContext()

  useEffect(() => {
    mixpanelService.track.userEnterLocationsPage()
  }, [])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="end">
        <Col>
          <Button onClick={onOpenCreateLocationForm} type="primary">
            {t('locations.new-button-label')}
          </Button>
        </Col>
      </Row>
      <Row gutter={[0, 32]} justify="center">
        <Col md={24}>
          <Table
            dataSource={locations}
            columns={LocationsTableColumns({
              onDeleteLocation: onDeleteLocation,
              onOpenEditLocationForm: onOpenEditLocationForm,
            })}
            scroll={{
              x: true,
            }}
            locale={{
              emptyText: (
                <Empty
                  style={{ padding: '50px' }}
                  description={t('table.empty.locations')}
                />
              ),
            }}
          />
        </Col>
      </Row>
    </Space>
  )
}

export { LocationsTable }
