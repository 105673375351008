import routes from './routes'

const {
  partners,
  customers,
  calendar,
  boats,
  additionals,
  services,
  memberships,
  charges,
  bankAccount,
  blockedDates,
  locations,
  convertors,
} = routes

export type MenuElement = {
  icon?: React.ComponentType
  key: string
  route: string
  options: MenuElement[]
  disabled: boolean
}

export enum EMenuKeys {
  PARTNERS = 'partners',
  CUSTOMERS = 'customers',
  CALENDAR = 'calendar',
  RESERVES = 'reserves',
  RESERVE = 'reserve',
  BOATS = 'boats',
  ADDITIONALS = 'additionals',
  SERVICES = 'services',
  MEMBERSHIPS = 'memberships',
  CHARGES = 'charges',
  BANK_ACCOUNTS = 'bank_accounts',
  MOVEMENTS = 'movements',
  BLOCKED_DATES = 'blocked_dates',
  LOCATIONS = 'locations',
  CONVERTORS = 'convertors',
}

export default [
  {
    key: EMenuKeys.PARTNERS,
    options: [],
    route: partners,
    disabled: false,
    onlyAdmin: false,
  },
  {
    key: EMenuKeys.CUSTOMERS,
    options: [],
    route: customers,
    disabled: false,
    onlyAdmin: true,
  },
  {
    key: EMenuKeys.CALENDAR,
    options: [],
    route: calendar,
    disabled: false,
    onlyAdmin: true,
  },
  {
    key: EMenuKeys.BOATS,
    options: [],
    route: boats,
    disabled: false,
    onlyAdmin: true,
  },
  {
    key: EMenuKeys.ADDITIONALS,
    options: [],
    route: additionals,
    disabled: false,
    onlyAdmin: true,
  },
  {
    key: EMenuKeys.SERVICES,
    options: [],
    route: services,
    disabled: false,
    onlyAdmin: true,
  },
  {
    key: EMenuKeys.MEMBERSHIPS,
    options: [],
    route: memberships,
    disabled: false,
    onlyAdmin: true,
  },
  {
    key: EMenuKeys.CHARGES,
    options: [],
    route: charges,
    disabled: false,
    onlyAdmin: true,
  },
  {
    key: EMenuKeys.BANK_ACCOUNTS,
    options: [],
    route: bankAccount,
    disabled: false,
    onlyAdmin: true,
  },
  {
    key: EMenuKeys.BLOCKED_DATES,
    options: [],
    route: blockedDates,
    disabled: false,
    onlyAdmin: true,
  },
  {
    key: EMenuKeys.LOCATIONS,
    options: [],
    route: locations,
    onlyAdmin: true,
    disabled: false,
  },
  {
    key: EMenuKeys.CONVERTORS,
    options: [],
    route: convertors,
    onlyAdmin: true,
    disabled: false,
  },
]
