import React from 'react'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { ETypeTable } from 'components/molecules/YachtsTable'
import { isPartner } from 'utils/user'
import { UserFormModal } from 'components/organisms/Users/UserFormModal/UserFormModal'
import { UserHeader } from 'components/organisms/Users/UserHeader/UserHeader'
import { ReservesViewModal } from 'components/organisms/Users/ReservesViewModal/ReservesViewModal'
import { EMenuKeys } from 'shared/constants/menuElements'
import { UsersPageProvider } from 'components/context/UsersPageContext'
import { UserPageRole } from 'components/context/UsersPageContext/UsersPageContext'
import { PartnersViewModal } from 'components/organisms/Users/PartnersViewModal/PartnersViewModal'
import { SalesRoomModal } from 'components/organisms/Businesses/SalesRoomModal/SalesRoomModal'
import { UsersTable } from 'components/organisms/Users/UsersTable'
import { MembershipsProvider } from 'components/context/MembershipsContext'
import { CountriesProvider } from 'components/context/CountriesContext'
import { LocationsProvider } from 'components/context/LocationsContext'

type Props = {
  role: UserPageRole
}

const UserPage: React.FC<WithUserProps & RouteComponentProps & Props> = (
  props
) => {
  const { role } = props
  const isPartnerPage = isPartner(role)
  const tableType = isPartnerPage ? ETypeTable.PARTNERS : ETypeTable.CUSTOMERS
  const active = isPartnerPage ? EMenuKeys.PARTNERS : EMenuKeys.CUSTOMERS

  return (
    <MainLayout {...props} active={active}>
      <MembershipsProvider>
        <CountriesProvider>
          <LocationsProvider>
            <UsersPageProvider role={role}>
              <UserHeader />
              <UsersTable type={tableType} />
              <UserFormModal />
              <ReservesViewModal />
              <PartnersViewModal />
              <SalesRoomModal />
            </UsersPageProvider>
          </LocationsProvider>
        </CountriesProvider>
      </MembershipsProvider>
    </MainLayout>
  )
}

export default UserPage
