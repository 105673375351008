import { Additional } from 'api/Additional/declarations'
import { Yacht } from 'api/Yacht/declarations'

export const getAdditionalByYacht = ({
  yacht,
  additionals,
}: {
  yacht: Yacht
  additionals: Additional[]
}): Additional[] => {
  if (!yacht) {
    return []
  }
  return additionals?.filter(({ yachts }) =>
    yachts?.some(({ id }) => id === yacht?.id)
  )
}
