import React, { useEffect, useState } from 'react'
import i18n from 'i18next'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { useParams } from 'react-router-dom'
import UserService from 'api/User'
import { User } from 'api/User/declarations'
import { Button, Space, Typography, Form, Table } from 'antd'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import BeneficiariesTableCells from './BeneficiariesTableCells'
import { Beneficiary } from 'api/Beneficiaries/declarations'
import BeneficiariesService from 'api/Beneficiaries'
import showMessage, { NoticeType } from 'utils/notifications'
import routes from 'shared/constants/routes'
import AppButton from 'components/molecules/AppButton'
import { BackButton } from 'components/molecules/BackButton'
import { EditButton, RemoveButton } from 'components/atoms/Table'

interface BeneficiariesParams {
  partnerId: string
  first_name: string
  last_name: string
  email: string
}

const { Title } = Typography

const BeneficiariesFormTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  gap: 32px;
`

const BeneficiariesFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const PartnerViewTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

const PartnerViewTitleSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const PartnerViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
`

const PartnerNotFoundContainer = styled.div`
  display: grid;
  justify-content: center;
  text-align: center;
  padding-top: 100px;
`

const PartnerView: React.FC<WithUserProps & RouteComponentProps> = (props) => {
  const { state } = useLocation<BeneficiariesParams>()
  const [user, setUser] = useState<User>()
  const [count, setCount] = useState(0)
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>()
  const { partnerId } = useParams<{ partnerId: string }>()
  const [addBtnDisabled, setAddBtnDisabled] = useState(true)
  const [addingBeneficiary, setAddingBeneficiary] = useState(false)
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const [hasErrorFindingUser, setHasErrorFindingUser] = useState(false)
  const maxNumBeneficiaries = 5
  const { partners } = routes

  useEffect(() => {
    if (state) {
      setUser(state)
    } else {
      fetchUser()
    }
  }, [])

  const fetchBeneficiaries = async () => {
    try {
      const beneficiaries = await UserService.getBeneficiariesByPartnerId(
        Number(partnerId)
      )
      setBeneficiaries(beneficiaries)
      setCount(beneficiaries.length)
    } catch (error) {
      showMessage(
        'Error',
        i18n.t('PartnerView.Not_found_beneficiaries.toast'),
        NoticeType.ERROR
      )
    }
  }

  useEffect(() => {
    if (user) {
      fetchBeneficiaries()
    }
  }, [user])

  useEffect(() => {
    console.log({ count, maxNumBeneficiaries, addingBeneficiary })
    if (count === maxNumBeneficiaries || addingBeneficiary) {
      setAddBtnDisabled(true)
    } else {
      setAddBtnDisabled(false)
    }
  }, [count, addingBeneficiary])

  const isEditing = (record: Beneficiary) => record.id === editingKey

  const edit = (record: Beneficiary) => {
    setAddBtnDisabled(true)
    form.setFieldsValue({
      ...record,
      phone_number: record.phone_number ? Number(record.phone_number) : null,
    })
    setEditingKey(record.id)
  }

  const fetchUser = async () => {
    try {
      const data = await UserService.findById(partnerId)
      setUser(data)
    } catch (error) {
      setHasErrorFindingUser(true)
      showMessage(
        'Error',
        i18n.t('PartnerView.Not_found_user.toast'),
        NoticeType.ERROR
      )
    }
  }

  const deleteBeneficiary = (record: Beneficiary) => {
    const newData = [...beneficiaries]
    const index = newData.findIndex((item) => record.id === item.id)
    newData.splice(index, 1)
    setBeneficiaries(newData)
    try {
      BeneficiariesService.removeBeneficiary(record.id)
      const newCount = count - 1
      setCount(newCount)
    } catch (error) {
      showMessage(
        'Error',
        i18n.t('PartnerView.Cancel_error.toast'),
        NoticeType.ERROR
      )
    }
  }

  const cancel = async (record?: Beneficiary) => {
    if (!record.email || !record.full_name || !record.phone_number) {
      const newData = [...beneficiaries]
      const index = newData.findIndex((item) => record.id === item.id)
      newData.splice(index, 1)
      setBeneficiaries(newData)
    }
    setEditingKey('')

    const nextDisabled = count === maxNumBeneficiaries
    setAddBtnDisabled(nextDisabled)

    if (addingBeneficiary) {
      setAddingBeneficiary(false)
      setCount(count - 1)
    }
  }

  const handleSave = async (beneficiaryId: string) => {
    const row = (await form.validateFields()) as Beneficiary
    const newData = [...beneficiaries]

    if (addingBeneficiary) {
      const data = {
        full_name: row.full_name,
        phone_number: row.phone_number,
        email: row.email,
        user_id: parseInt(partnerId),
      }

      try {
        const response = await BeneficiariesService.createBeneficiary(data)
        newData[newData.length - 1] = response
        setBeneficiaries(newData)
      } catch (errInfo) {
        showMessage(
          'Error',
          i18n.t('PartnerView.Save_error.toast'),
          NoticeType.ERROR
        )
      }
    } else {
      try {
        const response = await BeneficiariesService.updateBeneficiary(
          beneficiaryId,
          row
        )
        const index = newData.findIndex((item) => beneficiaryId === item.id)

        newData[index] = response
        setBeneficiaries(newData)
      } catch (errInfo) {
        showMessage(
          'Error',
          i18n.t('PartnerView.Edit_error.toast'),
          NoticeType.ERROR
        )
      }
    }

    setEditingKey('')
    setAddingBeneficiary(false)
    setAddBtnDisabled(true)
  }

  const handleAdd = () => {
    setAddBtnDisabled(true)
    setAddingBeneficiary(true)

    if (count <= maxNumBeneficiaries) {
      const newData: Beneficiary = {
        id: '-1',
        full_name: '',
        phone_number: '',
        email: '',
        user_id: -1,
        created_at: '',
        updated_at: '',
        deleted_at: '',
      }
      setBeneficiaries([...beneficiaries, newData])
      setCount(count + 1)
      edit(newData)
    }
  }

  const columns = [
    {
      title: i18n.t('PartnerView.Name_column'),
      dataIndex: 'full_name',
      width: '25%',
      editable: true,
      inputType: 'string',
      message: i18n.t('PartnerView.required.name'),
    },
    {
      title: i18n.t('PartnerView.PhoneNumber_column'),
      dataIndex: 'phone_number',
      width: '15%',
      editable: true,
      inputType: 'number',
      message: i18n.t('PartnerView.required.phone'),
    },
    {
      title: i18n.t('PartnerView.Email_column'),
      dataIndex: 'email',
      width: '40%',
      editable: true,
      inputType: 'email',
      message: i18n.t('PartnerView.required.email'),
    },
    {
      title: i18n.t('PartnerView.Operation_column'),
      dataIndex: 'operation',

      render: (_: any, record: Beneficiary) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => handleSave(record.id)}
              style={{ marginRight: 8 }}
            >
              {i18n.t('PartnerView.Btn.Save')}
            </Typography.Link>
            <Typography.Link onClick={() => cancel(record)}>
              {i18n.t('PartnerView.Btn.Cancel')}
            </Typography.Link>
          </span>
        ) : (
          <Space>
            <EditButton
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              text={i18n.t('PartnerView.Btn.Edit')}
            ></EditButton>
            <RemoveButton
              text={i18n.t('PartnerView.Btn.Delete')}
              disabled={editingKey !== ''}
              onClick={() => deleteBeneficiary(record)}
              // title={i18n.t('PartnerView.Popconfirm.Title.Delete')}
              // cancelText={i18n.t('PartnerView.Popconfirm.ConfirmBtn.Cancel')}
            />
          </Space>
        )
      },
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: Beneficiary) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        message: col.message,
      }),
    }
  })

  if (hasErrorFindingUser) {
    return (
      <MainLayout {...props} showTitle={false}>
        <PartnerNotFoundContainer>
          <Title>{i18n.t('PartnerView.Not_found_user.title')}</Title>
          <Typography.Paragraph>
            {i18n.t('PartnerView.Not_found_user.subTitle')}
          </Typography.Paragraph>
          <Button href={partners}>
            {i18n.t('PartnerView.Not_found.button')}
          </Button>
        </PartnerNotFoundContainer>
      </MainLayout>
    )
  }

  return (
    <MainLayout {...props} showTitle={false}>
      <PartnerViewContainer>
        <PartnerViewTitleSectionContainer>
          <PartnerViewTitleContainer>
            <BackButton>{i18n.t('PartnerView.goBack.Button')}</BackButton>
          </PartnerViewTitleContainer>

          <div>
            <Title level={2}>{user?.first_name}</Title>
            <Typography.Paragraph>{user?.email}</Typography.Paragraph>
          </div>
        </PartnerViewTitleSectionContainer>

        <BeneficiariesFormContainer>
          <BeneficiariesFormTitle>
            <Title level={5}>
              {i18n.t('PartnerView.counter')} ({count}/{maxNumBeneficiaries})
            </Title>
            <AppButton
              style={{ margin: '0 0 5px 0' }}
              onClick={handleAdd}
              disabled={addBtnDisabled}
            >
              {i18n.t('PartnerView.add_button')}
            </AppButton>
          </BeneficiariesFormTitle>

          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: BeneficiariesTableCells,
                },
              }}
              bordered
              dataSource={beneficiaries}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
            />
          </Form>
        </BeneficiariesFormContainer>
      </PartnerViewContainer>
    </MainLayout>
  )
}

export default PartnerView
export type { BeneficiariesParams }
