import { Membership, FindMembershipsParams } from 'api/Membership/declarations'
import { createContext } from 'react'

interface IMembershipsContext {
  memberships: Membership[]
  getMemberships: (sort?: FindMembershipsParams) => Promise<void>
}

const MembershipsContext = createContext<IMembershipsContext | undefined>(
  undefined
)

export default MembershipsContext
export type { IMembershipsContext }
