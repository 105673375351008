import { Additional } from 'api/Additional/declarations'
import { Location } from 'api/Location/declarations'
import { Reserve } from 'api/Reserve/declarations'
import { User } from 'api/User/declarations'
import { Yacht } from 'api/Yacht/declarations'
import { createContext } from 'react'
import { IReserveHours } from 'utils/reserves'

interface IReservePageContext {
  loading: boolean
  dateReserve: Date
  reserve: Reserve
  partner: User
  locations: Location[]
  locationId: number
  yachts: Yacht[]
  yacht: Yacht
  reserveHour?: IReserveHours
  additionals: Additional[]
  hourAvailable: number[]
  amount: number
  amountAdditional: number
  cancelReserve: (reserve: Reserve) => void
  submitForm: (reserve: Reserve) => void
  onChangeLocation: (locationId) => void
  onChangeYacth: (yachtId) => void
  onChangeStartHour: (start_hour) => void
  onChangeEndHour: (end_hour) => void
  onChangeAdditional: (additionals) => void
  validateUserEmail: (email: string) => Promise<void>
}

const ReserveContext = createContext<IReservePageContext | undefined>(undefined)

export default ReserveContext
export type { IReservePageContext }
