import { Col, Form, Input, Row } from 'antd'
import { SaleRoom } from 'api/SaleRoom/declaration'
import { FormFooter } from 'components/molecules/Form/FormFooter'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  style?: React.CSSProperties
  sale_room?: SaleRoom
  loading: boolean
  handleSubmit: (data) => void
  cancelEdit?: () => void
}

const FORM_ITEM_STYLE = { marginBottom: 0 }
const FOOTER_STYLE = { marginTop: 0 }

export const SaleRoomForm = ({
  sale_room,
  loading,
  style = {},
  handleSubmit,
  cancelEdit,
}: Props) => {
  {
    const { t } = useTranslation()
    const [form] = Form.useForm()

    useEffect(() => {
      form.setFieldsValue(sale_room)
    }, [sale_room, form])

    const onCancelEdition = () => {
      form.setFieldsValue(sale_room)
      cancelEdit()
    }
    const onSubmit = (room) => {
      handleSubmit(room)
      form.resetFields()
    }

    const { name } = sale_room ?? {}

    return (
      <Form onFinish={onSubmit} form={form}>
        <Row align="middle" gutter={12} style={style}>
          <Col xs={15}>
            <Form.Item
              name="name"
              initialValue={name}
              rules={[{ required: true, message: t('form.required.name') }]}
              style={FORM_ITEM_STYLE}
            >
              <Input
                type="text"
                placeholder={t('users.placeholder.sale_room')}
              />
            </Form.Item>
          </Col>
          <Col>
            <FormFooter
              allowEdition={true}
              loading={loading}
              onCancelEdition={onCancelEdition}
              dataForm={sale_room}
              style={FOOTER_STYLE}
            />
          </Col>
        </Row>
      </Form>
    )
  }
}
