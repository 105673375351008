import React from 'react'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { EMenuKeys } from 'shared/constants/menuElements'
import { ServicesPageProvider } from 'components/context/ServicesPageContext'
import ServicesTable from 'components/organisms/Services/ServiceTable/ServicesTable'
import ServiceFormModal from 'components/organisms/Services/ServiceFormModal/ServiceFormModal'
import ServicesHeader from 'components/organisms/Services/ServiceHeader/ServiceHeader'
import ServiceImagesModal from 'components/organisms/Services/ServiceImagesModal/ServiceImagesModal'

const ServicesPage: React.FC<WithUserProps & RouteComponentProps> = (props) => (
  <MainLayout {...props} active={EMenuKeys.SERVICES}>
    <ServicesPageProvider>
      <ServicesHeader />
      <ServicesTable />
      <ServiceFormModal />
      <ServiceImagesModal />
    </ServicesPageProvider>
  </MainLayout>
)

export default ServicesPage
