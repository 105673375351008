import { Form, Input, Row, Col, Button, Divider } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import tryFunction from 'utils/try'

interface IConvertorFormValues {
  name_es: string
  name_en: string
}

interface IConverterForm {
  initialValues?: Partial<IConvertorFormValues>
  isEditionForm?: boolean
  onSubmit?: (values: IConvertorFormValues) => Promise<void>
}

const ConvertorForm = ({
  initialValues,
  isEditionForm = false,
  onSubmit = async (values) => {},
}: IConverterForm) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitButtonTranslationKey = `locations.form.${
    isEditionForm ? 'edit' : 'create'
  }-submit`

  const onFinish = (values: IConvertorFormValues) => {
    tryFunction(
      async () => {
        await onSubmit(values)
      },
      setIsSubmitting,
      true
    )
  }

  return (
    <Form initialValues={initialValues} onFinish={onFinish}>
      <Row>
        <Col xs={24}>
          <Divider>{t('locations.form.section.general')}</Divider>
          <Form.Item
            name="name_es"
            label={`${t('locations.form.field.name')} (es)`}
            rules={[{ required: true, message: t('form.required.name') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name_en"
            label={`${t('locations.form.field.name')} (en)`}
            rules={[{ required: true, message: t('form.required.name') }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col offset={16} style={{ width: '100%' }}>
          <Button type="primary" block htmlType="submit" loading={isSubmitting}>
            {t(submitButtonTranslationKey)}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { ConvertorForm }
