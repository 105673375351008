import { Additional } from 'api/Additional/declarations'
import { Charge, EPaymentType } from 'api/Charge/declarations'
import { Location } from 'api/Location/declarations'
import { User } from 'api/User/declarations'
import { Yacht } from 'api/Yacht/declarations'
import { EReserveTap } from 'components/organisms/Users/ReservesViewModal/ReservesView/ReservesView'

export enum EStatusReserve {
  RESERVE = 'reserve',
  PRE_RESERVE = 'pre_reserve',
}

export type Reserve = {
  id?: number
  status: EStatusReserve
  code: string
  passengers_number: number
  partner_description: string
  admin_description: string
  cancel: boolean
  yacht_id?: number
  yacht?: Yacht
  charge_id?: number
  charge?: Charge
  location_id?: number
  location?: Location
  start_date?: Date | string
  end_date?: Date | string
  membership_name?: string
  user_id?: number
  user?: User
  created_at?: Date
  yachts_additionals: [{ id: number; additional: Additional }]
} & ReserveCreate

export type ReserveCreate = {
  amount?: number
  additionals_ids?: number[]
  payment_method_type?: EPaymentType
}

export type ReserveCharge = {
  reserve: Reserve
  charge: Charge
}

export const FilterReserveTap = {
  [EReserveTap.AFTER]: {
    cancel: false,
    past: false,
  },
  [EReserveTap.BEFORE]: {
    cancel: false,
    past: true,
  },
  [EReserveTap.CANCELED]: {
    cancel: true,
    past: undefined,
  },
}

export type ICalendarEvent = {
  id: number
  number_reserves: number
  number_pre_reserves: number
  start: Date
  end: Date
  isBlocked?: boolean
}

export type ReserveParams = {
  page?: number
  per_page?: number
  date?: Date
  location_id?: number
  yacht_id?: number
  membership_name?: string
  business_id?: number
  sale_room_id?: number
  user_id?: number
  cancel?: boolean
  past?: boolean
  status?: EStatusReserve
  without_images?: boolean
}
