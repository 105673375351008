import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/en-in'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
  Calendar as BigCalendar,
  momentLocalizer,
  SlotInfo,
  Views,
} from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { CalendarEventView } from './CalendarViewMonth'
import colors from 'shared/constants/colors'
import { CalendarHeader } from './CalendarHeader'
import useCalendarPage from 'hooks/CalendarPage'
import { When } from 'react-if'
import { LoadingSpin } from 'components/molecules/LoadingSpin'
import { ICalendarEvent } from 'api/Reserve/declarations'
import { useEffect } from 'react'
import mixpanelService from 'services/mixpanel'

const { AQUA, GRAY_BLUE } = colors

type PropCalendar = {
  heigth?: string
}

const eventStyleGetter = ({ isBlocked }: ICalendarEvent) => {
  const style: React.CSSProperties = {
    borderRadius: '5px',
    opacity: 0.8,
    backgroundColor: isBlocked ? GRAY_BLUE : AQUA,
  }

  return {
    style,
  }
}

export const Calendar = ({ heigth = '80vh' }: PropCalendar) => {
  const { events, calendarDate, loading, redirectReservesPage } =
    useCalendarPage()
  const { i18n } = useTranslation()
  moment.locale(i18n.language)
  const localizer = momentLocalizer(moment)

  const onSelecSlot = ({ start }: SlotInfo) => {
    mixpanelService.track.userEnterCalendarDay({ day: start.toString() })
    redirectReservesPage({ start } as ICalendarEvent)
  }

  useEffect(() => {
    mixpanelService.track.userEnterCalendarPage()
  }, [])

  return (
    <>
      <When condition={loading}>
        <LoadingSpin />
      </When>
      <BigCalendar
        date={calendarDate}
        localizer={localizer}
        events={events}
        selectable={true}
        style={{ height: heigth }}
        defaultView={Views.MONTH}
        defaultDate={new Date()}
        components={{
          month: { event: CalendarEventView },
          toolbar: CalendarHeader,
        }}
        onSelectEvent={redirectReservesPage}
        onSelectSlot={onSelecSlot}
        eventPropGetter={eventStyleGetter}
        views={{ month: true }}
      />
    </>
  )
}
