import FullscreenModal from 'components/molecules/FullscreenModal'
import useYachtsPage from 'hooks/useYachtsPageContext'
import { YachtForm } from './YachtForm/YachtForm'

export const YachtFormModal = () => {
  const {
    showModal,
    loading,
    handledCloseYachtModalForm: onCancel,
  } = useYachtsPage()
  return (
    <FullscreenModal
      visible={showModal.yachtForm}
      loading={loading}
      onCancel={onCancel}
      maskClosable={false}
      maxWidth="900px"
      destroyOnClose
    >
      <YachtForm />
    </FullscreenModal>
  )
}
