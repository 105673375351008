import FullscreenModal from 'components/molecules/FullscreenModal'
import useConvertorsPageContext from 'hooks/useConvertorsPageContext'
import { ConvertorForm } from './ConvertorForm'

const NewConvertorForm = () => {
  const {
    createConvertorFormIsOpen,
    onCloseCreateConvertorForm,
    onCreateConvertor,
  } = useConvertorsPageContext()

  return (
    <FullscreenModal
      visible={createConvertorFormIsOpen}
      maskClosable={false}
      onCancel={onCloseCreateConvertorForm}
      maxWidth="450px"
      destroyOnClose
      height="fit-content"
      loading={false}
    >
      <ConvertorForm onSubmit={onCreateConvertor} />
    </FullscreenModal>
  )
}

export { NewConvertorForm }
