import AuthService from 'api/Auth'
import UserService from 'api/User'
import ConvertorService from 'api/Convertor'
import { Convertor } from 'api/Convertor/declarations'
import {
  EStatusPartner,
  EUserRole,
  User,
  UserCreate,
  UserParams,
  UserSort,
  UserUpdate,
} from 'api/User/declarations'
import { formatUserList } from 'components/organisms/Users/UsersTable/format'
import { SortDirection } from 'interfaces/shared'
import { useContext, useEffect, useState } from 'react'
import scrollToTop from 'utils/scroll'
import tryFunction from 'utils/try'
import { isPartner } from 'utils/user'
import UsersPageContext, {
  IUsersPageContext,
  UserPageRole,
} from './UsersPageContext'
import CountriesContext from '../CountriesContext'
import MembershipsContext from '../MembershipsContext'
import LocationsContext from '../LocationsContext'
import useMainLayout from 'hooks/useAccessUser'
import mixpanelService from 'services/mixpanel'

type Props = {
  children
  role: UserPageRole
}

export type IUserShowModal = {
  userForm: boolean
  reservesView: boolean
  partnersView: boolean
  salesRoomView: boolean
}

const initialParams: UserParams = {
  page: 1,
  per_page: 9,
  search: undefined,
  customer_id: undefined,
  membership_id: undefined,
  partner_status: undefined,
  sale_room_id: undefined,
  sort: 'created_at DESC',
}

const initialSort: UserSort = {
  email: undefined,
  first_name: undefined,
  last_name: undefined,
  partner_code: undefined,
  created_at: SortDirection.DESC,
}

const initialShowModal: IUserShowModal = {
  userForm: false,
  reservesView: false,
  partnersView: false,
  salesRoomView: false,
}

const UsersPageProvider = ({ children, role }: Props) => {
  const { isAdmin } = useMainLayout()
  const { countries, getCountries } = useContext(CountriesContext)
  const { locations, getLocations } = useContext(LocationsContext)
  const { memberships, getMemberships } = useContext(MembershipsContext)
  const [users, setUsers] = useState<UserCreate[]>([])
  const [count, setCount] = useState<number>(0)
  const [customers, setCustomers] = useState<User[]>([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const [userEdit, setUserEdit] = useState<UserCreate>(undefined)
  const [showModal, setShowModal] = useState<IUserShowModal>(initialShowModal)
  const [userSort, setUserSort] = useState<UserSort>(initialSort)
  const [userParams, setUserParams] = useState<UserParams>({
    role,
    ...initialParams,
  })
  const [convertors, setConvertors] = useState<Convertor[]>([])

  useEffect(() => {
    if (refresh) {
      loadData()
      setRefresh(false)
    }
  }, [role, userParams, showModal, userSort, users, userEdit])

  const loadData = () => {
    tryFunction(async () => {
      await getUsers()
      await getCountries()
      await getMemberships()
      await getLocations()
      await getConvertors()
      if (isAdmin && isPartner(role)) {
        await getCustomers()
      }
      scrollToTop()
    }, setLoading)
  }

  const getConvertors = async () => {
    const convertors = await ConvertorService.find()
    setConvertors(convertors)
  }

  const getUsers = async () => {
    const { data: users, count } = await UserService.paginate(
      userParams,
      userSort
    )
    setUsers(formatUserList(users))
    setCount(count)
  }

  const getCustomers = async () => {
    const customers = await UserService.find({ role: EUserRole.CUSTOMER })
    setCustomers(customers)
  }

  const submitUserForm = (data: UserUpdate | UserCreate) => {
    tryFunction(
      async () => {
        if (userEdit) {
          await UserService.update(userEdit.id, data as UserUpdate)
        } else {
          await AuthService.register(data as UserCreate)
        }
        setRefresh(true)
        handledCloseUserModalForm()
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const updatePartnerStatus = (user: UserCreate, status: EStatusPartner) => {
    tryFunction(
      async () => {
        setUserEdit(user)
        await UserService.update(user.id, { user: { partner_status: status } })
        setRefresh(true)
        setUserEdit(undefined)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const updateActive = (user: UserCreate) => {
    tryFunction(
      async () => {
        setUserEdit(user)
        await UserService.updateActive(user)
        setRefresh(true)
        setUserEdit(undefined)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const getReport = (requestedPartners: boolean) => {
    tryFunction(
      async () => {
        await UserService.find({ ...userParams, csv: true })

        if (requestedPartners) {
          mixpanelService.track.partnersReportExported()
        } else {
          mixpanelService.track.resortsReportExported()
        }
      },
      setLoading,
      true
    )
  }

  const onChangeUserSort = (key: string, direction: SortDirection) => {
    setRefresh(true)
    setUserSort({ ...userSort, [key]: direction })
  }

  const onChangeFilter = (params: UserParams) => {
    setRefresh(true)
    setUserParams(params)
  }

  const onChangePage = (page: number) => {
    setRefresh(true)
    setUserParams({ ...userParams, page })
  }

  const handledOpenUserModalForm = (user?: UserCreate) => {
    setUserEdit(user)
    setShowModal({ ...showModal, userForm: true })
  }

  const handledCloseUserModalForm = () => {
    setUserEdit(undefined)
    setShowModal({ ...showModal, userForm: false })
  }

  const createUser = () => {
    handledOpenUserModalForm()
  }

  const handledOpenReservesViewModal = (user: UserCreate) => {
    setUserEdit(user)
    setShowModal({ ...showModal, reservesView: true })
  }

  const handledCloseReservesViewModal = () => {
    setUserEdit(undefined)
    setShowModal({ ...showModal, reservesView: false })
  }

  const handledOpenPartnersViewModal = (user: UserCreate) => {
    setUserEdit(user)
    setShowModal({ ...showModal, partnersView: true })
  }

  const handledClosePartnersViewModal = () => {
    setUserEdit(undefined)
    setShowModal({ ...showModal, partnersView: false })
  }

  const handledOpenSalesRoomViewModal = (user: UserCreate) => {
    setUserEdit(user)
    setShowModal({ ...showModal, salesRoomView: true })
  }

  const handledCloseSalesRoomViewModal = () => {
    setUserEdit(undefined)
    setShowModal({ ...showModal, salesRoomView: false })
  }

  const contextValue: IUsersPageContext = {
    isPartnerPage: isPartner(role),
    role,
    loading,
    showModal,
    userEdit,
    count,
    users,
    customers,
    countries,
    memberships,
    locations,
    userParams,
    userSort,
    submitUserForm,
    updatePartnerStatus,
    updateActive,
    getReport,
    onChangeUserSort,
    onChangeFilter,
    onChangePage,
    createUser,
    handledOpenUserModalForm,
    handledCloseUserModalForm,
    handledOpenReservesViewModal,
    handledCloseReservesViewModal,
    handledOpenPartnersViewModal,
    handledClosePartnersViewModal,
    handledOpenSalesRoomViewModal,
    handledCloseSalesRoomViewModal,
    convertors,
  }

  return (
    <UsersPageContext.Provider value={contextValue}>
      {children}
    </UsersPageContext.Provider>
  )
}

export { UsersPageProvider }
