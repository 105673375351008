import { BankAccountTableColumns } from './BankAccountTableColumns'
import useBankAccountPageContext from 'hooks/useBankAccountPageContext'
import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import { Button, Col, Row, Space, Statistic } from 'antd'
import { useTranslation } from 'react-i18next'
import { DollarCircleOutlined } from '@ant-design/icons'
import mixpanelService from 'services/mixpanel'
import { useEffect } from 'react'

const BankAccountsTable = () => {
  const { t } = useTranslation()
  const {
    bankAccounts,
    count,
    loading,
    bankAccountParams,
    totalBalance,
    handledOpenBankAccountModalForm,
    deleteBankAccount,
    createBankAccount,
    onPageChange,
    openBankMovements,
  } = useBankAccountPageContext()
  const dataSource = bankAccounts.map((account) => ({
    key: account.id,
    ...account,
  }))

  useEffect(() => {
    mixpanelService.track.userEnterBankAccountsPage()
  }, [])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between" align="middle">
        <Col>
          <Statistic
            title={t('bankAccount.table.balance')}
            prefix={<DollarCircleOutlined />}
            value={totalBalance}
          />
        </Col>
        <Col>
          <Button onClick={createBankAccount} type="primary">
            {t('bankAccount.new-button-label')}
          </Button>
        </Col>
      </Row>
      <YachtsTable
        loading={loading}
        dataSource={dataSource}
        columns={BankAccountTableColumns({
          deleteBankAccount: deleteBankAccount,
          onOpenEditBankAccountForm: handledOpenBankAccountModalForm,
          onOpenBankMovements: openBankMovements,
        })}
        count={count}
        onChangePage={onPageChange}
        type={ETypeTable.BANK_ACCOUNTS}
        pagination={bankAccountParams}
      />
    </Space>
  )
}

export { BankAccountsTable }
