import { createContext } from 'react'
import { BankAccount, BankAccountsParams } from 'api/BankAccount/declarations'

export type BankAccountShowModal = {
  bankAccountForm: boolean
}

interface IBankAccountPageContext {
  loading: boolean
  bankAccountEdit?: BankAccount
  bankAccounts: BankAccount[]
  count: number
  totalBalance: number
  showModal: BankAccountShowModal
  bankAccountParams: BankAccountsParams
  onPageChange: (pageNumber: number) => void
  submitBankAccountForm: (data: BankAccount) => void
  handledCloseBankAccountModalForm: () => void
  handledOpenBankAccountModalForm: (bankAccount: BankAccount) => void
  deleteBankAccount: (bankAccount: BankAccount) => void
  openBankMovements: (bankAccount: BankAccount) => void
  createBankAccount: () => void
}

const BankAccountPageContext = createContext<IBankAccountPageContext>(undefined)

export type { IBankAccountPageContext }
export default BankAccountPageContext
