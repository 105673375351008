import { FC, useEffect, useState } from 'react'
import scrollToTop from 'utils/scroll'
import tryFunction from 'utils/try'
import { Additional, AdditionalParams } from 'api/Additional/declarations'
import { Membership } from 'api/Membership/declarations'
import AdditionalService from 'api/Additional'
import AdditionalsPageContext, {
  AdditionalShowModal,
  IAdditionalsPageContext,
} from './AdditionalsPageContext'
import MembershipService from 'api/Membership'

const initialParams: AdditionalParams = {
  page: 1,
  per_page: 9,
}

const initialShowModal: AdditionalShowModal = {
  additionalForm: false,
  imagesForm: false,
}

const AdditionalsPageProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [additionals, setAdditionals] = useState<Additional[]>([])
  const [additionalEdit, setAdditionalEdit] = useState<Additional>(undefined)
  const [memberships, setMemberships] = useState<Membership[]>([])
  const [params, setparams] = useState<AdditionalParams>(initialParams)
  const [showModal, setShowModal] =
    useState<AdditionalShowModal>(initialShowModal)

  useEffect(() => {
    if (refresh) {
      load()
      setRefresh(false)
    }
  }, [params, showModal, , additionalEdit])

  useEffect(() => {
    if (additionalEdit) {
      updateModalAdditionalEdit()
    }
  }, [additionals])

  const load = () => {
    tryFunction(async () => {
      await getAdditionals()
      await getMemberships()
      scrollToTop()
    }, setLoading)
  }

  const getAdditionals = async () => {
    const { data: additionals, count } = await AdditionalService.paginate(
      params
    )
    setAdditionals(additionals)
    setCount(count)
  }

  const getMemberships = async () => {
    const memberships = await MembershipService.find()
    setMemberships(memberships)
  }

  const submitAdditionalForm = (data: Additional) => {
    tryFunction(
      async () => {
        if (additionalEdit) {
          await AdditionalService.update(additionalEdit.id, data)
        } else {
          await AdditionalService.create(data)
        }
        setRefresh(true)
        handledCloseAdditionalModalForm()
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onDelete = (additional: Additional) => {
    tryFunction(
      async () => {
        setAdditionalEdit(additional)
        await AdditionalService.delete(additional.id)
        setRefresh(true)
        setAdditionalEdit(undefined)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onUploadImage = (image, image_id?: number) => {
    tryFunction(
      async () => {
        const { id: additional_id } = additionalEdit
        await AdditionalService.uploadImage(additional_id, image, image_id)
        await getAdditionals()
        setRefresh(true)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onDeleteImage = (image_id?: number) => {
    tryFunction(
      async () => {
        const { id: additional_id } = additionalEdit
        await AdditionalService.deleteImage(additional_id, image_id)
        await getAdditionals()
        setRefresh(true)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const updateModalAdditionalEdit = () => {
    const { id: additional_id } = additionalEdit
    const additionalUpdate = additionals.find(({ id }) => id === additional_id)
    if (additionalUpdate) {
      setAdditionalEdit(additionalUpdate)
    }
  }

  const onChangePage = (page: number) => {
    setRefresh(true)
    setparams({ ...params, page })
  }

  const handledOpenAdditionalModalForm = (additional?: Additional) => {
    setAdditionalEdit(additional)
    setShowModal({ ...showModal, additionalForm: true })
  }

  const handledCloseAdditionalModalForm = () => {
    setAdditionalEdit(undefined)
    setShowModal({ ...showModal, additionalForm: false })
  }

  const handledOpenImagesModalForm = (additional: Additional) => {
    setAdditionalEdit(additional)
    setShowModal({ ...showModal, imagesForm: true })
  }

  const handledCloseImagesModalForm = () => {
    setAdditionalEdit(undefined)
    setShowModal({ ...showModal, imagesForm: false })
  }

  const createAdditional = () => {
    handledOpenAdditionalModalForm()
  }

  const contextValue: IAdditionalsPageContext = {
    loading,
    memberships,
    additionalEdit,
    count,
    params,
    additionals,
    showModal,
    submitAdditionalForm,
    onDelete,
    onChangePage,
    handledOpenAdditionalModalForm,
    handledCloseAdditionalModalForm,
    handledOpenImagesModalForm,
    handledCloseImagesModalForm,
    createAdditional,
    onUploadImage,
    onDeleteImage,
  }

  return (
    <AdditionalsPageContext.Provider value={contextValue}>
      {children}
    </AdditionalsPageContext.Provider>
  )
}

export { AdditionalsPageProvider }
