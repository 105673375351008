import { Row, Col } from 'antd'
import AppButton from 'components/molecules/AppButton'
import useServicesPage from 'hooks/translate/useServicesPageContext'
import { useTranslation } from 'react-i18next'

const ServicesHeader = () => {
  const { t } = useTranslation()
  const { loading, createService } = useServicesPage()

  return (
    <Row justify="end" style={{ marginBottom: 25 }}>
      <Col>
        <AppButton loading={loading} onClick={createService}>
          {t(`services.button.create`)}
        </AppButton>
      </Col>
    </Row>
  )
}

export default ServicesHeader
