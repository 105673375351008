
import BankAccountPageContext from 'components/context/BankAccountContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useBankAccountPageContext = () => {
  const contextName = 'BankAccountPageContext'
  const contextValue = useContext(BankAccountPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useBankAccountPageContext