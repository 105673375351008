import i18n from 'i18next'
import { Col, Row } from 'antd'
import { SeeMoreButton, TableHeader } from 'components/atoms/Table'
import { Reserve } from 'api/Reserve/declarations'
import { getUserFullName } from 'utils/user'
import { CancelButton } from 'components/atoms/Table/ActionButtons/CancelButton'
import { formatDate, formatHour, formatPrice } from 'utils/common'
import { getPathnameReserve, getReserveStatus } from 'utils/reserves'
import { Link } from 'react-router-dom'

type Props = {
  viewReserve?: (reserve: Reserve) => void
  cancelReserve?: (reserve: Reserve) => void
}

export const ReservesColumns = ({
  viewReserve = () => {},
  cancelReserve = () => {},
}: Props) => [
  {
    title: (
      <TableHeader width="120px">
        {i18n.t('reserves.table.row.code')}
      </TableHeader>
    ),
    dataIndex: 'code',
    key: 'code',
    render: (text: string, record: Reserve) => (
      <Link to={getPathnameReserve(record)}>#{record.code}</Link>
    ),
  },
  {
    title: <TableHeader>{i18n.t('table.row.status')}</TableHeader>,
    dataIndex: 'status',
    render: (text: string, record: Reserve) => getReserveStatus(record),
  },
  {
    title: (
      <TableHeader>{i18n.t('reserves.table.row.partner_name')}</TableHeader>
    ),
    dataIndex: 'partner_name',
    render: (text: string, record: Reserve) => getUserFullName(record.user),
  },
  {
    title: <TableHeader>{i18n.t('reserves.table.row.email')}</TableHeader>,
    dataIndex: 'email',
    render: (text: string, record: Reserve) => record.user?.email || 'N/D',
  },
  {
    title: (
      <TableHeader width="100px">
        {i18n.t('reserves.table.row.paid')}
      </TableHeader>
    ),
    dataIndex: 'paid',
    render: (text: string, record: Reserve) =>
      formatPrice(record.charge?.paid?.toString()) || 'N/D',
  },
  {
    title: (
      <TableHeader>{i18n.t('reserves.table.row.charge_status')}</TableHeader>
    ),
    dataIndex: 'charge_status',
    render: (text: string, record: Reserve) =>
      i18n.t(`charge.status.${record.charge?.status}`),
  },
  {
    title: (
      <TableHeader>{i18n.t('reserves.table.row.membership_name')}</TableHeader>
    ),
    dataIndex: 'membership_name',
    render: (text: string, record: Reserve) => record.membership_name || 'N/D',
  },
  {
    title: <TableHeader>{i18n.t('reserves.table.row.location')}</TableHeader>,
    dataIndex: 'location',
    render: (text: string, record: Reserve) => record.location?.name || 'N/D',
  },
  {
    title: <TableHeader>{i18n.t('reserves.table.row.boat')}</TableHeader>,
    dataIndex: 'boat',
    render: (text: string, record: Reserve) => record.yacht?.name || 'N/D',
  },
  {
    title: (
      <TableHeader width="80px">
        {i18n.t('reserves.table.row.date')}
      </TableHeader>
    ),
    dataIndex: 'date',
    render: (text: string, record) => formatDate(record?.start_date),
  },
  {
    title: <TableHeader>{i18n.t('reserves.table.row.start_hour')}</TableHeader>,
    dataIndex: 'start_hour',
    render: (text: string, record: Reserve) =>
      formatHour(record.start_date) || 'N/D',
  },
  {
    title: <TableHeader>{i18n.t('reserves.table.row.end_hour')}</TableHeader>,
    dataIndex: 'end_hour',
    render: (text: string, record: Reserve) =>
      formatHour(record.end_date) || 'N/D',
  },
  {
    title: (
      <TableHeader width="100px">{i18n.t('table.row.options')}</TableHeader>
    ),
    dataIndex: 'options',
    fixed: 'right',
    render: (text: string, record: Reserve) => {
      const onView = () => viewReserve(record)
      const onCancel = () => cancelReserve(record)

      return (
        <Row gutter={16}>
          <Col>
            <SeeMoreButton text={i18n.t('table.view')} onClick={onView} />
          </Col>
          <Col>
            <CancelButton
              text={i18n.t('reservers.action.cancel')}
              onClick={onCancel}
              disabled={record.cancel}
            />
          </Col>
        </Row>
      )
    },
  },
]
