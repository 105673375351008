import MembershipsPageContext from 'components/context/MembershipsPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useMembershipsPageContext = () => {
  const contextName = 'MembershipsPageContext'
  const contextValue = useContext(MembershipsPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useMembershipsPageContext
