import FullscreenModal from 'components/molecules/FullscreenModal'
import useMembershipsPageContext from 'hooks/useMembershipsPageContext'
import { MembershipForm } from './MembershipForm'

const EditMembershipForm = () => {
  const { editFormState, onCloseEditMembershipForm, onUpdateMembership } =
    useMembershipsPageContext()

  return (
    <FullscreenModal
      visible={editFormState.isOpen}
      maskClosable={false}
      onCancel={onCloseEditMembershipForm}
      maxWidth="450px"
      destroyOnClose
      loading={false}
    >
      <MembershipForm
        initialValues={editFormState.membership}
        isEditionForm
        onSubmit={onUpdateMembership}
      />
    </FullscreenModal>
  )
}

export { EditMembershipForm }
