import { User } from 'api/User/declarations'
import { useState } from 'react'
import mixpanelService from 'services/mixpanel'
import { isAdmin } from 'utils/user'

export type WithUserProps = {
  user?: User
  rememberMe?: boolean
  setUser?: (user: User) => void
  clearUser?: () => void
  isAdmin?: () => boolean
}

const withUser =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  ({ ...props }: P) => {
    const USER_KEY = 'user'

    const getUser = (): User | null => {
      const userString = localStorage.getItem(USER_KEY)
      if (!userString) {
        return null
      }

      const user = JSON.parse(userString)

      const isAdmin = user.role === 'admin'

      mixpanelService.identify(
        user.email,
        user.first_name + ' ' + user.last_name,
        isAdmin,
        user.customer
      )

      return user
    }

    const [user, setUser] = useState<User | null>(getUser())

    const saveUser = (user: User) => {
      localStorage.setItem(USER_KEY, JSON.stringify(user))
      setUser(user)
    }

    const clearUser = () => localStorage.removeItem(USER_KEY)

    const isAccessAdmin = () => isAdmin(user?.role)

    return (
      <Component
        user={user}
        isAdmin={isAccessAdmin}
        setUser={saveUser}
        clearUser={clearUser}
        {...(props as P)}
      />
    )
  }

export default withUser
