import { FC, useEffect, useState } from 'react'
import BankAccountPageContext, {
  BankAccountShowModal,
  IBankAccountPageContext,
} from './BankAccountsPageContext'
import { BankAccount, BankAccountsParams } from 'api/BankAccount/declarations'
import tryFunction from 'utils/try'
import scrollToTop from 'utils/scroll'
import BankAccountService from 'api/BankAccount/BanckAccount'
import { useHistory } from 'react-router-dom'
import routes from 'shared/constants/routes'

const initialShowModal: BankAccountShowModal = {
  bankAccountForm: false,
}

const initialParams: BankAccountsParams = {
  per_page: 10,
  page: 1,
}

const BankAccountsPageProvider: FC = ({ children }) => {
  const { push } = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(true)
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([])
  const [count, setCount] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [bankAccountEdit, setBankAccountEdit] = useState<BankAccount>()
  const [bankAccountParams, setBankAccountParams] =
    useState<BankAccountsParams>(initialParams)
  const [showModal, setShowModal] =
    useState<BankAccountShowModal>(initialShowModal)

  useEffect(() => {
    if (refresh) {
      loadData()
      setRefresh(false)
    }
  }, [bankAccountParams, showModal])

  const loadData = () => {
    tryFunction(async () => {
      await getBankAccounts()
      await getTotalBalance()
      scrollToTop()
    }, setLoading)
  }

  const getBankAccounts = async () => {
    const { data: accounts, count } =
      await BankAccountService.findWithPagination(bankAccountParams)
    setBankAccounts(accounts)
    setCount(count)
  }

  const getTotalBalance = async () => {
    const { total_balance } = await BankAccountService.getTotalBalance()
    setTotalBalance(total_balance)
  }

  const onPageChange = (pageNumber: number) => {
    setBankAccountParams({ ...bankAccountParams, page: pageNumber })
  }

  const handledCloseBankAccountModalForm = () => {
    setBankAccountEdit(undefined)
    setShowModal({ ...showModal, bankAccountForm: false })
  }

  const handledOpenBankAccountModalForm = (bankAccount?: BankAccount) => {
    setBankAccountEdit(bankAccount)
    setShowModal({ ...showModal, bankAccountForm: true })
  }

  const createBankAccount = () => {
    handledOpenBankAccountModalForm()
  }

  const submitBankAccountForm = (data: BankAccount) => {
    tryFunction(
      async () => {
        if (bankAccountEdit) {
          await BankAccountService.update(bankAccountEdit.id, data)
        } else {
          await BankAccountService.create(data)
        }
        setRefresh(true)
        handledCloseBankAccountModalForm()
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const deleteBankAccount = (account: BankAccount) => {
    tryFunction(
      async () => {
        await BankAccountService.delete(account.id)
        await getBankAccounts()
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const openBankMovements = (account: BankAccount) =>
    push(routes.movements(account.id))

  const contextValue: IBankAccountPageContext = {
    loading,
    bankAccounts,
    count,
    totalBalance,
    bankAccountEdit,
    showModal,
    bankAccountParams,
    onPageChange,
    submitBankAccountForm,
    handledCloseBankAccountModalForm,
    handledOpenBankAccountModalForm,
    createBankAccount,
    deleteBankAccount,
    openBankMovements,
  }

  return (
    <BankAccountPageContext.Provider value={contextValue}>
      {children}
    </BankAccountPageContext.Provider>
  )
}

export { BankAccountsPageProvider }
