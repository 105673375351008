import FullscreenModal from 'components/molecules/FullscreenModal'
import useYachtsPage from 'hooks/useYachtsPageContext'
import { ImagesForm } from './ImagesForm/ImagesForm'

export const ImagesFormModal = () => {
  const {
    showModal,
    loading,
    handledCloseImagesModalForm: onCancel,
  } = useYachtsPage()
  return (
    <FullscreenModal
      visible={showModal.imagesForm}
      loading={loading}
      onCancel={onCancel}
      maskClosable={false}
      maxWidth="1000px"
      destroyOnClose
    >
      <ImagesForm />
    </FullscreenModal>
  )
}
