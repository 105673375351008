import { Yacht } from 'api/Yacht/declarations'
import { createContext } from 'react'

interface IYachtsContext {
  yachts: Yacht[]
  getYachts: () => Promise<void>
  setLocationId: (location?: number) => void
  locationId: number
}

const YachtsContext = createContext<IYachtsContext | undefined>(undefined)

export default YachtsContext
export type { IYachtsContext }
