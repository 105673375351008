import axios from 'axios'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from '../BaseService/declarations'
import { SaleRoom } from './declaration'

export class SaleRoomService extends BaseService<SaleRoom> {
  protected name: string = 'sale-room'

  async paginate(params): Promise<PaginatedResponse<SaleRoom>> {
    try {
      const response = await axios.get<Response<SaleRoom[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      const data = response.data.data as SaleRoom[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async create(body: SaleRoom): Promise<SaleRoom> {
    try {
      const response = await axios.post<Response<SaleRoom>>(
        `${this.url}/${this.name}`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as SaleRoom
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async update(sale_room_id: number, body: SaleRoom): Promise<SaleRoom> {
    try {
      const response = await axios.put<Response<SaleRoom>>(
        `${this.url}/${this.name}/${sale_room_id}`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as SaleRoom
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async delete(sale_room_id: number): Promise<void> {
    try {
      const response = await axios.delete<Response<SaleRoom>>(
        `${this.url}/${this.name}/${sale_room_id}`,
        this.getHeaders()
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new SaleRoomService()
export default service
