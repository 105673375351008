import i18n from 'i18next'
import { Col, Row } from 'antd'
import { EditButton, RemoveButton, TableHeader } from 'components/atoms/Table'
import { Location } from 'api/Location/declarations'
import getLocationStatus from 'utils/getLocationsStatus'

type ILocationsTableColumns = {
  onOpenEditLocationForm: (location: Location) => void
  onDeleteLocation: (location: Location) => void
}

export const LocationsTableColumns = ({
  onOpenEditLocationForm,
  onDeleteLocation,
}: ILocationsTableColumns) => [
  {
    title: <TableHeader>{i18n.t('table.row.name')}</TableHeader>,
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: <TableHeader>{i18n.t('table.row.status')}</TableHeader>,
    key: 'status',
    render: (text: string, { status }: Location) => getLocationStatus(status),
  },
  {
    title: (
      <TableHeader width="150px">{i18n.t('table.row.options')}</TableHeader>
    ),
    key: 'options',
    render: (text: string, location: Location) => {
      const onClickEdit = () => onOpenEditLocationForm(location)
      const onClickDelete = () => onDeleteLocation(location)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />
          </Col>
          <Col>
            <RemoveButton
              text={i18n.t('table.delete')}
              onClick={onClickDelete}
            />
          </Col>
        </Row>
      )
    },
  },
]
