import React from 'react'
import { Spin } from 'antd'
import TextAlign from 'components/atoms/TextAlign/TextAlign'
import { LoadingOutlined } from '@ant-design/icons'

export const LoadingSpin = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />

  return (
    <TextAlign align="center">
      <Spin indicator={antIcon} />
    </TextAlign>
  )
}
