import { Service, ServiceParams } from 'api/Service/declarations'
import { createContext } from 'react'

export type ServiceShowModal = {
  serviceForm: boolean
  imagesForm: boolean
}

interface IServicesPageContext {
  loading: boolean
  serviceEdit?: Service
  params?: ServiceParams
  count: number
  services: Service[]
  showModal: ServiceShowModal
  submitServiceForm: (data: Service) => void
  onDelete: (data: Service) => void
  onChangePage: (page: number) => void
  handledOpenServiceModalForm: (data?: Service) => void
  handledCloseServiceModalForm: () => void
  handledOpenImagesModalForm: (data: Service) => void
  handledCloseImagesModalForm: () => void
  createService: () => void
  onUploadImage: (image, image_id?: number) => void
  onDeleteImage: (image_id?: number) => void
}

const ServicesPageContext = createContext<IServicesPageContext | undefined>(
  undefined
)

export default ServicesPageContext
export type { IServicesPageContext }
