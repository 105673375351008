import { Business } from 'api/Business/declarations'
import { Membership } from 'api/Membership/declarations'

export const getMembershipsByBusiness = ({
  business,
  memberships,
}: {
  business: Business
  memberships: Membership[]
}): Membership[] => {
  if (!business) {
    return memberships
  }
  return memberships?.filter(({ businesses }) =>
    businesses?.some(({ id }) => id === business?.id)
  )
}
