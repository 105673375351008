import React, { useEffect, useState } from 'react'
import { When } from 'react-if'
import { Layout } from 'antd'
import { useTranslation } from 'react-i18next'
import LateralMenu from 'components/molecules/LateralMenu'
import AppHeader from 'components/molecules/AppHeader'
import { EMenuKeys } from 'shared/constants/menuElements'
import { WithUserProps } from 'hooks/withUser'
import AuthService from 'api/Auth'
import UserService from 'api/User'
import { RouteComponentProps } from 'react-router-dom'
import routes from 'shared/constants/routes'
import useToken from 'hooks/useToken'
import { ELanguage } from 'interfaces/i18n'
import Title from 'components/atoms/Title/Title'
import scrollToTop from 'utils/scroll'
import { MainLayoutProvider } from 'components/context/MainLayoutContext'

const { Content, Footer } = Layout

type Props = WithUserProps &
  RouteComponentProps & {
    active?: EMenuKeys
    showTitle?: boolean
  }

const MainLayout: React.FC<Props> = ({
  children,
  active = EMenuKeys.PARTNERS,
  user,
  history,
  showTitle = true,
  clearUser = () => true,
  setUser = () => true,
}) => {
  const { clearToken } = useToken()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [language, setLanguage] = useState<ELanguage>(
    user?.profile?.locale || ELanguage.ES
  )

  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language)
    }
    scrollToTop()
  }, [language, user])

  async function doLogout() {
    try {
      setLoading(true)
      await AuthService.logout()
    } catch (error) {
    } finally {
      clearToken()
      clearUser()
      setLoading(false)
      history.push(routes.login)
    }
  }

  const handleChangeLanguage = async (value: ELanguage) => {
    try {
      setLoading(true)
      const { user } = await UserService.updateLocale(value)
      setUser(user)
    } catch (error) {
    } finally {
      await i18n.changeLanguage(value)
      setLanguage(value)
      setLoading(false)
    }
  }

  return (
    <MainLayoutProvider user={user}>
      <AppHeader
        onLogout={doLogout}
        handleChange={handleChangeLanguage}
        loading={loading}
      />
      <Layout style={{ height: 'auto' }}>
        <LateralMenu active={active} />
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: '20px 24px 10px',
              margin: 0,
              minHeight: 800,
            }}
          >
            <When condition={showTitle && active}>
              <Title>{t(`side-menu.${active}`)}</Title>
            </When>
            {children}
          </Content>
          <Footer style={{ textAlign: 'center', padding: 0 }}>
            {`© ${new Date().getFullYear()} YACHTVIBES.${t(
              'footer.all-reserved'
            )}`}
          </Footer>
        </Layout>
      </Layout>
    </MainLayoutProvider>
  )
}

export default MainLayout
