import { FC, useEffect, useState } from 'react'
import BlockedDatesPageContext, {
  IBlockedDatesPageContext,
  IEditFormState,
} from './BlockedDatesPageContext'
import useDisclosure from 'hooks/useDisclosure'
import { SortDirection } from 'interfaces/shared'
import BlockedDateService from 'api/BlockedDate'
import type {
  IBlockedDate,
  GetBlockedDatesParams,
  ICreateBlockedDate,
  IUpdateBlockedDate,
} from 'api/BlockedDate/declarations'
import tryFunction from 'utils/try'
import useLocationsContext from 'hooks/useLocationsContext'
import useMembershipsContext from 'hooks/useMembershipsContext'
import useYachtsContext from 'hooks/useYachtsContext'

const BlockedDatesPageProvider: FC = ({ children }) => {
  const [blockedDatesParams, setBlockedDatesParams] =
    useState<GetBlockedDatesParams>({
      per_page: 10,
      page: 1,
    })
  const [blockedDates, setBlockedDates] = useState<IBlockedDate[]>([])
  const [blockedDatesCount, setBlockedDatesCount] = useState(0)
  const [isLoadingBlockedDates, setIsLoadingBlockedDates] = useState(false)
  const {
    isOpen: createBlockedDateFormIsOpen,
    onClose: onCloseCreateBlockedDateForm,
    onOpen: onOpenCreateBlockedDateForm,
  } = useDisclosure()
  const [dateSort, setDateSort] = useState<SortDirection | undefined>(undefined)
  const [editBlockedDateFormState, setEditBlockedDateFormState] =
    useState<IEditFormState>({ isOpen: false })
  const { locations, getLocations } = useLocationsContext()
  const { memberships, getMemberships } = useMembershipsContext()
  const { yachts, getYachts } = useYachtsContext()

  useEffect(() => {
    getLocations()
    getMemberships()
    getYachts()
  }, [])

  const updateBlockedDates = async () => {
    tryFunction(async () => {
      const { count, data } = await BlockedDateService.findWithPagination({
        ...blockedDatesParams,
        sort: { date: dateSort },
      })
      setBlockedDates(data)
      setBlockedDatesCount(count)
    }, setIsLoadingBlockedDates)
  }

  useEffect(() => {
    updateBlockedDates()
  }, [dateSort])

  const onOpenEditBlockedDateForm = (blockedDate: IBlockedDate) =>
    setEditBlockedDateFormState({ blockedDate, isOpen: true })

  const onCloseEditBlockedDateForm = () =>
    setEditBlockedDateFormState({
      ...editBlockedDateFormState,
      isOpen: false,
    })

  const onPageChange = (pageNumber: number) => {
    setBlockedDatesParams({ ...blockedDatesParams, page: pageNumber })
  }

  const onCreateBlockedDate = async (body: ICreateBlockedDate) => {
    await BlockedDateService.createBlockedDate(body)
    await updateBlockedDates()
    onCloseCreateBlockedDateForm()
  }

  const onUpdateBlockedDate = async (body: IUpdateBlockedDate) => {
    await BlockedDateService.updateBlockedDate(
      editBlockedDateFormState.blockedDate?.id,
      body
    )
    await updateBlockedDates()
    onCloseEditBlockedDateForm()
  }

  const onDeleteBlockedDate = (blockedDate: IBlockedDate) => {
    tryFunction(
      async () => {
        await BlockedDateService.deleteBlockedDate(blockedDate.id)
        await updateBlockedDates()
      },
      () => {},
      true
    )
  }

  const contextValue: IBlockedDatesPageContext = {
    blockedDates,
    createBlockedDateFormIsOpen,
    onCloseCreateBlockedDateForm,
    onOpenCreateBlockedDateForm,
    setDateSort,
    dateSort,
    onCreateBlockedDate,
    onDeleteBlockedDate,
    blockedDatesCount,
    isLoadingBlockedDates,
    blockedDatesParams,
    onPageChange,
    editBlockedDateFormState,
    onOpenEditBlockedDateForm,
    onCloseEditBlockedDateForm,
    onUpdateBlockedDate,
    locations,
    memberships,
    yachts,
  }

  return (
    <BlockedDatesPageContext.Provider value={contextValue}>
      {children}
    </BlockedDatesPageContext.Provider>
  )
}

export { BlockedDatesPageProvider }
