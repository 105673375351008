import { Country } from 'api/Country/declarations'
import { createContext } from 'react'

interface ICountriesContext {
  countries: Country[]
  getCountries: () => Promise<void>
}

const CountriesContext = createContext<ICountriesContext | undefined>(undefined)

export default CountriesContext
export type { ICountriesContext }
