import styled from 'styled-components'

const TextCalendar = styled.p`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  span {
    font-weight: bold;
    padding-left: 5px;
  }
`

export default TextCalendar
