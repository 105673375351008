import { Tabs } from 'antd'
import { Reserve } from 'api/Reserve/declarations'
import { useTranslation } from 'react-i18next'
import { ReservesTable } from './ReservesTable'

const { TabPane } = Tabs

type Props = {
  reserves?: Reserve[]
  loading: boolean
  count: number
  params
  onChangeTap?: (tap: EReserveTap) => void
  onChangePage?: (page: number) => void
}

export enum EReserveTap {
  BEFORE = 'before',
  AFTER = 'after',
  CANCELED = 'canceled',
}

export const ReservesView = ({
  reserves = [],
  loading,
  params,
  count,
  onChangeTap,
  onChangePage,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Tabs defaultActiveKey={EReserveTap.AFTER} onChange={onChangeTap}>
      {Object.keys(EReserveTap).map((tap: EReserveTap) => (
        <TabPane
          tab={t(`reserves.tab.${EReserveTap[tap]}`)}
          key={EReserveTap[tap]}
        >
          <ReservesTable
            tap={EReserveTap[tap]}
            reserves={reserves}
            loading={loading}
            onChangePage={onChangePage}
            pagination={{ ...params }}
            count={count}
          />
        </TabPane>
      ))}
    </Tabs>
  )
}
