import i18n from 'i18next'
import { Col, Row } from 'antd'
import {
  CancelButton,
  TableHeader,
  SeeMoreButton,
} from 'components/atoms/Table'
import { Charge } from 'api/Charge/declarations'
import getFormattedDate from 'utils/getFormattedDate'
import getChargeStatus from 'utils/getChargeStatus'
import getChargePaymentType from 'utils/getChargePaymentType'
import { formatPrice } from 'utils/common'

type IChargesTableColumns = {
  onOpenChargeDetails: (charge: Charge) => void
  onCancelCharge: (chargeId: number) => Promise<void>
}

export const ChargesTableColumns = ({
  onOpenChargeDetails,
  onCancelCharge,
}: IChargesTableColumns) => [
  {
    title: <TableHeader>{i18n.t('charge.table.client-name')}</TableHeader>,
    dataIndex: 'customer_name',
    key: 'customer_name',
  },
  {
    title: <TableHeader>{i18n.t('charge.table.payment-date')}</TableHeader>,
    key: 'processed_at',
    render: (text: string, charge: Charge) =>
      getFormattedDate(charge.processed_at),
  },
  {
    title: <TableHeader>{i18n.t('charge.table.amount')}</TableHeader>,
    key: 'paid',
    render: (text: string, charge: Charge) =>
      charge.paid ? formatPrice(charge.paid.toString()) : 'N/A',
  },
  {
    title: <TableHeader>{i18n.t('charge.table.payment-status')}</TableHeader>,
    key: 'status',
    render: (text: string, { status }: Charge) => getChargeStatus(status),
  },
  {
    title: <TableHeader>{i18n.t('charge.table.payment-method')}</TableHeader>,
    key: 'payment_type',
    render: (text: string, { payment_type }: Charge) =>
      getChargePaymentType(payment_type),
  },
  {
    title: <TableHeader>{i18n.t('charge.table.reference')}</TableHeader>,
    key: 'payment_id',
    render: (text: string, { payment_id }: Charge) =>
      payment_id ? payment_id : 'N/A',
  },
  {
    title: (
      <TableHeader width="150px">{i18n.t('table.row.options')}</TableHeader>
    ),
    key: 'options',
    render: (text: string, charge: Charge) => {
      const onSeeMore = () => onOpenChargeDetails(charge)
      const onCancel = () => onCancelCharge(charge.id)

      return (
        <Row gutter={16}>
          <Col>
            <SeeMoreButton text={i18n.t('table.view')} onClick={onSeeMore} />
          </Col>
          <Col>
            <CancelButton
              text={i18n.t('charge.table.cancel')}
              onClick={onCancel}
              confirmationMessage={i18n.t('charge.table.cancel-confirm')}
            />
          </Col>
        </Row>
      )
    },
  },
]
