import moment from 'moment-timezone'
import FullscreenModal from 'components/molecules/FullscreenModal'
import useBlockedDatesPageContext from 'hooks/useBlockedDatesPageContext'
import { BlockedDateForm, IBlockedDateFormValues } from './BlockedDateForm'

const NewBlockedDateForm = () => {
  const {
    createBlockedDateFormIsOpen,
    onCloseCreateBlockedDateForm,
    onCreateBlockedDate,
  } = useBlockedDatesPageContext()

  const onSubmit = async (values: IBlockedDateFormValues) => {
    await onCreateBlockedDate({
      ...values,
      timezone: moment.tz.guess(),
      date: values.date.toDate(),
    })
  }

  return (
    <FullscreenModal
      visible={createBlockedDateFormIsOpen}
      maskClosable={false}
      onCancel={onCloseCreateBlockedDateForm}
      maxWidth="450px"
      destroyOnClose
      height="fit-content"
      loading={false}
    >
      <BlockedDateForm onSubmit={onSubmit} />
    </FullscreenModal>
  )
}

export { NewBlockedDateForm }
