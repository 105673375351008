import FullscreenModal from 'components/molecules/FullscreenModal'
import useUsersPage from 'hooks/useUsersPageContext'
import { UserForm } from './UserForm/UserForm'

export const UserFormModal = () => {
  const {
    showModal,
    loading,
    handledCloseUserModalForm: onCancel,
  } = useUsersPage()
  return (
    <FullscreenModal
      visible={showModal.userForm}
      loading={loading}
      onCancel={onCancel}
      maskClosable={false}
      maxWidth="650px"
      destroyOnClose
    >
      <UserForm />
    </FullscreenModal>
  )
}
