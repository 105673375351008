import FullscreenModal from 'components/molecules/FullscreenModal'
import useLocationsPageContext from 'hooks/useLocationsPageContext'
import { LocationForm } from './LocationForm'

const NewLocationForm = () => {
  const {
    createLocationFormIsOpen,
    onCloseCreateLocationForm,
    onCreateLocation,
  } = useLocationsPageContext()

  return (
    <FullscreenModal
      visible={createLocationFormIsOpen}
      maskClosable={false}
      onCancel={onCloseCreateLocationForm}
      maxWidth="450px"
      destroyOnClose
      height="fit-content"
      loading={false}
    >
      <LocationForm onSubmit={onCreateLocation} />
    </FullscreenModal>
  )
}

export { NewLocationForm }
