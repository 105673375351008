import moment from 'moment'
import momentTz from 'moment-timezone'
import ReserveService from 'api/Reserve'
import { useHistory } from 'react-router-dom'
import {
  EStatusReserve,
  ICalendarEvent,
  Reserve,
} from 'api/Reserve/declarations'
import { FC, useContext, useEffect, useState } from 'react'
import scrollToTop from 'utils/scroll'
import tryFunction from 'utils/try'
import CalendarPageContext, {
  ICalendarPageContext,
} from './CalendarPageContext'
import LocationsContext from '../LocationsContext'
import YachtsContext from '../YachtsContext'
import MembershipsContext from '../MembershipsContext'
import routes from 'shared/constants/routes'
import { getReserveDateParamUrl } from 'utils/reserves'
import UserService from 'api/User'
import BlockedDateService from 'api/BlockedDate'
import { EUserRole } from 'api/User/declarations'
import { Business } from 'api/Business/declarations'
import { SaleRoom } from 'api/SaleRoom/declaration'

export type CalendarParams = {
  year: number
  month: number
  location_id?: number
  yacht_id?: number
  membership_name?: string
  business_id?: number
  sale_room_id?: number
}

const initialParams: CalendarParams = {
  year: moment().year(),
  month: moment().month() + 1,
}

export const FORMAT_DATE_URL = 'YYYY-MM-DD'

const CalendarPageProvider: FC = ({ children }) => {
  const { push } = useHistory()
  const { locations, getLocations } = useContext(LocationsContext)
  const { yachts, getYachts, setLocationId } = useContext(YachtsContext)
  const { memberships, getMemberships } = useContext(MembershipsContext)
  const [events, setEvents] = useState<ICalendarEvent[]>([])
  const [calendarDate, setCalendarDate] = useState<Date>(moment().toDate())
  const [loading, setLoading] = useState<boolean>(false)
  const [params, setParams] = useState<CalendarParams>(initialParams)
  const [preReserves, setPreReserves] = useState<Reserve[]>([])
  const [businesses, setBusinesses] = useState<Business[]>([])
  const [saleRooms, setSaleRooms] = useState<SaleRoom[]>([])

  useEffect(() => {
    onChangeDate()
    loadData()
  }, [params])

  const loadData = () => {
    tryFunction(async () => {
      await getReserves()
      await getPreReserves()
      await getLocations()
      await getMemberships()
      await getYachts()
      await getBusinessesAndSalesRoom()
      scrollToTop()
    }, setLoading)
  }

  const getBusinessesAndSalesRoom = async () => {
    const customers = await UserService.find({
      role: EUserRole.CUSTOMER,
      without_images: true,
    })
    const newBusinesses = customers
      .filter(({ business }) => Boolean(business))
      .map(({ business }) => business)
    const newSaleRooms = newBusinesses
      .filter(({ sales_room }) => Boolean(sales_room))
      .map(({ sales_room }) => sales_room)
      .flat()
    setBusinesses(newBusinesses)
    setSaleRooms(newSaleRooms)
  }

  const getReserves = async () => {
    const events = await ReserveService.calendar(params)
    const blockedDatesEvents = await BlockedDateService.calendar({
      month: params.month,
      year: params.year,
      timezone: momentTz.tz.guess(),
    })
    const daysOff = blockedDatesEvents.filter(
      ({ blocked_date: { whole_fleet } }) => Boolean(whole_fleet)
    )
    const daysOffEvents: ICalendarEvent[] = daysOff.map(
      ({ blocked_date: { id, date } }) => ({
        id,
        number_reserves: 0,
        number_pre_reserves: 0,
        start: date,
        end: date,
        isBlocked: true,
      })
    )
    setEvents([...events, ...daysOffEvents])
  }

  const getPreReserves = async () => {
    const preReserves = await ReserveService.find({
      cancel: false,
      past: false,
      status: EStatusReserve.PRE_RESERVE,
      without_images: true,
    })
    setPreReserves(preReserves)
  }

  const onChangeDate = () => {
    const { month, year } = params
    const calendarDate = moment()
      .year(year)
      .month(month - 1)
      .toDate()
    setCalendarDate(calendarDate)
  }

  const redirectReservesPage = ({ start }: ICalendarEvent) => {
    const date = getReserveDateParamUrl(start)
    const path = routes.reserves(date)
    push(path)
  }

  const contextValue: ICalendarPageContext = {
    loading,
    events,
    calendarDate,
    params,
    locations,
    yachts,
    memberships,
    preReserves,
    setParams,
    setLocationId,
    redirectReservesPage,
    businesses,
    saleRooms,
  }

  return (
    <CalendarPageContext.Provider value={contextValue}>
      {children}
    </CalendarPageContext.Provider>
  )
}

export { CalendarPageProvider }
