import FullscreenModal from 'components/molecules/FullscreenModal'
import useServicesPage from 'hooks/translate/useServicesPageContext'
import { ServiceForm } from './ServiceForm/ServiceForm'

const ServiceFormModal = () => {
  const {
    showModal,
    loading,
    handledCloseServiceModalForm: onCancel,
  } = useServicesPage()
  return (
    <FullscreenModal
      visible={showModal.serviceForm}
      loading={loading}
      onCancel={onCancel}
      maskClosable={false}
      maxWidth="600px"
      height="auto"
      destroyOnClose
    >
      <ServiceForm />
    </FullscreenModal>
  )
}

export default ServiceFormModal
