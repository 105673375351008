import { BaseActionButton, IActionButton } from './BaseActionButton'
import COLORS from 'shared/constants/colors'
import { TeamOutlined } from '@ant-design/icons'

const SeeBeneficiariesButton = (props: IActionButton) => {
  const { AQUA_DARK } = COLORS

  return (
    <BaseActionButton {...props} icon={<TeamOutlined color={AQUA_DARK} />} />
  )
}

export default SeeBeneficiariesButton
