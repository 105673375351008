import { PaginationData } from 'api/BaseService/declarations'
import { User } from 'api/User/declarations'

export enum EStatusPayment {
  SUCCEEDED = 'succeeded',
  PAID = 'paid',
  PENDING = 'pending',
  FAILED = 'failed',
  CANCELED = 'canceled',
}

export enum EPaymentType {
  CASH = 'cash',
  SPEI = 'spei',
  TPV = 'tpv',
  CARD = 'card',
}

export enum ECardType {
  CASH = 'cash',
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export type Charge = {
  id: number
  paid: number
  payment_id: string
  error_code: string
  concept: string
  currency: string
  status: EStatusPayment
  customer_name: string
  card_last4?: string
  card_type?: string
  card_brand?: string
  auth_code?: string
  issuer?: string
  fee?: number
  processed_at?: Date
  payment_type?: EPaymentType
  user_id?: number
  user?: User
  customer_email: string
}

export type GetChargesParams = {
  search?: string
  status?: EStatusPayment
  start_date?: Date
  end_date?: Date
} & PaginationData
