const scrollToTop = () => {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // Chrome - Firefox
}

export const scrollToTopSelector = (selector: string) => {
  document.querySelector(selector)?.scrollTo(0, 0)
}

export const scrollToTopFullscreenModal = () => {
  scrollToTopSelector('.ant-modal-content>div')
}

export default scrollToTop
