import { Col, Row } from 'antd'
import ImageUploader from 'components/molecules/ImageUploader'
import { IImage } from 'interfaces/image'
import { FavoriteButton, RemoveButton } from 'components/atoms/Table'
import { useTranslation } from 'react-i18next'
import { IImageSizes } from 'components/atoms/ContainerPreviewUploader'
import { When } from 'react-if'

type Props = {
  size: IImageSizes
  loading: boolean
  image: IImage
  useFavorite?: boolean
  defaultImage?: IImage
  onEdit: (image, id: number | string) => void
  onDelete: (id: number | string) => void
  onDefault?: (id: number | string) => void
}

export const ImageAction = ({
  size,
  loading,
  image,
  useFavorite = true,
  defaultImage,
  onEdit,
  onDelete,
  onDefault = () => {},
}: Props) => {
  const { t } = useTranslation()
  const { id, url } = image
  const isDefault = id === defaultImage?.id

  const onEditImage = (image) => onEdit(image, id)
  const onDeleteImage = () => onDelete(id)
  const onDefaultImage = () => onDefault(id)

  return (
    <Row gutter={[16, 16]} justify="center" align="middle">
      <Col>
        <ImageUploader
          disabled={loading}
          imageUrl={url}
          onImageLoaded={onEditImage}
          size={size}
        />
        <Row gutter={0} justify="end" style={{ marginTop: 12 }}>
          <When condition={useFavorite}>
            <Col>
              <FavoriteButton
                text={t('table.default')}
                isFavorite={isDefault}
                disabled={isDefault}
                onClick={onDefaultImage}
              />
            </Col>
          </When>
          <Col>
            <RemoveButton text={t('table.delete')} onClick={onDeleteImage} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
