import { Badge, Tooltip, Dropdown } from 'antd'
import { NotificationOutlined } from '@ant-design/icons'
import useCalendarPage from 'hooks/CalendarPage'
import { useTranslation } from 'react-i18next'
import { PreReservesOverlay } from './PreReservesOverlay'

const ICON_STYLE = { paddingRight: 20, fontSize: 20 }

export const PreReservesDropdown = () => {
  const { t } = useTranslation()
  const { preReserves } = useCalendarPage()

  return (
    <Tooltip title={t('calendar.pre_reserve.view')}>
      <Dropdown overlay={<PreReservesOverlay />} trigger={['click']}>
        <Badge count={preReserves.length}>
          <NotificationOutlined style={ICON_STYLE} />
        </Badge>
      </Dropdown>
    </Tooltip>
  )
}
