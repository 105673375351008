import { Additional } from 'api/Additional/declarations'
import axios from 'axios'
import { IPickerPosition } from 'components/molecules/Maps/FormMap'
import { IImage } from 'interfaces/image'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from '../BaseService/declarations'
import { Yacht, YachtsParams, FindYachtsParams } from './declarations'

export class YachtService extends BaseService<Yacht> {
  protected name: string = 'yacht'

  async findYachtAddons(yacht_id: number): Promise<Additional[]> {
    try {
      const response = await axios.get<Response<Additional[]>>(
        `${this.url}/${this.name}/${yacht_id}/addons`,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as Additional[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async find(params: FindYachtsParams): Promise<Yacht[]> {
    try {
      const response = await axios.get<Response<Yacht[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      const data = response.data.data as Yacht[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async paginate(params: YachtsParams): Promise<PaginatedResponse<Yacht>> {
    try {
      const response = await axios.get<Response<Yacht[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      const data = response.data.data as Yacht[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async create(body: Yacht): Promise<Yacht> {
    try {
      const response = await axios.post<Response<Yacht>>(
        `${this.url}/${this.name}`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as Yacht
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async update(yacht_id: number, body: Yacht): Promise<Yacht> {
    try {
      const response = await axios.put<Response<Yacht>>(
        `${this.url}/${this.name}/${yacht_id}`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as Yacht
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async updateMaps(yacht_id: number, position: IPickerPosition): Promise<void> {
    try {
      const body = {
        latitude: position.lat,
        longitude: position.lng,
      }
      await axios.post<Response<Yacht>>(
        `${this.url}/${this.name}/${yacht_id}/maps`,
        body,
        this.getHeaders()
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async active(yacht: Yacht): Promise<void> {
    try {
      const { id: yacht_id, active } = yacht
      await axios.post<Response<Yacht>>(
        `${this.url}/${this.name}/${yacht_id}/active`,
        { active: !active },
        this.getHeaders()
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async delete(yacht_id: number): Promise<void> {
    try {
      await axios.delete<Response<{}>>(
        `${this.url}/${this.name}/${yacht_id}`,
        this.getHeaders()
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async uploadImage(
    yacht_id: number,
    image,
    image_id?: number
  ): Promise<IImage> {
    try {
      const params = { image_id }
      const formData = new FormData()
      formData.append('image', image)
      const response = await axios.post<Response<IImage>>(
        `${this.url}/${this.name}/${yacht_id}/image`,
        formData,
        this.getHeadersMultipart({ params })
      )
      const { data: axiosData } = response
      return axiosData.data as IImage
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async deleteImage(yacht_id: number, image_id: number): Promise<void> {
    try {
      const params = { image_id }
      await axios.delete<Response<IImage>>(
        `${this.url}/${this.name}/${yacht_id}/image`,
        this.getHeaders({ params })
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async defaultImage(yacht_id: number, image_id: number): Promise<void> {
    try {
      const params = { image_id }
      await axios.post<Response<IImage>>(
        `${this.url}/${this.name}/${yacht_id}/image/default`,
        {},
        this.getHeaders({ params })
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new YachtService()
export default service
