import { FC, useState, useEffect } from 'react'
import ChargesPageContext, {
  IChargesPageContext,
  IChargeDetailsModalState,
} from './ChargesPageContext'
import {
  GetChargesParams,
  Charge,
  EStatusPayment,
} from 'api/Charge/declarations'
import ChargeService from 'api/Charge'
import tryFunction from 'utils/try'
import { Moment } from 'moment'

const ChargesPageProvider: FC = ({ children }) => {
  const [chargesParams, setChargesParams] = useState<GetChargesParams>({
    per_page: 10,
    page: 1,
  })
  const [charges, setCharges] = useState<Charge[]>([])
  const [chargesCount, setChargesCount] = useState(0)
  const [isLoadingCharges, setIsLoadingCharges] = useState(false)
  const [chargeDetailsModalState, setChargeDetailsModalState] =
    useState<IChargeDetailsModalState>({ isOpen: false })

  const getCharges = () => {
    tryFunction(async () => {
      const { data, count } = await ChargeService.findWithPagination(
        chargesParams
      )
      setCharges(data)
      setChargesCount(count)
    }, setIsLoadingCharges)
  }

  useEffect(() => {
    getCharges()
  }, [chargesParams])

  const onPageChange = (pageNumber: number) => {
    setChargesParams({ ...chargesParams, page: pageNumber })
  }

  const onStatusFilterChange = (status?: EStatusPayment) => {
    setChargesParams({ ...chargesParams, status })
  }

  const onDateFilterChange = (dates?: [Moment, Moment]) => {
    setChargesParams({
      ...chargesParams,
      start_date: dates ? dates[0].toDate() : undefined,
      end_date: dates ? dates[1].toDate() : undefined,
    })
  }

  const onSearchChange = (search: string) => {
    setChargesParams({ ...chargesParams, search })
  }

  const onCancelCharge = async (chargeId: number) => {
    tryFunction(
      async () => {
        await ChargeService.cancelCharge(chargeId)
      },
      setIsLoadingCharges,
      true
    )
    getCharges()
  }

  const onOpenChargeDetailsModal = (charge: Charge) =>
    setChargeDetailsModalState({ isOpen: true, charge })

  const onCloseChargeDetailsModal = () =>
    setChargeDetailsModalState({ isOpen: false })

  const contextValue: IChargesPageContext = {
    charges,
    chargesCount,
    onPageChange,
    isLoadingCharges,
    chargesParams,
    onStatusFilterChange,
    onDateFilterChange,
    onSearchChange,
    onCancelCharge,
    chargeDetailsModalState,
    onOpenChargeDetailsModal,
    onCloseChargeDetailsModal,
  }

  return (
    <ChargesPageContext.Provider value={contextValue}>
      {children}
    </ChargesPageContext.Provider>
  )
}

export { ChargesPageProvider }
