import { Row, Col } from 'antd'
import ImageUploader, {
  IUploadedImage,
} from 'components/molecules/ImageUploader'
import { When } from 'react-if'
import { LoadingSpin } from 'components/molecules/LoadingSpin'
import { UploadRules } from 'components/molecules/UploadRules'
import { ImageList } from 'components/molecules/ImageList'
import useServicesPage from 'hooks/translate/useServicesPageContext'

const IMAGES_SIZE = {
  height: '200px',
  width: ' 200px',
}

const MAX_IMAGES = 3

export const ImagesForm = () => {
  const { height, width } = IMAGES_SIZE
  const { serviceEdit, loading, onDeleteImage, onUploadImage } =
    useServicesPage()

  const onDelete = (image_id: number) => onDeleteImage(image_id)
  const onUpload = (image: IUploadedImage, image_id?: number) =>
    onUploadImage(image?.file, image_id)

  const { images } = serviceEdit ?? {}
  const isDisabledButton = loading || images?.length >= MAX_IMAGES

  return (
    <Row gutter={[32, 32]} justify="space-between">
      <ImageList
        images={images}
        size={IMAGES_SIZE}
        loading={loading}
        onEdit={onUpload}
        onDelete={onDelete}
        useFavorite={false}
      />
      <Col xs={24}>
        <When condition={loading}>
          <LoadingSpin />
        </When>
        <Row gutter={[16, 50]}>
          <Col xs={24}>
            <UploadRules
              width={width}
              height={height}
              imagesNumber={MAX_IMAGES}
            />
          </Col>
          <Col xs={12}>
            <ImageUploader
              disabled={isDisabledButton}
              onImageLoaded={onUpload}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
