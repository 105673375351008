import { UserCreate, UserUpdate } from 'api/User/declarations'

export const formatUpdatePartner = ({
  email,
  first_name,
  middle_name,
  last_name,
  maternal_last_name,
  partner_status,
  partner_code,
  country_id,
  customer_id,
  phone,
  birthdate,
  address,
  hear_about_us,
  sale_date,
  membership_expiration,
  sale_room_id,
  convertors_ids,
  membership_id,
}: UserCreate): UserUpdate => ({
  user: {
    email,
    first_name,
    middle_name,
    last_name,
    maternal_last_name,
    partner_status,
    partner_code,
    country_id,
    customer_id,
    sale_room_id,
    convertors_ids,
    membership_id,
  },
  profile: {
    phone,
    birthdate,
    address,
    hear_about_us,
    sale_date,
    membership_expiration,
  },
})

export const formatUpdateCustomer = ({
  email,
  name,
  phone,
  convertors_ids,
  memberships_ids,
  location_id,
}: UserCreate): UserUpdate => ({
  user: {
    email,
    location_id,
    convertors_ids,
    memberships_ids,
    name,
  },
  profile: {
    phone,
  },
})
