import { Row, Col, DatePicker } from 'antd'
import AppButton from 'components/molecules/AppButton'
import { FiltersContainer } from 'components/atoms/Table'
import { useTranslation } from 'react-i18next'
import { CustomerSelector } from '../Selector/CustomerSelector'
import { PartnerStatusSelector } from '../Selector/PartnerStatusSelector'
import { MembershipSelector } from 'components/organisms/Membership/Selector/MembershipSelector'
import { SaleRoomSelector } from 'components/organisms/Businesses/Selector/SaleRoomSelector'
import { useEffect, useState } from 'react'
import { When } from 'react-if'
import useMainLayout from 'hooks/useAccessUser'
import useUsersPage from 'hooks/useUsersPageContext'
import { YachtSearch } from 'components/atoms/Search/Search'
import { Moment } from 'moment'
import mixpanelService from 'services/mixpanel'

const { RangePicker } = DatePicker

const style_filter: React.CSSProperties = {
  width: '25%',
}

export const UserHeader = () => {
  const { t } = useTranslation()
  const { isAdmin, user } = useMainLayout()
  const {
    isPartnerPage,
    role,
    loading,
    userParams,
    customers,
    memberships,
    createUser,
    getReport,
    onChangeFilter,
  } = useUsersPage()
  const [salesRoom, setSalesRoom] = useState([])
  const [saleRoom, setSaleRoom] = useState(undefined)
  useEffect(() => {
    if (!isAdmin && isPartnerPage) {
      const sales_room = user?.business?.sales_room || []
      setSalesRoom(sales_room)
    }

    if (isPartnerPage) {
      mixpanelService.track.enterPartnersPage()
    } else {
      mixpanelService.track.userEnterResortsPage()
    }
  }, [])

  const getSalesRoom = (customer_id) => {
    const customer = customers.find(({ id }) => id === customer_id)
    return customer.business?.sales_room || []
  }

  const onChangeSearch = (search) =>
    onChangeFilter({ ...userParams, search, page: 1 })

  const onChangePartnerStatus = (partner_status) =>
    onChangeFilter({ ...userParams, partner_status, page: 1 })

  const onChangeCustomer = (customer_id) => {
    onChangeFilter({ ...userParams, customer_id, page: 1 })
    let sales_room = []
    if (customer_id) {
      sales_room = getSalesRoom(customer_id)
    } else {
      setSaleRoom(undefined)
    }
    setSalesRoom(sales_room)
  }

  const onChangeSaleRoom = (sale_room_id) => {
    onChangeFilter({ ...userParams, sale_room_id, page: 1 })
    setSaleRoom(sale_room_id)
  }

  const onChangeMembership = (membership_id) => {
    onChangeFilter({ ...userParams, membership_id, page: 1 })
  }

  const onSaleDateFilterChange = (dates?: [Moment, Moment]) => {
    onChangeFilter({
      ...userParams,
      sale_date_from: dates
        ? dates[0].startOf('day').utc().toDate()
        : undefined,
      sale_date_to: dates ? dates[1].endOf('day').utc().toDate() : undefined,
      page: 1,
    })
  }

  const requestGetReport = () => {
    if (isPartnerPage) {
      const startDate = userParams.sale_date_from
        ? userParams.sale_date_from.toString()
        : undefined
      const endDate = userParams.sale_date_to
        ? userParams.sale_date_to.toString()
        : undefined

      mixpanelService.track.partnersReportRequested({
        resort: userParams.customer_id,
        sale_room: userParams.sale_room_id,
        status: userParams.partner_status,
        membership: userParams.membership_id,
        startDate: startDate,
        endDate: endDate,
        search: userParams.search,
      })
    } else {
      mixpanelService.track.resortsReportExportRequested({
        search: userParams.search,
      })
    }

    getReport(isPartnerPage)
  }

  return (
    <>
      <When condition={isPartnerPage}>
        <FiltersContainer>
          <When condition={isAdmin}>
            <CustomerSelector
              customers={customers}
              placeholder={t('users.placeholder.filter.customer')}
              onChange={onChangeCustomer}
              style={style_filter}
              allowClear
            />
          </When>
          <SaleRoomSelector
            sales_room={salesRoom}
            placeholder={t('users.placeholder.filter.sale_room')}
            onChange={onChangeSaleRoom}
            style={style_filter}
            disabled={!salesRoom?.length}
            value={saleRoom}
            allowClear
          />
        </FiltersContainer>
        <FiltersContainer>
          <PartnerStatusSelector
            placeholder={t('users.placeholder.filter.status')}
            onChange={onChangePartnerStatus}
            style={style_filter}
            allowClear
          />
          <MembershipSelector
            memberships={memberships}
            placeholder={t('users.placeholder.filter.membership')}
            onChange={onChangeMembership}
            style={style_filter}
            allowClear
          />
          <RangePicker
            style={{ width: '300px' }}
            onChange={onSaleDateFilterChange}
          />
        </FiltersContainer>
      </When>
      <Row justify="space-between" style={{ marginBottom: 25 }}>
        <Col xs={12}>
          <YachtSearch
            placeholder={t('users.placeholder.search')}
            onSearch={onChangeSearch}
            allowClear
          />
        </Col>
        <Col>
          <Row gutter={16}>
            <Col>
              <AppButton
                loading={loading}
                onClick={requestGetReport}
                type="default"
              >
                {t('users.button.report')}
              </AppButton>
            </Col>
            <Col>
              <AppButton loading={loading} onClick={createUser}>
                {t(`users.button.create.${role}`)}
              </AppButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
