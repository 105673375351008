import { Select, SelectProps } from 'antd'
import { EStatusPayment } from 'api/Charge/declarations'
import getChargeStatus from 'utils/getChargeStatus'

const ChargeStatusSelector = (props: SelectProps<EStatusPayment>) => {
  const options = Object.values(EStatusPayment).map((status) => ({
    value: status,
    label: getChargeStatus(status),
  }))

  return <Select options={options} {...props} />
}

export { ChargeStatusSelector }
