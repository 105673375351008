import i18n from 'i18next'
import { Col, Row } from 'antd'
import { EditButton, RemoveButton, TableHeader } from 'components/atoms/Table'
import { getMembershipsPrice, getPublicPrice } from 'utils/yachts'
import { ImageButton } from 'components/atoms/Table/ActionButtons/ImagesButton'
import { Additional } from 'api/Additional/declarations'

type Props = {
  handledOpenImagesModalForm: (additional: Additional) => void
  handledOpenAdditionalModalForm: (additional?: Additional) => void
  onDelete?: (additional: Additional) => void
}
export const AdditionalsColumns = ({
  handledOpenAdditionalModalForm,
  handledOpenImagesModalForm,
  onDelete,
}: Props) => [
  {
    title: <TableHeader>{i18n.t('table.row.name')} (en)</TableHeader>,
    dataIndex: 'name_en',
    key: 'name_en',
  },
  {
    title: <TableHeader>{i18n.t('table.row.name')} (es)</TableHeader>,
    dataIndex: 'name_es',
    key: 'name_es',
  },
  {
    title: (
      <TableHeader>{i18n.t('additionals.table.row.public_price')}</TableHeader>
    ),
    dataIndex: 'public_price',
    render: (text: string, record: Additional) => getPublicPrice(record),
  },
  {
    title: (
      <TableHeader>
        {i18n.t('additionals.table.row.memberships_price')}
      </TableHeader>
    ),
    dataIndex: 'memberships_price',
    render: (text: string, record: Additional) => getMembershipsPrice(record),
  },
  {
    title: (
      <TableHeader width="150px">{i18n.t('table.row.options')}</TableHeader>
    ),
    dataIndex: 'options',
    fixed: 'right',
    render: (text: string, record) => {
      const onClickImages = () => handledOpenImagesModalForm(record)
      const onClickEdit = () => handledOpenAdditionalModalForm(record)
      const onClickDelete = () => onDelete(record)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />
          </Col>
          <Col>
            <ImageButton
              text={i18n.t('table.images')}
              onClick={onClickImages}
            />
          </Col>
          <Col>
            <RemoveButton
              text={i18n.t('table.delete')}
              onClick={onClickDelete}
            />
          </Col>
        </Row>
      )
    },
  },
]
