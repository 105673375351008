import { Location } from 'api/Location/declarations'
import { Membership } from 'api/Membership/declarations'
import { ICalendarEvent, Reserve } from 'api/Reserve/declarations'
import { Yacht } from 'api/Yacht/declarations'
import { createContext } from 'react'
import { CalendarParams } from './CalendarPageProvider'
import { Business } from 'api/Business/declarations'
import { SaleRoom } from 'api/SaleRoom/declaration'

interface ICalendarPageContext {
  loading: boolean
  events: ICalendarEvent[]
  calendarDate: Date
  params: CalendarParams
  locations: Location[]
  yachts: Yacht[]
  memberships: Membership[]
  preReserves: Reserve[]
  setParams: (params: CalendarParams) => void
  setLocationId: (locationId?: number) => void
  redirectReservesPage: ({ start }: ICalendarEvent) => void
  businesses: Business[]
  saleRooms: SaleRoom[]
}

const CalendarContext = createContext<ICalendarPageContext | undefined>(
  undefined
)

export default CalendarContext
export type { ICalendarPageContext }
