import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { WithUserProps } from 'hooks/withUser'
import { EMenuKeys } from 'shared/constants/menuElements'
import { BlockedDatesPageProvider } from 'components/context/BlockedDatesPageContext'
import {
  BlockedDatesTable,
  EditBlockedDateForm,
  NewBlockedDateForm,
} from 'components/organisms/BlockedDates'
import { YachtsProvider } from 'components/context/YachtsContext'
import { LocationsProvider } from 'components/context/LocationsContext'
import { MembershipsProvider } from 'components/context/MembershipsContext'

const BlockedDatesPage: React.FC<WithUserProps & RouteComponentProps> = (
  props
) => (
  <MainLayout {...props} active={EMenuKeys.BLOCKED_DATES}>
    <YachtsProvider withoutImages onlyDropdownData>
      <LocationsProvider withoutImages>
        <MembershipsProvider withoutImages>
          <BlockedDatesPageProvider>
            <BlockedDatesTable />
            <NewBlockedDateForm />
            <EditBlockedDateForm />
          </BlockedDatesPageProvider>
        </MembershipsProvider>
      </LocationsProvider>
    </YachtsProvider>
  </MainLayout>
)

export default BlockedDatesPage
