import { useEffect, useState, ChangeEventHandler, FormEvent } from 'react'
import styled from 'styled-components'
import { When } from 'react-if'
import {
  GoogleMap,
  Marker,
  useLoadScript,
  StandaloneSearchBox,
} from '@react-google-maps/api'
import { useGeocode } from 'hooks/useGeocode'
import { SearchBar } from './SearchBar'
import { Button, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

export interface IPickerPosition {
  lat: number
  lng: number
}

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const initialPickerPosition: IPickerPosition = {
  lat: 21.16203938847893,
  lng: -86.80866712529253,
}

const Container = styled.div`
  display: grid;
  grid-gap: 20px;
  height: fit-content;
  max-width: 800px;
  width: 100%;
`

const containerStyle = {
  width: '100%',
  height: '400px',
}

const libraries: ['places'] = ['places']

const emptyOnSubmit = (e: FormEvent) => {
  e.preventDefault()
}

const geInitialPosition = (
  position: IPickerPosition
): { initial: IPickerPosition; emptyPosition: boolean } => {
  let initialPosition = initialPickerPosition
  let emptyPosition = true

  if (position.lat && position.lng) {
    initialPosition = position
    emptyPosition = false
  }
  return { initial: initialPosition, emptyPosition }
}

export type FormMapPros = {
  position: IPickerPosition
  loading: boolean
  SearchBox?: boolean
  draggable?: boolean
  zoom?: number
  searchBox?: string
  save: (position: IPickerPosition) => void
}

const FormMap = ({
  position,
  loading,
  searchBox,
  SearchBox = true,
  draggable = true,
  zoom = 14,
  save,
}: FormMapPros) => {
  const Geocode = useGeocode()
  const { t } = useTranslation()
  const { initial, emptyPosition } = geInitialPosition(position)
  const buttonTitle = t(`form.${emptyPosition ? 'create' : 'save'}`)
  const [isFirsLoading, setIsFirstLoading] = useState(true)
  const [searchBoxValue, setSearchBoxValue] = useState('')
  const [searchBoxRef, setSearchboxRef] = useState(undefined)
  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
    libraries,
  })
  const [pickerPosition, setPickerPosition] = useState<IPickerPosition>(initial)

  const onLoad = (ref) => setSearchboxRef(ref)

  const onSearchBoxValueChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSearchBoxValue(event.target.value)
  }

  const onPlacesChanged = async () => {
    const address = searchBoxRef.getPlaces()[0]
    if (!address) {
      console.error('ERROR in config api google map')
    }
    try {
      const response = await Geocode.fromAddress(address.formatted_address)
      const { lat, lng } = response.results[0].geometry.location
      if (!lat || !lng) {
        console.error('ERROR in location api google map')
      }
      setPickerPosition({
        lat,
        lng,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onPickerPositionChange = async () => {
    try {
      await Geocode.fromLatLng(pickerPosition.lat, pickerPosition.lng)
    } catch (error) {
      console.error(error)
    }
  }

  const onMarkerPositionChange = (event) => {
    const { latLng } = event
    setPickerPosition({
      lat: latLng.lat(),
      lng: latLng.lng(),
    })
  }

  const onSubmit = () => save(pickerPosition)

  useEffect(() => {
    if (isFirsLoading) {
      setIsFirstLoading(false)
    } else {
      onPickerPositionChange()
    }
  }, [pickerPosition])

  return (
    <Container>
      <When condition={isLoaded}>
        <When condition={SearchBox}>
          <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <SearchBar
              focus
              value={searchBoxValue}
              placeholder={t('yachts.form.map-search')}
              onSubmit={emptyOnSubmit}
              onChange={onSearchBoxValueChange}
            />
          </StandaloneSearchBox>
        </When>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={pickerPosition}
          zoom={zoom}
        >
          <Marker
            position={pickerPosition}
            draggable={draggable && !loading}
            onDragEnd={onMarkerPositionChange}
          />
        </GoogleMap>
        <Row align="bottom" justify="end">
          <Col>
            <Button type="primary" loading={loading} onClick={onSubmit}>
              {buttonTitle}
            </Button>
          </Col>
        </Row>
      </When>
    </Container>
  )
}

export { FormMap }
