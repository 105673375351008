import styled from 'styled-components'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { BaseActionButton, IActionButton } from './BaseActionButton'
import COLORS from 'shared/constants/colors'

const { DANGER, SUCCESS } = COLORS

const PublishedIcon = styled(EyeOutlined)`
  color: ${SUCCESS};
`

const UnpublishedIcons = styled(EyeInvisibleOutlined)`
  color: ${DANGER};
`

interface ITogglePublished extends IActionButton {
  published: boolean
}

const TogglePublishedButton = ({
  published,
  ...restOfProps
}: ITogglePublished) => {
  const Icon = published ? PublishedIcon : UnpublishedIcons

  return <BaseActionButton {...restOfProps} icon={<Icon />} />
}

export { TogglePublishedButton }
