import { Col, Form, Row } from 'antd'
import { MonthSelector } from 'components/organisms/Calendar/Selector/MonthSelector'
import { YearSelector } from 'components/organisms/Calendar/Selector/YearSelector'
import { LocationSelector } from 'components/organisms/Location/Selector/LocationSelector'
import { MembershipSelector } from 'components/organisms/Membership/Selector/MembershipSelector'
import { YachtSelector } from 'components/organisms/Yachts/Selector/YachtSelector'
import useCalendarPage from 'hooks/CalendarPage'
import { useTranslation } from 'react-i18next'
import { BusinessSelector } from '../Businesses/Selector/BusinessSelector'
import { SaleRoomSelector } from '../Businesses/Selector/SaleRoomSelector'

const filterStyle = { width: '100%' }

export const CalendarHeader = () => {
  const { t } = useTranslation()
  const {
    loading,
    params,
    locations,
    yachts,
    memberships,
    setParams,
    setLocationId,
    businesses,
    saleRooms,
  } = useCalendarPage()

  const onChangeYear = (year) => setParams({ ...params, year })

  const onChangeMonth = (month) => setParams({ ...params, month })

  const onChangeYacht = (yacht_id) => setParams({ ...params, yacht_id })

  const onChangeBusiness = (business_id) =>
    setParams({ ...params, business_id })

  const onChangeSaleRoom = (sale_room_id) =>
    setParams({ ...params, sale_room_id })

  const onChangeMembership = (membership_name) =>
    setParams({ ...params, membership_name })

  const onChangeLocation = (location_id) => {
    setLocationId(location_id)
    setParams({ ...params, location_id })
  }

  return (
    <Form initialValues={params}>
      <Row
        gutter={12}
        style={{ paddingBottom: '10px' }}
        justify="space-between"
      >
        <Col xs={10}>
          <Form.Item label={t('calendar.filter.label.year')} name="year">
            <YearSelector
              loading={loading}
              style={filterStyle}
              onChange={onChangeYear}
            />
          </Form.Item>
          <Form.Item label={t('calendar.filter.label.month')} name="month">
            <MonthSelector
              loading={loading}
              style={filterStyle}
              onChange={onChangeMonth}
            />
          </Form.Item>
        </Col>
        <Col xs={10}>
          <Form.Item
            label={t('calendar.filter.label.location')}
            name="location_id"
          >
            <LocationSelector
              loading={loading}
              locations={locations}
              style={filterStyle}
              onChange={onChangeLocation}
              allowClear
            />
          </Form.Item>
          <Form.Item label={t('calendar.filter.label.yacht')} name="yacht_id">
            <YachtSelector
              loading={loading}
              yachts={yachts}
              style={filterStyle}
              onChange={onChangeYacht}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={t('calendar.filter.label.membership')}
            name="membership_name"
          >
            <MembershipSelector
              keyValue="name"
              loading={loading}
              memberships={memberships}
              style={filterStyle}
              onChange={onChangeMembership}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={t('calendar.filter.label.business')}
            name="business_id"
          >
            <BusinessSelector
              businesses={businesses}
              onChange={onChangeBusiness}
              allowClear
            />
          </Form.Item>
          <Form.Item
            label={t('calendar.filter.label.sale-room')}
            name="sale_room_id"
          >
            <SaleRoomSelector
              sales_room={saleRooms}
              onChange={onChangeSaleRoom}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
