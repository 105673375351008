import ReservePageContext from 'components/context/ReservePageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useReservePage = () => {
  const contextName = 'ReservePageContext'
  const contextValue = useContext(ReservePageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}
export default useReservePage
