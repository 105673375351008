import i18n from 'i18next'
import { Col, Row } from 'antd'
import { RemoveButton, TableHeader, EditButton } from 'components/atoms/Table'
import { Movement } from 'api/Movements/declarations'
import { formatDate, formatPrice } from 'utils/common'

type IMovementsTableColumns = {
  onOpenEditMovementForm: (movement: Movement) => void
  onDeleteMovement: (movement: Movement) => void
}

export const MovementsTableColumns = ({
  onOpenEditMovementForm,
  onDeleteMovement,
}: IMovementsTableColumns) => [
  {
    title: <TableHeader>{i18n.t('movements.table.date')}</TableHeader>,
    dataIndex: 'date',
    key: 'date',
    render: (text: string, { date }: Movement) => formatDate(date),
  },
  {
    title: <TableHeader>{i18n.t('movements.table.movementType')}</TableHeader>,
    dataIndex: 'type',
    key: 'type',
    render: (text: string, { type }: Movement) =>
      i18n.t(`movements.type.${type}`),
  },
  {
    title: <TableHeader>{i18n.t('movements.table.amount')}</TableHeader>,
    dataIndex: 'amount',
    key: 'amount',
    render: (text: string, { amount }: Movement) =>
      formatPrice(amount.toString()),
  },
  {
    title: <TableHeader>{i18n.t('movements.table.provider')}</TableHeader>,
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: (
      <TableHeader width="120px">
        {i18n.t('movements.table.concept')}
      </TableHeader>
    ),
    dataIndex: 'concept',
    key: 'concept',
  },
  {
    title: <TableHeader>{i18n.t('movements.table.invoice')}</TableHeader>,
    dataIndex: 'has_invoice',
    key: 'has_invoice',
    render: (text: string, { has_invoice }: Movement) =>
      i18n.t(
        `movements.type.${has_invoice ? 'has_invoice' : "hasn't_invoice"}`
      ),
  },
  {
    title: <TableHeader>{i18n.t('movements.table.invoiceStatus')}</TableHeader>,
    dataIndex: 'invoice_status',
    key: 'invoice_status',
    render: (text: string, { invoice_status }: Movement) =>
      invoice_status
        ? i18n.t(`movements.invoice_status.${invoice_status}`)
        : 'N.A',
  },
  {
    title: (
      <TableHeader width="120px">{i18n.t('table.row.description')}</TableHeader>
    ),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: (
      <TableHeader width="150px">{i18n.t('table.row.options')}</TableHeader>
    ),
    key: 'options',
    render: (text: string, movements: Movement) => {
      const onClickEdit = () => onOpenEditMovementForm(movements)
      const onDelete = () => onDeleteMovement(movements)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />
          </Col>
          <Col>
            <RemoveButton text={i18n.t('table.delete')} onClick={onDelete} />
          </Col>
        </Row>
      )
    },
  },
]
