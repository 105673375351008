import { FC, useState, useEffect } from 'react'
import MovementsPageContext, {
  MovementShowModal,
  IMovementsPageContext,
} from './MovementsPageContext'
import {
  EMovementType,
  Movement,
  MovementParams,
} from 'api/Movements/declarations'
import tryFunction from 'utils/try'
import scrollToTop from 'utils/scroll'
import { useHistory, useParams } from 'react-router-dom'
import BankAccountService from 'api/BankAccount/BanckAccount'
import MovementService from 'api/Movements/Movement'
import { BankAccount } from 'api/BankAccount/declarations'
import { Moment } from 'moment'

export type ReserveParams = {
  id: string
}

const initialParams: MovementParams = {
  per_page: 10,
  page: 1,
}

const initialShowModal: MovementShowModal = {
  movementForm: false,
}

const MovementsPageProvider: FC = ({ children }) => {
  const { goBack } = useHistory()
  const { id: bank_account_id } = useParams<ReserveParams>()
  const [bankAccount, setBankAccount] = useState<BankAccount>()
  const [loading, setLoading] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(true)
  const [movements, setMovements] = useState<Movement[]>([])
  const [count, setCount] = useState(0)
  const [movementEdit, setMovementEdit] = useState<Movement>()
  const [movementParams, setMovementParams] =
    useState<MovementParams>(initialParams)
  const [showModal, setShowModal] =
    useState<MovementShowModal>(initialShowModal)

  useEffect(() => {
    tryFunction(async () => {
      await getMovements()
      scrollToTop()
    }, setLoading)
  }, [movementParams])

  useEffect(() => {
    if (refresh) {
      loadData()
      setRefresh(false)
    }
  }, [movementEdit, showModal, refresh])

  const loadData = () => {
    tryFunction(async () => {
      await getBankAccount()
      await getMovements()
      scrollToTop()
    }, setLoading)
  }

  const getMovements = async () => {
    const { data: movements, count } = await MovementService.findWithPagination(
      {
        ...movementParams,
        bank_account_id,
      }
    )
    setMovements(movements)
    setCount(count)
    return movements
  }

  const getBankAccount = async () => {
    try {
      const bankAccount = await BankAccountService.findOne(
        Number(bank_account_id)
      )
      setBankAccount(bankAccount)
    } catch (error) {
      setTimeout(goBack, 1000)
      throw error
    }
  }
  const onPageChange = (pageNumber: number) => {
    setMovementParams({ ...movementParams, page: pageNumber })
  }

  const handledCloseMovementsModalForm = () => {
    setMovementEdit(undefined)
    setShowModal({ ...showModal, movementForm: false })
  }

  const handledOpenMovementsModalForm = (movement?: Movement) => {
    setMovementEdit(movement)
    setShowModal({ ...showModal, movementForm: true })
  }

  const createMovement = () => {
    handledOpenMovementsModalForm()
  }

  const onMovementTypeFilterChange = (type?: EMovementType) =>
    setMovementParams({ ...movementParams, type })

  const onDateFilterChange = (dates?: [Moment, Moment]) => {
    setMovementParams({
      ...movementParams,
      start_date: dates ? dates[0].toDate() : undefined,
      end_date: dates ? dates[1].toDate() : undefined,
    })
  }

  const submitMovementsForm = (movement: Movement) => {
    tryFunction(
      async () => {
        if (movementEdit) {
          await MovementService.update(movementEdit.id, movement)
        } else {
          const newMovement = await MovementService.create(movement)
          await updateMovementEdit(newMovement)
          loadData()
          return
        }
        handledCloseMovementsModalForm()
        setRefresh(true)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const deleteMovement = (movement: Movement) => {
    tryFunction(
      async () => {
        await MovementService.delete(movement.id)
        await getMovements()
        await getBankAccount()
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onUpload = async (file) => {
    tryFunction(
      async () => {
        await MovementService.uploadFile(movementEdit.id, file)
        await updateMovementEdit(movementEdit)
      },
      setLoading,
      true
    )
  }

  const updateMovementEdit = async (movement: Movement) => {
    const movements = await getMovements()
    const { id: movement_id } = movement
    const movementUpdate = movements.find(({ id }) => id === movement_id)
    if (movementUpdate) {
      setMovementEdit(movementUpdate)
    }
  }

  const contextValue: IMovementsPageContext = {
    bankAccount,
    movements,
    count,
    loading,
    movementParams,
    showModal,
    movementEdit,
    onPageChange,
    submitMovementsForm,
    handledCloseMovementsModalForm,
    handledOpenMovementsModalForm,
    createMovement,
    deleteMovement,
    onUpload,
    onMovementTypeFilterChange,
    onDateFilterChange,
  }

  return (
    <MovementsPageContext.Provider value={contextValue}>
      {children}
    </MovementsPageContext.Provider>
  )
}

export { MovementsPageProvider }
