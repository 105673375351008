import { Col, Empty } from 'antd'
import { IImageSizes } from 'components/atoms/ContainerPreviewUploader'
import { IImage } from 'interfaces/image'
import { When } from 'react-if'
import { ImageAction } from './ImageAction'
import { IUploadedImage } from './ImageUploader'

type Props = {
  images: IImage[]
  size?: IImageSizes
  loading?: boolean
  useFavorite?: boolean
  defaultImage?: IImage
  onEdit: (image: IUploadedImage, image_id: number) => void
  onDelete: (image_id: number) => void
  onDefault?: (image_id: number) => void
}

export const ImageList = ({
  images,
  size,
  loading,
  useFavorite = true,
  defaultImage,
  onEdit,
  onDelete,
  onDefault = () => {},
}: Props) => (
  <>
    <When condition={images?.length === 0}>
      <Col xs={24}>
        <Empty description="No images" />
      </Col>
    </When>
    {images?.map((image, index) => (
      <Col key={index} xs={12}>
        <ImageAction
          size={size}
          loading={loading}
          image={image}
          defaultImage={defaultImage}
          onEdit={onEdit}
          onDefault={onDefault}
          onDelete={onDelete}
          useFavorite={useFavorite}
        />
      </Col>
    ))}
  </>
)
