import { RcFile } from 'antd/lib/upload'
import { PaginationData } from 'api/BaseService/declarations'

export type Movement = {
  id?: number
  amount?: number
  type?: EMovementType
  date?: string | Date
  provider?: string
  concept?: string
  description?: string
  has_invoice?: boolean
  invoice_status?: EInvoiceStatus
  file_id?: number
  bank_account_id?: number
  file?: string
  upload?: RcFile
}

export enum EMovementType {
  INPUT = 'input',
  OUTPUT = 'output',
}

export enum EInvoiceStatus {
  PENDING = 'pending',
  EMIT = 'emit',
  PAID = 'paid',
  CANCELLED = 'cancelled',
}

export type MovementParams = {
  bank_account_id?: number | string
  type?: EMovementType
  start_date?: Date
  end_date?: Date
} & PaginationData
