import { FC, useEffect, useState } from 'react'
import LocationService from 'api/Location'
import LocationsPageContext, {
  ILocationsPageContext,
  IEditFormState,
} from './LocationsPageContext'
import useLocationsContext from 'hooks/useLocationsContext'
import useDisclosure from 'hooks/useDisclosure'
import type { Location, ICreateLocation } from 'api/Location/declarations'
import IImageData from 'interfaces/shared'
import tryFunction from 'utils/try'

const LocationsPageProvider: FC = ({ children }) => {
  const { locations, getLocations } = useLocationsContext()
  const {
    isOpen: createLocationFormIsOpen,
    onClose: onCloseCreateLocationForm,
    onOpen: onOpenCreateLocationForm,
  } = useDisclosure()
  const [editFormState, setEditFormState] = useState<IEditFormState>({
    isOpen: false,
  })

  useEffect(() => {
    getLocations()
  }, [])

  const onCloseEditLocationForm = () =>
    setEditFormState({ ...editFormState, isOpen: false })

  const onOpenEditLocationForm = (location: Location) =>
    setEditFormState({ location, isOpen: true })

  const onCreateLocation = async (body: ICreateLocation, image: IImageData) => {
    const location = await LocationService.createLocation(body)
    if (image?.file) {
      await LocationService.uploadLocationImage(location.id, image.file)
    }
    await getLocations()
    onCloseCreateLocationForm()
  }

  const onUpdateLocation = async (body: ICreateLocation, image: IImageData) => {
    const locationId = editFormState.location?.id
    const location = await LocationService.updateLocation(locationId, body)
    if (image?.file) {
      await LocationService.uploadLocationImage(location.id, image.file)
    }
    await getLocations()
    onCloseEditLocationForm()
  }

  const onDeleteLocation = (location: Location) => {
    tryFunction(
      async () => {
        await LocationService.deleteLocation(location.id)
        await getLocations()
      },
      () => {},
      true
    )
  }

  const contextValue: ILocationsPageContext = {
    locations,
    createLocationFormIsOpen,
    onCloseCreateLocationForm,
    onOpenCreateLocationForm,
    editFormState,
    onOpenEditLocationForm,
    onCloseEditLocationForm,
    onCreateLocation,
    onUpdateLocation,
    onDeleteLocation,
  }

  return (
    <LocationsPageContext.Provider value={contextValue}>
      {children}
    </LocationsPageContext.Provider>
  )
}

export { LocationsPageProvider }
