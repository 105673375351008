import CalendarPageContext from 'components/context/CalendarPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useCalendarPage = () => {
  const contextName = 'CalendarPageContext'
  const contextValue = useContext(CalendarPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}
export default useCalendarPage
