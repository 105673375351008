import { Button, Col, Row } from 'antd'
import Upload, { RcFile } from 'antd/lib/upload'
import { useTranslation } from 'react-i18next'
import showMessage, { NoticeType } from 'utils/notifications'
import { UploadOutlined, FileDoneOutlined } from '@ant-design/icons'
import { isNullOrUndefined } from 'util'
import { When } from 'react-if'
import { BaseActionButton } from 'components/atoms/Table/ActionButtons/BaseActionButton'

const STYLE_DOCUMENT_ICON = { fontSize: '80px' }

type Props = {
  disabled?: boolean
  urlFile?: string
  loading?: boolean
  onUpload: (file) => void
}

export const FileUploader = ({
  disabled = false,
  urlFile,
  loading,
  onUpload,
}: Props) => {
  const { t } = useTranslation()

  const isValidFile = (file: File) => {
    const maxSizeInMB = 5
    const isLessThanMaxSize = file.size / 1024 / 1024 < maxSizeInMB
    if (!isLessThanMaxSize) {
      showMessage(
        t('image_uploader.sorry_title'),
        t('image_uploader.error.size_5MB'),
        NoticeType.ERROR
      )
    }
    return isLessThanMaxSize
  }

  const handleUpload = async (file: RcFile) => {
    try {
      if (isValidFile(file)) {
        onUpload(file)
      }
    } catch (error) {
      showMessage(
        t('image_uploader.sorry_title'),
        t('image_uploader.error.load'),
        NoticeType.ERROR
      )
    } finally {
      return ''
    }
  }

  return (
    <Row gutter={62} align="middle">
      <When condition={!isNullOrUndefined(urlFile)}>
        <Col xs={4}>
          <BaseActionButton
            text={t('form.file.view')}
            style={{ padding: '0' }}
            icon={<FileDoneOutlined style={STYLE_DOCUMENT_ICON} />}
            target="_blank"
            href={urlFile}
          />
        </Col>
      </When>
      <When condition={!disabled}>
        <Col>
          <Upload action={handleUpload} showUploadList={false}>
            <Button icon={<UploadOutlined />} loading={loading}>
              {t('form.file.upload')}
            </Button>
          </Upload>
        </Col>
      </When>
    </Row>
  )
}
