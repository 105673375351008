import MembershipsContext from 'components/context/MembershipsContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useMembershipsContext = () => {
  const contextName = 'MembershipsContext'
  const contextValue = useContext(MembershipsContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useMembershipsContext
