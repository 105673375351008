import { Country } from 'api/Country/declarations'
import YachtService from 'api/Yacht'
import { FC, useState } from 'react'
import YachtsContext, { IYachtsContext } from './YachtsContext'

interface IYachtsProvider {
  withoutImages?: boolean
  onlyDropdownData?: boolean
}

const YachtsProvider: FC<IYachtsProvider> = ({
  children,
  withoutImages = false,
  onlyDropdownData = false,
}) => {
  const [yachts, setYachts] = useState<Country[]>([])
  const [locationId, setLocationId] = useState<number>()

  const getYachts = async () => {
    const yachts = await YachtService.find({
      location_id: locationId,
      without_images: withoutImages,
      dropdown: onlyDropdownData,
    })
    setYachts(yachts)
  }

  const contextValue: IYachtsContext = {
    yachts,
    locationId,
    getYachts,
    setLocationId,
  }

  return (
    <YachtsContext.Provider value={contextValue}>
      {children}
    </YachtsContext.Provider>
  )
}

export { YachtsProvider }
