import axios from 'axios'
import { CalendarParams } from 'components/context/CalendarPageContext'
import moment from 'moment-timezone'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from '../BaseService/declarations'
import {
  ICalendarEvent,
  Reserve,
  ReserveCharge,
  ReserveParams,
} from './declarations'
import mixpanelService from 'services/mixpanel'

const timezone = moment.tz.guess()

export class ReserveService extends BaseService<Reserve> {
  protected name: string = 'reserve'

  async find(params: ReserveParams): Promise<Reserve[]> {
    try {
      const response = await axios.get<Response<Reserve[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params: { ...params, timezone } })
      )
      const data = response.data.data as Reserve[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async paginate(params: ReserveParams): Promise<PaginatedResponse<Reserve>> {
    try {
      const response = await axios.get<Response<Reserve[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params: { ...params, timezone } })
      )
      const data = response.data.data as Reserve[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async create(body: Reserve): Promise<ReserveCharge> {
    try {
      const response = await axios.post<Response<ReserveCharge>>(
        `${this.url}/${this.name}`,
        { ...body, timezone },
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as ReserveCharge
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async update(reserve_id: number, body: Reserve): Promise<ReserveCharge> {
    try {
      const response = await axios.put<Response<ReserveCharge>>(
        `${this.url}/${this.name}/${reserve_id}`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as ReserveCharge
      mixpanelService.track.bookingEdited()
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async calendar(params: CalendarParams): Promise<ICalendarEvent[]> {
    try {
      const response = await axios.get<Response<ICalendarEvent[]>>(
        `${this.url}/${this.name}/calendar`,
        this.getHeaders({ params: { ...params, timezone } })
      )
      const data = response.data.data as ICalendarEvent[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async availableHoursPerDay(params: {
    yacht_id: number
    date: Date
  }): Promise<number[]> {
    try {
      const response = await axios.get<Response<number[]>>(
        `${this.url}/${this.name}/available-hours-per-day`,
        this.getHeaders({ params })
      )
      const data = response.data.data as number[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async findOne(id: number | string): Promise<Reserve> {
    try {
      const response = await axios.get<Response<Reserve>>(
        `${this.url}/${this.name}/${id}`,
        this.getHeaders()
      )
      const data = response.data.data as Reserve

      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async cancel(id: number | string): Promise<Reserve> {
    try {
      const response = await axios.post<Response<Reserve>>(
        `${this.url}/${this.name}/${id}/cancel`,
        {},
        this.getHeaders()
      )
      const data = response.data.data as Reserve
      mixpanelService.track.bookingCancelled({ bookingId: data.id })
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new ReserveService()
export default service
