import styled, { css } from 'styled-components'

export type TitleProps = {
  padding?: string
  size?: number
}

const Title = styled.h1<TitleProps>(
  ({ padding = '5px 0px 10px 0px' }, size = 32) => css`
    padding: ${padding};
    font-size: ${32}px;
  `
)

export default Title

export const TitleDatePicker = styled.input`
  background-color: #f0f2f5;
  border: none;
  font-weight: 500;
  padding-left: 1%;
  width: 280px;

  :focus {
    border: none;
  }

  :hover {
    cursor: pointer;
  }
`
