import ReservesPageContext from 'components/context/ReservesPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useReservesPage = () => {
  const contextName = 'ReservesPageContext'
  const contextValue = useContext(ReservesPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}
export default useReservesPage
