import { useTranslation } from 'react-i18next'
import { Button, Col, Row, Space } from 'antd'
import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import { BlockedDatesTableColumns } from './BlockedDatesTableColumns'
import useBlockedDatesPageContext from 'hooks/useBlockedDatesPageContext'
import { useEffect, useState } from 'react'
import useLocationsContext from 'hooks/useLocationsContext'
import useMembershipsContext from 'hooks/useMembershipsContext'
import useYachtsContext from 'hooks/useYachtsContext'
import { SortDirection } from 'interfaces/shared'
import mixpanelService from 'services/mixpanel'

const BlockedDatesTable = () => {
  const { t } = useTranslation()
  const {
    blockedDates,
    blockedDatesCount,
    isLoadingBlockedDates,
    onPageChange,
    blockedDatesParams,
    onDeleteBlockedDate,
    onOpenCreateBlockedDateForm,
    onOpenEditBlockedDateForm,
    dateSort,
    setDateSort,
  } = useBlockedDatesPageContext()
  const { locations, getLocations } = useLocationsContext()
  const { memberships, getMemberships } = useMembershipsContext()
  const { yachts, getYachts } = useYachtsContext()
  const [availableLocationsIds, setAvailableLocationsIds] = useState<number[]>(
    []
  )
  const [availableYachtsIds, setAvailableYachtsIds] = useState<number[]>([])
  const [availableMembershipsIds, setAvailableMembershipsIds] = useState<
    number[]
  >([])

  useEffect(() => {
    setAvailableLocationsIds(locations.map(({ id }) => id))
    setAvailableYachtsIds(yachts.map(({ id }) => id))
    setAvailableMembershipsIds(memberships.map(({ id }) => id))
  }, [locations, memberships, yachts])

  useEffect(() => {
    getLocations()
    getMemberships()
    getYachts()
  }, [])

  const onDateSortChange = (key: string, sortDirection: SortDirection) => {
    setDateSort(sortDirection)
  }

  useEffect(() => {
    mixpanelService.track.userEnterBlockedDatesPage()
  }, [])

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      <Row justify="end">
        <Col>
          <Button onClick={onOpenCreateBlockedDateForm} type="primary">
            {t('blocked-dates.new-button-label')}
          </Button>
        </Col>
      </Row>
      <YachtsTable
        loading={isLoadingBlockedDates}
        dataSource={blockedDates}
        columns={BlockedDatesTableColumns({
          onDeleteBlockedDate,
          onOpenEditBlockedDateForm,
          availableLocationsIds,
          availableYachtsIds,
          availableMembershipsIds,
          onDateSortChange,
          dateSort,
        })}
        count={blockedDatesCount}
        onChangePage={onPageChange}
        type={ETypeTable.CHARGES}
        pagination={blockedDatesParams}
      />
    </Space>
  )
}

export { BlockedDatesTable }
