import { getToken } from 'utils/token'
import i18n from 'i18next'
import { AxiosError, AxiosRequestConfig } from 'axios'

class BaseService<T> {
  protected url: string | undefined = process.env.REACT_APP_API_URL

  protected getHeaders({ params }: AxiosRequestConfig = {}) {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${getToken()?.token.token}`,
        ...this.getAcceptLanguage(),
      },
      params,
    }
    return config
  }

  protected getHeadersMultipart({ params }: AxiosRequestConfig = {}) {
    return {
      headers: {
        authorization: `Bearer ${getToken()?.token.token}`,
        'content-type': 'multipart/form-data',
        ...this.getAcceptLanguage(),
      },
      params,
    }
  }

  protected getHeaderLocale = () => ({
    headers: this.getAcceptLanguage(),
  })

  protected getAcceptLanguage = () => ({
    'Accept-Language': i18n.language,
  })

  protected handleError(error: AxiosError & Error) {
    if (error.response) {
      throw new Error(error.response.data.message)
    } else {
      throw new Error(error.message)
    }
  }
}
export default BaseService
