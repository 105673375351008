import CountryService from 'api/Country'
import { Country } from 'api/Country/declarations'
import { FC, useState } from 'react'
import CountriesContext, { ICountriesContext } from './CountriesContext'

const CountriesProvider: FC = ({ children }) => {
  const [countries, setCountries] = useState<Country[]>([])

  const getCountries = async () => {
    const countries = await CountryService.find()
    setCountries(countries)
  }

  const contextValue: ICountriesContext = {
    countries,
    getCountries,
  }

  return (
    <CountriesContext.Provider value={contextValue}>
      {children}
    </CountriesContext.Provider>
  )
}

export { CountriesProvider }
