import {
  ExclamationCircleOutlined,
  CalendarOutlined,
  DollarCircleOutlined,
  IdcardOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons'
import { Col, Modal, Row, Statistic } from 'antd'
import Title from 'components/atoms/Title/Title'
import AppButton from 'components/molecules/AppButton'
import { BackButton } from 'components/molecules/BackButton'
import useReservePage from 'hooks/ReservePage'
import { useTranslation } from 'react-i18next'
import { When } from 'react-if'
import { formatDate } from 'utils/common'
import {
  getInitilaFormValueStartAndEndHour,
  getPriceAdditional,
  getReserveHours,
} from 'utils/reserves'
import { getMembership } from 'utils/user'

const { confirm } = Modal

export const ReseservesHeader = () => {
  const { t } = useTranslation()
  const {
    dateReserve,
    reserve,
    amount,
    amountAdditional,
    partner,
    reserveHour,
    cancelReserve,
  } = useReservePage()

  const getHours = () => {
    const hours = reserve
      ? getInitilaFormValueStartAndEndHour(reserve)
      : reserveHour
    return getReserveHours(hours)
  }
  const { cancel } = reserve ?? {}
  const title = reserve
    ? `#${reserve.code}${cancel ? ` / ${t('reserves.status.cancel')}` : ''}`
    : t('reserve.title.create')
  const membership = reserve
    ? reserve.membership_name
    : getMembership(partner)?.name
  const hours = getHours()
  const additional = reserve ? getPriceAdditional(reserve) : amountAdditional
  const paid = reserve ? reserve.charge?.paid : amount

  const onCancel = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: '¿Quieres cancelar la reservación?',
      content: 'Esta acción ya no podra ser revertida.',
      onOk() {
        cancelReserve(reserve)
      },
      okText: 'Sí',
      cancelText: 'No',
      okButtonProps: { danger: true },
      cancelButtonProps: { type: 'primary' },
    })
  }

  return (
    <>
      <Title>{title}</Title>
      <Row justify="space-between" style={{ marginBottom: 25 }}>
        <Col>
          <Row gutter={32}>
            <Col>
              <Statistic
                title={t('reserve.title.date')}
                value={formatDate(dateReserve, 'DD/MM/YYYY')}
                prefix={<CalendarOutlined />}
              />
            </Col>
            <Col>
              <Statistic
                title={t('reserve.title.membership')}
                value={membership || 'N.A'}
                prefix={<IdcardOutlined />}
              />
            </Col>
            <Col>
              <Statistic
                title={t('reserve.title.hours')}
                value={hours || 0}
                prefix={<FieldTimeOutlined />}
              />
            </Col>
            <Col>
              <Statistic
                title={t('reserve.title.additional')}
                value={additional || 0}
                prefix={<DollarCircleOutlined />}
              />
            </Col>
            <Col>
              <Statistic
                title={t('reserve.title.amount')}
                value={paid || 0}
                prefix={<DollarCircleOutlined />}
              />
            </Col>
          </Row>
        </Col>
        <When condition={!!reserve}>
          <Col>
            <AppButton onClick={onCancel} danger disabled={cancel}>
              {t('reserve.button.cancel')}
            </AppButton>
          </Col>
        </When>
      </Row>
      <BackButton style={{ paddingLeft: 0 }} />
    </>
  )
}
