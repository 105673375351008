import { SelectProps, Select } from 'antd'
import { EStatusReserve } from 'api/Reserve/declarations'
import { useTranslation } from 'react-i18next'

type Props = {} & SelectProps<'tag'>

export const ReserveStatusSelector = ({ ...props }: Props) => {
  const { t } = useTranslation()

  const status = Object.values(EStatusReserve).map((status) => ({
    label: t(`reserves.status.${status}`),
    value: status,
  }))

  return <Select options={status} {...props} />
}
