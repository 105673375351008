import { InputNumber, InputNumberProps } from 'antd'
import { formatPrice, parsePrice } from 'utils/common'

const PriceInput = ({ ...props }: InputNumberProps) => (
  <InputNumber
    {...props}
    step="0.01"
    formatter={formatPrice}
    parser={parsePrice}
  />
)

export default PriceInput
