import { createContext } from 'react'
import {
  Charge,
  EStatusPayment,
  GetChargesParams,
} from 'api/Charge/declarations'
import { Moment } from 'moment'

interface IChargeDetailsModalState {
  isOpen: boolean
  charge?: Charge
}

interface IChargesPageContext {
  charges: Charge[]
  onPageChange: (pageNumber: number) => void
  isLoadingCharges: boolean
  chargesCount: number
  chargesParams: GetChargesParams
  onStatusFilterChange: (status: EStatusPayment) => void
  onDateFilterChange: (dates?: [Moment, Moment]) => void
  onSearchChange: (search: string) => void
  onCancelCharge: (chargeId: number) => Promise<void>
  chargeDetailsModalState: IChargeDetailsModalState
  onCloseChargeDetailsModal: () => void
  onOpenChargeDetailsModal: (charge: Charge) => void
}

const ChargesPageContext = createContext<IChargesPageContext>(undefined)

export type { IChargesPageContext, IChargeDetailsModalState }
export default ChargesPageContext
