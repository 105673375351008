import i18n from 'i18next'
import { Col, Row } from 'antd'
import { EditButton, RemoveButton, TableHeader } from 'components/atoms/Table'
import { Convertor } from 'api/Convertor/declarations'
import { SortDirection } from 'interfaces/shared'

type IConvertorsTableColumns = {
  onOpenEditConvertorForm: (convertor: Convertor) => void
  onDeleteConvertor: (convertor: Convertor) => void
  nameSort: SortDirection
  onNameSortChange: (key: string, direction: SortDirection) => void
}

export const ConvertorsTableColumns = ({
  onOpenEditConvertorForm,
  onDeleteConvertor,
  nameSort,
  onNameSortChange,
}: IConvertorsTableColumns) => [
  {
    title: (
      <TableHeader
        showSort
        sort={{ name: nameSort }}
        onChange={onNameSortChange}
        name="name_en"
      >
        {i18n.t('table.row.name')} (en)
      </TableHeader>
    ),
    dataIndex: 'name_en',
    key: 'name_en',
  },
  {
    title: (
      <TableHeader name="name_es">{i18n.t('table.row.name')} (es)</TableHeader>
    ),
    dataIndex: 'name_es',
    key: 'name_es',
  },
  {
    title: (
      <TableHeader width="150px">{i18n.t('table.row.options')}</TableHeader>
    ),
    key: 'options',
    render: (text: string, convertor: Convertor) => {
      const onEdit = () => onOpenEditConvertorForm(convertor)
      const onDelete = () => onDeleteConvertor(convertor)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onEdit} />
          </Col>
          <Col>
            <RemoveButton text={i18n.t('table.delete')} onClick={onDelete} />
          </Col>
        </Row>
      )
    },
  },
]
