import { Row, Col, Button, Space } from 'antd'
import { ConvertorsTableColumns } from './ConvertorsTableColumns'
import useConvertorsPageContext from 'hooks/useConvertorsPageContext'
import { useTranslation } from 'react-i18next'
import { SortDirection } from 'interfaces/shared'
import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import mixpanelService from 'services/mixpanel'
import { useEffect } from 'react'

const ConvertorsTable = () => {
  const { t } = useTranslation()
  const {
    convertors,
    onOpenCreateConvertorForm,
    onOpenEditConvertorForm,
    setNameSort,
    nameSort,
    onDeleteConvertor,
    isLoadingConvertors,
    convertorsCount,
    onPageChange,
    convertorsParams,
  } = useConvertorsPageContext()

  const onNameSortChange = (key: string, sortDirection: SortDirection) => {
    setNameSort(sortDirection)
  }

  useEffect(() => {
    mixpanelService.track.userEnterAfterSaleGiftsPage()
  }, [])

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="end">
        <Col>
          <Button onClick={onOpenCreateConvertorForm} type="primary">
            {t('convertors.new-button-label')}
          </Button>
        </Col>
      </Row>
      <YachtsTable
        loading={isLoadingConvertors}
        dataSource={convertors}
        columns={ConvertorsTableColumns({
          onDeleteConvertor,
          onOpenEditConvertorForm: onOpenEditConvertorForm,
          onNameSortChange,
          nameSort,
        })}
        count={convertorsCount}
        onChangePage={onPageChange}
        type={ETypeTable.CONVERTORS}
        pagination={convertorsParams}
      />
    </Space>
  )
}

export { ConvertorsTable }
