import { useTranslation } from 'react-i18next'
import { When } from 'react-if'
import { Form, Input, Divider, FormInstance, Select } from 'antd'
import { Country } from 'api/Country/declarations'
import { User, UserCreate } from 'api/User/declarations'
import { CountrySelector } from 'components/organisms/Countries/Selector/CountrySelector'
import { CustomerSelector } from '../../Selector/CustomerSelector'
import { PartnerStatusSelector } from '../../Selector/PartnerStatusSelector'
import ImageUploader, {
  IUploadedImage,
} from 'components/molecules/ImageUploader'
import IImageData from 'interfaces/shared'
import { Avatar } from 'components/molecules/Avatar'
import { MembershipSelector } from 'components/organisms/Membership/Selector/MembershipSelector'
import { Membership } from 'api/Membership/declarations'
import useMainLayout from 'hooks/useAccessUser'
import { useEffect, useState } from 'react'
import { SaleRoomSelector } from 'components/organisms/Businesses/Selector/SaleRoomSelector'
import { ConvertorSelector } from 'components/organisms/Convertor/Selector/ConvertorSelector'
import { Convertor } from 'api/Convertor/declarations'
import { getMembershipsByBusiness } from 'utils/membership'
import { getConvertorsByBusiness } from 'utils/convertors'
import { isNullOrUndefined } from 'util'
import moment from 'moment'

type Props = {
  form: FormInstance
  user: UserCreate
  allowEdition: boolean
  countries: Country[]
  customers?: User[]
  memberships?: Membership[]
  convertors?: Convertor[]
  imageData: IImageData
  onImageUpload: (imageData: IUploadedImage) => void
  onChildChange?: (newShowAddContract: boolean) => void
}

const HEARD_ABOUT_US_TEX_AREA = { max: 120, height: 80 }

const howYouHeardAboutUsOptions = [
  'Member Referral',
  'Received a Mailing',
  'Online Advertisement',
  'Social Media',
  'Event',
  'Previous User',
  'Other',
]

export const PartnerInputs = ({
  form,
  user,
  allowEdition,
  countries = [],
  customers = [],
  memberships = [],
  convertors = [],
  imageData,
  onImageUpload,
  onChildChange,
}: Props) => {
  const { t } = useTranslation()
  const { isAdmin, user: accessUser } = useMainLayout()
  const [salesRoom, setSalesRoom] = useState([])
  const [membershipOptions, setMembershipsOptions] = useState(memberships)
  const [convertorsOptions, setConvertorsOptions] = useState(convertors)
  const [membershipId, setMembershipId] = useState<number>(user?.membership_id)

  const OnChangeCustomer = (customer_id) => {
    if (customer_id) {
      const sales_room = getSalesRoom(customer_id)
      setSalesRoom(sales_room)
      const memberships = getMemberships(customer_id)
      setMembershipsOptions(memberships)
      const convertors = getConvertorsOptions(customer_id)
      setConvertorsOptions(convertors)
    }
    onChildChange(customer_id)
  }

  const onChangeMembership = (membershipId) => {
    setMembershipId(membershipId)
  }

  const getSalesRoom = (customer_id) => {
    const customer = customers.find(({ id }) => id === customer_id)
    return customer.business?.sales_room || []
  }

  const getMemberships = (customer_id: number) => {
    const customer = customers.find(({ id }) => id === customer_id)
    const business = customer?.business
    return getMembershipsByBusiness({ business, memberships })
  }

  const getConvertorsOptions = (customer_id: number) => {
    const customer = customers.find(({ id }) => id === customer_id)
    const business = customer?.business
    return getConvertorsByBusiness({ business, convertors })
  }

  const validatorSaleDate = {
    validator(_, value) {
      const expirationDate = form.getFieldValue('membership_expiration')
      if (
        expirationDate &&
        moment(value).startOf('hour') >= moment(expirationDate).startOf('hour')
      ) {
        return Promise.reject(t('reserve.form.error.start_hour'))
      }
      return Promise.resolve()
    },
  }

  const validatorExpirationDate = {
    validator(_, value) {
      const saleDate = form.getFieldValue('sale_date')
      if (
        saleDate &&
        moment(value).startOf('hour') <= moment(saleDate).startOf('hour')
      ) {
        return Promise.reject(t('reserve.form.error.end_hour'))
      }
      return Promise.resolve()
    },
  }

  const {
    first_name,
    middle_name,
    last_name,
    maternal_last_name,
    partner_status,
    partner_code,
    country_id,
    customer_id,
    phone,
    birthdate,
    address,
    hear_about_us,
    sale_date,
    membership_expiration,
    membership_id,
    sale_room_id,
    avatar,
    convertors: convertorsInit,
  } = user ? user : ({} as UserCreate)
  const convertors_ids = convertorsInit?.map(({ id }) => id) || []

  useEffect(() => {
    if (customer_id && isAdmin) {
      OnChangeCustomer(customer_id)
    }
    if (!isAdmin) {
      const sales_room = accessUser?.business?.sales_room || []
      setSalesRoom(sales_room)
      const convertors = accessUser?.business?.convertors || []
      setConvertorsOptions(convertors)
    }
  }, [])

  return (
    <>
      <Form.Item style={{ textAlign: 'center' }} name="avatar">
        <ImageUploader
          disabled={!allowEdition}
          onImageLoaded={onImageUpload}
          defaultPreview={
            <Avatar
              url={imageData?.url || avatar}
              size={150}
              disabled={!allowEdition}
            />
          }
        />
      </Form.Item>
      <Divider>{t('users.form.div.credential')}</Divider>
      <Form.Item
        label={t('users.table.row.email')}
        name="email"
        rules={[{ required: true, message: t('users.form.required.email') }]}
      >
        <Input type="email" disabled={(user && !isAdmin) || !allowEdition} />
      </Form.Item>
      <When condition={!user}>
        <Form.Item
          label={t('users.form.password')}
          name="password"
          rules={[
            { required: true, message: t('users.form.required.password') },
          ]}
        >
          <Input type="password" />
        </Form.Item>
      </When>
      <Divider>{t('users.form.div.personal-info')}</Divider>
      <Form.Item
        label={t('users.form.first_name')}
        name="first_name"
        initialValue={first_name}
        rules={[
          {
            required: true,
            message: t('users.form.required.first_name'),
          },
        ]}
      >
        <Input disabled={!allowEdition} />
      </Form.Item>
      <Form.Item
        label={t('users.form.middle_name')}
        name="middle_name"
        initialValue={middle_name}
      >
        <Input disabled={!allowEdition} />
      </Form.Item>
      <Form.Item
        label={t('users.form.last_name')}
        name="last_name"
        initialValue={last_name}
        rules={[
          {
            required: true,
            message: t('users.form.required.last_name'),
          },
        ]}
      >
        <Input disabled={!allowEdition} />
      </Form.Item>
      <Form.Item
        label={t('users.form.maternal_last_name')}
        name="maternal_last_name"
        initialValue={maternal_last_name}
      >
        <Input disabled={!allowEdition} />
      </Form.Item>
      <Form.Item
        label={t('users.table.row.phone')}
        name="phone"
        initialValue={phone}
        rules={[{ required: true, message: t('users.form.required.phone') }]}
      >
        <Input disabled={!allowEdition} type="phone" />
      </Form.Item>
      <Form.Item
        label={t('users.form.birthdate')}
        name="birthdate"
        initialValue={birthdate}
        rules={[
          {
            type: 'date',
            message: t('form.error.date'),
          },
        ]}
      >
        <Input disabled={!allowEdition} type="date" />
      </Form.Item>
      <Form.Item
        label={t('users.table.row.address')}
        name="address"
        initialValue={address}
        rules={[{ required: true, message: t('users.form.required.address') }]}
      >
        <Input disabled={!allowEdition} />
      </Form.Item>
      <Form.Item
        label={t('users.table.row.country')}
        name="country_id"
        initialValue={country_id}
        rules={[{ required: true, message: t('users.form.required.country') }]}
      >
        <CountrySelector countries={countries} disabled={!allowEdition} />
      </Form.Item>
      <Divider>{t('users.form.div.more')}</Divider>
      <When condition={isAdmin}>
        <Form.Item
          label={t('users.table.row.customer')}
          name="customer_id"
          initialValue={customer_id}
          rules={[
            { required: true, message: t('users.form.required.customer_id') },
          ]}
        >
          <CustomerSelector
            disabled={!allowEdition}
            customers={customers}
            onChange={OnChangeCustomer}
          />
        </Form.Item>
      </When>
      <Form.Item
        label={t('users.table.row.sale_room')}
        name="sale_room_id"
        initialValue={sale_room_id}
        rules={[
          { required: true, message: t('users.form.required.sale_room') },
        ]}
      >
        <SaleRoomSelector
          disabled={!allowEdition || !salesRoom.length}
          sales_room={salesRoom}
        />
      </Form.Item>
      <Form.Item
        label={t('users.table.row.status')}
        name="partner_status"
        initialValue={partner_status}
        rules={[
          {
            required: true,
            message: t('users.form.required.partner_status'),
          },
        ]}
      >
        <PartnerStatusSelector disabled={!allowEdition} isForm />
      </Form.Item>
      <Form.Item
        label={t('users.table.row.partner_code')}
        name="partner_code"
        initialValue={partner_code}
        rules={[
          { required: true, message: t('users.form.required.partner_code') },
        ]}
      >
        <Input disabled={!allowEdition} />
      </Form.Item>
      <Form.Item
        label={t('users.table.row.membership')}
        name="membership_id"
        initialValue={membership_id}
        rules={[
          { required: true, message: t('users.form.required.membership') },
        ]}
      >
        <MembershipSelector
          disabled={!allowEdition}
          memberships={membershipOptions}
          onChange={onChangeMembership}
        />
      </Form.Item>
      <When condition={!isNullOrUndefined(membershipId)}>
        <Form.Item
          label={t('users.table.row.sale_date')}
          name="sale_date"
          initialValue={sale_date}
          rules={[
            {
              type: 'date',
              message: t('form.error.date'),
            },
            {
              required: true,
              message: t('users.form.required.sale_date'),
            },
            validatorSaleDate,
          ]}
        >
          <Input disabled={!allowEdition} type="date" />
        </Form.Item>
        <Form.Item
          label={t('users.table.row.membership_expiration')}
          name="membership_expiration"
          initialValue={membership_expiration}
          rules={[
            {
              type: 'date',
              message: t('form.error.date'),
            },
            {
              required: true,
              message: t('users.form.required.membership_expiration'),
            },
            validatorExpirationDate,
          ]}
        >
          <Input disabled={!allowEdition} type="date" />
        </Form.Item>
      </When>
      <Form.Item
        label={t('users.form.convertors')}
        name="convertors_ids"
        initialValue={convertors_ids}
      >
        <ConvertorSelector
          disabled={!allowEdition}
          convertors={convertorsOptions}
          mode="multiple"
        />
      </Form.Item>
      <Form.Item
        label={t('users.table.row.hear_about_us')}
        name="hear_about_us"
        initialValue={hear_about_us}
      >
        <Select disabled={!allowEdition}>
          {howYouHeardAboutUsOptions.map((option) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}
