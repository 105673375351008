import { SelectProps, Select } from 'antd'
import { SaleRoom } from 'api/SaleRoom/declaration'
import { filterOptions } from 'utils/common'

type Props = {
  sales_room: SaleRoom[]
} & SelectProps<'tag'>

export const SaleRoomSelector = ({ sales_room, ...props }: Props) => {
  const sales_room_options = sales_room.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  return (
    <Select
      options={sales_room_options}
      {...props}
      filterOption={filterOptions}
      showSearch
    />
  )
}
