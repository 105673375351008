import { Row, Col } from 'antd'
import AppButton from 'components/molecules/AppButton'
import useAdditionalsPage from 'hooks/useAdditionalsPageContext'
import { useTranslation } from 'react-i18next'

export const AdditionalHeader = () => {
  const { t } = useTranslation()
  const { loading, createAdditional } = useAdditionalsPage()

  return (
    <Row justify="end" style={{ marginBottom: 25 }}>
      <Col>
        <AppButton loading={loading} onClick={createAdditional}>
          {t(`additionals.button.create`)}
        </AppButton>
      </Col>
    </Row>
  )
}
