import styled, { css } from 'styled-components'

export interface IImageSizes {
  width?: string
  height?: string
}

const ContainerPreviewUploader = styled.div<IImageSizes>(
  ({ width = '100%', height = 'auto' }: IImageSizes) => css`
    box-shadow: '0px 0px 13px 8px rgba(0,0,0,0.1)';
    cursor: 'pointer';
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    width: ${width};
    height: ${height};
  `
)

export default ContainerPreviewUploader
