import { Col, Row } from 'antd'
import Title from 'components/atoms/Title/Title'
import { useTranslation } from 'react-i18next'
import { PreReservesDropdown } from './PreReservesDropdown/PreReservesDropdown'

export const CalendarTitle = () => {
  const { t } = useTranslation()

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Title>{t('side-menu.calendar')}</Title>
      </Col>
      <Col>
        <PreReservesDropdown />
      </Col>
    </Row>
  )
}
