import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import useMainLayout from 'hooks/useAccessUser'
import useUsersPage from 'hooks/useUsersPageContext'
import { UserColumns } from './UserColums'

type Props = {
  type: ETypeTable.PARTNERS | ETypeTable.CUSTOMERS
}

const UsersTable = ({ type }: Props) => {
  const {
    isPartnerPage,
    loading,
    users,
    count,
    userParams,
    userSort,
    onChangePage,
    onChangeUserSort,
    handledOpenUserModalForm,
    handledOpenReservesViewModal,
    updatePartnerStatus,
    handledOpenPartnersViewModal,
    handledOpenSalesRoomViewModal,
    updateActive,
  } = useUsersPage()
  const { isAdmin } = useMainLayout()
  return (
    <YachtsTable
      loading={loading}
      dataSource={users}
      count={count}
      pagination={{ ...userParams }}
      type={type}
      onChangePage={onChangePage}
      columns={UserColumns({
        isAdmin,
        userSort,
        isPartnerPage,
        onChangeUserSort,
        onEditUser: handledOpenUserModalForm,
        onReservesView: handledOpenReservesViewModal,
        onChangePartnerStatus: updatePartnerStatus,
        onViewPartners: handledOpenPartnersViewModal,
        onViewSaleRoom: handledOpenSalesRoomViewModal,
        onActive: updateActive,
      })}
    />
  )
}

export default UsersTable
