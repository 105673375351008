import { useState, useEffect } from 'react'
import {
  Form,
  Row,
  Col,
  Divider,
  Input,
  Button,
  Space,
  Upload,
  Alert,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { If, Then, Else, When } from 'react-if'
import { UserCreate, UserUpdate } from 'api/User/declarations'
import { isPartner } from 'utils/user'
import { PartnerInputs } from './PartnerInputs'
import { formatUpdateCustomer, formatUpdatePartner } from './format'
import { FormFooter } from 'components/molecules/Form/FormFooter'
import { IUploadedImage } from 'components/molecules/ImageUploader'
import IImageData from 'interfaces/shared'
import { scrollToTopFullscreenModal } from 'utils/scroll'
import useMainLayout from 'hooks/useAccessUser'
import useUsersPage from 'hooks/useUsersPageContext'
import { CustomersInputs } from './CustomerInputs'
import { getStartOfDayForDate } from 'utils/common'
import Text from 'antd/lib/typography/Text'
import { useTranslation } from 'react-i18next'
import tryFunction from 'utils/try'
import AuthService from 'api/Auth'
import showMessage, { NoticeType } from 'utils/notifications'
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface'

interface IResetPasswordFormValues {
  adminPassword: string
  confirmNewPassword: string
  newPassword: string
}

interface UserFormValues extends UserCreate {
  contractUpload: UploadFile<any>[]
}

export const UserForm = () => {
  const [defaultFileList, setDefaultFileList] = useState<UploadFile<any>[]>([])
  const resortsIdsWithContractEnabled = JSON.parse(
    process.env.REACT_APP_RESORTS_IDS_WITH_CONTRACT_ENABLED || '[]'
  )
  const { isAdmin, user_id } = useMainLayout()
  const isResortAdminWithContractEnabled =
    resortsIdsWithContractEnabled.includes(user_id)
  const exoticResortID = JSON.parse(process.env.REACT_APP_EXOTIC_RESORT_ID)

  const {
    isPartnerPage,
    role,
    loading,
    countries,
    memberships,
    locations,
    customers,
    userEdit: user,
    submitUserForm,
    convertors,
    handledCloseUserModalForm,
  } = useUsersPage()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const isUserFromResortWithContractsEnabled =
    user && resortsIdsWithContractEnabled.includes(user['customer_id'])
  const [allowEdition, setAllowEdition] = useState<boolean>(!user)
  const [allowContractEdition, setAllowContractEdition] = useState<boolean>(
    !user
  )
  const [imageData, setImageData] = useState<IImageData>()
  const [resetFormPasswordValues, setResetFormPasswordValues] =
    useState<IResetPasswordFormValues>(undefined)
  const [displayConfirmPasswordReset, setDisplayConfirmPasswordReset] =
    useState<boolean>(false)
  const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false)
  const [resetPasswordForm] = Form.useForm()
  const [allowPasswordReset, setAllowPasswordEReset] = useState<boolean>(true)
  const [showAddContract, setShowAddContract] = useState<boolean>(
    !user && isResortAdminWithContractEnabled
  )
  const [userHasContract, setUserHasContract] = useState<boolean>(false)
  const [contractFileIsValid, setContractFileIsValid] = useState<boolean>(true)
  const [
    displayPendingContractUploadMessage,
    setDisplayPendingContractUploadMessage,
  ] = useState<boolean>(false)

  const onPasswordReset = (formValues: IResetPasswordFormValues) => {
    setResetFormPasswordValues(formValues)
    setDisplayConfirmPasswordReset(true)
  }

  const onCancelPasswordReset = () => setDisplayConfirmPasswordReset(false)
  const handleChildChange = (newShowAddContract) => {
    if (!user) {
      form.resetFields(['file'])
      setShowAddContract(exoticResortID === newShowAddContract)
    }
  }
  const onConfirmPasswordReset = () => {
    const { newPassword, confirmNewPassword, adminPassword } =
      resetFormPasswordValues
    const passwordsMatch = newPassword === confirmNewPassword

    if (!passwordsMatch) {
      showMessage('Error', t('users.form.pass_dont_match'), NoticeType.ERROR)
      return
    }

    const passwordHasMinimumLength = newPassword.length >= 8

    if (!passwordHasMinimumLength) {
      showMessage('Error', t('users.form.minimum_length'), NoticeType.ERROR)
      return
    }

    tryFunction(
      async () => {
        await AuthService.resetPassword(user.id, newPassword, adminPassword)
        resetPasswordForm.resetFields()
        handledCloseUserModalForm()
      },
      setIsResettingPassword,
      true,
      t('form.alert.success.title'),
      t('user.form.success-reset-password-reset'),
      null,
      () => {
        onCancelPasswordReset()
      }
    )
  }

  useEffect(() => {
    if (allowEdition) {
      scrollToTopFullscreenModal()
    }
  }, [allowEdition])

  const setInitialContractFileValue = () => {
    const fileUrl = user.contract_view_url
    if (!fileUrl) return

    const fileName = `${t('users.contract')}-${user.partner_code}`
    const file: UploadFile<any> = {
      uid: '1',
      name: fileName,
      status: 'done' as UploadFileStatus,
      url: fileUrl,
      type: 'application/pdf',
    }
    setDefaultFileList([file])
    setUserHasContract(true)
  }

  useEffect(() => {
    form.setFieldsValue(user)

    if (isUserFromResortWithContractsEnabled) {
      setShowAddContract(true)
      setInitialContractFileValue()
    }

    if (user && exoticResortID === user['customer_id']) {
      setAllowPasswordEReset(false)
    }
  }, [user, form])

  const toggleAllowEdition = () => {
    const shouldAllowEdition = isResortAdminWithContractEnabled
      ? false
      : !allowEdition
    const shouldAllowContractEdition = !allowContractEdition
    setAllowEdition(shouldAllowEdition)
    setAllowContractEdition(shouldAllowContractEdition)
  }

  const onImageUpload = (imageData: IUploadedImage) =>
    setImageData({
      ...imageData,
      url: imageData?.url || '',
      alt: imageData?.file?.name || '',
    })

  const onCancelEdition = () => {
    form.setFieldsValue(user)
    form.resetFields(['contractUpload'])
    form.resetFields(['file'])
    setAllowEdition(false)
    setAllowContractEdition(false)
    setDisplayPendingContractUploadMessage(false)
    setContractFileIsValid(true)
  }

  const fileIsLessThan10MB = (file: File) => file.size / 1024 / 1024 < 10

  const onSubmit = (userForm: UserFormValues) => {
    if (imageData) {
      userForm.file = imageData.file
    }
    const { birthdate, sale_date, membership_expiration } = userForm
    const isRegisterPartner = isPartnerPage
    let userBody: UserUpdate | UserCreate = {
      ...userForm,
      role,
      birthdate: getStartOfDayForDate(birthdate),
      sale_date: getStartOfDayForDate(sale_date),
      membership_expiration: getStartOfDayForDate(membership_expiration),
    }
    const isRegisterPartnerForCustomer = isRegisterPartner && !isAdmin
    if (isRegisterPartnerForCustomer) {
      userBody.customer_id = user_id
    }
    const shouldParseUpdate = isRegisterPartner && user
    if (shouldParseUpdate) {
      userBody = formatUpdatePartner(userBody)
    } else if (user) {
      userBody = formatUpdateCustomer(userBody)
    }
    if (imageData) {
      userBody.file = imageData.file
    }

    const fileList = userForm.contractUpload
    const contractFile = fileList?.[0]?.originFileObj
    if (
      Array.isArray(fileList) &&
      fileList.length > 0 &&
      fileIsLessThan10MB(contractFile)
    ) {
      userBody.contract = contractFile
    }
    submitUserForm(userBody)
  }

  // Avoids the default behavior of the upload component, which is to upload the file to the server
  // We don't want to upload the file until the user clicks the submit button
  const dummyUploadFunction = (file: File) => {
    if (!fileIsLessThan10MB(file)) {
      setContractFileIsValid(false)
      return true
    }
    setContractFileIsValid(true)
    return false
  }

  return (
    <Row>
      <Col xs={24}>
        <Form onFinish={onSubmit} form={form}>
          <If condition={isPartner(role)}>
            <Then>
              <PartnerInputs
                form={form}
                user={user}
                allowEdition={allowEdition}
                countries={countries}
                customers={customers}
                memberships={memberships}
                imageData={imageData}
                onImageUpload={onImageUpload}
                convertors={convertors}
                onChildChange={handleChildChange}
              />
            </Then>
            <Else>
              <CustomersInputs
                user={user}
                memberships={memberships}
                locations={locations}
                allowEdition={allowEdition}
                imageData={imageData}
                onImageUpload={onImageUpload}
                convertors={convertors}
              />
            </Else>
          </If>
          <When condition={showAddContract}>
            <Divider>{t('users.contract')}</Divider>
            <Form.Item
              name="contractUpload"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                setUserHasContract(true)
                setDisplayPendingContractUploadMessage(true)
                if (Array.isArray(e)) {
                  return e
                }
                return e?.fileList || []
              }}
            >
              <Upload
                name="upload"
                listType="picture"
                maxCount={1}
                defaultFileList={defaultFileList}
                disabled={!allowContractEdition}
                accept="application/pdf"
                beforeUpload={dummyUploadFunction}
                showUploadList={{
                  showRemoveIcon: false,
                }}
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled={!allowContractEdition}
                >
                  {userHasContract
                    ? t('users.form.contract.update')
                    : t('users.form.contract.upload')}
                </Button>
              </Upload>
            </Form.Item>
            {displayPendingContractUploadMessage && contractFileIsValid && (
              <Alert
                message={t('users.form.contract.upload.pending')}
                type="info"
                showIcon
              />
            )}
            {!contractFileIsValid && (
              <Alert
                message={t('users.form.contract.error.size')}
                type="error"
                showIcon
              />
            )}
          </When>
          <FormFooter
            allowEdition={allowEdition || allowContractEdition}
            loading={loading}
            toggleAllowEdition={toggleAllowEdition}
            onCancelEdition={onCancelEdition}
            dataForm={user}
            viewEdit={isAdmin || isResortAdminWithContractEnabled}
          />
        </Form>
        <When condition={allowPasswordReset}>
          <If condition={isPartner(role) && Boolean(user) && isAdmin}>
            <Form onFinish={onPasswordReset} form={resetPasswordForm}>
              <Divider>{t('users.form.div.password_reset')}</Divider>
              <Form.Item
                label={t('users.form.new_password')}
                name="newPassword"
                rules={[{ required: true, message: t('form.required') }]}
              >
                <Input
                  type="password"
                  onChange={onCancelPasswordReset}
                  disabled={isResettingPassword}
                />
              </Form.Item>
              <Form.Item
                label={t('users.form.confirm_new_password')}
                name="confirmNewPassword"
                rules={[{ required: true, message: t('form.required') }]}
              >
                <Input
                  type="password"
                  onChange={onCancelPasswordReset}
                  disabled={isResettingPassword}
                />
              </Form.Item>
              <Form.Item
                label={t('users.form.admin_password')}
                name="adminPassword"
                rules={[{ required: true, message: t('form.required') }]}
              >
                <Input
                  type="password"
                  onChange={onCancelPasswordReset}
                  disabled={isResettingPassword}
                />
              </Form.Item>
              <If condition={!displayConfirmPasswordReset}>
                <Then>
                  <Button type="primary" danger htmlType="submit">
                    {t('users.button.partner.reset_password')}
                  </Button>
                </Then>
                <Else>
                  <Space direction="vertical">
                    <Text type="danger">
                      {t('users.form.reset_password_notice')}
                    </Text>
                    <Space>
                      <Button
                        onClick={onCancelPasswordReset}
                        type="primary"
                        loading={isResettingPassword}
                      >
                        {t('users.button.partner.cancel_reset_password')}
                      </Button>
                      <Button
                        onClick={onConfirmPasswordReset}
                        type="ghost"
                        danger
                        loading={isResettingPassword}
                      >
                        {t('users.button.partner.confirm_reset_password')}
                      </Button>
                    </Space>
                  </Space>
                </Else>
              </If>
            </Form>
          </If>
        </When>
      </Col>
    </Row>
  )
}
