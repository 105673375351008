import { Select, SelectProps } from 'antd'
import { Membership } from 'api/Membership/declarations'
import { filterOptions } from 'utils/common'

type Props = {
  memberships: Membership[]
  keyValue?: 'id' | 'name'
} & SelectProps<'tag'>

export const MembershipSelector = ({
  memberships,
  keyValue = 'id',
  ...props
}: Props) => {
  const memberships_options = memberships.map((membership) => ({
    label: membership.name,
    value: membership[keyValue],
  }))

  return (
    <Select
      options={memberships_options}
      {...props}
      filterOption={filterOptions}
      showSearch={false}
      showArrow
    />
  )
}
