import { Select, SelectProps } from 'antd'
import { Additional } from 'api/Additional/declarations'
import { filterOptions } from 'utils/common'

type Props = {
  addittionals: Additional[]
} & SelectProps<'tag' | 'multiple'>

export const AdditionalSelector = ({ addittionals, ...props }: Props) => {
  const additional_options = addittionals.map(({ id, name_en }) => ({
    label: name_en,
    value: id,
  }))

  return (
    <Select
      options={additional_options}
      {...props}
      filterOption={filterOptions}
      showSearch
    />
  )
}
