import styled from 'styled-components'
import { Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { BaseActionButton, IActionButton } from './BaseActionButton'
import COLORS from 'shared/constants/colors'

const { DANGER } = COLORS

const RemoveIcon = styled(DeleteOutlined)`
  color: ${DANGER};
`

const emptyOnClick = () => {}

const RemoveButton = ({ onClick, ...props }: IActionButton) => (
  <Popconfirm
    title="¿Quieres eliminar?"
    okText="Sí"
    cancelText="Cancelar"
    onConfirm={onClick}
  >
    <BaseActionButton {...props} icon={<RemoveIcon />} onClick={emptyOnClick} />
  </Popconfirm>
)

export { RemoveButton }
