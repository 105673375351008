import { SelectProps, Select } from 'antd'
import { EPaymentType } from 'api/Charge/declarations'

type Props = {
  isForm?: boolean
} & SelectProps<'tag'>

export const PaymentTypeSelector = ({ isForm = false, ...props }: Props) => {
  const options = Object.values(EPaymentType).map((type) => ({
    label: type,
    value: type,
    disabled: isForm && type === EPaymentType.CARD,
  }))

  return <Select options={options} {...props} />
}
