import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { WithUserProps } from 'hooks/withUser'
import { EMenuKeys } from 'shared/constants/menuElements'
import { ConvertorsPageProvider } from 'components/context/ConvertorsPageContext'
import {
  ConvertorsTable,
  EditConvertorForm,
  NewConvertorForm,
} from 'components/organisms/Converters'

const ConvertorsPage: React.FC<WithUserProps & RouteComponentProps> = (
  props
) => (
  <MainLayout {...props} active={EMenuKeys.CONVERTORS}>
    <ConvertorsPageProvider>
      <ConvertorsTable />
      <NewConvertorForm />
      <EditConvertorForm />
    </ConvertorsPageProvider>
  </MainLayout>
)

export default ConvertorsPage
