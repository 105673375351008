import { useHistory, useParams } from 'react-router-dom'
import { FC, useContext, useEffect, useState } from 'react'
import scrollToTop from 'utils/scroll'
import tryFunction from 'utils/try'
import { Reserve } from 'api/Reserve/declarations'
import ReservePageContext, { IReservePageContext } from './ReservePageContext'
import { User } from 'api/User/declarations'
import {
  calculateAmount,
  getParamsDate,
  getPathnameReserve,
  getReserveHours,
  IReserveHours,
} from 'utils/reserves'
import ReserveService from 'api/Reserve'
import { useTranslation } from 'react-i18next'
import LocationsContext from '../LocationsContext'
import YachtsContext from '../YachtsContext'
import AdditionalsContext from '../AdditionalsContext'
import { Yacht } from 'api/Yacht/declarations'
import showMessage, { NoticeType } from 'utils/notifications'
import { Additional } from 'api/Additional/declarations'
import UserService from 'api/User'

export type ReserveParams = {
  date: string
  id: string
}

const ReservePageProvider: FC = ({ children }) => {
  const { replace } = useHistory()
  const { id, date } = useParams<ReserveParams>()
  const { t } = useTranslation()
  const { locations, getLocations } = useContext(LocationsContext)
  const { additionals, getAdditionals } = useContext(AdditionalsContext)
  const { yachts, locationId, getYachts, setLocationId } =
    useContext(YachtsContext)
  const [dateReserve] = useState<Date>(getParamsDate(date))
  const [loading, setLoading] = useState<boolean>(false)
  const [reserve, setReserve] = useState<Reserve>()
  const [partner, setPartner] = useState<User>()
  const [yacht, setYactch] = useState<Yacht>()
  const [selectAdditional, setSelectAdditionals] = useState<Additional[]>()
  const [amount, setAmount] = useState<number>(0)
  const [amountAdditional, setAmountAdditional] = useState<number>(0)
  const [hourAvailable, setHourAvailable] = useState<number[]>([])
  const [reserveHour, setReserveHours] = useState<IReserveHours>()

  useEffect(() => {
    loadData()
  }, [id])

  useEffect(() => {
    getYachts()
  }, [locationId])

  useEffect(() => {
    if (
      !reserve &&
      yacht &&
      partner &&
      reserveHour?.end_hour &&
      reserveHour?.start_hour
    ) {
      loadAmount()
    }
  }, [partner, yacht, selectAdditional, reserveHour])

  const loadData = () => {
    tryFunction(async () => {
      if (id !== 'new') {
        await getReserve()
      }

      await getLocations()
      await getYachts()
      await getAdditionals()
      scrollToTop()
    }, setLoading)
  }

  const loadAmount = () => {
    try {
      const hoursNumber = getReserveHours(reserveHour)
      const { membership } = partner
      const { total, amountAdditional } = calculateAmount({
        yacht,
        membership,
        hoursNumber,
        additionals: selectAdditional,
      })
      setAmount(total)
      setAmountAdditional(amountAdditional)
    } catch (error) {
      showMessage('Error', error.message, NoticeType.ERROR)
    }
  }

  const getReserve = async () => {
    const reserve = await ReserveService.findOne(id)
    setReserve(reserve)
    setYactch(reserve.yacht)
    setPartner(reserve.user)
  }

  const getHourAvailable = async () => {
    const reserve = await ReserveService.availableHoursPerDay({
      date: dateReserve,
      yacht_id: yacht.id,
    })
    setHourAvailable(hourAvailable)
  }

  const submitForm = (body: Reserve) => {
    body.user_id = partner.id

    tryFunction(
      async () => {
        if (reserve) {
          await ReserveService.update(reserve.id, body)
          await getReserve()
        } else {
          const { reserve } = await ReserveService.create(body)
          const path = getPathnameReserve(reserve)
          replace(path)
        }
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const cancelReserve = (reserve: Reserve) => {
    tryFunction(
      async () => {
        await ReserveService.cancel(reserve.id)
        await getReserve()
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onChangeYacth = (yactId) => {
    const yacht = yachts.find(({ id }) => yactId === id)
    yacht && setYactch(yacht)
  }

  const onChangeLocation = (locationId) => {
    setLocationId(locationId)
  }

  const onChangeStartHour = (start_hour) => {
    setReserveHours({ ...reserveHour, start_hour })
  }

  const onChangeEndHour = (end_hour) => {
    setReserveHours({ ...reserveHour, end_hour })
  }

  const onChangeAdditional = (additional) => {
    const selectAdditional = additionals.filter(({ id }) =>
      additional.includes(id)
    )
    setSelectAdditionals(selectAdditional)
  }

  const validateUserEmail = async (email: string) => {
    try {
      const partners = await UserService.find({ search: email })

      if (partners.length) {
        setPartner(partners[0])
      }

      if (!partners.length) {
        throw new Error(t('error.not-found-email'))
      }
    } catch (error) {
      throw new Error(t('error.not-found-email'))
    }
  }

  const contextValue: IReservePageContext = {
    loading,
    dateReserve,
    reserve,
    partner,
    locations,
    locationId,
    yachts,
    yacht,
    reserveHour,
    additionals,
    hourAvailable,
    amount,
    amountAdditional,
    cancelReserve,
    submitForm,
    onChangeLocation,
    onChangeYacth,
    onChangeStartHour,
    onChangeEndHour,
    onChangeAdditional,
    validateUserEmail,
  }

  return (
    <ReservePageContext.Provider value={contextValue}>
      {children}
    </ReservePageContext.Provider>
  )
}

export { ReservePageProvider }
