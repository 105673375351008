import React from 'react'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { EMenuKeys } from 'shared/constants/menuElements'
import { BankAccountsTable } from 'components/organisms/BankAccount/BankAccountTable'
import BankAccountFormModal from 'components/organisms/BankAccount/BankAccountFormModal'
import { BankAccountsPageProvider } from 'components/context/BankAccountContext/BankAccountsPageProvider'

const BankAccountPage: React.FC<WithUserProps & RouteComponentProps> = (
  props
) => (
  <MainLayout {...props} active={EMenuKeys.BANK_ACCOUNTS}>
    <BankAccountsPageProvider>
      <BankAccountsTable />
      <BankAccountFormModal />
    </BankAccountsPageProvider>
  </MainLayout>
)

export default BankAccountPage
