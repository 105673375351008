import axios from 'axios'
import { IImage } from 'interfaces/image'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from '../BaseService/declarations'
import { Additional, AdditionalParams } from './declarations'

export class AdditionalService extends BaseService<Additional> {
  protected name: string = 'additional'

  async find(): Promise<Additional[]> {
    try {
      const response = await axios.get<Response<Additional[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders()
      )
      const data = response.data.data as Additional[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async paginate(
    params: AdditionalParams
  ): Promise<PaginatedResponse<Additional>> {
    try {
      const response = await axios.get<Response<Additional[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      const data = response.data.data as Additional[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async create(body: Additional): Promise<Additional> {
    try {
      const response = await axios.post<Response<Additional>>(
        `${this.url}/${this.name}`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as Additional
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async update(additional_id: number, body: Additional): Promise<Additional> {
    try {
      const response = await axios.put<Response<Additional>>(
        `${this.url}/${this.name}/${additional_id}`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as Additional
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async delete(additional_id: number): Promise<void> {
    try {
      await axios.delete<Response<{}>>(
        `${this.url}/${this.name}/${additional_id}`,
        this.getHeaders()
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async uploadImage(
    additional_id: number,
    image,
    image_id?: number
  ): Promise<IImage> {
    try {
      const params = { image_id }
      const formData = new FormData()
      formData.append('image', image)
      const response = await axios.post<Response<IImage>>(
        `${this.url}/${this.name}/${additional_id}/image`,
        formData,
        this.getHeadersMultipart({ params })
      )
      const { data: axiosData } = response
      return axiosData.data as IImage
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async deleteImage(additional_id: number, image_id: number): Promise<void> {
    try {
      const params = { image_id }
      await axios.delete<Response<IImage>>(
        `${this.url}/${this.name}/${additional_id}/image`,
        this.getHeaders({ params })
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new AdditionalService()
export default service
