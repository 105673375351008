import { Menu } from 'antd'
import menuElements, {
  EMenuKeys,
  MenuElement,
} from 'shared/constants/menuElements'
import { SideMenu } from 'components/atoms/SideMenu'
import { Link } from 'react-router-dom'
import UseMenuTitle from 'hooks/translate/UseMenuTitle'
import useMainLayout from 'hooks/useAccessUser'

const { Item } = Menu

type Props = {
  active?: EMenuKeys
}
const LateralMenu = ({ active = EMenuKeys.PARTNERS }: Props) => {
  const { translate } = UseMenuTitle()
  const { isAdmin } = useMainLayout()

  return (
    <SideMenu style={{ height: 'inherit' }}>
      <Menu
        mode="inline"
        selectedKeys={[active]}
        style={{ height: '100%', borderRight: 0 }}
      >
        {menuElements
          .filter(({ onlyAdmin }) => !onlyAdmin || (onlyAdmin && isAdmin))
          .map(({ key, disabled, route }: MenuElement) => (
            <Item key={key} disabled={disabled}>
              <Link to={route}>{translate({ key })}</Link>
            </Item>
          ))}
      </Menu>
    </SideMenu>
  )
}

export default LateralMenu
