import { createContext } from 'react'
import { Membership } from 'api/Membership/declarations'
import { Additional, AdditionalParams } from 'api/Additional/declarations'

export type AdditionalShowModal = {
  additionalForm: boolean
  imagesForm: boolean
}

interface IAdditionalsPageContext {
  loading: boolean
  memberships: Membership[]
  additionalEdit?: Additional
  params?: AdditionalParams
  count: number
  additionals: Additional[]
  showModal: AdditionalShowModal
  submitAdditionalForm: (data: Additional) => void
  onDelete: (data: Additional) => void
  onChangePage: (page: number) => void
  handledOpenAdditionalModalForm: (data?: Additional) => void
  handledCloseAdditionalModalForm: () => void
  handledOpenImagesModalForm: (data: Additional) => void
  handledCloseImagesModalForm: () => void
  createAdditional: () => void
  onUploadImage: (image, image_id?: number) => void
  onDeleteImage: (image_id?: number) => void
}

const AdditionalsPageContext = createContext<
  IAdditionalsPageContext | undefined
>(undefined)

export default AdditionalsPageContext
export type { IAdditionalsPageContext }
