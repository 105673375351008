import { Col, Row } from 'antd'
import { SaleRoom } from 'api/SaleRoom/declaration'
import Title from 'components/atoms/Title/Title'
import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import { useTranslation } from 'react-i18next'
import { SaleRoomForm } from '../SaleRoomForm/SaleRoomForm'
import { SalesRoomColumns } from './SalesRoomColumns'

type Props = {
  loading: boolean
  rooms: SaleRoom[]
  sale_room: SaleRoom
  count: number
  params
  setSaleRoom: (sale_room: SaleRoom) => void
  onChangePage: (page: number) => void
  onSubmit: (sale_room: SaleRoom) => void
  onDelete: (sale_room: SaleRoom) => void
}

export const PartnersTable = ({
  loading,
  rooms,
  count,
  params,
  sale_room,
  setSaleRoom,
  onChangePage,
  onSubmit,
  onDelete,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Title>{t('users.table.row.sales_room')}</Title>
      </Col>
      <Col xs={24}>
        <SaleRoomForm
          loading={loading}
          handleSubmit={onSubmit}
          style={{ paddingBottom: 20 }}
        />
      </Col>
      <Col xs={24}>
        <YachtsTable
          loading={loading}
          dataSource={rooms}
          count={count}
          pagination={{ ...params }}
          type={ETypeTable.SALE_ROOMS}
          onChangePage={onChangePage}
          columns={SalesRoomColumns({
            sale_room,
            loading,
            setSaleRoom,
            onDelete,
            onEdit: onSubmit,
          })}
        />
      </Col>
    </Row>
  )
}
