import MembershipService from 'api/Membership'
import { Membership, FindMembershipsParams } from 'api/Membership/declarations'
import useMainLayout from 'hooks/useAccessUser'
import { FC, useState } from 'react'
import { getMembershipsByBusiness } from 'utils/membership'
import MembershipsContext, { IMembershipsContext } from './MembershipContext'

interface IMembershipsProviders {
  withoutImages?: boolean
}

const MembershipsProvider: FC<IMembershipsProviders> = ({
  children,
  withoutImages,
}) => {
  const { user, isAdmin } = useMainLayout()
  const business = user?.business
  const [memberships, setMemberships] = useState<Membership[]>([])

  const getMemberships = async (sort?: FindMembershipsParams) => {
    const memberships = await MembershipService.find({
      ...sort,
      without_images: withoutImages,
    })
    setMemberships(
      isAdmin
        ? memberships
        : getMembershipsByBusiness({ business, memberships })
    )
  }

  const contextValue: IMembershipsContext = {
    memberships,
    getMemberships,
  }

  return (
    <MembershipsContext.Provider value={contextValue}>
      {children}
    </MembershipsContext.Provider>
  )
}

export { MembershipsProvider }
