import { SelectProps, Select } from 'antd'
import { tActive } from 'utils/user'

type Props = {
  value
} & SelectProps<'tag'>

export const ActiveSelector = ({ value, ...props }: Props) => {
  const status = [true, false].map((opt) => ({
    label: tActive(opt),
    value: `${opt}`,
  }))

  return <Select options={status} value={`${value}`} {...props} />
}
