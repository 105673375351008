import { FC, useEffect, useState } from 'react'
import MembershipService from 'api/Membership'
import MembershipsPageContext, {
  IMembershipsPageContext,
  IEditFormState,
} from './MembershipsPageContext'
import useMembershipsContext from 'hooks/useMembershipsContext'
import useDisclosure from 'hooks/useDisclosure'
import IImageData, { SortDirection } from 'interfaces/shared'
import type { ICreateMembership, Membership } from 'api/Membership/declarations'
import tryFunction from 'utils/try'

const MembershipsPageProvider: FC = ({ children }) => {
  const { memberships, getMemberships } = useMembershipsContext()
  const {
    isOpen: createMembershipFormIsOpen,
    onClose: onCloseCreateMembershipForm,
    onOpen: onOpenCreateMembershipForm,
  } = useDisclosure()
  const [editFormState, setEditFormState] = useState<IEditFormState>({
    isOpen: false,
  })
  const [priceSort, setPriceSort] = useState<SortDirection | undefined>(
    undefined
  )

  const updateMemberships = async () => {
    await getMemberships({ price: priceSort })
  }

  useEffect(() => {
    updateMemberships()
  }, [priceSort])

  const onCloseEditMembershipForm = () =>
    setEditFormState({ ...editFormState, isOpen: false })

  const onOpenEditMembershipForm = (membership: Membership) =>
    setEditFormState({ membership, isOpen: true })

  const onCreateMembership = async (
    body: ICreateMembership,
    images: IImageData[]
  ) => {
    const membership = await MembershipService.createMembership(body)
    await Promise.all(
      images.map(
        async (image) =>
          await MembershipService.uploadMembershipImage(
            membership.id,
            image.file
          )
      )
    )
    await updateMemberships()
    onCloseCreateMembershipForm()
  }

  const onUpdateMembership = async (
    body: ICreateMembership,
    images: IImageData[]
  ) => {
    const membershipId = editFormState.membership?.id
    const membershipImages = editFormState.membership?.images || []
    await MembershipService.updateMembership(membershipId, body)
    const imagesToRemove = membershipImages.filter(
      (image) => !Boolean(images.find(({ id }) => id === image.id))
    )
    const imagesToCreate = images.filter((image) => Boolean(image.file))
    await Promise.all(
      imagesToRemove.map(
        async (image) =>
          await MembershipService.deleteMembershipImage(membershipId, image.id)
      )
    )
    await Promise.all(
      imagesToCreate.map(async (image) => {
        await MembershipService.uploadMembershipImage(membershipId, image.file)
      })
    )
    await updateMemberships()
    onCloseEditMembershipForm()
  }

  const onDeleteMembership = (membership: Membership) => {
    tryFunction(
      async () => {
        await MembershipService.deleteMembership(membership.id)
        await updateMemberships()
      },
      () => {},
      true
    )
  }

  const contextValue: IMembershipsPageContext = {
    memberships,
    createMembershipFormIsOpen,
    onCloseCreateMembershipForm,
    onOpenCreateMembershipForm,
    editFormState,
    onOpenEditMembershipForm,
    onCloseEditMembershipForm,
    setPriceSort,
    priceSort,
    onCreateMembership,
    onUpdateMembership,
    onDeleteMembership,
  }

  return (
    <MembershipsPageContext.Provider value={contextValue}>
      {children}
    </MembershipsPageContext.Provider>
  )
}

export { MembershipsPageProvider }
