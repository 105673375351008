import i18n from 'i18next'
import { ELanguage } from './interfaces/i18n'
import { initReactI18next } from 'react-i18next'
import lang from 'lang'

i18n.use(initReactI18next).init({
  resources: lang,
  lng: ELanguage.ES,
  fallbackLng: ELanguage.ES,
  interpolation: {
    escapeValue: false,
  },
})
