import { SelectProps, Select } from 'antd'
import { EInvoiceStatus } from 'api/Movements/declarations'
import { useTranslation } from 'react-i18next'

type Props = {} & SelectProps<'tag'>

export const InvoiceStatusSelector = ({ ...props }: Props) => {
  const { t } = useTranslation()
  const options = Object.values(EInvoiceStatus).map((type) => ({
    label: t(`movements.invoice_status.${type}`),
    value: type,
  }))

  return <Select options={options} {...props} />
}
