import { Select, SelectProps } from 'antd'
import { Convertor } from 'api/Convertor/declarations'
import { filterOptions } from 'utils/common'

type Props = {
  convertors: Convertor[]
} & SelectProps<'multiple'>

export const ConvertorSelector = ({ convertors, ...props }: Props) => {
  const convertors_options = convertors.map(({ id, name_en }) => ({
    label: name_en,
    value: id,
  }))

  return (
    <Select
      options={convertors_options}
      {...props}
      filterOption={filterOptions}
      showSearch={false}
      showArrow
    />
  )
}
