import { Form } from 'antd'
import useYachtsPage from 'hooks/useYachtsPageContext'
import { useTranslation } from 'react-i18next'
import { AdditionalSelector } from '../../Selector/AdditionalSelector'

type Props = {
  allowEdition: boolean
}

export const YachtAdditionalsInputs = ({ allowEdition }: Props) => {
  const { t } = useTranslation()
  const { additionals } = useYachtsPage()

  return (
    <Form.Item name="additionals_ids">
      <AdditionalSelector
        addittionals={additionals}
        disabled={!allowEdition}
        mode="multiple"
        placeholder={t('yachts.form.placeholder.additional')}
      />
    </Form.Item>
  )
}
