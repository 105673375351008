import UsersPageContext from 'components/context/UsersPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useUsersPage = () => {
  const contextName = 'UsersPageContext'
  const contextValue = useContext(UsersPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useUsersPage
