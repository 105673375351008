import { Button, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import showMessage, { NoticeType } from 'utils/notifications'
import type { RcFile } from 'antd/lib/upload'
import { useTranslation } from 'react-i18next'
import { isValidateFormatImage } from 'utils/common'
import { Else, If, Then } from 'react-if'
import ContainerPreviewUploader, {
  IImageSizes,
} from 'components/atoms/ContainerPreviewUploader'

interface IUploadedImage {
  url: string
  file?: RcFile
}

interface IImageUploader {
  imageUrl?: string
  onImageLoaded: (imageData: IUploadedImage, image_id?: number) => void
  excludePng?: boolean
  disabled?: boolean
  size?: IImageSizes
  defaultPreview?
}

interface IImagePreview {
  id?: number | string
  url: string
  alt: string
  isDefault?: boolean
  onRemove?: () => void
  onFavorite?: () => void
  allowEdition?: boolean
  allowSelectDefault?: boolean
}

const ImageUploader = ({
  imageUrl,
  excludePng = false,
  disabled = false,
  size = { width: '100%' },
  onImageLoaded,
  defaultPreview,
}: IImageUploader) => {
  const { t } = useTranslation()
  const { width, height } = size

  const getBase64 = (file: RcFile) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve((reader.result as string) || '')
      reader.onerror = (error) => reject(error)
    })

  const isValidImage = (file: File) => {
    const isJpg = isValidateFormatImage(file.type, excludePng)
    if (!isJpg) {
      showMessage(
        t('image_uploader.sorry_title'),
        t('image_uploader.error.format'),
        NoticeType.ERROR
      )
    }
    const isLt2M = file.size / 1024 / 1024 < 5
    if (!isLt2M) {
      showMessage(
        t('image_uploader.sorry_title'),
        t('image_uploader.error.size_5MB'),
        NoticeType.ERROR
      )
    }
    return isJpg && isLt2M
  }

  const handleUpload = async (file: RcFile) => {
    try {
      if (isValidImage(file)) {
        const resultUrl = await getBase64(file)
        onImageLoaded({
          url: resultUrl,
          file,
        })
      }
    } catch (error) {
      showMessage(
        t('image_uploader.sorry_title'),
        t('image_uploader.error.load'),
        NoticeType.ERROR
      )
    } finally {
      return ''
    }
  }

  return (
    <Upload action={handleUpload} showUploadList={false} disabled={disabled}>
      <If condition={imageUrl}>
        <Then>
          <img src={imageUrl} style={{ ...size, objectFit: 'cover' }} />
        </Then>
        <Else>
          <ContainerPreviewUploader width={width} height={height}>
            <If condition={defaultPreview}>
              <Then>{defaultPreview}</Then>
              <Else>
                <Button type="primary" size="large" disabled={disabled}>
                  <PlusOutlined style={{ marginBottom: 8 }} size={200} />
                  {t('form.add')}
                </Button>
              </Else>
            </If>
          </ContainerPreviewUploader>
        </Else>
      </If>
    </Upload>
  )
}

export default ImageUploader
export type { IUploadedImage, IImagePreview }
