import FullscreenModal from 'components/molecules/FullscreenModal'
import useBankAccountPageContext from 'hooks/useBankAccountPageContext'
import { BankAccountForm } from './BankAccountForm'

const BankAccountFormModal = () => {
  const { showModal, loading, handledCloseBankAccountModalForm } =
    useBankAccountPageContext()
  return (
    <FullscreenModal
      visible={showModal.bankAccountForm}
      loading={loading}
      maskClosable={false}
      maxWidth="600px"
      padding="10%"
      height="auto"
      destroyOnClose
      onCancel={handledCloseBankAccountModalForm}
    >
      <BankAccountForm />
    </FullscreenModal>
  )
}

export default BankAccountFormModal
