import { Space } from 'antd'
import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import { ChargesTableColumns } from './ChargesTableColumns'
import { ChargesTableHeader } from './ChargesTableHeader'
import useChargesPageContext from 'hooks/useChargesPageContext'
import mixpanelService from 'services/mixpanel'
import { useEffect } from 'react'

const ChargesTable = () => {
  const {
    charges,
    chargesCount,
    isLoadingCharges,
    onPageChange,
    chargesParams,
    onCancelCharge,
    onOpenChargeDetailsModal,
  } = useChargesPageContext()

  useEffect(() => {
    mixpanelService.track.userEnterChargesPage()
  }, [])

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      <ChargesTableHeader />
      <YachtsTable
        loading={isLoadingCharges}
        dataSource={charges}
        columns={ChargesTableColumns({
          onCancelCharge,
          onOpenChargeDetails: onOpenChargeDetailsModal,
        })}
        count={chargesCount}
        onChangePage={onPageChange}
        type={ETypeTable.CHARGES}
        pagination={chargesParams}
      />
    </Space>
  )
}

export { ChargesTable }
