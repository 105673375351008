import { Location } from 'api/Location/declarations'
import { Size } from 'api/Size/declarations'
import { Yacht, YachtsParams } from 'api/Yacht/declarations'
import { YachtsShowModal } from './YachtsPageProvider'
import { createContext } from 'react'
import { Additional } from 'api/Additional/declarations'
import { Membership } from 'api/Membership/declarations'
import { Service } from 'api/Service/declarations'
import { IPickerPosition } from 'components/molecules/Maps/FormMap'

interface IYachtsPageContext {
  loading: boolean
  locations: Location[]
  sizes: Size[]
  additionals: Additional[]
  services: Service[]
  memberships: Membership[]
  yachtEdit?: Yacht
  params?: YachtsParams
  count: number
  yachts: Yacht[]
  showModal: YachtsShowModal
  submitYachtForm: (data: Yacht) => void
  onActive: (data: Yacht) => void
  onDelete: (data: Yacht) => void
  onChangeFilter: (params: YachtsParams) => void
  onChangePage: (page: number) => void
  handledOpenYachtModalForm: (data?: Yacht) => void
  handledCloseYachtModalForm: () => void
  handledOpenImagesModalForm: (data?: Yacht) => void
  handledCloseImagesModalForm: () => void
  handledOpenMapModalForm: (data?: Yacht) => void
  handledCloseMapModalForm: () => void
  createYacht: () => void
  onUploadImage: (image, image_id?: number) => void
  onDeleteImage: (image_id?: number) => void
  onDefaultImage: (image_id?: number) => void
  submitUpdateMap: (position: IPickerPosition) => void
}

const YachtsPageContext = createContext<IYachtsPageContext | undefined>(
  undefined
)

export default YachtsPageContext
export type { IYachtsPageContext }
