import MovementsPageContext from 'components/context/MovementsPageContext/MovementsPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useMovementsPageContext = () => {
  const contextName = 'MovementsPageContext'
  const contextValue = useContext(MovementsPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useMovementsPageContext
