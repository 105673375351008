import YachtsPageContext from 'components/context/YachtsPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useYachtsPage = () => {
  const contextName = 'YachtsPageContext'
  const contextValue = useContext(YachtsPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useYachtsPage
