import { Col, Row } from 'antd'
import { User, UserParams } from 'api/User/declarations'
import Title from 'components/atoms/Title/Title'
import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import { useTranslation } from 'react-i18next'
import { PartnersColumns } from './PartnersColumns'

type Props = {
  loading: boolean
  users: User[]
  count: number
  userParams: UserParams
  onChangePage: (page: number) => void
}

export const PartnersTable = ({
  loading,
  users,
  count,
  userParams,
  onChangePage,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Title>{t('side-menu.partners')}</Title>
      </Col>
      <Col xs={24}>
        <YachtsTable
          loading={loading}
          dataSource={users}
          count={count}
          pagination={{ ...userParams }}
          type={ETypeTable.PARTNERS}
          onChangePage={onChangePage}
          columns={PartnersColumns()}
        />
      </Col>
    </Row>
  )
}
