import i18n from 'i18next'
import { TableHeader } from 'components/atoms/Table'
import { formatDate } from 'utils/common'

export type Props = {}

export const PartnersColumns = () => [
  {
    title: <TableHeader>{i18n.t('users.table.row.status')}</TableHeader>,
    dataIndex: 'partner_status',
    key: 'partner_status',
    render: (text: string, record) =>
      i18n.t(`users.partner_status.${record?.partner_status}`),
  },
  {
    title: <TableHeader>{i18n.t('users.table.row.name')}</TableHeader>,
    dataIndex: 'first_name',
    key: 'first_name',
  },
  {
    title: (
      <TableHeader name="last_name">
        {i18n.t('users.table.row.last_name')}
      </TableHeader>
    ),
    dataIndex: 'last_name',
    key: 'last_name',
  },
  {
    title: (
      <TableHeader name="email">{i18n.t('users.table.row.email')}</TableHeader>
    ),
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: (
      <TableHeader name="partner_code">
        {i18n.t('users.table.row.partner_code')}
      </TableHeader>
    ),
    dataIndex: 'partner_code',
    key: 'partner_code',
  },
  {
    title: (
      <TableHeader name="created_at">
        {i18n.t('users.table.row.register_date')}
      </TableHeader>
    ),
    dataIndex: 'register_date',
    key: 'register_date',
    render: (text: string, record) => formatDate(record.created_at),
  },
]
