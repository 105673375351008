import sideMenuES from 'lang/sideMenu/es.json'
import sideMenuEN from 'lang/sideMenu/en.json'
import footerEs from 'lang/footer/es.json'
import footerEN from 'lang/footer/en.json'
import yachtsTableES from 'lang/YachtsTable/es.json'
import yachtsTableEN from 'lang/YachtsTable/en.json'
import usersES from 'lang/Users/es.json'
import usersEN from 'lang/Users/en.json'
import FormES from 'lang/Form/es.json'
import FormEN from 'lang/Form/en.json'
import ReservesES from 'lang/Reserves/es.json'
import ReservesEN from 'lang/Reserves/en.json'
import ImageUploaderES from 'lang/ImageUploader/es.json'
import ImageUploaderEN from 'lang/ImageUploader/en.json'
import YachtsES from 'lang/Yachts/es.json'
import YachtsEN from 'lang/Yachts/en.json'
import UploadRulesES from 'lang/UploadRules/es.json'
import UploadRulesEN from 'lang/UploadRules/en.json'
import AdditionalsES from 'lang/Additionals/es.json'
import AdditionalsEN from 'lang/Additionals/en.json'
import ServicesES from 'lang/Services/es.json'
import ServicesEN from 'lang/Services/en.json'
import CalendarES from 'lang/Calendar/es.json'
import CalendarEN from 'lang/Calendar/en.json'
import ReserveES from 'lang/Reserve/es.json'
import ReserveEN from 'lang/Reserve/en.json'
import ChargeES from 'lang/Charge/es.json'
import ChargeEN from 'lang/Charge/en.json'
import LocationsES from 'lang/Locations/es.json'
import LocationsEN from 'lang/Locations/en.json'
import MembershipsES from 'lang/Memberships/es.json'
import MembershipsEN from 'lang/Memberships/en.json'
import ConvertorsES from 'lang/Convertors/es.json'
import ConvertorsEN from 'lang/Convertors/en.json'
import BlockedDatesES from 'lang/BlockedDates/es.json'
import BlockedDatesEN from 'lang/BlockedDates/en.json'
import BankAccountES from 'lang/BankAccount/es.json'
import BankAccountEN from 'lang/BankAccount/en.json'
import MovementES from 'lang/Movement/es.json'
import MovementEN from 'lang/Movement/en.json'
import ErrorMessageES from 'lang/Error/es.json'
import ErrorMessageEN from 'lang/Error/en.json'
import PartnerViewES from 'lang/PartnerView/es.json'
import PartnerViewEN from 'lang/PartnerView/en.json'

const lang = {
  en: {
    translation: {
      ...sideMenuEN,
      ...footerEN,
      ...yachtsTableEN,
      ...usersEN,
      ...FormEN,
      ...ReservesEN,
      ...ImageUploaderEN,
      ...YachtsEN,
      ...UploadRulesEN,
      ...AdditionalsEN,
      ...ServicesEN,
      ...CalendarEN,
      ...ReserveEN,
      ...ChargeEN,
      ...LocationsEN,
      ...MembershipsEN,
      ...ConvertorsEN,
      ...BlockedDatesEN,
      ...BankAccountEN,
      ...MovementEN,
      ...ErrorMessageEN,
      ...PartnerViewEN,
    },
  },
  es: {
    translation: {
      ...sideMenuES,
      ...footerEs,
      ...yachtsTableES,
      ...usersES,
      ...FormES,
      ...ReservesES,
      ...ImageUploaderES,
      ...YachtsES,
      ...UploadRulesES,
      ...AdditionalsES,
      ...ServicesES,
      ...CalendarES,
      ...ReserveES,
      ...ChargeES,
      ...LocationsES,
      ...MembershipsES,
      ...ConvertorsES,
      ...BlockedDatesES,
      ...BankAccountES,
      ...MovementES,
      ...ErrorMessageES,
      ...PartnerViewES,
    },
  },
}
export default lang
