import { CSSProperties } from 'react'
import { Form, Row, Col, TimePicker, FormInstance } from 'antd'
import { STYLE_INPUT_ANTD } from 'components/organisms/Reserves/Reserve/ReserveForm'
import useYachtsPage from 'hooks/useYachtsPageContext'
import { useTranslation } from 'react-i18next'
import { FORMAT_HOUR } from 'utils/reserves'

const STYLE_TITLE: CSSProperties = {
  fontWeight: 'bold',
  textAlign: 'center',
  paddingBottom: 18,
}

type Props = {
  allowEdition: boolean
  form: FormInstance
}

const Title = ({ title }) => (
  <Col xs={23} style={STYLE_TITLE}>
    {title}
  </Col>
)

export const YachtAvailableHoursInputs = ({ allowEdition, form }: Props) => {
  const { t } = useTranslation()
  const { memberships } = useYachtsPage()

  const validEndHour = (name: string) => ({
    validator(_, value) {
      if (value?.startOf('hour') <= form.getFieldValue(name)?.startOf('hour')) {
        return Promise.reject(t('reserve.form.error.end_hour'))
      }
      return Promise.resolve()
    },
  })

  const validStartHour = (name: string) => ({
    validator(_, value) {
      if (value?.startOf('hour') >= form.getFieldValue(name)?.startOf('hour')) {
        return Promise.reject(t('reserve.form.error.start_hour'))
      }
      return Promise.resolve()
    },
  })

  return (
    <Row gutter={[12, 12]}>
      <Col lg={12}>
        <Row gutter={12}>
          <Title title={t('yachts.form.public_hour')} />
          <Col xs={12}>
            <Form.Item
              label={t('yachts.form.start_hour')}
              name="public_start_hour"
              rules={[
                {
                  required: true,
                  message: t('yachts.form.required.hour'),
                },
                validStartHour('public_end_hour'),
              ]}
            >
              <TimePicker
                disabled={!allowEdition}
                format={FORMAT_HOUR}
                style={STYLE_INPUT_ANTD}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={t('yachts.form.end_hour')}
              name="public_end_hour"
              rules={[
                {
                  required: true,
                  message: t('yachts.form.required.hour'),
                },
                validEndHour('public_start_hour'),
              ]}
            >
              <TimePicker
                disabled={!allowEdition}
                format={FORMAT_HOUR}
                style={STYLE_INPUT_ANTD}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col lg={12}>
        {memberships.map(({ id, name }) => (
          <Row gutter={12} key={id}>
            <Title title={t('yachts.form.hour', { replace: { name } })} />
            <Col xs={12}>
              <Form.Item
                label={t('yachts.form.start_hour')}
                name={`${name}_start_hour`}
                rules={[
                  {
                    required: true,
                    message: t('yachts.form.required.hour'),
                  },
                  validStartHour(`${name}_end_hour`),
                ]}
              >
                <TimePicker
                  disabled={!allowEdition}
                  format={FORMAT_HOUR}
                  style={STYLE_INPUT_ANTD}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label={t('yachts.form.end_hour')}
                name={`${name}_end_hour`}
                rules={[
                  {
                    required: true,
                    message: t('yachts.form.required.hour'),
                  },
                  validEndHour(`${name}_start_hour`),
                ]}
              >
                <TimePicker
                  disabled={!allowEdition}
                  format={FORMAT_HOUR}
                  style={STYLE_INPUT_ANTD}
                />
              </Form.Item>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  )
}
