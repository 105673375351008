import { User } from 'api/User/declarations'
import { formatDate } from 'utils/common'

export const formatUserList = (users: User[]): Array<User & any> =>
  users.map(
    ({
      id,
      profile,
      country,
      business,
      sale_room,
      created_at,
      customer,
      ...rest
    }: User) => ({
      key: id,
      id,
      phone: profile?.phone,
      hear_about_us: profile?.hear_about_us,
      country: country?.name,
      name: business?.name,
      sale_room: sale_room?.name,
      customer: customer?.business?.name,
      address: profile.address,
      register_date: formatDate(created_at),
      birthdate: formatDate(profile?.birthdate),
      sale_date: formatDate(profile?.sale_date),
      membership_expiration: formatDate(profile.membership_expiration),
      location: business?.location,
      sales_room: business?.sales_room,
      convertors: business?.convertors,
      memberships: business?.memberships,
      business: business,
      ...rest,
    })
  )
