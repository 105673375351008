import AdditionalsPageContext from 'components/context/AdditionalsPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useAdditionalsPage = () => {
  const contextName = 'AdditionalsPageContext'
  const contextValue = useContext(AdditionalsPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useAdditionalsPage
