import { Form, Row, Col, Input, Checkbox, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import useMovementsPageContext from 'hooks/useMovementsPageContext'
import { Movement } from 'api/Movements/declarations'
import { useEffect, useState } from 'react'
import { scrollToTopFullscreenModal } from 'utils/scroll'
import { FormFooter } from 'components/molecules/Form/FormFooter'
import PriceInput from 'components/molecules/PriceInput'
import { MovementTypeSelector } from './Selector/MovementTypeSelector'
import { InvoiceStatusSelector } from './Selector/InvoiceStatusSelector'
import { When } from 'react-if'
import { formatDate, getStartOfDayForDate } from 'utils/common'
import { FileUploader } from 'components/molecules/FileUploader/FileUploader'
import { isNullOrUndefined } from 'util'

const DESCRIPTION_TEX_AREA = { max: 200, height: 100 }

export const STYLE_INPUT_NUMBER = { width: '100%' }

export const MovementsForm = () => {
  const { t } = useTranslation()
  const {
    submitMovementsForm: onSubmit,
    movementEdit,
    bankAccount,
    loading,
    onUpload,
  } = useMovementsPageContext()
  const has_invoice = movementEdit?.has_invoice || false
  const [form] = Form.useForm()
  const [allowEdition, setAllowEdition] = useState(!movementEdit)
  const [hasInvoice, setHasInvoice] = useState<boolean>(has_invoice)

  const onChangeHasInvoice = (event) => {
    setHasInvoice(event?.target?.checked as boolean)
  }

  useEffect(() => {
    if (allowEdition) {
      scrollToTopFullscreenModal()
    }
  }, [allowEdition])

  useEffect(() => {
    resetForm()
  }, [movementEdit, form])

  const toggleAllowEdition = () => setAllowEdition(!allowEdition)

  const onCancelEdition = () => {
    resetForm()
    setAllowEdition(false)
  }

  const resetForm = () => {
    if (movementEdit) {
      form.setFieldsValue(getInitialValues())
    }
  }

  const onFinish = (values: Movement) => {
    const { date } = values
    let body: Movement = {
      ...values,
      bank_account_id: bankAccount.id,
      date: getStartOfDayForDate(date),
    }
    onSubmit(body)
  }

  const getInitialValues = () => {
    const { amount, type, provider, concept, description, invoice_status } =
      movementEdit ?? {}
    const initialDate = movementEdit?.date ?? new Date()
    const date = formatDate(initialDate)

    return {
      amount,
      type,
      provider,
      concept,
      description,
      has_invoice,
      invoice_status,
      date,
    }
  }

  const initialValues = getInitialValues()

  return (
    <Form onFinish={onFinish} form={form} initialValues={initialValues}>
      <Row>
        <Col xs={24}>
          <Form.Item
            name="type"
            label={t('movements.table.movementType')}
            rules={[
              {
                required: true,
                message: t('movements.form.required.movementType'),
              },
            ]}
          >
            <MovementTypeSelector disabled={!allowEdition} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="provider"
            label={t('movements.table.provider')}
            rules={[
              {
                required: true,
                message: t('movements.form.required.provider.'),
              },
            ]}
          >
            <Input disabled={!allowEdition} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="amount"
            label={t('movements.table.amount')}
            rules={[
              {
                required: true,
                message: t('movements.form.required.mount'),
              },
            ]}
          >
            <PriceInput
              disabled={!allowEdition}
              min={1}
              style={STYLE_INPUT_NUMBER}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="concept"
            label={t('movements.table.concept')}
            rules={[
              {
                required: true,
                message: t('movements.form.required.concept'),
              },
            ]}
          >
            <Input disabled={!allowEdition} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="date"
            label={t('movements.table.date')}
            rules={[
              {
                type: 'date',
                message: t('form.error.date'),
              },
              {
                required: true,
                message: t('movements.form.required.date'),
              },
            ]}
          >
            <Input disabled={!allowEdition} type="date" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="description" label={t('table.row.description')}>
            <TextArea
              maxLength={DESCRIPTION_TEX_AREA.max}
              style={{ height: DESCRIPTION_TEX_AREA.height }}
              disabled={!allowEdition}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="has_invoice"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: t('movements.form.required.invoice'),
              },
            ]}
          >
            <Checkbox disabled={!allowEdition} onChange={onChangeHasInvoice}>
              {t('movements.form.invoice')}
            </Checkbox>
          </Form.Item>
        </Col>
        <When condition={hasInvoice}>
          <Col xs={24}>
            <Form.Item
              name="invoice_status"
              label={t('movements.table.invoiceStatus')}
              rules={[
                {
                  required: true,
                  message: t('movements.form.required.invoiceStatus'),
                },
              ]}
            >
              <InvoiceStatusSelector disabled={!allowEdition} />
            </Form.Item>
          </Col>
        </When>
        <When condition={!isNullOrUndefined(movementEdit)}>
          <Divider>{t('movements.form.div.file')}</Divider>
          <Col xs={24} style={{ paddingBottom: '5%' }}>
            <FileUploader
              urlFile={movementEdit?.file}
              disabled={!allowEdition}
              onUpload={onUpload}
            />
          </Col>
        </When>

        <Col xs={24}>
          <FormFooter
            allowEdition={allowEdition}
            loading={loading}
            toggleAllowEdition={toggleAllowEdition}
            onCancelEdition={onCancelEdition}
            dataForm={movementEdit}
          />
        </Col>
      </Row>
    </Form>
  )
}
