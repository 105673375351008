import styled from 'styled-components'

const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  padding-bottom: 25px;
`

export { FiltersContainer }
