import { Card } from 'antd'
import { CalendarPageProvider } from 'components/context/CalendarPageContext'
import { LocationsProvider } from 'components/context/LocationsContext'
import { MembershipsProvider } from 'components/context/MembershipsContext'
import { YachtsProvider } from 'components/context/YachtsContext'
import MainLayout from 'components/layouts/MainLayout'
import { Calendar } from 'components/organisms/Calendar/Calendar'
import { CalendarTitle } from 'components/organisms/Calendar/CalendarTitle'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import { EMenuKeys } from 'shared/constants/menuElements'

const CalendarPage: React.FC<WithUserProps & RouteComponentProps> = (props) => (
  <MainLayout {...props} active={EMenuKeys.CALENDAR} showTitle={false}>
    <LocationsProvider withoutImages>
      <YachtsProvider withoutImages onlyDropdownData>
        <MembershipsProvider withoutImages>
          <CalendarPageProvider>
            <CalendarTitle />
            <Card>
              <Calendar />
            </Card>
          </CalendarPageProvider>
        </MembershipsProvider>
      </YachtsProvider>
    </LocationsProvider>
  </MainLayout>
)

export default CalendarPage
