import { FC, useEffect, useState } from 'react'
import ConvertorsPageContext, {
  IConvertorsPageContext,
  IEditFormState,
} from './ConvertorsPageContext'
import useDisclosure from 'hooks/useDisclosure'
import ConvertorService from 'api/Convertor'
import type {
  Convertor,
  ICreateConvertor,
  GetConvertorsParams,
} from 'api/Convertor/declarations'
import { SortDirection } from 'interfaces/shared'
import tryFunction from 'utils/try'

const ConvertorsPageProvider: FC = ({ children }) => {
  const [convertorsParams, setConvertorsParams] = useState<GetConvertorsParams>(
    {
      per_page: 10,
      page: 1,
    }
  )
  const [convertors, setConvertors] = useState<Convertor[]>([])
  const [convertorsCount, setConvertorsCount] = useState(0)
  const [isLoadingConvertors, setIsLoadingConvertors] = useState(false)
  const {
    isOpen: createConvertorFormIsOpen,
    onClose: onCloseCreateConvertorForm,
    onOpen: onOpenCreateConvertorForm,
  } = useDisclosure()
  const [editFormState, setEditFormState] = useState<IEditFormState>({
    isOpen: false,
  })
  const [nameSort, setNameSort] = useState<SortDirection | undefined>(undefined)

  const updateConvertors = () => {
    tryFunction(async () => {
      const { count, data } = await ConvertorService.findWithPagination({
        ...convertorsParams,
        sort: { name_en: nameSort },
      })
      setConvertors(data)
      setConvertorsCount(count)
    }, setIsLoadingConvertors)
  }

  useEffect(() => {
    updateConvertors()
  }, [nameSort, convertorsParams])

  const onPageChange = (pageNumber: number) => {
    setConvertorsParams({ ...convertorsParams, page: pageNumber })
  }

  const onCloseEditConvertorForm = () =>
    setEditFormState({ ...editFormState, isOpen: false })

  const onOpenEditConvertorForm = (convertor: Convertor) =>
    setEditFormState({ convertor, isOpen: true })

  const onCreateConvertor = async (body: ICreateConvertor) => {
    await ConvertorService.createConvertor(body)
    updateConvertors()
    onCloseCreateConvertorForm()
  }

  const onUpdateConvertor = async (body: ICreateConvertor) => {
    const convertorId = editFormState.convertor?.id
    await ConvertorService.updateConvertor(convertorId, body)
    updateConvertors()
    onCloseEditConvertorForm()
  }

  const onDeleteConvertor = (convertor: Convertor) => {
    tryFunction(
      async () => {
        await ConvertorService.deleteConvertor(convertor.id)
        updateConvertors()
      },
      () => {},
      true
    )
  }

  const contextValue: IConvertorsPageContext = {
    convertors,
    createConvertorFormIsOpen,
    onCloseCreateConvertorForm,
    onOpenCreateConvertorForm,
    editFormState,
    onOpenEditConvertorForm,
    onCloseEditConvertorForm,
    setNameSort,
    nameSort,
    onCreateConvertor,
    onUpdateConvertor,
    onDeleteConvertor,
    convertorsCount,
    isLoadingConvertors,
    onPageChange,
    convertorsParams,
  }

  return (
    <ConvertorsPageContext.Provider value={contextValue}>
      {children}
    </ConvertorsPageContext.Provider>
  )
}

export { ConvertorsPageProvider }
