import { FC } from 'react'
import styled, { css } from 'styled-components'
import { Modal, ModalProps } from 'antd'

interface ICustomModal {
  maxWidth?: string
  height?: string
  padding?: string
}

const CustomModal = styled(Modal)<ICustomModal>(
  ({ maxWidth = '90vw', height = '90vh', padding = '5% 10%' }) => css`
    margin: 0px;
    padding-bottom: 0px;
    top: 0px;
    transform-origin: 0px 0px;
    width: unset !important;
    z-index: 5;

    & .ant-modal-content {
      height: ${height};
      max-height: 1080px;
      max-width: 2000px;
      overflow: hidden;
      width: ${maxWidth};
    }

    & .ant-modal-body {
      height: 100%;
      overflow-y: scroll;
      padding: ${padding};
    }
  `
)

type IFullscreenModal = ModalProps & ICustomModal & { loading: boolean }

const FullscreenModal: FC<IFullscreenModal> = ({
  children,
  loading = false,
  maskClosable = true,
  ...modalProps
}) => (
  <CustomModal
    {...modalProps}
    centered
    footer={null}
    closable={!loading}
    maskClosable={maskClosable && !loading}
  >
    {children}
  </CustomModal>
)

export default FullscreenModal
export type { IFullscreenModal }
