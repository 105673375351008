import { Form, Row, Col, InputNumber } from 'antd'
import useYachtsPage from 'hooks/useYachtsPageContext'
import { useTranslation } from 'react-i18next'
import { STYLE_INPUT_NUMBER } from './YachtForm'

type Props = {
  allowEdition: boolean
}

export const YachtServicesInputs = ({ allowEdition }: Props) => {
  const { t } = useTranslation()
  const { services } = useYachtsPage()

  return (
    <Row gutter={[12, 12]}>
      <Col lg={12}>
        <Form.Item
          label={t('yachts.form.bathrooms_number')}
          name="bathrooms_number"
          rules={[
            {
              required: false,
              message: t('yachts.form.required.bathrooms_number'),
            },
          ]}
        >
          <InputNumber
            type="number"
            disabled={!allowEdition}
            min={0}
            style={STYLE_INPUT_NUMBER}
          />
        </Form.Item>
        <Form.Item
          label={t('yachts.form.bedrooms_number')}
          name="bedrooms_number"
          rules={[
            {
              required: false,
              message: t('yachts.form.required.bedrooms_number'),
            },
          ]}
        >
          <InputNumber
            type="number"
            disabled={!allowEdition}
            min={0}
            style={STYLE_INPUT_NUMBER}
          />
        </Form.Item>
        <Form.Item
          label={t('yachts.form.beds_number')}
          name="beds_number"
          rules={[
            {
              required: false,
              message: t('yachts.form.required.beds_number'),
            },
          ]}
        >
          <InputNumber
            type="number"
            disabled={!allowEdition}
            min={0}
            style={STYLE_INPUT_NUMBER}
          />
        </Form.Item>
      </Col>
      <Col lg={12}>
        {services.map(({ name_en }) => (
          <Form.Item key={name_en} label={name_en} name={name_en}>
            <InputNumber
              type="number"
              disabled={!allowEdition}
              min={1}
              style={STYLE_INPUT_NUMBER}
              placeholder={t('form.placeholder.number')}
            />
          </Form.Item>
        ))}
      </Col>
    </Row>
  )
}
