import { Yacht } from 'api/Yacht/declarations'

export enum EMeasurementUnity {
  FT = 'ft',
  METERS = 'm',
}

export type Size = {
  id?: number
  number?: number
  measurement_unity?: EMeasurementUnity
}
