import { User, UserCreate } from 'api/User/declarations'
import axios from 'axios'
import BaseService from '../BaseService/BaseService'
import { Response } from '../BaseService/declarations'
import { AuthResponse } from './declarations'
import UserService from '../User/User'
import mixpanelService from 'services/mixpanel'

export class AuthService extends BaseService<{}> {
  protected name = 'auth'

  async register(body: UserCreate): Promise<AuthResponse> {
    try {
      const response = await axios.post<Response<AuthResponse>>(
        `${this.url}/${this.name}/register`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as AuthResponse

      if (body.file) {
        await UserService.uploadAvatar(data?.user.id, body.file)
      }

      if (body.contract) {
        await UserService.uploadContract({
          partnerId: String(data?.user.id || ''),
          partnerCode: data?.user.partner_code || '',
          contract: body.contract,
        })
      }

      if (data.user.role === 'partner') {
        mixpanelService.track.newPartnerAdded({
          email: data.user.email,
          name: data.user.first_name + ' ' + data.user.last_name,
          resort: data.user.customer?.first_name,
        })
      } else {
        mixpanelService.track.newResortAdded({
          email: data.user.email,
          name: data.user.first_name + ' ' + data.user.last_name,
        })
      }

      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async login(email: string, password: string): Promise<AuthResponse> {
    try {
      const response = await axios.post<Response<AuthResponse>>(
        `${this.url}/${this.name}/login`,
        {
          email,
          password,
        },
        this.getHeaderLocale()
      )
      const { data: axiosData } = response
      const responseData = axiosData.data as AuthResponse
      const isadmin = responseData.user.role === 'admin'

      mixpanelService.track.userLoggedIn({
        name: responseData.user.first_name + responseData.user.last_name,
        email: responseData.user.email,
        isAdmin: isadmin,
        resort: responseData.user.customer?.first_name || '',
      })

      return responseData
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async logout(): Promise<void> {
    try {
      await axios.post(`${this.url}/${this.name}/logout`, {}, this.getHeaders())
      mixpanelService.track.userLoggedOut()
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async resetPassword(userId: number, password: string, adminPassword: string) {
    const body = {
      userId,
      password,
      adminPassword,
    }
    try {
      const response = await axios.post<Response<User>>(
        `${this.url}/${this.name}/admin-reset`,
        body,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const data = axiosData.data as User
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new AuthService()
export default service
