import axios from 'axios'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from '../BaseService/declarations'
import { Charge, GetChargesParams } from './declarations'
import { sortQuery } from 'utils/common'
import { SortDirection } from 'interfaces/shared'

export class ChargeService extends BaseService<Charge> {
  protected name: string = 'charge'

  async findWithPagination(
    params: GetChargesParams
  ): Promise<PaginatedResponse<Charge>> {
    const sortParams = sortQuery({ processed_at: SortDirection.DESC })
    try {
      const response = await axios.get<Response<Charge[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params: { ...params, sort: sortParams } })
      )
      const data = response.data.data as Charge[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async cancelCharge(chargeId: number) {
    try {
      await axios.post<Response<Charge>>(
        `${this.url}/${this.name}/${chargeId}/cancel`,
        {},
        this.getHeaders()
      )
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new ChargeService()
export default service
