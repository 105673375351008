import { Location } from 'api/Location/declarations'
import { Membership } from 'api/Membership/declarations'
import { Reserve, ReserveParams } from 'api/Reserve/declarations'
import { Yacht } from 'api/Yacht/declarations'
import { createContext } from 'react'
import { Business } from 'api/Business/declarations'
import { SaleRoom } from 'api/SaleRoom/declaration'

interface IReservesPageContext {
  loading: boolean
  dateReserves: Date
  reserves: Reserve[]
  count: number
  params: ReserveParams
  locations: Location[]
  yachts: Yacht[]
  memberships: Membership[]
  setParams: (params: ReserveParams) => void
  setLocationId: (locationId?: number) => void
  onChangeDate: (date) => void
  onChangeFilter: (params: ReserveParams) => void
  onChangePage: (page: number) => void
  createReserve: () => void
  viewReserve: (reserve: Reserve) => void
  cancelReserve: (reserve: Reserve) => void
  businesses: Business[]
  saleRooms: SaleRoom[]
}

const ReservesContext = createContext<IReservesPageContext | undefined>(
  undefined
)

export default ReservesContext
export type { IReservesPageContext }
