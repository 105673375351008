export enum ELocationStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SOON = 'soon',
}

export interface Location {
  id: number
  name: string
  status: ELocationStatus
  image_id?: number
}

export interface ICreateLocation {
  name: string
  status: ELocationStatus
}

export type FindLocationParams = {
  without_images?: boolean
}
