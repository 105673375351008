import i18n from 'i18next'
import { EditButton, TableHeader } from 'components/atoms/Table'
import { Avatar } from 'components/molecules/Avatar'
import { PropsActions } from './UserColums'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Col, Modal, Row } from 'antd'
import { SeeReserervesButton } from 'components/atoms/Table/ActionButtons/SeeReservesButton'
import { PartnerStatusSelector } from '../Selector/PartnerStatusSelector'
import mixpanelService from 'services/mixpanel'
import SeeBeneficiariesButton from 'components/atoms/Table/ActionButtons/SeeBeneficiariesButton'
import { BeneficiariesParams } from 'pages/PartnerView/PartnerView'
import routes from 'shared/constants/routes'
import { useHistory } from 'react-router-dom'

const { confirm } = Modal

type Props = {
  isAdmin: boolean
} & PropsActions

export const PartnerColumns = ({
  isAdmin,
  userSort,
  onChangeUserSort,
  onEditUser,
  onReservesView,
  onChangePartnerStatus,
}: Props) => {
  const { partnerView } = routes
  const navigate = useHistory()

  const columns = [
    {
      title: <TableHeader>{i18n.t('users.table.row.avatar')}</TableHeader>,
      dataIndex: 'avatar',
      render: (text: string, record) => {
        const onClickEdit = () => onEditUser(record)
        return <Avatar url={record.avatar} onEdit={onClickEdit} />
      },
    },
    {
      title: <TableHeader>{i18n.t('users.table.row.status')}</TableHeader>,
      dataIndex: 'partner_status',
      key: 'partner_status',
      render: (text: string, record) => {
        const onChange = (status) => {
          confirm({
            icon: <ExclamationCircleOutlined />,
            content: i18n.t('users.confirm.status'),
            onOk() {
              mixpanelService.track.partnerStatusModified()
              onChangePartnerStatus(record, status)
            },
          })
        }
        return (
          <PartnerStatusSelector
            onChange={onChange}
            style={{ width: 120 }}
            value={record?.partner_status}
            isForm
          />
        )
      },
    },
    {
      title: (
        <TableHeader
          name="first_name"
          onChange={onChangeUserSort}
          sort={userSort}
          showSort
        >
          {i18n.t('users.table.row.name')}
        </TableHeader>
      ),
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: (
        <TableHeader
          name="last_name"
          onChange={onChangeUserSort}
          sort={userSort}
          showSort
        >
          {i18n.t('users.table.row.last_name')}
        </TableHeader>
      ),
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: (
        <TableHeader
          name="email"
          onChange={onChangeUserSort}
          sort={userSort}
          showSort
        >
          {i18n.t('users.table.row.email')}
        </TableHeader>
      ),
      dataIndex: 'email',
      key: 'email',
      render: (text: string, record) =>
        record.email ===
        `${record.partner_code?.replace(' ', '')}@temporal-email.com`
          ? ''
          : record.email,
    },
    {
      title: <TableHeader>{i18n.t('users.table.row.phone')}</TableHeader>,
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: <TableHeader>{i18n.t('users.table.row.country')}</TableHeader>,
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: (
        <TableHeader width="100px">
          {i18n.t('users.table.row.sale_room')}
        </TableHeader>
      ),
      dataIndex: 'sale_room',
      key: 'sale_room',
    },
    {
      title: (
        <TableHeader
          name="partner_code"
          onChange={onChangeUserSort}
          sort={userSort}
          showSort
        >
          {i18n.t('users.table.row.partner_code')}
        </TableHeader>
      ),
      dataIndex: 'partner_code',
      key: 'partner_code',
    },
    {
      title: <TableHeader>{i18n.t('users.table.row.membership')}</TableHeader>,
      dataIndex: 'membership',
      render: (text: string, record) => record.membership?.name,
    },
    // A contract column is not useful right how. However, it can be added in the future.
    // We can use the following code to add a column with a link to the contract:
    // This code is missing translations
    // {
    //   title: <TableHeader>{'Contrato'}</TableHeader>,
    //   dataIndex: 'contract_view_url',
    //   key: 'contract_view_url',
    //   render: (url: string) => {
    //     if (url) {
    //       return (
    //         // Renderiza un enlace con el `href` apuntando a `url`
    //         <a href={url} target="_blank" rel="noopener noreferrer">
    //           Ver Contrato
    //         </a>
    //       )
    //     }
    //     return '' // Si no hay URL, muestra un valor vacío
    //   },
    // },
    {
      title: (
        <TableHeader
          name="created_at"
          onChange={onChangeUserSort}
          sort={userSort}
          showSort
        >
          {i18n.t('users.table.row.register_date')}
        </TableHeader>
      ),
      dataIndex: 'register_date',
      key: 'register_date',
    },
    {
      title: <TableHeader>{i18n.t('users.table.row.sale_date')}</TableHeader>,
      dataIndex: 'sale_date',
      key: 'sale_date',
    },
    {
      title: (
        <TableHeader>
          {i18n.t('users.table.row.membership_expiration')}
        </TableHeader>
      ),
      dataIndex: 'membership_expiration',
      key: 'membership_expiration',
    },
    {
      title: (
        <TableHeader>{i18n.t('users.table.row.hear_about_us')}</TableHeader>
      ),
      dataIndex: 'hear_about_us',
      key: 'hear_about_us',
    },

    {
      title: (
        <TableHeader width="100px">
          {i18n.t('users.table.row.options')}
        </TableHeader>
      ),
      key: 'options',
      fixed: 'right',
      render: (text: string, record) => {
        const onClickEdit = () => onEditUser(record)
        const onClickReservesView = () => {
          mixpanelService.track.viewPartnerReserves({
            section: 'Upcoming',
          })
          onReservesView(record)
        }

        const handleBeneficiariesButtonClick = (props: BeneficiariesParams) => {
          const state = {
            first_name: props.first_name,
            last_name: props.last_name,
            email: props.email,
          }

          navigate.push({ pathname: partnerView(props.partnerId), state })
        }

        return (
          <>
            <Row gutter={16}>
              <Col>
                <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />

                <SeeReserervesButton
                  text={i18n.t('table.view.reserves')}
                  onClick={onClickReservesView}
                />
                <SeeBeneficiariesButton
                  text={i18n.t('table.beneficiaries')}
                  onClick={() =>
                    handleBeneficiariesButtonClick({
                      partnerId: record.id,
                      first_name: record.first_name,
                      last_name: record.last_name,
                      email: record.email,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        )
      },
    },
  ]
  if (!isAdmin) {
    return columns
  }
  const customerRowIndex = 7
  return [
    ...columns.slice(0, customerRowIndex - 1),
    {
      title: (
        <TableHeader width="100px">
          {i18n.t('users.table.row.customer')}
        </TableHeader>
      ),
      dataIndex: 'customer',
      key: 'customer',
    },
    ...columns.slice(customerRowIndex, columns.length),
  ]
}
