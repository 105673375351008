import React from 'react'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { EMenuKeys } from 'shared/constants/menuElements'
import { MovementsPageProvider } from 'components/context/MovementsPageContext/MovementsPageProvider'
import { MovementsTable } from 'components/organisms/Movements/MovementsTable'
import MovementsFormModal from 'components/organisms/Movements/MovementFormModal'

const MovementsPage: React.FC<WithUserProps & RouteComponentProps> = (
  props
) => (
  <MainLayout {...props} active={EMenuKeys.MOVEMENTS} showTitle={false}>
    <MovementsPageProvider>
      <MovementsTable />
      <MovementsFormModal />
    </MovementsPageProvider>
  </MainLayout>
)

export default MovementsPage
