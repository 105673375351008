import { Select, SelectProps } from 'antd'
import { Yacht } from 'api/Yacht/declarations'
import { filterOptions } from 'utils/common'

type Props = {
  yachts: Yacht[]
} & SelectProps<'tag'>

export const YachtSelector = ({ yachts, ...props }: Props) => {
  const yachts_options = yachts.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  return (
    <Select options={yachts_options} {...props} filterOption={filterOptions} />
  )
}
