import { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { SortDirection } from 'interfaces/shared'
import { When } from 'react-if'
import { TableIconSort } from './TableIconSort'

type Props = {
  width?: string
}
const TableHeaderStyled = styled.div<Props>(
  ({ width = 'max-content' }) => css`
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: ${width};
  `
)

type TableHeader = {
  showSort?: boolean
  name?: string
  sort?
  onChange?: (key: string, direction?: SortDirection) => void
  children
} & Props

const TableHeader: FC<TableHeader> = ({
  children,
  showSort,
  name,
  sort,
  onChange,
  ...props
}: TableHeader) => {
  return (
    <TableHeaderStyled {...props}>
      {children}
      <When condition={showSort}>
        <TableIconSort sort={sort} name={name} onChange={onChange} />
      </When>
    </TableHeaderStyled>
  )
}
export { TableHeader }
