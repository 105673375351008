import AdditionalService from 'api/Additional'
import { Additional } from 'api/Additional/declarations'
import { FC, useState } from 'react'
import AdditionalsContext, { IAdditionalsContext } from './AdditionalsContext'

const AdditionalsProvider: FC = ({ children }) => {
  const [additionals, setAdditionals] = useState<Additional[]>([])

  const getAdditionals = async () => {
    const additionals = await AdditionalService.find()
    setAdditionals(additionals)
  }

  const contextValue: IAdditionalsContext = {
    additionals,
    getAdditionals,
  }

  return (
    <AdditionalsContext.Provider value={contextValue}>
      {children}
    </AdditionalsContext.Provider>
  )
}

export { AdditionalsProvider }
