import React from 'react'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { EMenuKeys } from 'shared/constants/menuElements'
import { ReserveForm } from 'components/organisms/Reserves/Reserve/ReserveForm'
import { ReseservesHeader } from 'components/organisms/Reserves/Reserve/ReserveHeader'
import { ReservePageProvider } from 'components/context/ReservePageContext'
import { LocationsProvider } from 'components/context/LocationsContext'
import { AdditionalsProvider } from 'components/context/AdditionalsContext'
import { YachtsProvider } from 'components/context/YachtsContext'

const ReservePage: React.FC<WithUserProps & RouteComponentProps> = (props) => (
  <MainLayout {...props} showTitle={false} active={EMenuKeys.RESERVE}>
    <LocationsProvider>
      <YachtsProvider>
        <AdditionalsProvider>
          <ReservePageProvider>
            <ReseservesHeader />
            <ReserveForm />
          </ReservePageProvider>
        </AdditionalsProvider>
      </YachtsProvider>
    </LocationsProvider>
  </MainLayout>
)

export default ReservePage
