import { Form, Row, Col, Input, Divider, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import useBankAccountPageContext from 'hooks/useBankAccountPageContext'
import { BankAccount } from 'api/BankAccount/declarations'
import { useEffect, useState } from 'react'
import { scrollToTopFullscreenModal } from 'utils/scroll'
import { FormFooter } from 'components/molecules/Form/FormFooter'

const DESCRIPTION_TEX_AREA = { max: 200, height: 100 }

export const STYLE_INPUT_NUMBER = { width: '100%' }

type IBankAccountFormValues = {} & BankAccount

export const BankAccountForm = () => {
  const { t } = useTranslation()
  const {
    submitBankAccountForm: onSubmit,
    bankAccountEdit,
    loading,
  } = useBankAccountPageContext()
  const [form] = Form.useForm()
  const [allowEdition, setAllowEdition] = useState(!bankAccountEdit)

  useEffect(() => {
    if (allowEdition) {
      scrollToTopFullscreenModal()
    }
  }, [allowEdition])

  useEffect(() => {
    resetForm()
  }, [bankAccountEdit, form])

  const toggleAllowEdition = () => setAllowEdition(!allowEdition)

  const onCancelEdition = () => {
    resetForm()
    setAllowEdition(false)
  }

  const resetForm = () => {
    if (bankAccountEdit) {
      form.setFieldsValue(bankAccountEdit)
    }
  }

  const getInitialValues = () => {
    const { name, account_number, description } = bankAccountEdit ?? {}

    return {
      name,
      description,
      account_number,
    }
  }

  const initialValues = getInitialValues()

  const onFinish = (values: IBankAccountFormValues) => {
    onSubmit(values as BankAccount)
  }

  return (
    <Form onFinish={onFinish} form={form} initialValues={initialValues}>
      <Row>
        <Col xs={24}>
          <Form.Item
            name="name"
            label={t('table.row.name')}
            rules={[
              {
                required: true,
                message: t('bankAccount.form.required.name'),
              },
            ]}
          >
            <Input disabled={!allowEdition} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="account_number"
            label={t('bankAccount.table.account_number')}
            rules={[
              {
                required: true,
                message: t('bankAccount.form.required.accountNumber'),
              },
            ]}
          >
            <Input disabled={!allowEdition} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item name="description" label={t('table.row.description')}>
            <TextArea
              maxLength={DESCRIPTION_TEX_AREA.max}
              style={{ height: DESCRIPTION_TEX_AREA.height }}
              disabled={!allowEdition}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <FormFooter
            allowEdition={allowEdition}
            loading={loading}
            toggleAllowEdition={toggleAllowEdition}
            onCancelEdition={onCancelEdition}
            dataForm={bankAccountEdit}
          />
        </Col>
      </Row>
    </Form>
  )
}
