import mixpanel from 'mixpanel-browser'
import AuthFlowEvents from './events/AuthFlowEvents'
import PartnersFlowEvens from './events/PartnersFlowEvens'
import ResortsFlowEvents from './events/ResortsFlowEvents'
import CalendarFlowEvens from './events/CalendarFlowEvens'
import EnterPageEvents from './events/EnterPageEvents'

const mixpanelKey = process.env.REACT_APP_MIXPANEL_KEY

const MixpanelService = () => {
  const authFlowEvents = AuthFlowEvents()
  const partnersFlowEvens = PartnersFlowEvens()
  const resortsFlowEvents = ResortsFlowEvents()
  const calendarFlowEvens = CalendarFlowEvens()
  const enterPageEvents = EnterPageEvents()
  let idenfified = false

  const init = () => {
    mixpanel.init(mixpanelKey, { ignore_dnt: true })
  }

  const trackEvent = (eventName: string, properties?: any) => {
    mixpanel.track(eventName, properties)
  }

  const identify = (email, name, isAdmin, resort) => {
    if (!idenfified) {
      mixpanel.identify(email)

      mixpanel.people.set({
        $email: email,
        $name: name,
        IsAdmin: isAdmin,
        Resort: resort,
      })

      idenfified = true
    }
  }

  return {
    init,
    trackEvent,
    identify,
    track: {
      ...authFlowEvents,
      ...partnersFlowEvens,
      ...resortsFlowEvents,
      ...calendarFlowEvens,
      ...enterPageEvents,
    },
  }
}

const mixpanelService = MixpanelService()
export default mixpanelService
