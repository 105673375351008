import { Typography, Divider, Space } from 'antd'
import { EPaymentType } from 'api/Charge/declarations'
import FullscreenModal from 'components/molecules/FullscreenModal'
import useChargesPageContext from 'hooks/useChargesPageContext'
import { useTranslation } from 'react-i18next'
import { When } from 'react-if'
import { formatPrice } from 'utils/common'
import getChargePaymentType from 'utils/getChargePaymentType'
import getChargeStatus from 'utils/getChargeStatus'
import getFormattedDate from 'utils/getFormattedDate'

const { Title, Text } = Typography

const ChargeDetailsModal = () => {
  const { t } = useTranslation()
  const { chargeDetailsModalState, onCloseChargeDetailsModal } =
    useChargesPageContext()
  const { isOpen, charge } = chargeDetailsModalState

  return (
    <FullscreenModal
      visible={isOpen}
      onCancel={onCloseChargeDetailsModal}
      maxWidth="450px"
      destroyOnClose
      height="fit-content"
      loading={false}
    >
      <Title level={4}>{t('charge.details.title')}</Title>
      <Space direction="vertical">
        <Text>
          <Text strong>Id</Text>: {charge?.id}
        </Text>
        <Text>
          <Text strong>{t('charge.details.amount')}</Text>:{' '}
          {formatPrice(charge?.paid.toString())}
        </Text>
        <Text>
          <Text strong>{t('charge.details.status')}</Text>:{' '}
          {getChargeStatus(charge?.status)}
        </Text>
        <Text>
          <Text strong>{t('charge.details.date')}</Text>:{' '}
          {getFormattedDate(charge?.processed_at)}
        </Text>
      </Space>
      <Divider>{t('charge.details.customer')}</Divider>
      <Space direction="vertical">
        <Text>
          <Text strong>{t('charge.details.customer-name')}</Text>:{' '}
          {charge?.customer_name}
        </Text>
        <When condition={Boolean(charge?.customer_email)}>
          <Space direction="vertical">
            <Text>
              <Text strong>{t('charge.details.customer-email')}</Text>:{' '}
              {charge?.customer_email}
            </Text>
          </Space>
        </When>
      </Space>
      <Divider>{t('charge.details.payment-method')}</Divider>
      <Space direction="vertical">
        <Text>
          <Text strong>{t('charge.details.payment-method-type')}</Text>:{' '}
          {getChargePaymentType(charge?.payment_type)}
        </Text>
        <When condition={charge?.payment_type === EPaymentType.CARD}>
          <Space direction="vertical">
            <Text>
              <Text strong>{t('charge.details.card-brand')}</Text>:{' '}
              {charge?.card_brand}
            </Text>
            <Text>
              <Text strong>{t('charge.details.card-last-4')}</Text>:{' '}
              {charge?.card_last4}
            </Text>
          </Space>
        </When>
        <When condition={Boolean(charge?.payment_id)}>
          <Space direction="vertical">
            <Text>
              <Text strong>{t('charge.details.stripe-reference')}</Text>:{' '}
              {charge?.payment_id}
            </Text>
          </Space>
        </When>
        <Text>
          <Text strong>{t('charge.details.concept')}</Text>: {charge?.concept}
        </Text>
      </Space>
    </FullscreenModal>
  )
}

export { ChargeDetailsModal }
