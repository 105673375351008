import { Else, If, Then } from 'react-if'
import { Avatar as AvatarAtnd } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { ActionContainer } from 'components/atoms/ActionContainer'

type Props = {
  url?: string
  size?: number
  disabled?: boolean
  onEdit?: () => void
}

export const Avatar = ({
  url,
  size = 50,
  disabled = false,
  onEdit = () => {},
}: Props) => {
  return (
    <ActionContainer onClick={onEdit} disabled={disabled}>
      <If condition={url}>
        <Then>
          <AvatarAtnd src={url} size={size} />
        </Then>
        <Else>
          <AvatarAtnd icon={<UserOutlined />} size={size} />
        </Else>
      </If>
    </ActionContainer>
  )
}
