import styled from 'styled-components'
import Logo from 'assets/logo.svg'
import Devices from 'utils/devices'

export const NavbarLogo = styled.img.attrs({
  src: Logo,
})`
  filter: brightness(0) invert(1);
  float: left;
  width: 150px;

  @media ${Devices.laptop} {
  }
`
