import React from 'react'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { EMenuKeys } from 'shared/constants/menuElements'
import { AdditionalsPageProvider } from 'components/context/AdditionalsPageContext'
import AdditionalsTable from 'components/organisms/Additionals/AdditionalsTable/AdditionalsTable'
import { AdditionalFormModal } from 'components/organisms/Additionals/AdditionalFormModal/AdditionalFormModal'
import { AdditionalHeader } from 'components/organisms/Additionals/AdditionalHeader/AdditionalHeader'
import { ImagesFormModal } from 'components/organisms/Additionals/ImagesFormModal/ImagesFormModal'

const AdditionalsPage: React.FC<WithUserProps & RouteComponentProps> = (
  props
) => (
  <MainLayout {...props} active={EMenuKeys.ADDITIONALS}>
    <AdditionalsPageProvider>
      <AdditionalHeader />
      <AdditionalsTable />
      <AdditionalFormModal />
      <ImagesFormModal />
    </AdditionalsPageProvider>
  </MainLayout>
)

export default AdditionalsPage
