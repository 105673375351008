import showMessage, { NoticeType } from 'utils/notifications'
import i18n from 'i18next'

const tryFunction = async (
  doneFunction: () => Promise<void>,
  setLoading: (loading: boolean) => void = () => {},
  showSuccesMessage: boolean = false,
  title_sucess: string = i18n.t('form.alert.success.title'),
  message_sucess: string = i18n.t('form.alert.success.message'),
  errorFunction: () => void = () => {},
  finalFunction: () => void = () => {},
  loading = true
) => {
  try {
    setLoading(loading)
    await doneFunction()
    if (showSuccesMessage) {
      showMessage(title_sucess, message_sucess, NoticeType.SUCCESS)
    }
  } catch (error) {
    showMessage('Error', error.message, NoticeType.ERROR)
    errorFunction()
  } finally {
    setLoading(false)
    finalFunction()
  }
}

export default tryFunction
