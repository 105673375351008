import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import useYachtsPage from 'hooks/useYachtsPageContext'
import { YachtsColumns } from './YachtsColumns'
import { useEffect } from 'react'
import mixpanelService from 'services/mixpanel'

const BoatsTable = () => {
  const {
    loading,
    params,
    yachts,
    count,
    onActive,
    onDelete,
    onChangePage,
    handledOpenYachtModalForm,
    handledOpenImagesModalForm,
    handledOpenMapModalForm,
  } = useYachtsPage()
  const dataSource = yachts.map((yacht) => ({
    key: yacht.id,
    ...yacht,
  }))

  useEffect(() => {
    mixpanelService.track.userEnterYachtsPage()
  }, [])

  return (
    <YachtsTable
      loading={loading}
      dataSource={dataSource}
      count={count}
      pagination={{ ...params }}
      type={ETypeTable.BOATS}
      onChangePage={onChangePage}
      columns={YachtsColumns({
        handledOpenYachtModalForm,
        handledOpenMapModalForm,
        handledOpenImagesModalForm,
        onActive,
        onDelete,
      })}
    />
  )
}

export default BoatsTable
