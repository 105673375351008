import FullscreenModal from 'components/molecules/FullscreenModal'
import useAdditionalsPage from 'hooks/useAdditionalsPageContext'
import { AdditonalForm } from './AdditionalForm/AdditonalForm'

export const AdditionalFormModal = () => {
  const {
    showModal,
    loading,
    handledCloseAdditionalModalForm: onCancel,
  } = useAdditionalsPage()
  return (
    <FullscreenModal
      visible={showModal.additionalForm}
      loading={loading}
      onCancel={onCancel}
      maskClosable={false}
      maxWidth="800px"
      destroyOnClose
    >
      <AdditonalForm />
    </FullscreenModal>
  )
}
