import axios from 'axios'
import BaseService from '../BaseService/BaseService'
import { Response } from '../BaseService/declarations'
import { Beneficiary, BeneficiaryRequestParams } from './declarations'

export class BeneficiariesService extends BaseService<{}> {
  protected name = 'beneficiary'

  async updateBeneficiary(
    id: string,
    params: BeneficiaryRequestParams
  ): Promise<Beneficiary> {
    try {
      const response = await axios.put<Response<Beneficiary>>(
        `${this.url}/${this.name}/${id}`,
        params,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const responseData = axiosData.data as Beneficiary
      return responseData
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async removeBeneficiary(id: string): Promise<Beneficiary> {
    try {
      const response = await axios.delete<Response<Beneficiary>>(
        `${this.url}/${this.name}/${id}`,
        this.getHeaders()
      )
      const { data: axiosData } = response
      const responseData = axiosData.data as Beneficiary

      return responseData
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async createBeneficiary(
    params: BeneficiaryRequestParams
  ): Promise<Beneficiary> {
    try {
      const response = await axios.post<Response<Beneficiary>>(
        `${this.url}/${this.name}`,
        params,
        this.getHeaders()
      )

      const { data: axiosData } = response
      const responseData = axiosData.data as Beneficiary
      return responseData
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new BeneficiariesService()
export default service
