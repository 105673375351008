import { User } from 'api/User/declarations'
import { createContext } from 'react'

interface IMainLayoutContext {
  user: User
  isAdmin: boolean
  user_id: number
}

const MainLayoutContext = createContext<IMainLayoutContext | undefined>(
  undefined
)

export default MainLayoutContext
export type { IMainLayoutContext }
