import axios from 'axios'
import { sortQuery } from 'utils/common'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from '../BaseService/declarations'
import {
  ICreateConvertor,
  Convertor,
  GetConvertorsParams,
} from './declarations'

export class ConvertorService extends BaseService<Convertor> {
  protected name: string = 'convertor'

  async find(): Promise<Convertor[]> {
    try {
      const response = await axios.get<Response<Convertor[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders()
      )
      const data = response.data.data as Convertor[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async findWithPagination(
    params?: GetConvertorsParams
  ): Promise<PaginatedResponse<Convertor>> {
    const sortParams = sortQuery(params?.sort)
    try {
      const response = await axios.get<Response<Convertor[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params: { ...params, sort: sortParams } })
      )
      const data = response.data.data as Convertor[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async createConvertor(body: ICreateConvertor) {
    try {
      const response = await axios.post<Response<Convertor>>(
        `${this.url}/${this.name}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as Convertor
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async updateConvertor(convertorId: number, body: Partial<ICreateConvertor>) {
    try {
      const response = await axios.put<Response<Convertor>>(
        `${this.url}/${this.name}/${convertorId}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as Convertor
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async deleteConvertor(convertorId: number) {
    try {
      const response = await axios.delete<Response<Convertor>>(
        `${this.url}/${this.name}/${convertorId}`,
        this.getHeaders()
      )
      const data = response.data.data as Convertor
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new ConvertorService()
export default service
