import { Col, Row } from 'antd'
import { ICalendarEvent } from 'api/Reserve/declarations'
import TextCalendar from 'components/atoms/Calendar'
import { useTranslation } from 'react-i18next'
import { When } from 'react-if'

type CalendarEventViewProps = {
  event: ICalendarEvent
}

export const CalendarEventView = ({ event }: CalendarEventViewProps) => {
  const { t } = useTranslation()
  const { id, number_reserves, number_pre_reserves, isBlocked } = event
  return (
    <Row key={id}>
      <When condition={number_reserves > 0}>
        <Col xs={24}>
          <TextCalendar>
            {t('calendar.number_reserves')}:<span>{number_reserves}</span>
          </TextCalendar>
        </Col>
      </When>
      <Col xs={24}>
        <When condition={number_pre_reserves > 0}>
          <TextCalendar>
            {t('calendar.number_pre_reserves')}:
            <span>{number_pre_reserves}</span>
          </TextCalendar>
        </When>
      </Col>
      <When condition={isBlocked}>
        <Col xs={24}>
          <TextCalendar>{t('calendar.event.blocked')}</TextCalendar>
        </Col>
      </When>
    </Row>
  )
}
