import mixpanel from 'mixpanel-browser'

interface ITrackResortAdded {
  email: string
  name: string
}

interface ITrackResortsReportExportRequested {
  search: string
}

const ResortsFlowEvents = () => {
  const userEnterResortsPage = () => {
    mixpanel.track('Enter Resorts Page')
  }

  const newResortAdded = ({ email, name }: ITrackResortAdded) => {
    mixpanel.track('New Resort Added', { Email: email, Name: name })
  }

  const resortsReportExportRequested = ({
    search,
  }: ITrackResortsReportExportRequested) => {
    mixpanel.track('Resorts Report Export Requested', {
      Applied_filters: search,
    })
  }

  const resortsReportExported = () => {
    mixpanel.track('Resorts Report Exported')
  }

  const resortInfoEdited = () => {
    mixpanel.track('Resort Info Edited')
  }

  return {
    userEnterResortsPage,
    newResortAdded,
    resortsReportExportRequested,
    resortsReportExported,
    resortInfoEdited,
  }
}

export default ResortsFlowEvents
