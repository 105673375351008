import FullscreenModal from 'components/molecules/FullscreenModal'
import useMovementsPageContext from 'hooks/useMovementsPageContext'
import { MovementsForm } from './MovementForm'

const MovementsFormModal = () => {
  const { showModal, loading, handledCloseMovementsModalForm } =
    useMovementsPageContext()
  return (
    <FullscreenModal
      visible={showModal.movementForm}
      loading={loading}
      maskClosable={false}
      maxWidth="800px"
      padding="10%"
      height="auto"
      destroyOnClose
      onCancel={handledCloseMovementsModalForm}
    >
      <MovementsForm />
    </FullscreenModal>
  )
}

export default MovementsFormModal
