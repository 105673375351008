import { FormMap, IPickerPosition } from 'components/molecules/Maps/FormMap'
import FullscreenModal from 'components/molecules/FullscreenModal'
import useYachtsPage from 'hooks/useYachtsPageContext'
import Title from 'components/atoms/Title/Title'

export const MapFormModal = () => {
  const {
    showModal,
    loading,
    yachtEdit,
    handledCloseMapModalForm: onCancel,
    submitUpdateMap: save,
  } = useYachtsPage()
  const position: IPickerPosition = {
    lat: parseFloat(yachtEdit?.latitude),
    lng: parseFloat(yachtEdit?.longitude),
  }
  const location = yachtEdit?.location?.name
  const title = `${yachtEdit?.name}${location ? `- ${location}` : ''}`

  return (
    <FullscreenModal
      visible={showModal.mapForm}
      loading={loading}
      onCancel={onCancel}
      maskClosable={false}
      maxWidth="900px"
      height="fit-content"
      padding="5%"
      destroyOnClose
    >
      <Title>{title}</Title>
      <FormMap
        loading={loading}
        position={position}
        searchBox={location}
        save={save}
      />
    </FullscreenModal>
  )
}
