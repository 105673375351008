import { createContext, Dispatch, SetStateAction } from 'react'
import type {
  Convertor,
  ICreateConvertor,
  GetConvertorsParams,
} from 'api/Convertor/declarations'
import { SortDirection } from 'interfaces/shared'

interface IEditFormState {
  isOpen: boolean
  convertor?: Convertor
}

interface IConvertorsPageContext {
  convertors: Convertor[]
  createConvertorFormIsOpen: boolean
  onOpenCreateConvertorForm: () => void
  onCloseCreateConvertorForm: () => void
  editFormState: IEditFormState
  onCloseEditConvertorForm: () => void
  onOpenEditConvertorForm: (converter: Convertor) => void
  setNameSort: Dispatch<SetStateAction<SortDirection>>
  nameSort: SortDirection
  onCreateConvertor: (body: ICreateConvertor) => Promise<void>
  onUpdateConvertor: (body: ICreateConvertor) => Promise<void>
  onDeleteConvertor: (convertor: Convertor) => void
  convertorsCount: number
  isLoadingConvertors: boolean
  onPageChange: (pageNumber: number) => void
  convertorsParams: GetConvertorsParams
}

const ConvertorsPageContext = createContext<IConvertorsPageContext | undefined>(
  undefined
)

export type { IConvertorsPageContext, IEditFormState }
export default ConvertorsPageContext
