import { Row, Col } from 'antd'
import AppButton from 'components/molecules/AppButton'
import { FiltersContainer } from 'components/atoms/Table'
import { useTranslation } from 'react-i18next'
import useYachtsPage from 'hooks/useYachtsPageContext'
import { YachtSearch } from 'components/atoms/Search/Search'
import { SizeSelector } from '../Selector/SizeSelector'
import { LocationSelector } from 'components/organisms/Location/Selector/LocationSelector'

const style_filter: React.CSSProperties = {
  width: '25%',
}

export const YachtsHeader = () => {
  const { t } = useTranslation()
  const { loading, params, sizes, locations, onChangeFilter, createYacht } =
    useYachtsPage()
  const onChangeSearch = (search) =>
    onChangeFilter({ ...params, search, page: 1 })

  const onChangeLocation = (location_id) =>
    onChangeFilter({ ...params, location_id, page: 1 })

  const onChangeSize = (size_id) =>
    onChangeFilter({ ...params, size_id, page: 1 })

  return (
    <>
      <FiltersContainer>
        <LocationSelector
          locations={locations}
          placeholder={t('yachts.placeholder.locations')}
          onChange={onChangeLocation}
          style={style_filter}
          allowClear
        />
        <SizeSelector
          sizes={sizes}
          placeholder={t('yachts.placeholder.size')}
          onChange={onChangeSize}
          style={style_filter}
          allowClear
        />
      </FiltersContainer>
      <Row justify="space-between" style={{ marginBottom: 25 }}>
        <Col xs={12}>
          <YachtSearch
            placeholder={t('yachts.placeholder.search')}
            onSearch={onChangeSearch}
            allowClear
          />
        </Col>
        <Col>
          <Row gutter={16}>
            <Col>
              <AppButton loading={loading} onClick={createYacht}>
                {t(`yachts.button.create`)}
              </AppButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
