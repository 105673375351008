import SaleRoomService from 'api/SaleRoom'
import { SaleRoom } from 'api/SaleRoom/declaration'
import FullscreenModal from 'components/molecules/FullscreenModal'
import useUsersPage from 'hooks/useUsersPageContext'
import { useEffect, useState } from 'react'
import tryFunction from 'utils/try'
import { PartnersTable } from './SalesRoomTable/SalesRoomTable'

const initialParams = {
  page: 1,
  per_page: 5,
}

export const SalesRoomModal = () => {
  const {
    showModal,
    userEdit: user,
    handledCloseSalesRoomViewModal: onCancel,
  } = useUsersPage()
  const { salesRoomView: visible } = showModal
  const [rooms, setRooms] = useState<SaleRoom[]>([])
  const [sale_room, setSaleRoom] = useState<SaleRoom>()
  const [count, setCount] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [params, setParams] = useState(initialParams)
  const business_id = user?.business?.id

  useEffect(() => {
    if (visible || refresh) {
      getPartners()
    } else if (rooms?.length || refresh) {
      resetStates()
    }
    setRefresh(false)
  }, [visible, params, refresh])

  const resetStates = () => {
    setRooms([])
    setCount(0)
    setParams(initialParams)
  }

  const getPartners = async () => {
    tryFunction(async () => {
      const { data: rooms, count } = await SaleRoomService.paginate({
        ...params,
        business_id,
      })
      setRooms(rooms)
      setCount(count)
    }, setLoading)
  }

  const onSubmit = (room) => {
    tryFunction(
      async () => {
        if (sale_room) {
          await SaleRoomService.update(sale_room.id, room)
        } else {
          await SaleRoomService.create({ ...room, business_id })
        }
        setSaleRoom(undefined)
        setRefresh(true)
      },
      setLoading,
      true
    )
  }

  const onDelete = (sale_room: SaleRoom) => {
    tryFunction(
      async () => {
        await SaleRoomService.delete(sale_room.id)
        setRefresh(true)
      },
      setLoading,
      true
    )
  }
  const onChangePage = (page: number) => {
    setParams({ ...params, page })
  }

  return (
    <FullscreenModal
      visible={visible}
      loading={loading}
      onCancel={onCancel}
      maxWidth="900px"
      destroyOnClose
    >
      <PartnersTable
        loading={loading}
        rooms={rooms}
        params={params}
        count={count}
        sale_room={sale_room}
        onChangePage={onChangePage}
        onSubmit={onSubmit}
        onDelete={onDelete}
        setSaleRoom={setSaleRoom}
      />
    </FullscreenModal>
  )
}
