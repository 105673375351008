import BlockedDatesPageContext from 'components/context/BlockedDatesPageContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useBlockedDatesPageContext = () => {
  const contextName = 'BlockedDatesPageContext'
  const contextValue = useContext(BlockedDatesPageContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useBlockedDatesPageContext
