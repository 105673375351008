import { Form, Input, Row, Col, Button, Divider, Space } from 'antd'
import ImageUploader, {
  IUploadedImage,
} from 'components/molecules/ImageUploader'
import { useState, useEffect } from 'react'
import IImageData from 'interfaces/shared'
import { useTranslation } from 'react-i18next'
import PriceInput from 'components/molecules/PriceInput'
import { ImageList } from 'components/molecules/ImageList'
import { UploadRules } from 'components/molecules/UploadRules'
import tryFunction from 'utils/try'

const maxNumberOfImages = 3
const suggestedWidth = '1920px'
const suggestedHeight = '1080px'

interface IMembershipFormValues {
  name: string
  price: number
  images: IImageData[]
  description_en?: string
  description_es?: string
}

interface IMembershipForm {
  initialValues?: Partial<IMembershipFormValues>
  isEditionForm?: boolean
  onSubmit?: (
    values: IMembershipFormValues,
    images: IImageData[]
  ) => Promise<void>
}

const MembershipForm = ({
  initialValues,
  isEditionForm = false,
  onSubmit = async (values, images) => {},
}: IMembershipForm) => {
  const { t } = useTranslation()
  const [imagesData, setImagesData] = useState<IImageData[]>(
    initialValues?.images || []
  )
  const [disableUploadButton, setDisableUploadButton] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitButtonTranslationKey = `locations.form.${
    isEditionForm ? 'edit' : 'create'
  }-submit`

  useEffect(() => {
    setDisableUploadButton(imagesData.length === maxNumberOfImages)
  }, [imagesData])

  const onImageUpload = (imageData: IUploadedImage) => {
    if (imagesData.length < maxNumberOfImages) {
      setImagesData(
        imagesData.concat({
          ...imageData,
          alt: imageData.url,
          id: imageData.file.uid,
        })
      )
    }
  }

  const onRemoveImage = (imageId: number | string) => {
    const newImages = imagesData.filter((image) => image.id !== imageId)
    setImagesData(newImages)
  }

  const onEditImage = (newImage: IUploadedImage, newImageId: number) => {
    const newImagesData = imagesData.map((image) => {
      if (image.id === newImageId) {
        return {
          ...newImage,
          alt: newImage.url,
          id: newImage.file.uid,
        }
      }
      return image
    })
    setImagesData(newImagesData)
  }

  const onFinish = async (values: IMembershipFormValues) => {
    tryFunction(
      async () => {
        await onSubmit(values, imagesData)
      },
      setIsSubmitting,
      true
    )
  }

  return (
    <Form initialValues={initialValues} onFinish={onFinish}>
      <Row>
        <Col xs={24}>
          <Divider>{t('locations.form.section.general')}</Divider>
          <Form.Item
            name="name"
            label={t('locations.form.field.name')}
            rules={[{ required: true, message: t('form.required.name') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label={t('additionals.form.public_price')}
            rules={[
              { required: true, message: t('additionals.form.required.price') },
            ]}
          >
            <PriceInput min={1} style={{ width: '100%' }} />
          </Form.Item>
          <Divider>{t('memberships.form.section.description')} (en)</Divider>
          <Form.Item name="description_en">
            <Input.TextArea maxLength={500} rows={7} />
          </Form.Item>
          <Divider>{t('memberships.form.section.description')} (es)</Divider>
          <Form.Item name="description_es">
            <Input.TextArea maxLength={500} rows={7} />
          </Form.Item>
          <Divider>{t('memberships.form.section.images')}*</Divider>
          <div
            style={{
              margin: '0 auto',
              width: 'fit-content',
              marginBottom: '50px',
            }}
          >
            <Form.Item
              name="images"
              rules={[{ required: true, message: t('form.required.image') }]}
            >
              <Space align="center" size={16} direction="vertical">
                <Row gutter={[32, 32]} justify="space-between">
                  <ImageList
                    images={imagesData}
                    useFavorite={false}
                    onDelete={onRemoveImage}
                    onEdit={onEditImage}
                  />
                </Row>
                <UploadRules
                  imagesNumber={maxNumberOfImages}
                  width={suggestedWidth}
                  height={suggestedHeight}
                />
                <ImageUploader
                  disabled={disableUploadButton}
                  onImageLoaded={onImageUpload}
                />
              </Space>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col offset={16} style={{ width: '100%' }}>
          <Button type="primary" block htmlType="submit" loading={isSubmitting}>
            {t(submitButtonTranslationKey)}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { MembershipForm }
