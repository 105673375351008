export default {
  LIGHT_BLUE: '#61dafb',
  GRAY_BLUE: '#282c34',
  AQUA: '#00BFBF',
  AQUA_DARK: '#004040',
  BACKGROUND_GRAY: '#F7F7F8',
  BEIGE_BORDER: '#F1E7D1',
  BLACK_BROWNISH: '#262424',
  BLACK: 'black',
  BOX_SHADOW: '#00000029',
  BROWN_BUTTON: '#BBA88E',
  GRADIENT_DOTTED_LINES:
    'linear-gradient(to right, black 28%, rgba(255,255,255,0) 0%)',
  WHITE: 'white',
  DANGER: 'red',
  SUCCESS: 'green',
}
