import React, { useEffect, useState } from 'react'
import GradiantBackground from 'components/atoms/GradientBackground/GradientBackground'
import COLORS from 'shared/constants/colors'
import LoginForm from 'components/molecules/LoginForm'
import CenterVerticallyContainer from 'components/molecules/CenterVerticallyContainer'
import StaticImage from 'components/atoms/StaticImage/StaticImage'
import { AuthRequest, AuthResponse } from 'api/Auth/declarations'
import showMessage, { NoticeType } from 'utils/notifications'
import AuthService from 'api/Auth'
import useToken from 'hooks/useToken'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import routes from 'shared/constants/routes'
import withUser, { WithUserProps } from 'hooks/withUser'
import BackGround from 'assets/login-bg.jpeg'

const { BOX_SHADOW } = COLORS
const IMAGE_OPACITY = 0.75

const LoginPage: React.FC<RouteComponentProps & WithUserProps> = ({
  history,
  setUser = () => true,
  clearUser = () => true,
}) => {
  const [loading, setLoading] = useState(false)
  const { setToken, clearToken } = useToken()

  async function handleSubmit({ email, password }: AuthRequest) {
    clearUser()
    clearToken()
    try {
      setLoading(true)
      const response: AuthResponse = await AuthService.login(email, password)
      const { refresh_token, token, user } = response
      setToken({
        token,
        refresh_token,
      })
      setUser(user)
      setTimeout(() => {
        showMessage('Correcto', 'Has iniciado sesión correctamente.')
        setLoading(false)

        // This is beacuse a rare behavior with the react router
        window.location.href = routes.home
      }, 1000)
    } catch (error) {
      showMessage('Error', error.message, NoticeType.ERROR)
      setLoading(false)
    }
  }

  return (
    <>
      <StaticImage
        src={BackGround}
        opacity={IMAGE_OPACITY}
        height="100%"
        width="100%"
      />
      <GradiantBackground firstColor={BOX_SHADOW} secondColor={BOX_SHADOW}>
        <CenterVerticallyContainer>
          <LoginForm onSubmit={handleSubmit} loading={loading} />
        </CenterVerticallyContainer>
      </GradiantBackground>
    </>
  )
}

export default withRouter(withUser(LoginPage))
