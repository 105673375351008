import { createContext, Dispatch, SetStateAction } from 'react'
import type {
  IBlockedDate,
  GetBlockedDatesParams,
  ICreateBlockedDate,
  IUpdateBlockedDate,
} from 'api/BlockedDate/declarations'
import { SortDirection } from 'interfaces/shared'
import { Location } from 'api/Location/declarations'
import { Membership } from 'api/Membership/declarations'
import { Yacht } from 'api/Yacht/declarations'

interface IEditFormState {
  isOpen: boolean
  blockedDate?: IBlockedDate
}

interface IBlockedDatesPageContext {
  blockedDates: IBlockedDate[]
  createBlockedDateFormIsOpen: boolean
  onOpenCreateBlockedDateForm: () => void
  onCloseCreateBlockedDateForm: () => void
  setDateSort: Dispatch<SetStateAction<SortDirection>>
  dateSort: SortDirection
  onCreateBlockedDate: (body: ICreateBlockedDate) => Promise<void>
  onDeleteBlockedDate: (blockedDate: IBlockedDate) => void
  blockedDatesCount: number
  isLoadingBlockedDates: boolean
  onPageChange: (pageNumber: number) => void
  blockedDatesParams: GetBlockedDatesParams
  editBlockedDateFormState: IEditFormState
  onOpenEditBlockedDateForm: (blockedDate: IBlockedDate) => void
  onCloseEditBlockedDateForm: () => void
  onUpdateBlockedDate: (body: IUpdateBlockedDate) => Promise<void>
  locations: Location[]
  memberships: Membership[]
  yachts: Yacht[]
}

const BlockedDatesPageContext = createContext<
  IBlockedDatesPageContext | undefined
>(undefined)

export type { IBlockedDatesPageContext, IEditFormState }
export default BlockedDatesPageContext
