import { User } from 'api/User/declarations'
import { FC } from 'react'
import { isAdmin } from 'utils/user'
import MainLayoutContext, { IMainLayoutContext } from './MainLayoutContext'

type Props = {
  user: User
  children
}
const MainLayoutProvider = ({ user, children }: Props) => {
  const { id: user_id, role } = user

  const contextValue: IMainLayoutContext = {
    user,
    user_id,
    isAdmin: isAdmin(role),
  }

  return (
    <MainLayoutContext.Provider value={contextValue}>
      {children}
    </MainLayoutContext.Provider>
  )
}

export { MainLayoutProvider }
