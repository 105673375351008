import { FC, useEffect, useState } from 'react'
import scrollToTop from 'utils/scroll'
import tryFunction from 'utils/try'
import { Service, ServiceParams } from 'api/Service/declarations'
import ServicesPageContext, {
  IServicesPageContext,
  ServiceShowModal,
} from './ServicesPageContext'
import ServiceService from 'api/Service'

const initialParams: ServiceParams = {
  page: 1,
  per_page: 9,
}

const initialShowModal: ServiceShowModal = {
  serviceForm: false,
  imagesForm: false,
}

const ServicesPageProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [services, setService] = useState<Service[]>([])
  const [serviceEdit, setServiceEdit] = useState<Service>(undefined)
  const [params, setparams] = useState<ServiceParams>(initialParams)
  const [showModal, setShowModal] = useState<ServiceShowModal>(initialShowModal)

  useEffect(() => {
    if (refresh) {
      loadData()
      setRefresh(false)
    }
  }, [params, showModal, , serviceEdit])

  useEffect(() => {
    if (serviceEdit) {
      updateModalserviceEdit()
    }
  }, [services])

  const loadData = () => {
    tryFunction(async () => {
      await getService()
      scrollToTop()
    }, setLoading)
  }

  const getService = async () => {
    const { data: service, count } = await ServiceService.paginate(params)
    setService(service)
    setCount(count)
  }

  const submitServiceForm = (data: Service) => {
    tryFunction(
      async () => {
        if (serviceEdit) {
          await ServiceService.update(serviceEdit.id, data)
        } else {
          await ServiceService.create(data)
        }
        setRefresh(true)
        handledCloseServiceModalForm()
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onDelete = (service: Service) => {
    tryFunction(
      async () => {
        setServiceEdit(service)
        await ServiceService.delete(service.id)
        setRefresh(true)
        setServiceEdit(undefined)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onUploadImage = (image, image_id?: number) => {
    tryFunction(
      async () => {
        const { id: service_id } = serviceEdit
        await ServiceService.uploadImage(service_id, image, image_id)
        await getService()
        setRefresh(true)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const onDeleteImage = (image_id?: number) => {
    tryFunction(
      async () => {
        const { id: service_id } = serviceEdit
        await ServiceService.deleteImage(service_id, image_id)
        await getService()
        setRefresh(true)
        scrollToTop()
      },
      setLoading,
      true
    )
  }

  const updateModalserviceEdit = () => {
    const { id: service_id } = serviceEdit
    const serviceUpdate = services.find(({ id }) => id === service_id)
    if (serviceUpdate) {
      setServiceEdit(serviceUpdate)
    }
  }

  const onChangePage = (page: number) => {
    setRefresh(true)
    setparams({ ...params, page })
  }

  const handledOpenServiceModalForm = (service?: Service) => {
    setServiceEdit(service)
    setShowModal({ ...showModal, serviceForm: true })
  }

  const handledCloseServiceModalForm = () => {
    setServiceEdit(undefined)
    setShowModal({ ...showModal, serviceForm: false })
  }

  const handledOpenImagesModalForm = (service: Service) => {
    setServiceEdit(service)
    setShowModal({ ...showModal, imagesForm: true })
  }

  const handledCloseImagesModalForm = () => {
    setServiceEdit(undefined)
    setShowModal({ ...showModal, imagesForm: false })
  }

  const createService = () => {
    handledOpenServiceModalForm()
  }

  const contextValue: IServicesPageContext = {
    loading,
    serviceEdit,
    count,
    params,
    services,
    showModal,
    submitServiceForm,
    onDelete,
    onChangePage,
    handledOpenServiceModalForm,
    handledCloseServiceModalForm,
    handledOpenImagesModalForm,
    handledCloseImagesModalForm,
    createService,
    onUploadImage,
    onDeleteImage,
  }

  return (
    <ServicesPageContext.Provider value={contextValue}>
      {children}
    </ServicesPageContext.Provider>
  )
}

export { ServicesPageProvider }
