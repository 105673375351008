import FullscreenModal from 'components/molecules/FullscreenModal'
import useAdditionalsPage from 'hooks/useAdditionalsPageContext'
import { ImagesForm } from './ImagesForm/ImagesForm'

export const ImagesFormModal = () => {
  const {
    showModal,
    loading,
    handledCloseImagesModalForm: onCancel,
  } = useAdditionalsPage()
  return (
    <FullscreenModal
      visible={showModal.imagesForm}
      loading={loading}
      onCancel={onCancel}
      maskClosable={false}
      maxWidth="900px"
      destroyOnClose
    >
      <ImagesForm />
    </FullscreenModal>
  )
}
