import i18n from 'i18next'
import { Col, Row } from 'antd'
import { RemoveButton, TableHeader, EditButton } from 'components/atoms/Table'
import { IBlockedDate } from 'api/BlockedDate/declarations'
import getFormattedDate from 'utils/getFormattedDate'
import { SortDirection } from 'interfaces/shared'

type IBlockedDatesTableColumns = {
  onDeleteBlockedDate: (blockedDate: IBlockedDate) => void
  onOpenEditBlockedDateForm: (blockedDate: IBlockedDate) => void
  availableMembershipsIds: number[]
  availableLocationsIds: number[]
  availableYachtsIds: number[]
  dateSort: SortDirection
  onDateSortChange: (key: string, direction: SortDirection) => void
}

export const BlockedDatesTableColumns = ({
  onDeleteBlockedDate,
  onOpenEditBlockedDateForm,
  availableLocationsIds,
  availableMembershipsIds,
  availableYachtsIds,
  dateSort,
  onDateSortChange,
}: IBlockedDatesTableColumns) => [
  {
    title: (
      <TableHeader
        showSort
        sort={{ date: dateSort }}
        onChange={onDateSortChange}
        name="date"
      >
        {i18n.t('blocked-dates.table.date')}
      </TableHeader>
    ),
    key: 'date',
    render: (text: string, { date }: IBlockedDate) => getFormattedDate(date),
  },
  {
    title: <TableHeader>{i18n.t('blocked-dates.table.locations')}</TableHeader>,
    key: 'location',
    render: (text: string, { locations }: IBlockedDate) =>
      locations.length === availableLocationsIds.length
        ? i18n.t('blocked-dates.form.all-option')
        : locations?.map(
            ({ name }, index) =>
              `${name}${index !== locations?.length - 1 ? ', ' : ''}`
          ) || '',
  },
  {
    title: (
      <TableHeader>{i18n.t('blocked-dates.table.memberships')}</TableHeader>
    ),
    key: 'membership',
    render: (text: string, { memberships, membership_free }: IBlockedDate) =>
      memberships.length === availableMembershipsIds.length
        ? i18n.t('blocked-dates.form.all-option')
        : `${membership_free ? i18n.t('memberships.regular') : ''}${
            membership_free && memberships.length ? ', ' : ''
          }${
            memberships?.map(
              ({ name }, index) =>
                `${name}${index !== memberships.length - 1 ? ', ' : ''}`
            ) || ''
          }`,
  },
  {
    title: <TableHeader>{i18n.t('blocked-dates.table.yachts')}</TableHeader>,
    key: 'yacht',
    render: (text: string, { yachts }: IBlockedDate) =>
      yachts.length === availableYachtsIds.length
        ? i18n.t('blocked-dates.form.all-option')
        : yachts?.map(
            ({ name }, index) =>
              `${name}${index !== yachts.length - 1 ? ', ' : ''}`
          ) || '',
  },
  {
    title: <TableHeader>{i18n.t('blocked-dates.table.comment')}</TableHeader>,
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: (
      <TableHeader width="150px">{i18n.t('table.row.options')}</TableHeader>
    ),
    key: 'options',
    render: (text: string, blockedDate: IBlockedDate) => {
      const onDelete = () => onDeleteBlockedDate(blockedDate)
      const onEdit = () => onOpenEditBlockedDateForm(blockedDate)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onEdit} />
          </Col>
          <Col>
            <RemoveButton text={i18n.t('table.delete')} onClick={onDelete} />
          </Col>
        </Row>
      )
    },
  },
]
