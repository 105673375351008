import i18n from 'i18next'
import { EditButton, RemoveButton, TableHeader } from 'components/atoms/Table'
import { Col, Row } from 'antd'
import { SaleRoom } from 'api/SaleRoom/declaration'
import { SaleRoomForm } from '../SaleRoomForm/SaleRoomForm'

export type Props = {
  sale_room?: SaleRoom
  loading: boolean
  setSaleRoom: (sale_room: SaleRoom) => void
  onEdit?: (sale_room: SaleRoom) => void
  onDelete?: (sale_room: SaleRoom) => void
}

export const SalesRoomColumns = ({
  sale_room,
  loading,
  setSaleRoom,
  onEdit,
  onDelete,
}: Props) => [
  {
    title: (
      <TableHeader width="600px">
        {i18n.t('users.table.row.sale_room')}
      </TableHeader>
    ),
    dataIndex: 'name',
    render: (text: string, record) => {
      const isEdit = sale_room && record?.id === sale_room?.id
      const cancelEdit = () => setSaleRoom(undefined)
      return isEdit ? (
        <SaleRoomForm
          loading={loading}
          sale_room={sale_room}
          handleSubmit={onEdit}
          cancelEdit={cancelEdit}
        />
      ) : (
        record.name
      )
    },
  },
  {
    title: (
      <TableHeader width="100px">
        {i18n.t('users.table.row.options')}
      </TableHeader>
    ),
    key: 'options',
    render: (text: string, record) => {
      const onClickEdit = () => setSaleRoom(record)
      const onClickDelete = () => onDelete(record)

      return (
        <Row gutter={16}>
          <Col>
            <EditButton text={i18n.t('table.edit')} onClick={onClickEdit} />
          </Col>
          <Col>
            <RemoveButton
              text={i18n.t('table.delete')}
              onClick={onClickDelete}
            />
          </Col>
        </Row>
      )
    },
  },
]
