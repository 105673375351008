import LocationsContext from 'components/context/LocationsContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useLocationsContext = () => {
  const contextName = 'LocationsContext'
  const contextValue = useContext(LocationsContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useLocationsContext
