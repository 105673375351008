import { Form, Input, InputNumber } from 'antd'
import { Beneficiary } from 'api/Beneficiaries/declarations'

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: InputType
  record: Beneficiary
  index: number
  message: string
  children: React.ReactNode
}

type InputType = 'string' | 'number' | 'email'

const BeneficiariesTableCells: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  message,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber type="number" style={{ width: '100%' }} />
    ) : (
      <Input type={inputType} />
    )

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              type: inputType,
              message: message,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export default BeneficiariesTableCells
