import { EnvironmentOutlined } from '@ant-design/icons'
import { BaseActionButton, IActionButton } from './BaseActionButton'
import COLORS from 'shared/constants/colors'

const { AQUA_DARK } = COLORS

const MapButton = (props: IActionButton) => (
  <BaseActionButton
    {...props}
    icon={<EnvironmentOutlined color={AQUA_DARK} />}
  />
)

export { MapButton }
