import { Row, Col } from 'antd'
import { User } from 'api/User/declarations'
import CenterVertically from 'components/atoms/CenterVertically/CenterVertically'
import Header from 'components/atoms/Header'
import { NavbarLogo } from 'components/atoms/NavbarLogo'
import TextAlign from 'components/atoms/TextAlign/TextAlign'
import { ELanguage } from 'interfaces/i18n'
import colors from 'shared/constants/colors'
import UserDropdown from './UserDropdown'

const { AQUA_DARK } = colors

type Props = {
  loading: boolean
  onLogout?: () => void
  handleChange: (value: ELanguage) => void
}

const AppHeader = ({ loading, onLogout = () => true, handleChange }: Props) => (
  <Header style={{ background: AQUA_DARK }}>
    <Row>
      <Col xs={12}>
        <CenterVertically minHeight={'64px'} justifyContent="initial">
          <NavbarLogo />
        </CenterVertically>
      </Col>
      <Col xs={12}>
        <CenterVertically minHeight={'64px'} justifyContent="initial">
          <TextAlign align="right">
            <UserDropdown
              onLogout={onLogout}
              handleChange={handleChange}
              loading={loading}
            />
          </TextAlign>
        </CenterVertically>
      </Col>
    </Row>
  </Header>
)

export default AppHeader
