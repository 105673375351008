import axios from 'axios'
import BaseService from '../BaseService'
import { Response } from '../BaseService/declarations'
import { Size } from './declarations'

export class SizeService extends BaseService<Size> {
  protected name: string = 'size'

  async find(): Promise<Size[]> {
    try {
      const response = await axios.get<Response<Size[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders()
      )
      const data = response.data.data as Size[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const size = new SizeService()
export default size
