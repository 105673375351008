import axios from 'axios'
import BaseService from '../BaseService'
import { Response } from '../BaseService/declarations'
import { Location, ICreateLocation, FindLocationParams } from './declarations'

export class LocationService extends BaseService<Location> {
  protected name: string = 'location'

  async find(params?: FindLocationParams): Promise<Location[]> {
    try {
      const response = await axios.get<Response<Location[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      const data = response.data.data as Location[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async createLocation(body: ICreateLocation) {
    try {
      const response = await axios.post<Response<Location>>(
        `${this.url}/${this.name}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as Location
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async updateLocation(locationId: number, body: Partial<ICreateLocation>) {
    try {
      const response = await axios.put<Response<Location>>(
        `${this.url}/${this.name}/${locationId}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as Location
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async uploadLocationImage(locationId: number, imageFile: any) {
    try {
      const formData = new FormData()
      formData.append('image', imageFile)
      const response = await axios.post<Response<Location>>(
        `${this.url}/${this.name}/${locationId}/image`,
        formData,
        this.getHeadersMultipart()
      )
      const data = response.data.data as Location
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async deleteLocation(locationId: number) {
    try {
      const response = await axios.delete<Response<Location>>(
        `${this.url}/${this.name}/${locationId}`,
        this.getHeaders()
      )
      const data = response.data.data as Location
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new LocationService()
export default service
