import { createContext } from 'react'
import {
  MovementParams,
  Movement,
  EMovementType,
} from 'api/Movements/declarations'
import { BankAccount } from 'api/BankAccount/declarations'
import { Moment } from 'moment'

export type MovementShowModal = {
  movementForm: boolean
}

interface IMovementsPageContext {
  bankAccount: BankAccount
  movements: Movement[]
  count: number
  loading: boolean
  movementParams: MovementParams
  showModal: MovementShowModal
  movementEdit: Movement
  onPageChange: (pageNumber: number) => void
  submitMovementsForm: (movement: Movement) => void
  handledCloseMovementsModalForm: () => void
  handledOpenMovementsModalForm: (movement: Movement) => void
  createMovement: () => void
  deleteMovement: (movement: Movement) => void
  onUpload: (file) => void
  onMovementTypeFilterChange: (type?: EMovementType) => void
  onDateFilterChange: (dates?: [Moment, Moment]) => void
}

const MovementsPageContext = createContext<IMovementsPageContext>(undefined)

export type { IMovementsPageContext }
export default MovementsPageContext
