import { RcFile } from 'antd/lib/upload'
import { Model, PaginationData } from 'api/BaseService/declarations'
import { Business } from 'api/Business/declarations'
import { Convertor } from 'api/Convertor/declarations'
import { Country } from 'api/Country/declarations'
import { Location } from 'api/Location/declarations'
import { Membership } from 'api/Membership/declarations'
import { SaleRoom } from 'api/SaleRoom/declaration'
import { ELanguage } from 'interfaces/i18n'
import { SortDirection } from 'interfaces/shared'

export interface UploadContractPayload {
  partnerId: string
  partnerCode: string
  contract: RcFile
}

export interface User extends Model {
  id?: number
  first_name: string
  middle_name?: string
  last_name: string
  maternal_last_name?: string
  email: string
  password?: string
  password_change_date?: Date
  active?: boolean
  role?: EUserRole
  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
  profile?: Profile
  partner_status?: EStatusPartner
  partner_code?: string
  country_id?: number
  conuntry?: Country
  customer_id?: number
  sale_room_id?: number
  sale_room?: SaleRoom
  customer?: User
  business?: Business
  membership_id?: number
  membership?: Membership
}

export interface Profile extends Model {
  id: number
  locale: ELanguage
  payment_key?: string
  phone?: string
  birthdate: Date
  time_zone: string
  user_id: number
  address?: string
  hear_about_us?: string
  sale_date?: Date
  membership_expiration?: Date
  created_at: string
  updated_at: string
  deleted_at: string
}

export interface UserProfile extends Model {
  user: User
  profile: Profile
}

export interface ContractURLResponse extends Model {
  url: string
}

export type UsersPaginated = {
  data: User[]
  count: number
}

export type UserUpdate = {
  user: {
    email?: string
    first_name?: string
    middle_name?: string
    last_name?: string
    maternal_last_name?: string
    role?: EUserRole
    partner_status?: EStatusPartner
    partner_code?: string
    country_id?: number
    customer_id?: number
    sale_room_id?: number
    location_id?: number
    convertors_ids?: number[]
    memberships_ids?: number[]
    name?: string
    membership_id?: number
  }
  profile?: {
    phone?: string
    birthdate?: Date
    time_zone?: string
    address?: string
    hear_about_us?: string
    sale_date?: Date
    membership_expiration?: Date
  }
  file?: RcFile
  contract?: RcFile
  contract_view_url?: string
}

export type UserCreate = {
  id?: number
  email?: string
  password?: string
  first_name?: string
  middle_name?: string
  last_name?: string
  maternal_last_name?: string
  role?: EUserRole
  partner_status?: EStatusPartner
  partner_code?: string
  country_id?: number
  customer_id?: number
  membership_id?: number
  sale_room_id?: number
  phone?: string
  birthdate: Date
  time_zone: string
  address?: string
  hear_about_us?: string
  sale_date?: Date
  membership_expiration?: Date
  file?: RcFile
  business?: Business
  name?: string
  avatar?: string
  location?: Location
  convertors?: Convertor[]
  memberships?: Membership[]
  sales_rooms?: SaleRoom[]
  location_id?: number
  convertors_ids: number[]
  memberships_ids: number[]
  active?: boolean
  contract?: RcFile
  contract_view_url?: string
}

export type UserParams = {
  search?: string
  customer_id?: number
  sale_room_id?: number
  membership_id?: number
  partner_status?: EStatusPartner
  role?: EUserRole
  sort?: string
  csv?: boolean
  without_images?: boolean
  sale_date_from?: Date
  sale_date_to?: Date
} & PaginationData

export type UserSort = {
  email?: SortDirection
  first_name?: SortDirection
  last_name?: SortDirection
  partner_code?: SortDirection
  created_at?: SortDirection
}

/**
 * Admin    level 1 -> access panel
 * customer level 2 -> business access limit panel
 * partner   level 3 -> end client, don't access panel
 */
export enum EUserRole {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
  PARTNER = 'partner',
}

/**
 * lead      -> register by the web public,  can reserve
 * pender   -> register by panel, can reserve (with admin or customer credentials)
 * process   -> enable access app web, can reserve and apply price membership
 * cancelled -> disable access app web
 * suspended -> enable access app web but  he doesn't reserver
 */
export enum EStatusPartner {
  LEAD = 'lead',
  PENDER = 'pender',
  PROCESSABLE = 'processable',
  CANCELLED = 'cancelled',
  SUSPENDED = 'suspended',
}

export const STATUS_AVAILABLE_RESERVE = [
  EStatusPartner.LEAD,
  EStatusPartner.PENDER,
  EStatusPartner.PROCESSABLE,
]
