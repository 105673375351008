import { SelectProps, Select } from 'antd'
import moment from 'moment'
import { filterOptions } from 'utils/common'

type Props = {} & SelectProps<'tag'>

export const YearSelector = ({ ...props }: Props) => {
  const yearVisibleNumber = 10
  const year = moment().year()
  const countries_options = [...Array(yearVisibleNumber)].map(
    (data, index) => ({
      label: year - 1 + index,
      value: year - 1 + index,
    })
  )

  return (
    <Select
      options={countries_options}
      {...props}
      filterOption={filterOptions}
    />
  )
}
