import MainLayoutContext from 'components/context/MainLayoutContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useMainLayout = () => {
  const contextName = 'MainLayoutContext'
  const contextValue = useContext(MainLayoutContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useMainLayout
