import axios from 'axios'
import BaseService from '../BaseService'
import { PaginatedResponse, Response } from '../BaseService/declarations'
import { BankAccount, BankAccountsParams, TotalBalance } from './declarations'

export class BankAccountService extends BaseService<BankAccount> {
  protected name: string = 'bank-account'

  async findOne(bank_account_id: number): Promise<BankAccount> {
    try {
      const response = await axios.get<Response<BankAccount>>(
        `${this.url}/${this.name}/${bank_account_id}`,
        this.getHeaders()
      )
      const data = response.data.data as BankAccount
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async find(): Promise<BankAccount[]> {
    try {
      const response = await axios.get<Response<BankAccount[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders()
      )
      const data = response.data.data as BankAccount[]
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async findWithPagination(
    params?: BankAccountsParams
  ): Promise<PaginatedResponse<BankAccount>> {
    try {
      const response = await axios.get<Response<BankAccount[]>>(
        `${this.url}/${this.name}`,
        this.getHeaders({ params })
      )
      const data = response.data.data as BankAccount[]
      const count = Number(response.headers['content-count'])
      return { data, count }
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async create(body: BankAccount): Promise<BankAccount> {
    try {
      const response = await axios.post<Response<BankAccount>>(
        `${this.url}/${this.name}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as BankAccount
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async update(
    bank_account_id: number,
    body: BankAccount
  ): Promise<BankAccount> {
    try {
      const response = await axios.put<Response<BankAccount>>(
        `${this.url}/${this.name}/${bank_account_id}`,
        body,
        this.getHeaders()
      )
      const data = response.data.data as BankAccount
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async delete(bank_account_id: number): Promise<BankAccount> {
    try {
      const response = await axios.delete<Response<BankAccount>>(
        `${this.url}/${this.name}/${bank_account_id}`,
        this.getHeaders()
      )
      const data = response.data.data as BankAccount
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }

  async getTotalBalance(): Promise<TotalBalance> {
    try {
      const response = await axios.get<Response<TotalBalance>>(
        `${this.url}/${this.name}/balance`,
        this.getHeaders()
      )
      const data = response.data.data as TotalBalance
      return data
    } catch (error) {
      throw this.handleError(error)
    }
  }
}

const service = new BankAccountService()
export default service
