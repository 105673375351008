import { SelectProps, Select } from 'antd'
import { Size } from 'api/Size/declarations'
import { filterOptions } from 'utils/common'

type Props = {
  sizes: Size[]
} & SelectProps<'tag'>

export const SizeSelector = ({ sizes, ...props }: Props) => {
  const sizes_options = sizes.map(({ id, number }) => ({
    label: number.toString(),
    value: id,
  }))

  return (
    <Select
      options={sizes_options}
      {...props}
      filterOption={filterOptions}
      showSearch
    />
  )
}
