import { Dropdown, Menu, Button } from 'antd'
import {
  LogoutOutlined,
  DownOutlined,
  CheckOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { NavbarDropdownItem } from 'components/atoms/NavbarDropdownItem'
import { ELanguage } from '../../interfaces/i18n'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import useMainLayout from 'hooks/useAccessUser'

type Props = {
  loading: boolean
  onLogout?: () => void
  handleChange: (value: ELanguage) => void
}

const UserDropdown = ({ loading = false, onLogout, handleChange }: Props) => {
  const { user } = useMainLayout()
  const { i18n } = useTranslation()
  const setES = () => handleChange(ELanguage.ES)
  const setEN = () => handleChange(ELanguage.EN)
  const visibility = (type: ELanguage): CSSProperties => ({
    visibility: type === i18n.language ? 'visible' : 'hidden',
  })

  const menu = (
    <Menu>
      <Menu.Item key="logout">
        <Button type="link" onClick={onLogout}>
          <LogoutOutlined /> Cerrar sesión
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="es">
        <Button type="link" onClick={setES}>
          <CheckOutlined style={visibility(ELanguage.ES)} />
          ES
        </Button>
      </Menu.Item>
      <Menu.Item key="en">
        <Button type="link" onClick={setEN}>
          <CheckOutlined style={visibility(ELanguage.EN)} />
          EN
        </Button>
      </Menu.Item>
    </Menu>
  )

  return user ? (
    <Dropdown overlay={menu} trigger={['click']} disabled={loading}>
      <NavbarDropdownItem>
        {user.first_name} {loading ? <LoadingOutlined /> : <DownOutlined />}
      </NavbarDropdownItem>
    </Dropdown>
  ) : null
}

export default UserDropdown
