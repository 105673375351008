import FullscreenModal from 'components/molecules/FullscreenModal'
import useServicesPage from 'hooks/translate/useServicesPageContext'
import { ImagesForm } from './ImagesForm/ImagesForm'

const ServiceImagesModal = () => {
  const {
    showModal,
    loading,
    handledCloseImagesModalForm: onCancel,
  } = useServicesPage()
  return (
    <FullscreenModal
      visible={showModal.imagesForm}
      loading={loading}
      onCancel={onCancel}
      maskClosable={false}
      maxWidth="900px"
      destroyOnClose
    >
      <ImagesForm />
    </FullscreenModal>
  )
}

export default ServiceImagesModal
