import { UserTokens } from 'hooks/useToken'

export const TOKENS_KEY = 'tokens'

export function getToken(): UserTokens | null {
  const tokenString = localStorage.getItem(TOKENS_KEY)
  if (!tokenString) {
    return null
  }
  const userToken = JSON.parse(tokenString)
  return userToken
}
