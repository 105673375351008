import { Additional, AdditionalPrice } from 'api/Additional/declarations'
import { Membership } from 'api/Membership/declarations'
import { Service } from 'api/Service/declarations'
import { EMeasurementUnity, Size } from 'api/Size/declarations'
import {
  Yacht,
  YachtPrice,
  YachtService,
  YatchAvailableHours,
} from 'api/Yacht/declarations'
import { isNullOrUndefined } from 'util'
import {
  concatPrices,
  formatPrice,
  getDateInHourFormatMoment,
  getHourInDate,
} from './common'

export type Prices = YachtPrice | AdditionalPrice

export const getPublicPriceData = (prices?: Prices[]): Prices => {
  return prices?.find(({ membership }) => isNullOrUndefined(membership))
}

export const getMembershipsPriceData = (prices?: Prices[]): Prices[] => {
  return prices?.filter(({ membership }) => !isNullOrUndefined(membership))
}

export const getPublicHoursData = (
  hours?: YatchAvailableHours[]
): YatchAvailableHours => {
  return hours?.find(({ membership }) => isNullOrUndefined(membership))
}

export const getMembershipsHoursData = (
  hours?: YatchAvailableHours[]
): YatchAvailableHours[] => {
  return hours?.filter(({ membership }) => !isNullOrUndefined(membership))
}

export const getPublicPrice = ({ prices }: Yacht | Additional) => {
  const pucblicPrice = getPublicPriceData(prices)
  return pucblicPrice?.price
    ? formatPrice(pucblicPrice?.price?.toString())
    : 'N/D'
}

export const getMembershipsPrice = ({ prices }: Yacht | Additional) => {
  const membershipsPrice = getMembershipsPriceData(prices)
  return concatPrices(membershipsPrice) || 'N/D'
}

export const getSize = ({ size }: Yacht) => {
  return size?.number || 'N/D'
}

export const getFormPricesForSubmit = (
  dataForm,
  membership: Membership[]
): Prices[] => {
  const { public_price } = dataForm
  let prices: YachtPrice[] = [{ price: public_price, membership_id: undefined }]
  prices = prices.concat(
    membership.map(({ name, id: membership_id }) => {
      const price = dataForm[name]
      delete dataForm[name]
      return { membership_id, price }
    })
  )
  delete dataForm.public_price

  return prices
}

export const getInitialFormValuesPrices = (prices?: Prices[]) => {
  if (!prices) {
    return {}
  }
  const publicPrice = getPublicPriceData(prices)
  const membershipsPrice = getMembershipsPriceData(prices)
  const memberShipsPriceForm = membershipsPrice?.reduce(
    (object: any, { membership, price }) => {
      object[membership?.name] = price
      return object
    },
    {}
  )
  return {
    public_price: publicPrice?.price,
    ...memberShipsPriceForm,
  }
}

export const getFormHoursForSubmit = (
  dataForm,
  membership: Membership[]
): YatchAvailableHours[] => {
  const { public_start_hour, public_end_hour } = dataForm
  let public_hours: YatchAvailableHours[] = [
    {
      start_hour: getHourInDate(public_start_hour),
      end_hour: getHourInDate(public_end_hour),
      membership_id: undefined,
    },
  ]
  public_hours = public_hours.concat(
    membership.map(({ name, id: membership_id }) => {
      const key_start = `${name}_start_hour`
      const key_end = `${name}_end_hour`
      const start_hour = getHourInDate(dataForm[key_start])
      const end_hour = getHourInDate(dataForm[key_end])
      delete dataForm[key_start]
      delete dataForm[key_end]

      return { membership_id, start_hour, end_hour }
    })
  )
  delete dataForm.public_price

  return public_hours
}

export const getInitialFormValuesHours = (
  available_hours?: YatchAvailableHours[]
) => {
  if (!available_hours) {
    return {}
  }
  const publicHours = getPublicHoursData(available_hours)
  const membershipsHours = getMembershipsHoursData(available_hours)
  const memberShipsHoursForm = membershipsHours?.reduce(
    (object: any, { membership, start_hour, end_hour }) => {
      object[`${membership?.name}_start_hour`] = getDateInHourFormatMoment(
        start_hour as string
      )
      object[`${membership?.name}_end_hour`] = getDateInHourFormatMoment(
        end_hour as string
      )
      return object
    },
    {}
  )
  const public_start_hour = getDateInHourFormatMoment(
    publicHours?.start_hour as string
  )
  const public_end_hour = getDateInHourFormatMoment(
    publicHours?.end_hour as string
  )

  return {
    public_start_hour,
    public_end_hour,
    ...memberShipsHoursForm,
  }
}

export const getFormSizeForSubmit = (dataForm): Size => ({
  number: dataForm.size,
  measurement_unity: EMeasurementUnity.FT,
})

export const getInitilaFormValueSize = (size?: Size) => size?.number

export const getFormYachtServicessForSubmit = (
  dataForm,
  services: Service[]
): YachtService[] => {
  let yachtServices: YachtService[] = []
  services.forEach(({ name_en, id: service_id }) => {
    if (dataForm[name_en]) {
      yachtServices.push({ service_id, quantity: dataForm[name_en] })
      delete dataForm[name_en]
    }
  })

  return yachtServices
}

export const getInitialFormValuesServices = (service?: YachtService[]) => {
  if (!service) {
    return {}
  }
  return service?.reduce((object: any, { service, quantity }) => {
    object[service?.name_en] = quantity
    return object
  }, {})
}

export const getInitilaFormValueAdditional = (additional?: Additional[]) =>
  additional?.map(({ id }) => id)

export const getPricePerHourYacht = ({
  yacht,
  membership,
}: {
  yacht: Yacht
  membership?: Membership
}): number => {
  const { prices, name } = yacht
  if (!prices) {
    throw new Error(`There isn't prices in boat ${name}`)
  }
  let priceData: Prices
  if (membership) {
    priceData = prices.find(
      ({ membership_id }) => membership_id === membership.id
    )
    if (!priceData) {
      throw new Error(`There isn't ${membership.name} price in boat ${name}`)
    }
  } else {
    priceData = prices.find(({ membership_id }) =>
      isNullOrUndefined(membership_id)
    )
    if (!priceData) {
      throw new Error(`There isn't public price in boat ${name}`)
    }
  }
  return priceData.price
}

const getPriceByAdditional = ({
  additional,
  membership,
}: {
  additional: Additional
  membership?: Membership
}): number => {
  const { prices, name_en } = additional
  if (!prices) {
    throw new Error(`There isn't prices in additional ${name_en}`)
  }
  let priceData: Prices
  if (membership) {
    priceData = prices.find(
      ({ membership_id }) => membership_id === membership.id
    )
    if (!priceData) {
      throw new Error(
        `There isn't ${membership.name} price in additional ${name_en}`
      )
    }
  } else {
    priceData = prices.find(({ membership_id }) =>
      isNullOrUndefined(membership_id)
    )
    if (!priceData) {
      throw new Error(`There isn't public price in additional ${name_en}`)
    }
  }
  return priceData.price
}

export const getPricePerAdditionals = ({
  additionals,
  membership,
}: {
  additionals: Additional[]
  membership?: Membership
}): number => {
  if (!additionals) {
    return 0
  }
  return additionals.reduce(
    (amount, additional) =>
      amount + getPriceByAdditional({ additional, membership }),
    0
  )
}
