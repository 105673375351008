import { Col, Form, Row } from 'antd'
import Title, { TitleDatePicker } from 'components/atoms/Title/Title'
import AppButton from 'components/molecules/AppButton'
import { BackButton } from 'components/molecules/BackButton'
import { BusinessSelector } from 'components/organisms/Businesses/Selector/BusinessSelector'
import { SaleRoomSelector } from 'components/organisms/Businesses/Selector/SaleRoomSelector'
import { LocationSelector } from 'components/organisms/Location/Selector/LocationSelector'
import { MembershipSelector } from 'components/organisms/Membership/Selector/MembershipSelector'
import { YachtSelector } from 'components/organisms/Yachts/Selector/YachtSelector'
import useReservesPage from 'hooks/ReservesPage'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/common'

export const ReseservesHeader = () => {
  const { t } = useTranslation()
  const {
    dateReserves,
    loading,
    params,
    yachts,
    locations,
    memberships,
    createReserve,
    onChangeDate,
    setParams,
    setLocationId,
    businesses,
    saleRooms,
  } = useReservesPage()
  const onChange = ({ currentTarget }) => onChangeDate(currentTarget.value)
  const onChangeYacht = (yacht_id) => setParams({ ...params, yacht_id })

  const onChangeMembership = (membership_name) =>
    setParams({ ...params, membership_name })

  const onChangeLocation = (location_id) => {
    setLocationId(location_id)
    setParams({ ...params, location_id })
  }

  const onChangeBusiness = (business_id) =>
    setParams({ ...params, business_id })

  const onChangeSaleRoom = (sale_room_id) =>
    setParams({ ...params, sale_room_id })

  const filterStyle = { width: '100%' }

  return (
    <>
      <Title>
        {t('side-menu.reserves')}
        <TitleDatePicker
          type="date"
          value={formatDate(dateReserves)}
          onChange={onChange}
        />
      </Title>
      <Row justify="space-between" style={{ marginBottom: 5 }}>
        <Col xs={20}>
          <Form initialValues={params}>
            <Row gutter={12} style={{ paddingBottom: '10px' }}>
              <Col xs={12}>
                <Form.Item
                  label={t('calendar.filter.label.location')}
                  name="location_id"
                >
                  <LocationSelector
                    loading={loading}
                    locations={locations}
                    style={filterStyle}
                    onChange={onChangeLocation}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t('calendar.filter.label.yacht')}
                  name="yacht_id"
                >
                  <YachtSelector
                    loading={loading}
                    yachts={yachts}
                    style={filterStyle}
                    onChange={onChangeYacht}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t('calendar.filter.label.membership')}
                  name="membership_name"
                >
                  <MembershipSelector
                    keyValue="name"
                    loading={loading}
                    memberships={memberships}
                    style={filterStyle}
                    onChange={onChangeMembership}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t('calendar.filter.label.business')}
                  name="business_id"
                >
                  <BusinessSelector
                    businesses={businesses}
                    allowClear
                    onChange={onChangeBusiness}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label={t('calendar.filter.label.sale-room')}
                  name="sale_room_id"
                >
                  <SaleRoomSelector
                    sales_room={saleRooms}
                    allowClear
                    onChange={onChangeSaleRoom}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col>
          <AppButton loading={loading} onClick={createReserve}>
            {t(`reserves.button.create`)}
          </AppButton>
        </Col>
      </Row>
      <BackButton style={{ paddingLeft: 0 }} />
    </>
  )
}
