import YachtsContext from 'components/context/YachtsContext'
import { useContext } from 'react'
import { validateContextValue } from 'utils/context'

const useYachtsContext = () => {
  const contextName = 'YachtsContext'
  const contextValue = useContext(YachtsContext)
  validateContextValue(contextValue, contextName)
  return contextValue
}

export default useYachtsContext
