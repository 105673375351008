import React from 'react'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import { EMenuKeys } from 'shared/constants/menuElements'
import { YachtsPageProvider } from 'components/context/YachtsPageContext'
import BoatsTable from 'components/organisms/Yachts/YachtsTable/YachtsTable'
import { YachtFormModal } from 'components/organisms/Yachts/YachtFormModal/YachtFormModal'
import { YachtsHeader } from 'components/organisms/Yachts/YachtHeader/YachtHeader'
import { ImagesFormModal } from 'components/organisms/Yachts/ImagesFormModal/ImagesFormModal'
import { MapFormModal } from 'components/organisms/Yachts/MapFormModal/MapFormModal'

const YachtsPage: React.FC<WithUserProps & RouteComponentProps> = (props) => (
  <MainLayout {...props} active={EMenuKeys.BOATS}>
    <YachtsPageProvider>
      <YachtsHeader />
      <BoatsTable />
      <YachtFormModal />
      <ImagesFormModal />
      <MapFormModal />
    </YachtsPageProvider>
  </MainLayout>
)

export default YachtsPage
