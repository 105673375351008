import { EditOutlined } from '@ant-design/icons'
import { BaseActionButton, IActionButton } from './BaseActionButton'
import COLORS from 'shared/constants/colors'

const { AQUA_DARK } = COLORS

const EditButton = (props: IActionButton) => (
  <BaseActionButton {...props} icon={<EditOutlined color={AQUA_DARK} />} />
)

export { EditButton }
