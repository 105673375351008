import { Form, Input, Row, Col, Button, Divider } from 'antd'
import { ELocationStatus } from 'api/Location/declarations'
import ImageUploader, {
  IUploadedImage,
} from 'components/molecules/ImageUploader'
import { useState } from 'react'
import IImageData from 'interfaces/shared'
import { useTranslation } from 'react-i18next'
import { LocationStatusSelect } from 'components/atoms/Form'
import tryFunction from 'utils/try'

interface ILocationFormValues {
  name: string
  status: ELocationStatus
  image: string
}

interface ILocationForm {
  initialValues?: Partial<ILocationFormValues>
  isEditionForm?: boolean
  onSubmit?: (values: ILocationFormValues, image: IImageData) => Promise<void>
}

const defaultInitialValues: Partial<ILocationFormValues> = {
  status: ELocationStatus.ACTIVE,
}

const LocationForm = ({
  initialValues = defaultInitialValues,
  isEditionForm = false,
  onSubmit = async (values, image) => {},
}: ILocationForm) => {
  const { t } = useTranslation()
  const [form] = Form.useForm<ILocationFormValues>()
  const [imageData, setImageData] = useState<IImageData>(
    initialValues.image
      ? { url: initialValues.image, alt: initialValues.image }
      : undefined
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const submitButtonTranslationKey = `locations.form.${
    isEditionForm ? 'edit' : 'create'
  }-submit`

  const onImageUpload = (imageData: IUploadedImage) => {
    setImageData({
      ...imageData,
      url: imageData?.url || '',
      alt: imageData?.file?.name || '',
    })
    form.setFieldsValue({ image: 'added' })
  }

  const onFinish = (values: ILocationFormValues) => {
    tryFunction(
      async () => {
        await onSubmit(values, imageData)
      },
      setIsSubmitting,
      true
    )
  }

  return (
    <Form initialValues={initialValues} onFinish={onFinish} form={form}>
      <Row>
        <Col xs={24}>
          <Divider>{t('locations.form.section.general')}</Divider>
          <Form.Item
            name="name"
            label={t('locations.form.field.name')}
            rules={[{ required: true, message: t('form.required.name') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="status"
            label={t('locations.form.field.status')}
            rules={[{ required: true, message: t('form.required.status') }]}
          >
            <LocationStatusSelect />
          </Form.Item>
          <Divider>{t('locations.form.section.image')}*</Divider>
          <div
            style={{
              margin: '0 auto',
              width: 'fit-content',
              marginBottom: '50px',
            }}
          >
            <Form.Item
              name="image"
              rules={[{ required: true, message: t('form.required.image') }]}
            >
              <ImageUploader
                disabled={false}
                onImageLoaded={onImageUpload}
                imageUrl={imageData?.url}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row>
        <Col offset={16} style={{ width: '100%' }}>
          <Button type="primary" block htmlType="submit" loading={isSubmitting}>
            {t(submitButtonTranslationKey)}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export { LocationForm }
