import { Button } from 'antd'
import {
  useRef,
  useEffect,
  MutableRefObject,
  ChangeEventHandler,
  FormEvent,
} from 'react'
import colors from 'shared/constants/colors'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'

const { AQUA_DARK } = colors

const Form = styled.form`
  border-bottom: 1px solid ${AQUA_DARK};
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr auto;
  padding: 5px 0px;
  width: 100%;
`

const SearchField = styled.input`
  border: none;
  font-size: 16px;
  padding: 5px;
  width: 100%;

  &:focus {
    outline: none;
  }
`

interface ISearchBar {
  placeholder: string
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void
  focus?: boolean
  inputRef?: MutableRefObject<HTMLInputElement>
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const SearchBar = ({
  placeholder,
  onSubmit,
  focus = false,
  inputRef: customInputRef = undefined,
  value,
  onChange = (e) => {},
}: ISearchBar) => {
  const inputRef = useRef<HTMLInputElement>()
  const finalInputRef = customInputRef ? customInputRef : inputRef

  useEffect(() => {
    if (focus) {
      finalInputRef.current.focus()
    }
  }, [])

  return (
    <Form onSubmit={onSubmit}>
      <SearchField
        placeholder={placeholder}
        ref={finalInputRef}
        value={value}
        onChange={onChange}
      />
      <Button
        type="link"
        icon={<SearchOutlined style={{ color: AQUA_DARK }} />}
        htmlType="submit"
      />
    </Form>
  )
}

export { SearchBar }
