import { ChargesPageProvider } from 'components/context/ChargesPageContext'
import MainLayout from 'components/layouts/MainLayout'
import { ChargeDetailsModal, ChargesTable } from 'components/organisms/Charges'
import { WithUserProps } from 'hooks/withUser'
import { RouteComponentProps } from 'react-router'
import { EMenuKeys } from 'shared/constants/menuElements'

const ChargesPage: React.FC<WithUserProps & RouteComponentProps> = (props) => (
  <MainLayout active={EMenuKeys.CHARGES} {...props}>
    <ChargesPageProvider>
      <ChargesTable />
      <ChargeDetailsModal />
    </ChargesPageProvider>
  </MainLayout>
)

export default ChargesPage
