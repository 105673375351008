import { Descriptions } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

type Props = {
  imagesNumber: number
  width: string
  height: string
}

export const UploadRules = ({ imagesNumber, width, height }: Props) => {
  const { t } = useTranslation()
  return (
    <Descriptions
      layout="horizontal"
      title={
        <>
          <InfoCircleOutlined />
          <span style={{ paddingLeft: 12 }}>{t('uploadRules.title')}</span>
        </>
      }
    >
      <Descriptions.Item label={t('uploadRules.images')} span={24}>
        {t('uploadRules.util')} {imagesNumber}.
      </Descriptions.Item>
      <Descriptions.Item label={t('uploadRules.format')} span={24}>
        png, jpeg, jpg.
      </Descriptions.Item>
      <Descriptions.Item label={t('uploadRules.size')} span={24}>
        {t('uploadRules.util')} 5 MB.
      </Descriptions.Item>
      <Descriptions.Item label={t('uploadRules.proportion')} span={24}>
        {`W:${width},  H:${height}`}.
      </Descriptions.Item>
    </Descriptions>
  )
}
