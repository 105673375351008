import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import useAdditionalsPage from 'hooks/useAdditionalsPageContext'
import { AdditionalsColumns } from './AdditionalsColumns'
import { useEffect } from 'react'
import mixpanelService from 'services/mixpanel'

const AdditionalsTable = () => {
  const {
    loading,
    params,
    additionals,
    count,
    onDelete,
    onChangePage,
    handledOpenAdditionalModalForm,
    handledOpenImagesModalForm,
  } = useAdditionalsPage()
  const dataSource = additionals.map((additional) => ({
    key: additional.id,
    ...additional,
  }))

  useEffect(() => {
    mixpanelService.track.userEnterAdditionalPage()
  }, [])

  return (
    <YachtsTable
      loading={loading}
      dataSource={dataSource}
      count={count}
      pagination={params}
      type={ETypeTable.ADDITIONALS}
      onChangePage={onChangePage}
      columns={AdditionalsColumns({
        handledOpenAdditionalModalForm,
        handledOpenImagesModalForm,
        onDelete,
      })}
    />
  )
}

export default AdditionalsTable
