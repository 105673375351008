import { MovementsTableColumns } from './MovementsColumns'
import useMovementPageContext from 'hooks/useMovementsPageContext'
import YachtsTable, { ETypeTable } from 'components/molecules/YachtsTable'
import { Button, Col, Row, Space, Statistic, DatePicker } from 'antd'
import i18n from 'i18next'
import Title from 'components/atoms/Title/Title'
import { useTranslation } from 'react-i18next'
import { EMenuKeys } from 'shared/constants/menuElements'
import { DollarCircleOutlined } from '@ant-design/icons'
import { BackButton } from 'components/molecules/BackButton'
import { MovementTypeSelector } from './Selector/MovementTypeSelector'

const { RangePicker } = DatePicker

const MovementsTable = () => {
  const { t } = useTranslation()
  const {
    movements,
    count,
    loading,
    movementParams,
    bankAccount,
    onPageChange,
    handledOpenMovementsModalForm,
    createMovement,
    deleteMovement,
    onMovementTypeFilterChange,
    onDateFilterChange,
  } = useMovementPageContext()
  const dataSource = movements.map((movement) => ({
    key: movement.id,
    ...movement,
  }))

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Title>
        {t(`side-menu.${EMenuKeys.MOVEMENTS}`)} / {bankAccount?.name}
      </Title>
      <Row justify="space-between" align="middle">
        <Col>
          <Statistic
            title={t('bankAccount.table.balance')}
            prefix={<DollarCircleOutlined />}
            value={bankAccount?.balance}
          />
        </Col>
        <Col>
          <Button onClick={createMovement} type="primary">
            {i18n.t('movements.new-button-label')}
          </Button>
        </Col>
      </Row>
      <BackButton style={{ paddingLeft: 0 }} />
      <Space>
        <RangePicker onChange={onDateFilterChange} />
        <MovementTypeSelector
          style={{ width: '200px' }}
          placeholder={t('movements.filter.type')}
          onChange={onMovementTypeFilterChange}
          allowClear
        />
      </Space>
      <YachtsTable
        loading={loading}
        dataSource={dataSource}
        columns={MovementsTableColumns({
          onDeleteMovement: deleteMovement,
          onOpenEditMovementForm: handledOpenMovementsModalForm,
        })}
        count={count}
        onChangePage={onPageChange}
        type={ETypeTable.MOVEMENTS}
        pagination={movementParams}
      />
    </Space>
  )
}

export { MovementsTable }
