import { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Divider } from 'antd'
import { FormFooter } from 'components/molecules/Form/FormFooter'
import { scrollToTopFullscreenModal } from 'utils/scroll'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import useServicesPage from 'hooks/translate/useServicesPageContext'

const DESCRIPTION_TEX_AREA = { max: 200, height: 100 }

export const STYLE_INPUT_NUMBER = { width: '100%' }

export const ServiceForm = () => {
  const { t } = useTranslation()
  const {
    serviceEdit,
    loading,
    submitServiceForm: onSubmit,
  } = useServicesPage()
  const [form] = Form.useForm()
  const [allowEdition, setAllowEdition] = useState(!serviceEdit)

  useEffect(() => {
    if (allowEdition) {
      scrollToTopFullscreenModal()
    }
  }, [allowEdition])

  useEffect(() => {
    resetForm()
  }, [serviceEdit, form])

  const toggleAllowEdition = () => setAllowEdition(!allowEdition)

  const onCancelEdition = () => {
    resetForm()
    setAllowEdition(false)
  }

  const resetForm = () => {
    if (serviceEdit) {
      form.setFieldsValue(serviceEdit)
    }
  }

  const getInitialValues = () => {
    const { name_en, name_es, description } = serviceEdit ?? {}

    return {
      name_en,
      name_es,
      description,
    }
  }

  const initialValues = getInitialValues()

  return (
    <Form onFinish={onSubmit} form={form} initialValues={initialValues}>
      <Row>
        <Col xs={24}>
          <Row gutter={[12, 12]}>
            <Divider>{t('table.row.name')} (en)</Divider>
            <Col xs={24}>
              <Form.Item
                name="name_en"
                rules={[
                  {
                    required: true,
                    message: t('services.form.required.name'),
                  },
                ]}
              >
                <Input type="text" disabled={!allowEdition} />
              </Form.Item>
            </Col>
            <Divider>{t('table.row.name')} (es)</Divider>
            <Col xs={24}>
              <Form.Item
                name="name_es"
                rules={[
                  {
                    required: true,
                    message: t('services.form.required.name'),
                  },
                ]}
              >
                <Input type="text" disabled={!allowEdition} />
              </Form.Item>
            </Col>
            <Divider>{t('services.form.div.description')}</Divider>
            <Col xs={24}>
              <Form.Item name="description">
                <TextArea
                  maxLength={DESCRIPTION_TEX_AREA.max}
                  style={{ height: DESCRIPTION_TEX_AREA.height }}
                  disabled={!allowEdition}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <FormFooter
            allowEdition={allowEdition}
            loading={loading}
            toggleAllowEdition={toggleAllowEdition}
            onCancelEdition={onCancelEdition}
            dataForm={serviceEdit}
          />
        </Col>
      </Row>
    </Form>
  )
}
