import { Form, Row, Col } from 'antd'
import PriceInput from 'components/molecules/PriceInput'
import useYachtsPage from 'hooks/useYachtsPageContext'
import { useTranslation } from 'react-i18next'
import { STYLE_INPUT_NUMBER } from './YachtForm'

type Props = {
  allowEdition: boolean
}

export const YachtPricesInputs = ({ allowEdition }: Props) => {
  const { t } = useTranslation()
  const { memberships } = useYachtsPage()

  return (
    <Row gutter={[12, 12]}>
      <Col lg={12}>
        <Form.Item
          label={t('yachts.form.public_price')}
          name="public_price"
          rules={[
            {
              required: true,
              message: t('yachts.form.required.price'),
            },
          ]}
        >
          <PriceInput
            disabled={!allowEdition}
            min={1}
            style={STYLE_INPUT_NUMBER}
          />
        </Form.Item>
      </Col>
      <Col lg={12}>
        {memberships.map(({ id, name }) => (
          <Form.Item
            key={id}
            label={name}
            name={name}
            rules={[
              {
                required: true,
                message: t('yachts.form.required.price'),
              },
            ]}
          >
            <PriceInput
              disabled={!allowEdition}
              min={1}
              style={STYLE_INPUT_NUMBER}
            />
          </Form.Item>
        ))}
      </Col>
    </Row>
  )
}
