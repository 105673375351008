import { useState, useEffect } from 'react'
import FullscreenModal from 'components/molecules/FullscreenModal'
import useBlockedDatesPageContext from 'hooks/useBlockedDatesPageContext'
import { BlockedDateForm, IBlockedDateFormValues } from './BlockedDateForm'
import moment from 'moment'

const EditBlockedDateForm = () => {
  const {
    onCloseEditBlockedDateForm,
    editBlockedDateFormState,
    onUpdateBlockedDate,
  } = useBlockedDatesPageContext()
  const [initialValues, setInitialValues] =
    useState<Partial<IBlockedDateFormValues>>(undefined)

  useEffect(() => {
    const blockedDate = editBlockedDateFormState.blockedDate
    const memberships_ids = blockedDate?.memberships.map(({ id }) => id)
    const yachts_ids = blockedDate?.yachts.map(({ id }) => id)
    const locations_ids = blockedDate?.locations.map(({ id }) => id)
    setInitialValues({
      date: moment(blockedDate?.date),
      memberships_ids,
      locations_ids,
      yachts_ids,
      description: blockedDate?.description,
      membership_free: blockedDate?.membership_free,
      whole_fleet: blockedDate?.whole_fleet,
    })
  }, [editBlockedDateFormState])

  const onSubmit = async (values: IBlockedDateFormValues) => {
    const valuesWithoutDate = {
      ...values,
      date: undefined,
    }
    await onUpdateBlockedDate(valuesWithoutDate)
  }

  return (
    <FullscreenModal
      visible={editBlockedDateFormState.isOpen}
      maskClosable={false}
      onCancel={onCloseEditBlockedDateForm}
      maxWidth="450px"
      destroyOnClose
      height="fit-content"
      loading={false}
    >
      <BlockedDateForm
        initialValues={initialValues}
        isEditionForm
        onSubmit={onSubmit}
      />
    </FullscreenModal>
  )
}

export { EditBlockedDateForm }
