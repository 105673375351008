import mixpanel from 'mixpanel-browser'

interface ITrackUserLogin {
  name: string
  email: string
  isAdmin: boolean
  resort: string
}

const AuthFlowEvents = () => {
  const userLoggedIn = ({ name, email, isAdmin, resort }: ITrackUserLogin) => {
    mixpanel.track('User Logged In', {
      Name: name,
      Email: email,
      IsAdmin: isAdmin,
      Resort: resort,
    })
  }

  const userLoggedOut = () => {
    mixpanel.track('User Logged Out')
  }

  return {
    userLoggedIn,
    userLoggedOut,
  }
}

export default AuthFlowEvents
